'use strict';
import config from '@/../config'
import { app } from '@/main.js'

const TrackingService = {
    getTrackingUrl(req) {

        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post('/integration/trackandtrace/getToken', req)
                .then(response => {
                    return req.truck 
                            ? resolve(app.config.globalProperties.$config.general.trackingUrl + '/#/trck?filters=' + response.data.access_token)
                            : resolve(app.config.globalProperties.$config.general.trackingUrl + '/#/ext?filters=' + response.data.access_token)
                }).catch(error => {
                    reject(error);
                });
        })
    }
}
export default TrackingService;