import { createStore } from 'vuex';
import commonClasses from './commonClasses';
const { orderStatus } = commonClasses;

import Order from './order'
import PaletReturn from './paletReturn'

let order = new Order()
let paletReturn = new PaletReturn()

function getValueFronSession(value, returnDefault) {
    if (sessionStorage.getItem(value) != null && sessionStorage.getItem(value) != 'undefined') {
        return JSON.parse(sessionStorage.getItem(value))
    }
    else
        return (returnDefault)
}
function getValueFronLocalStorage(vale, returnDefault) {
    if (localStorage.getItem(vale) != null && localStorage.getItem(vale) != 'undefined')
        return JSON.parse(localStorage.getItem(value))
    else
        return (returnDefault)
}

export default createStore({
    state: {
        Order: order,
        PaletReturn: paletReturn,
        ShiptoList: [],
        MaterialList: [],
        User: getValueFronSession('user', null),
        weigths: [],
        firstWeight: null,
        online: true,
        mode : 'Cloud',
        baseApi: '/api',
        baseEdge: '/apiedge',
        manualConnectionMode: false,
        tmsRefreshTimer: 30,
        orderCompleteResponse: {
            creditResult: true,
            OrderNo: 0,
            DeliveriesCount: 0,
            DeliveryId: '',
            credit: true,
            creditResponse: {
    
            }
        },
        vehicle: null,
        driver: null,
        vendor: null,
        trailer: null,
        environment: 'Production',
        clonedFrom: {
            OrderNo: null,
            deliveryNO: null
        },
        byTruck: null,
        deliveriesPlant: null
    },
    getters: {
        getConnectionMode(state) {
            return state.online
        },
        getConnectionManual(state) {
            return state.manualConnectionMode
        },
        getBaseApi(state) {
            return state.baseApi
        },
        getBaseEdge(state) {
            return state.baseEdge
        },
        getWiegths(state) {
            return state.weigths
        },
        contractMaterialList(state) {
            return state.MaterialList
        },
        orderMaterialList(state) {
            return state.Order.Materials
        },
        getUserLogin(state) {
            return state.User
        },
        isAuth(state) {
            if (state.User)
                return true
            else
                return false
        },
        getEnvironment(state) {
            return state.environment
        },
        getTmsTimer(state) {
            return state.tmsRefreshTimer
        },
        getVehicle(state) {
            return state.vehicle
        },
        getDriver(state) {
            return state.driver
        },
        getVendor(state) {
            return state.vendor
        },
        getTrailer(state) {
            return state.trailer
        },
        getShipToList(state) {
            return state.ShiptoList
        },
        getShipTo(state) {
            return state.Order.shipto
        },
        getOrder(state) {
            return state.Order
        },
        getOrderType(state) {
            return state.Order.orderType
        },
        getDeliveryId(state) {
            return state.orderCompleteResponse.DeliveryId
        },
        getOrderShipTo(state) {
            return state.Order.shipto
        },
        getOrderShipToContracts(state) {
            return state.Order.shipto.contracts
        },
        getIncoterm(state) {
            return state.Order.contract.incoterm
        },
        getFirstWeight(state) {
            return state.firstWeight
        },
        getNotes(state) {
            return state.Order.notes
        },
        getOrderClonedFrom(state) {
            return state.clonedFrom.OrderNo
        },
        getDeliveryClonedFrom(state) {
            return state.clonedFrom.deliveryNO
        },
        getOrderSoldto(state) {
            return state.Order.soldto
        },
        getOrderSalesArea(state) {
            return state.Order.salesArea
        },
        getIsTransferOrder(state) {
            return state.Order.isTransfer
        },
        getPreloadVehicle(state) {
            return state.byTruck
        },
        getVendorSubrange(state) {
            return state.vendorSubrange
        },
        getReloadMaterial(state){
            return state.reload
        },
        getCountryWasteGroups(state) {
            return state.User.country.country_parameters.find((p) => p.code == 'waste_groups') 
                ? (state.User.country.country_parameters.find((p) => p.code == 'waste_groups').value).split(",") 
                : [];
            // return true
        },
        getPlantBadgeEndpoint(state) {
            return state.User.plant.plant_parameters.find((p) => p.code == 'badge_endpoint_IP')
                ? state.User.plant.plant_parameters.find((p) => p.code == 'badge_endpoint_IP').value
                : null
        },
        getPlantWasteEnable(state) {
          return state.User.plant.plant_parameters.find((p) => p.code == 'waste_enabled')
              ? state.User.plant.plant_parameters.find((p) => p.code == 'waste_enabled').value
              : null
        },
        getUserCountryCode(state) {
            return state.User.country.code
        }
    },
    mutations: {
        setDeliveriesPlant(state, plant){
            state.deliveriesPlant = plant
        },
        printPaletOrder(state){
            state.PaletReturn.print()
        },
        addPaletOrderItem(state){
            state.PaletReturn.addNewRow()
            state.PaletReturn.print()
        },
        deletePaletReturnOrderItem(state, index){
            state.PaletReturn.deleteRow(index)
            state.PaletReturn.print()
        },
        setDefaultPaletReturnOrderItems(state){
            state.PaletReturn.setDefaultRows()
        },
        setPaletReturnOrderSoldTo(state, {index, selectedSoldTo}){
            state.PaletReturn.rows[index].setSoldTo(selectedSoldTo)
        },
        setPaletReturnOrderShipToOptions(state, { index, options}){
            state.PaletReturn.rows[index].options.shipTos = options
        },
        setPaletReturnOrderShipto(state, { index, selectedShipTo }){
            state.PaletReturn.rows[index].setShipTo(selectedShipTo)
        },
        setPaletReturnOrderReferenceOptions(state, {index, options}){
            state.PaletReturn.rows[index].options.references = options
        },
        setPaletReturnOrderContract(state, { index, selectedContract}){
            state.PaletReturn.rows[index].contract = selectedContract
        },
        setPaletReturnOrderMaterialOptions(state, { index, options}){
            state.PaletReturn.rows[index].options.materials = options
        },
        setPaletReturnOrderMaterial(state,{ index, selectedMaterial}){
            state.PaletReturn.rows[index].setMaterial(selectedMaterial)
        },
        setPaletReturnOrderReference(state, { index, reference}){
            state.PaletReturn.rows[index].reference = reference
        },
        setPaletReturnOrderInputValue(state, { index, name, value }){
            state.PaletReturn.rows[index][name] = value
        },
        togglePaletReturnOrderError(state, {index, error} ){
            state.PaletReturn.rows[index].errors[error] = true
        },
        //bef
        setConnectionMode(state) {
            state.manualConnectionMode = true
            state.online = !state.online
        },
        setOfflineMode(state) {
            state.online = false
        },
        setOnlineMode(state) {
            state.online = true
        },
        setTmsTimer(state, timer) {
            state.tmsRefreshTimer = timer
        },
        setMode(state, mode) {
            state.mode = mode
        },
        addWeigth(state, weigth) {
            state.weigths = state.weigths.filter(w => w.bascula != weigth.bascula)
            state.weigths.push(weigth)
        },
        setLogOut(state) {
            sessionStorage.removeItem("user")
            state.User = null
        },
        setUserLogin(state, user) {
            sessionStorage.setItem("user", user)
            state.User = user
        },
        setOrderSoldto(state, customer) {
            state.Order.soldto = customer
        },
        setSoldtoAsBlocked(state, isBlocked) {
            state.Order.soldto.isBlocked = isBlocked
        },
        setOrderSalesArea(state, salesArea) {
            // console.log("LLEGO aqui ==> ", salesArea)
            state.Order.salesArea = salesArea
        },
        setShiptos(state, list) {
            //state.ShiptoList = list.map(s => ({name : s.address.name , value: s.shipto}))
            // console.log("llego aqui ", list)
            list = list.map(l => ({ ...l, fullSearch: l.address.name + " " + l.address.city + " " + l.contracts[0].contract }))
            // console.log("llego aqui ", list)
            state.ShiptoList = list
        },
        setShipto(state, shipto) {
            state.Order.shipto = shipto
            //state.Order.contract.contract = shipto.contract
            //state.Order.incoterm = shipto.incoterm
        },
        setShiptoAsBlocked(state, isBlocked) {
            state.Order.shipto.isBlocked = isBlocked
        },
        setIncoterm(state, incoterm) {
            state.Order.incoterm = incoterm
        },
        setOrderPriority(state, orderPriority) {
            state.Order.selectedPriority = orderPriority
        },
        setOrderLogisticsOrganization(state, orderLO) {
            state.Order.logisticsOrganization = orderLO;
        },
        setOrderStatus(state, status) {
            state.Order.orderStatus = status
            switch (status) {
                case 1:
                    state.Order.status = orderStatus.New
                    break;
                case 2:
                    state.Order.status = orderStatus.OnHold
                    break;
                case 3:
                    state.Order.status = orderStatus.Provisional
                    break;
                case 4:
                    state.Order.status = orderStatus.Draft
                    break;
                case 5:
                    state.Order.status = orderStatus.Blocked
                    break;
                default:
                    break;
                }
        },
        setReleasedOrderStatus(state, status) {
            state.Order.orderStatus = status
            state.Order.status = orderStatus.Released
        },
        setOrderStatusValue(state, status) {
            switch (status) {
                case orderStatus.New:
                case orderStatus.Released:
                    state.Order.orderStatus = 1
                    break;
                case orderStatus.OnHold:
                    state.Order.orderStatus = 2
                    break;
                case orderStatus.Provisional:
                    state.Order.orderStatus = 3
                    break;
                case orderStatus.Draft:
                    state.Order.orderStatus = 4
                    break;
                case orderStatus.Blocked:
                    state.Order.orderStatus = 5
                    break;
                default:
                    break;
                }
        },
        setOrderSource(state, orderSource) {
            state.Order.orderSource = orderSource
        },
        setOrderDate(state, orderDate) {
            state.Order.selectedDate = orderDate
        },
        setOrderExpirationDate(state, orderExpirationDate) {
            state.Order.orderExpirationDate = orderExpirationDate
        },
        setShipToBlocked(state, blocked) {
            state.Order.blockedShipTo = blocked
        },
        setOrderContractExpired(state, isContractExpired) {
            state.Order.isContractExpired = isContractExpired
        },
        setOrderTime(state, orderTime) {
            state.Order.selectedTime = orderTime
        },
        setDeliveryType(state, deliveryType) {
            state.Order.deliveryType = deliveryType
        },
        setMaterialList(state, materials) {
            state.MaterialList = materials
        },
        setMaterial(state, materials) {
            state.Order.Materials = materials
        },
        setNotes(state, notes) {
            state.Order.notes = notes
        },
        setDeliveryNoteInstructions(state, notes) {
            state.Order.notes.DeliveryNoteInstructions = notes
        },
        setDispatcherInstructions(state, notes) {
            state.Order.notes.DispatcherInstructions = notes
        },
        setOrderInstructions(state, notes) {
            state.Order.notes.OrderInstructions = notes
        },
        setInvioceInstructions(state, notes) {
            state.Order.notes.InvioceInstructions = notes
        },
        setContract(state, contract) {
            if (contract.customerPONumber && !contract.isEdit) {
                state.Order.PONumber = contract.customerPONumber
            }
            delete contract.isEdit
            state.Order.contract = contract
        },
        setFrameContract(state, contract) {
            state.Order.frameContract = contract
        },
        setOrder(state, order) {
            state.Order = new Order()
            state.Order = order._source
            state.Order._id = order._id
        },
        resetOrder(state) {
            state.Order = new Order()
        },
        setOrderVendor(state, vendor) {
            state.Order.vendor = vendor
        },
        setOrderVendorLocation(state, location){
            state.Order.vendor.location = location
        },
        setOrderSTPO(state, STPO) {
            state.Order.STPO = STPO
        },
        setOrderPurchaseOrg(state, PurchaseOrg) {
            state.Order.purchaseOrg = PurchaseOrg
        },
        setOrderType(state, orderType) {
            state.Order.orderType = orderType
        },
        setOrderClonedFrom(state, orderNo) {
            state.clonedFrom.OrderNo = orderNo
        },
        setDeliveryClonedFrom(state, deliveryNO) {
            state.clonedFrom.deliveryNO = deliveryNO
        },
        setOrderPONumber(state, PONumber) {
            state.Order.PONumber = PONumber
        },
        addMaterial(state, material) {
            let index = state.Order.Materials.findIndex(m => m.material.id == material.material.id && m.orderItem == material.orderItem);
            console.log(index);
            if(index === -1) {
                state.Order.Materials.push(material)
            } else {
                state.Order.Materials[index] = material
            }
        },
        setMaterialDeliveriesGeneration(state, material) {
            let index = state.Order.Materials.findIndex(m => m.material.id == material.material.id && m.orderItem == material.orderItem);
            if (index > -1) { 
                state.Order.Materials[index].generateDeliveries = true; 
            }
        },
        deleteMaterial(state, material) {
            let index = state.Order.Materials.findIndex(m => m.material.id == material.materialID && m.orderItem == material.orderItem)
            console.log('Materiales ===== ', state.Order.Materials, index, material);
            if (index > -1) { // only splice array when item is found
                state.Order.Materials.splice(index, 1); // 2nd parameter means remove one item only
            }
            console.log('Materiales ===== ', state.Order.Materials, index);
        },
        deleteMaterials(state) {
            state.Order.Materials = [];
        },
        setRecomendedQuantity(state, quantity) {
            state.Order.Materials[0].baseUoMquantity = quantity
            state.Order.Materials[0].quantity = quantity
            state.Order.Materials[0].userQuantity = quantity
        },
        deleteSoldto(state) {
            // console.log("LLEGO AL STORE")
            state.Order = new Order()
        },
        deleteTime(state) {
            state.Order.selectedDate = ''
            state.Order.selectedTime = ''
            state.Order.deliveryType = ''
        },
        deleteShipto(state) {
            state.Order.Materials = []
            state.Order.contract = {}
            state.Order.shipto = {
                shipto : null,
                address : {
                    city : '',
                    country : '',
                    name : '',
                    street : '',
                    transportationZone : null
                }
            }
        },
        deleteSTPO(state) {
            state.Order.Materials = []
            state.Order.STPO = {}
        },
        resetClonedFrom(state) {
            state.clonedFrom = {
                OrderNo: null,
                deliveryNO: null
            }
        },
        selectTerminal(state, terminal) {
            state.User.terminal = terminal;
        },
        setSoldtoOTCInfo(state, data) {
            state.Order.soldto = { 
                ...state.Order.soldto,
                city: data.city,
                country: data.country,
                name: data.name,
                name1: data.name,
                postalcode: data.pcode,
                street: data.street,
                taxNumber4: data.vat,
                region: data.region || '',
                isOTC: true
            }
        },
        setShiptoOTCInfo(state, data) {
            state.Order.shipto.address.city = data.city;
            state.Order.shipto.address.country = data.country;
            state.Order.shipto.address.name = data.name;
            state.Order.shipto.address.street = data.street;
            state.Order.shipto.postalcode = data.pcode;
            state.Order.shipto = { 
                ...state.Order.shipto,
                shipto: data.shipto,
                contracts: data.contracts,
                fullSearch: data.name + ' ' + data.city,
                isOTC: true
            }
        },
        setShiptoAddress(state, data) {
            state.Order.shipto.address.city = data.city;
            state.Order.shipto.address.address = data.address;
            state.Order.shipto.address.name = data.name;
            state.Order.shipto.address.district = data.district;
            state.Order.shipto.address.postal_code = data.postal_code; 
            state.Order.shipto.address.transportationZone = data.transportationZone;
            state.Order.shipto.address.street = data.street;
            state.Order.shipto.address.region = data.region;
            state.Order.shipto.address.country = data.country;
            state.Order.shipto.address.contactName = data.contactName;
            state.Order.shipto.address.phone = data.phone; 
            state.Order.shipto.location = { lat: data.lat, lon: data.lon };
        },
        setShiptoParameters(state, parameters) {
            state.Order.shipto.parameters = parameters;
        },
        setShiptoAddresses(state, addresses) {
            state.Order.shipto.addresses = addresses;
        },
        setShiptoWorkingHours(state, workingHours) {
            state.Order.shipto.workingHours = workingHours;
        },
        setShiptoAttachments(state, attachments) {
            state.Order.shipto.attachments = attachments;
        },
        setPaymentInfo(state, data) {
            state.Order.payment = data;
            console.log(' state.Order.payment: ', state.Order.payment);
        },
        setCashInfo(state, data) {
            state.Order.cash = data;
            state.Order.cash.isCash = true
            console.log(' state.Order.cash: ', state.Order.cash);
        },
        setIsTransferOrder(state, data) {
            state.Order.isTransfer = data;
        },
        setOrderCreatedResponse(state, data) {
            console.log('setOrderCreatedResponse: ', data)
            state.orderCompleteResponse = data
        },
        setEnvironment(state, env) {
            state.environment = env
        },
        setVehicle(state, vehicle) {
            state.vehicle = vehicle
        },
        setDriver(state, driver) {
            state.driver = driver
        },
        setVendor(state, vendor) {
            state.vendor = vendor
        },
        setVendorSubrange(state, vendorSubrange) {
            state.vendorSubrange = vendorSubrange
        },
        setSelectedVendorSubrange(state, selected) {
            state.vendorSubrange.selected = selected
        },
        setTrailer(state, trailer) {
            state.trailer = trailer
        },
        setFirstWeight(state, weigth) {
            state.firstWeight = weigth
        },
        setPreloadVehicle(state, byTruck) {
            state.byTruck = byTruck
        },
        setBadge(state, badgeId) {
            state.Order.badge = badgeId
        },
        setReloadMaterials(state) {
            state.reload = true
        },
        deleteReloadMaterials(state) {
            delete state.reload
        }

    },
    actions: {

    },
})
