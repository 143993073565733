let numberMask = JSON.parse(localStorage.getItem("numberFormat")) || "de-DE";
export const weightCoversion = (weight, unitFrom, unitTo) => {
  let str = strCreation(weight, unitFrom, unitTo);
  return str;
};

const strCreation = (weight, unitFrom, unitTo) => {
  if (unitFrom === "KGM") {
    return returnKGMConversion(weight, unitTo);
  } else if (unitFrom === "T") {
    return returnTONConversion(weight, unitTo);
  } else {
    return Number(weight).toLocaleString(numberMask, { minimumFractionDigits: 2 }) + ` ${unitFrom}`
  }
};

const returnKGMConversion = (weight, unitTo) => {
  let str = "";
  if (unitTo === "T") {
    let weightAux = Number(weight) / 1000;
    str = weightAux.toFixed(2).toLocaleString(numberMask) + " TNE";
  } else {
    str = Number(weight).toLocaleString(numberMask) + " KGM";
  }

  return str;
};

const returnTONConversion = (weight, unitTo) => {
  let str = "";
  if (unitTo === "KGM") {
    let weightAux = Number(weight) * 1000;
    str =
      weightAux.toLocaleString(numberMask, { minimumFractionDigits: 2 }) +
      " KGM";
  } else {
    str =
      Number(weight).toLocaleString(numberMask, { minimumFractionDigits: 2 }) +
      " TNE";
  }

  return str;
};

export const getUnitISOCode = (e1Code, lovsArray) => {
  if (lovsArray) {
    let code = lovsArray.find((code) => code.name == e1Code);
    return code ? code.value : e1Code;
  }
  return !e1Code ? 'TN' : e1Code;
};

export const transformToUnit = (weight, unitFrom = '', unitTo = '') => {
  if ((unitFrom === 'TON' || unitFrom === 'TNE') && unitTo === "KGM") {
    return Number(weight) * 1000;
  } else if (unitFrom === 'KGM' && (unitTo === 'TON' || unitTo === 'TNE')) {
    let result = Number(weight) / 1000;
    return Number(result.toFixed(2));
  }else{
    return Number(weight);
  }
}
