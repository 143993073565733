<script setup>

import ContextMenu from 'primevue/contextmenu';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Calendar from 'primevue/calendar';
import MultiSelect from 'primevue/multiselect';
import Tooltip from 'primevue/tooltip';
import moment from 'moment';
import _ from 'lodash';
// import ColumnGroup from 'primevue/columngroup';     //optional for column grouping
// import Row from 'primevue/row';

//import DeliveryModalVue from '@/components/Deliveries/data-table/DeliveryModal.vue';
//import OrderModal from '@/components/Deliveries/data-table/OrderModal.vue';
import SAPModal from '@/components/Deliveries/data-table/SAPModal.vue';
import HistoryLog from '@/components/Deliveries/data-table/HistoryLog.vue';
import Status from '@/components/common/Status.vue';
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import deliveryService from "@/services/api/delivery.js";
import deliveryNoteService from "@/services/api/deliveryNote.js";
import orderService from "@/services/api/order.js";
import TrackingService from "@/services/api/tracking";
import Dialog from 'primevue/dialog';
import Dropdown from 'primevue/dropdown';
import InputText from 'primevue/inputtext';
import * as WeightUnit from '@/helper/weightUnit';
import { materialDescription } from '@/helper/textMaterials';
import { getETA } from '@/components/Deliveries/planner/core/tour';
import { getMomentInTimezone, getTimeZone } from '@/helper/rocks-time';
import commonClasses from "@/store/commonClasses.js";
import plantsService from '@/services/api/plants.js';

import HandWritingIcon from '@/components/Deliveries/retroticket/icons/HandWritingIcon.vue';
</script>

<script>
export default {
  components: {
    Dropdown,
    InputText
  },
  directives: {
    tooltip: Tooltip
  },
  data: function () {
    return {
      rows: 10,
      allFiltersVisible: false,
      Deliveries: [],
      DeliveriesAux: [],
      ordersList: [],
      deliveriesList: [],
      tabLinks: this.$store.getters.getUserCountryCode === 'FR' ? ["Deliveries"] : ["Orders", "Deliveries"],
      menuModel: [
        //{ label: 'Clone Delivery', command: (e) => this.onContextMenuSelection(e) },
        //{ label: 'Edit Delivery', command: (e) => this.onContextMenuSelection(e) },
        { label: 'Edit/View Delivery Detail', command: (e) => this.onContextMenuSelection(e) },
        { label: 'Clone Order', command: (e) => this.onContextMenuSelection(e) },
        { label: 'Download ePOD', command: (e) => this.onContextMenuSelection(e) },
        { label: 'Mark Delivery as Signed', command: (e) => this.onContextMenuSelection(e) },
        { label: 'Paper Ticket', command: (e) => this.onContextMenuSelection(e) },
        { label: 'Delivery History', command: (e) => this.onContextMenuSelection(e) },
        { label: 'Retro ticket', command: (e) => this.onContextMenuSelection(e) },
      ],
      expandedRows: [],
      filters: null,
      filtersValues: [],
      currentDelivery: null,
      dataDelivery: { deliveryNo: '', OrderNo: '', deliveryNoteNumber: '' },
      keyRender: 0,
      currentOrder: null,
      currentOrderDeliveries: [],
      currentDate: new Date(),
      plantsCombo: [],
      userPlant: null,
      orderType: [],
      user: null,
      value: null,
      status: null,
      selectedDelivery: null,
      param: this.$route.query.id || null,
      calendarFormat: JSON.parse(localStorage.getItem("dateFormat")) ? JSON.parse(localStorage.getItem("dateFormat")).calendar : 'dd/mm/yy',
      displayMomentFormat: JSON.parse(localStorage.getItem("dateFormat")) ? JSON.parse(localStorage.getItem("dateFormat")).moment : 'DD/MM/YYYY',
      displayDateTimeFormat: JSON.parse(localStorage.getItem("dateFormat")) ? JSON.parse(localStorage.getItem("dateFormat")).moment + " HH:mm" : 'DD/MM/YYYY HH:mm',
      numberMask: JSON.parse(localStorage.getItem("numberFormat")) || "de-DE",
      //<InputNumber v-model="userQuantity" mode="decimal" :locale="numberMask" :minFractionDigits="0" readonly style="border : none !important; width: 130px; />
      isBlocked: false,
      displayDeliver: false,
      displaySAPInfo: false,
      displayE1Info: false,
      SAPData: null,
      E1Data: null,
      plantsSelected: [],
      truckSelected: '',
      carrierSelected: '',
      soldToSelected: '',
      shipToSelected: '',
      poNumberSelected: '',
      creatorSelected: '',
      deliveryTypeSelected: '',
      materialTypeSelected: '',
      deliveryNoSelected: '',
      contractNumberSelected: '',
      contractNumberSelected: '',
      lastSearch: '',
      lastSearchTime: null,
      hasDeliveryDataChanged: false,
      showUnsavedChangesWarning: false,
      showPastDateAlert: false,
      currentDeliveryId: null,
      alertNotFromTodayStatus: ['Scheduled', 'Loading'],
      displayDeliveryLog : false,
      // locale: JSON.parse(localStorage.getItem('languageSelected')).locale
      locale: this.$locale,
      materialTypeExclusion: ['ZSRV', 'MIS', 'ZCON', 'MER', 'ZPAC', 'BAG'],
      countryCode: null,
      userCountry_id: JSON.parse(sessionStorage.getItem("user")).country.id_country
    }
  },
  beforeMount() {

    this.$store.commit("setDeliveriesPlant", JSON.parse(localStorage.getItem("userPlant")))

    this.keyRender += 1;
    this.$store.getters.getPlantWasteEnable === '1' ? '': ''
  },
  async created() {
    if (this.param !== null) {
      //console.log('this.$refs', this.$refs);
      this.showNewModal(this.param)
      //this.showModalFT(this.param)
    }
    this.currentDate = moment().format("yyyy-MM-DD");
    this.user = JSON.parse(sessionStorage.getItem("user"));
    this.countryCode = this.user.country.code

    this.userPlant = JSON.parse(sessionStorage.getItem("user")).plant.code;
    let sessionPlants = JSON.parse(sessionStorage.getItem("user")).plants;
    let plantsFromCountry = await plantsService.getPlantsByCountry(this.userCountry_id);
    let plants = plantsFromCountry.data.filter(x => sessionPlants.some(y => y.id === x.id));
    let clusters = [...new Set(plants.map(item => item.cluster))];
    let externalPlants = sessionPlants.filter(x => x.id == 0);
    for (let cluster of clusters) {
      let clusterData = { cluster: cluster, items: [] };
      for (let plant of plants) {
        if (plant.cluster == cluster) { clusterData.items.push({ id: plant.code, value: plant.code }) }
      }
      this.plantsCombo.push(clusterData);
    }

    this.plantsCombo.push({
      cluster: 'External Plants',
      items: externalPlants.map((ep => ({id: ep.code, value: ep.code})))
    });

    this.initFilter();
    this.initFiltersValues();
    this.initSearchValues();

    this.getDeliveriesBySavedFilters();
    if (this.param !== null) { this.showNewModal(this.param) }

    this.filterDeliveries({target: undefined})
  },
  methods: {
    goTo(path) {
      if (path) this.$router.push(path);
    },
    handleGoToDispatching(delivery){
      if(delivery._source.orderType == "TRAD"){
        this.goTo(`/dispatch?id=${delivery._id}`)
      }
      else {
        this.showNewModal(delivery._id, delivery._source.deliveryLocalDate, delivery._source.status)
      }
      
    },
    onTabClick(e) {
      switch(e.detail) {
        case 'Dispatch':
          this.$router.push('/dispatch')
          break;
        case 'Orders':
          this.$router.push('/orders-deliveries?id=od')
          break;
        case 'Storage Movement':
          this.$router.push('/storage-movement')
          break;
      }
    },
    initFilter() {
      this.filters = {
        'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'delivery': { id: 'delivery', value: null, /*matchMode: FilterMatchMode.EQUALS*/ },
        'status': [
          { id: 'status', value: 'Scheduled' },
          { id: 'status', value: 'Loading' },
          { id: 'status', value: 'Ready' },
          { id: 'status', value: 'Shipping' },
          { id: 'status', value: 'Arrived' },
          { id: 'status', value: 'Finished' },
          { id: 'status', value: 'Deleted' },
          { id: 'status', value: 'Onhold' },
          { id: 'status', value: 'Returned' },
          { id: 'status', value: 'Blocked' },
        ],
        // 'source': { id: 'source', value: null }, de momento nada ??¿?
        'plant': this.plantsCombo,
        // 'type': [{ id: 'type', value: null }],
        'truck': [{ id: 'truck', value: null }],
        'carrier': [{ id: 'carrier', value: null }],
        'materialType': [{ id: 'materialType', value: null }],
        'deliveryType': [
          { id: 'deliveryType', name: 'All', value: 'All' },
          { id: 'deliveryType', name: 'Delivered', value: commonClasses.deliveryType.Delivered },
          { id: 'deliveryType', name: 'Ex-Works', value: commonClasses.deliveryType.PickUp },
        ],
        'orderType': Object.entries(commonClasses.orderTypes).map(([key, value]) => ({ id: 'orderType', name: key, value }))
      }
    },
    // This method loads the filters that the user used the last time
    initFiltersValues() {
      const lastUsedFilters = JSON.parse(localStorage.getItem('deliveriesFilterPreferences')).filter(x=> x.id !== "status");
      this.filtersValues = lastUsedFilters
        ? lastUsedFilters
        : [{ id: "status", value: "Scheduled" }, { id: "plant", value: this.userPlant }, { id: "date", value: this.currentDate }];

      // The saved filter values are passed to the deliveries filter inputs/dropdowns
      const lastDateFilter = this.filtersValues.find(filter => filter.id === 'date');
      // The structure is different if the user chooses one date, or a range of dates
      if (lastDateFilter && lastDateFilter.value) {
        this.value = [new Date(), null];
      } else if (lastDateFilter && lastDateFilter.dateFrom) {
        this.value = [new Date(), null];
      } else {
        this.value = null;
      }
      const lastdeliveryTypeSelected = this.filtersValues.find(filter => filter.id === 'deliveryType');
      this.deliveryTypeSelected = lastdeliveryTypeSelected ? { id: 'deliveryType', name: lastdeliveryTypeSelected.name, value: lastdeliveryTypeSelected.value } : null;
      this.status = [{ id: "status", value:"Scheduled"}]//this.filtersValues.filter(filter => filter.id === 'status');
      /* The saved values are passed to the planstSelected variable only if they exist in the user's plantsCombo (they may not exist
      if the user has changed their country) */

      let plantsFiltered = this.filtersValues.filter(filter => filter.id === 'plant');
      let plantsSelectedFiltered = [];
      for (let element of this.plantsCombo) {
        plantsSelectedFiltered = [...plantsSelectedFiltered, ...element.items.filter(plant => plantsFiltered.some(filterPlant => filterPlant.value == plant.id))]
      }
      this.plantsSelected = plantsSelectedFiltered;

      this.orderType = this.filtersValues.filter(filter => filter.id === 'orderType');

      const lastPoNumberFilter = this.filtersValues.find(filter => filter.id === 'PONumber');
      this.poNumberSelected = lastPoNumberFilter ? lastPoNumberFilter.value : '';
      const lastShipToFilter = this.filtersValues.find(filter => filter.id === 'shipto');
      this.shipToSelected = lastShipToFilter ? lastShipToFilter.value : '';
      const lastSoldToFilter = this.filtersValues.find(filter => filter.id === 'soldto');
      this.soldToSelected = lastSoldToFilter ? lastSoldToFilter.value : '';
      const lastCreatorFilter = this.filtersValues.find(filter => filter.id === 'creator');
      this.creatorSelected = lastCreatorFilter ? lastCreatorFilter.value : '';
    },
    clusterFilterClick(element) {
      let clusterPlants = this.plantsCombo.filter(x => x.cluster == element);
      if(clusterPlants[0].items.some(item => this.plantsSelected.includes(item))) {
        this.plantsSelected = this.plantsSelected.filter((p) => !clusterPlants[0].items.includes(p));
      }
      else{
        this.plantsSelected.push(...clusterPlants[0].items);
      }
      this.filterDeliveries({target: undefined});
    },
    // This method loads the search values that the user used the last time
    initSearchValues() {
      const userSearchPreferences = JSON.parse(localStorage.getItem('deliveriesSearchPreferences')) || [];
      const lastSearchDelivery = userSearchPreferences.find(filter => filter.field === 'deliveryNO');
      this.deliveryNoSelected = lastSearchDelivery ? lastSearchDelivery.value : '';
      const lastSearchContract = userSearchPreferences.find(filter => filter.field === 'contract');
      this.contractNumberSelected = lastSearchContract ? lastSearchContract.value : '';
      const lastGlobalSearch = userSearchPreferences.find(filter => filter.field === 'globalSearch');
      this.lastSearch = lastGlobalSearch ? lastGlobalSearch.value : '';
    },
    /* This method checks if the filters last used by the user that are loaded dynamically with the data returned
    by an api call still have those last used values or not */
    checkDynamicFilters() {
      const lastTruckSelected = this.filtersValues.find(filter => filter.id === 'truck');
      if (lastTruckSelected && this.filters['truck'].some(f => f.value === lastTruckSelected.value)) {
        this.truckSelected = { id: lastTruckSelected.value, value: lastTruckSelected.value };
      } else if (lastTruckSelected) {
        // If the value doesn't exist in the filters list populated by the api call, the value is removed from filtersValues
        this.filtersValues.splice(this.filtersValues.findIndex(filter => filter.id === 'truck'), 1);
      }
      const lastCarrierSelected = this.filtersValues.find(filter => filter.id === 'carrier');
      if (lastCarrierSelected && this.filters['carrier'].some(f => f.value === lastCarrierSelected.value)) {
        this.carrierSelected = { id: lastCarrierSelected.value, value: lastCarrierSelected.value };
      } else if (lastCarrierSelected) {
        this.filtersValues.splice(this.filtersValues.findIndex(filter => filter.id === 'carrier'), 1);
      }
      const lastmaterialTypeSelected = this.filtersValues.find(filter => filter.id === 'materialType');
      if (lastmaterialTypeSelected && this.filters['materialType'].some(f => f.value === lastmaterialTypeSelected.value)) {
        this.materialTypeSelected = { id: lastmaterialTypeSelected.value, value: lastmaterialTypeSelected.value };
      } else if (lastmaterialTypeSelected) {
        this.filtersValues.splice(this.filtersValues.findIndex(filter => filter.id === 'materialType'), 1);
      }
      this.changeSavedFilters(this.filtersValues);
    },
    changeSavedFilters(filtersValues) {
      localStorage.setItem('deliveriesFilterPreferences', JSON.stringify(filtersValues));
    },
    changeSavedSearchFilters(filters) {
      localStorage.setItem('deliveriesSearchPreferences', JSON.stringify(filters));
    },
    resetFilters() {
      this.filtersValues = [{ id: "status", value: "Scheduled" }, { id: "plant", value: this.userPlant }, { id: "date", value: this.currentDate }];
      this.changeSavedFilters(this.filtersValues);
      localStorage.removeItem('deliveriesSearchPreferences');
      this.deliveryTypeSelected = null;
      this.status = [{ id: "status", value:"Scheduled"}]//this.filtersValues.filter(filter => filter.id === 'status');
      this.plantsSelected = [];
      this.value = [new Date(this.currentDate), null];
      this.truckSelected = null;
      this.carrierSelected = null;
      this.materialTypeSelected = null;
      this.poNumberSelected = '';
      this.shipToSelected = '';
      this.soldToSelected = '';
      this.creatorSelected = '';
      this.deliveryNoSelected = '';
      this.contractNumberSelected = '';
      this.lastSearch = '';
      this.getDeliveriesFiltered(this.filtersValues);
    },
    resetDirectSearchFilters() {
      const userSearchPreferences = JSON.parse(localStorage.getItem('deliveriesSearchPreferences')) || [];
      const newPreferences = userSearchPreferences.filter(filter => filter.field === 'globalSearch');
      this.changeSavedSearchFilters(newPreferences);
      this.deliveryNoSelected = '';
      this.contractNumberSelected = '';
    },
    onContextMenu(e, props) {
      this.selectedDelivery = props.data;
      this.$refs.cm.show(e.originalEvent || e);
    },
    onContextMenuSelection(e) {
      if (e.item.label === "View Delivery Detail") {
        this.showModal(this.selectedDelivery._id);
      } else if (e.item.label === "Clone Order") {
        this.cloneOrder(this.selectedDelivery)
      } else if (e.item.label === "Edit/View Delivery Detail") {
        //console.log('edit Delivery: ', this.selectedDelivery._id);
        this.showNewModal(this.selectedDelivery._id);
      } else if (e.item.label === "Duplicate Delivery") {
        //console.log('Duplicate Delivery: ', this.selectedDelivery._id);
      } else if (e.item.label === "Download ePOD") {
        this.getDeliveryNote(this.selectedDelivery)
      } else if (e.item.label === "Mark Delivery as Signed") {
        deliveryService.markAsSignedDelivery(this.selectedDelivery._id, this.selectedDelivery._source.status).then((response) => {
          this.$notify({
            title: this.$t("Delivery marked as signed"),
            type: 'success',
          });
          this.getDeliveriesFiltered(this.filtersValues);
        })
      } else if (e.item.label === "Paper Ticket") {
        deliveryService.markAsPaperTicketDelivery(this.selectedDelivery._id).then((response) => {
          this.$notify({
            title: this.$t("Delivery marked as Paper Ticket"),
            type: 'success',
          });
          this.getDeliveriesFiltered(this.filtersValues);
        });
      } else if (e.item.label === "Delivery History") {
        console.log('Delivery History: ', this.selectedDelivery._id);
        this.displayDeliveryLog = true;
      } else if (e.item.label === "Retro ticket") {
        deliveryService.retroTicket(this.selectedDelivery._id, 'Deleted').then((response) => {
          this.$notify({
            title: this.$t("Delivery sent for retro ticket"),
            type: 'success',
          });
          this.getDeliveriesFiltered(this.filtersValues);
        })
      }
    },
    getDeliveryNote(delivery) {
      deliveryNoteService.getDeliveryNote(delivery).then((response) => {
        let data = { pdfBase64: response.data, fileName: delivery._source.document.s3.fileName }
        this.downloadPDF(data)
      })
    },
    downloadPDF(data) {
      //console.log('data: ', data);
      const url = `data:application/pdf;base64,${data.pdfBase64}`;
      const a = document.createElement('a');
      a.download = data.fileName ? data.fileName : `${this.currentDelivery._source.deliveryNO}.pdf`;
      a.href = url;
      a.target = '_self';

      a.click();
      setTimeout(function () {
        a.remove();
        URL.revokeObjectURL(url);
      }, 100);
    },
    getDeliveries(plant) {
      deliveryService.getDeliveries(plant).then((response) => {
        this.Deliveries = response.data.data;
        this.DeliveriesAux = this.Deliveries;
        this.filters['truck'] = response.data.filters != undefined ? response.data.filters.truck : 'All';
        this.filters['carrier'] = response.data.filters != undefined ? response.data.filters.carrier : 'All';
        this.filters['materialType'] = response.data.filters != undefined ? response.data.filters.materialType : 'All';
      })
    },
    async getDeliveriesFiltered(filters, searchTime = null) {
      const response = await deliveryService.getDeliveriesFilteredSmall(filters);
      // The last search date is checked to avoid rewriting the data if the user is currently typing in the general search input
      if (!searchTime || searchTime === this.lastSearchTime) {
        this.Deliveries = response.data.data;
        this.DeliveriesAux = this.Deliveries;
        //console.log('THIS FILTERS -->', response.data.filters)
        this.filters['truck'] = response.data.filters != undefined ? response.data.filters.truck : [{ id: 'truck', value: 'All' }];
        this.filters['carrier'] = response.data.filters != undefined ? response.data.filters.carrier : [{ id: 'carrier', value: 'All' }];
        this.filters['materialType'] = response.data.filters != undefined ? response.data.filters.materialType : [{ id: 'materialType', value: 'All' }];
        this.checkDynamicFilters();
      }
    },
    getDeliveriesList() {
      this.deliveriesList = [];
      this.Deliveries.forEach((d) => {
        this.deliveriesList.push(d._id)
      })
    },
    /* This method chooses which filtering method is used depending on the filters used by the user because not all filter inputs
    call the same method */
    async getDeliveriesBySavedFilters() {
      const userSearchPreferences = JSON.parse(localStorage.getItem('deliveriesSearchPreferences')) || [];
      if (this.deliveryNoSelected) {
        const lastSearch = userSearchPreferences.find(filter => filter.field === 'deliveryNO');
        await this.searchDelivery({
          target: {
            id: lastSearch.field,
            value: lastSearch.value
          }
        });
      } else if (this.contractNumberSelected) {
        const lastSearch = userSearchPreferences.find(filter => filter.field === 'contract');
        await this.searchDelivery({
          target: {
            id: lastSearch.field,
            value: lastSearch.value
          }
        });
      } else {
        await this.getDeliveriesFiltered(this.filtersValues);
      }
      if (this.lastSearch) {
        const lastSearch = userSearchPreferences.find(filter => filter.field === 'globalSearch');
        this.searchTable({
          target: {
            id: lastSearch.field,
            value: lastSearch.value
          }
        });
      }
    },
    showModalFT(id) {
      deliveryService.getDelivery(id).then((response) => {
        this.currentDelivery = response.data[0];
        this.dataDelivery = { deliveryNo: this.currentDelivery._source.deliveryNO, OrderNo: this.currentDelivery._source.OrderNo, OrderE1: this.currentDelivery._source.OrderE1, deliveryNoteNumber: this.currentDelivery._source.deliveryNoteNumber }
        setTimeout(() => { if (this.$refs && this.$refs.modalDelivery) this.$refs.modalDelivery.open = true; });
      })
    },
    showModal(id) {
      if (this.dataDelivery.deliveryNo !== '') {
        this.currentDelivery = null;
      }
      this.getDeliveriesList();
      deliveryService.getDelivery(id).then((response) => {
        this.currentDelivery = response.data[0];
        this.dataDelivery = { deliveryNo: this.currentDelivery._source.deliveryNO, OrderNo: this.currentDelivery._source.OrderNo, OrderE1: this.currentDelivery._source.OrderE1, deliveryNoteNumber: this.currentDelivery._source.deliveryNoteNumber }
        setTimeout(() => { if (this.$refs && this.$refs.modalDelivery) this.$refs.modalDelivery.open = true; });
      })
    },
    showModalOrder(oN) {
      this.currentOrder = null;
      orderService.getOrderByON(oN).then((response) => {
        this.currentOrder = response.data[0];
        deliveryService.getOrderDeliveries(response.data[0]._source.OrderNo).then((response) => {
          this.currentOrderDeliveries = response.data;
        })
        setTimeout(() => { this.$refs.modalOrder.open = true; });
      })
    },
    searchTable(e) {
      // Exact datetime of the search to control if the user's still typing in the search input
      const currentsearchTime = Date.now();
      this.lastSearchTime = currentsearchTime;

      let searchTerm = e.target.value
      let searchField = e.target.id;
      if (searchTerm != null && searchTerm.length >= 3) {
        let auxDeliveries = this.DeliveriesAux;
        this.Deliveries = auxDeliveries.filter((o) => {
          return o._source.soldto && o._source.soldto.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            o._source.soldto && o._source.soldto.customer.toLowerCase().includes(searchTerm.toLowerCase()) ||
            o._source.shipto && o._source.shipto.address.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            o._source.shipto && o._source.shipto.address.transportationZone.toLowerCase().includes(searchTerm.toLowerCase()) ||
            o._source.shipto && o._source.shipto.shipto.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
            o._source.logistic.carrier && o._source.logistic.carrier.name
            && o._source.logistic.carrier.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            o._source.logistic.truck && o._source.logistic.truck.plateNumber
            && o._source.logistic.truck.plateNumber.toLowerCase().includes(searchTerm.toLowerCase()) ||
            o._source.deliveryNO && o._source.deliveryNO.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
            o._source.OrderNo && o._source.OrderNo.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
            o._source.OrderE1 && o._source.OrderE1.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
            o._source.cnn && o._source.cnn.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
            o._source.material && o._source.material.some(material =>
              material.description
                ? material.description.toLowerCase().includes(searchTerm.toLowerCase())
                : material.descriptions[0].text.toLowerCase().includes(searchTerm.toLowerCase())
            ) ||
            o._source.auditData && o._source.auditData.createdBy.toLowerCase().includes(searchTerm.toLowerCase());
        })
      }
      if (searchTerm.length < 1) {
        /* The datetime of the current search is pass to the function that calls the deliveries api
        so it can check if the user has continued typing because making a debounced search is not enough
        to avoid the response of the api rewriting the filtered list of deliveries */
        this.getDeliveriesFiltered(this.filtersValues, currentsearchTime);
      }
      const userSearchPreferences = JSON.parse(localStorage.getItem('deliveriesSearchPreferences')) || [];
      let position = userSearchPreferences.findIndex(filter => filter.field === searchField);
      if (position === -1) {
        userSearchPreferences.push({ field: searchField, value: searchTerm });
      } else {
        userSearchPreferences[position].value = searchTerm;
      }
      this.changeSavedSearchFilters(userSearchPreferences);
    },
    debouncedSearchTable: _.debounce(function (e) {
      this.searchTable(e);
    }, 200),
    filterDeliveries(e) {
      // Clear all direct text input filters
      this.resetDirectSearchFilters();

      //DATE FILTER
      let position = this.filtersValues.findIndex(x => x.id == 'date');
      if (e.target != undefined && e.target.id === 'date') {
        let dateFrom = moment(e.target.detail[0]).format("yyyy-MM-DD");
        let dateTo = '';
        if (e.target.detail.length > 1) {
          dateTo = moment(e.target.detail[1]).format("yyyy-MM-DD");
        }
        if (dateFrom != '' && dateTo != '') {
          this.filtersValues[position] = { id: 'date', dateFrom: dateFrom, dateTo: dateTo }
        } else {
          this.filtersValues[position].value = dateFrom;
        }
      }

      //STATUS FILTER
      position = this.filtersValues.findIndex(x => x.id == 'status');
      if (position == -1) {
        this.filtersValues.push(...this.status);
      } else {
        this.filtersValues = this.filtersValues.filter(x => x.id != 'status');
        this.filtersValues.push(...this.status);
      }

      //TRUCK FILTER
      if (this.truckSelected != undefined && this.truckSelected != '') {
        position = this.filtersValues.findIndex(x => x.id == 'truck');
        if (this.truckSelected.id == 'All' && position != -1) {
          this.filtersValues.splice(position, 1);
        } else if (this.truckSelected.id != 'All') {
          if (position == -1) {
            this.filtersValues.push({ id: 'truck', value: this.truckSelected.value });
          } else {
            this.filtersValues[position].value = this.truckSelected.value
          }
        }

      }

      //CARRIER FILTER
      if (this.carrierSelected != undefined && this.carrierSelected != '') {
        position = this.filtersValues.findIndex(x => x.id == 'carrier');
        if (this.carrierSelected.id == 'All' && position != -1) {
          this.filtersValues.splice(position, 1);
        } else if (this.carrierSelected.id != 'All') {
          if (position == -1) {
            this.filtersValues.push({ id: 'carrier', value: this.carrierSelected.value });
          } else {
            this.filtersValues[position].value = this.carrierSelected.value
          }
        }
      }

      //DELIVERY TYPE FILTER
      if (this.deliveryTypeSelected != undefined && this.deliveryTypeSelected != '') {
        position = this.filtersValues.findIndex(x => x.id == 'deliveryType');
        if (this.deliveryTypeSelected.value != 'All') {
          if (position == -1) {
            this.filtersValues.push({ id: 'deliveryType', name: this.deliveryTypeSelected.name, value: this.deliveryTypeSelected.value });
          } else {
            this.filtersValues[position].value = this.deliveryTypeSelected.value;
            this.filtersValues[position].name = this.deliveryTypeSelected.name;
          }
        } else {
          if (position != -1) {
            this.filtersValues.splice(position, 1);
          }
        }
      }

      //ORDER TYPE FILTER
      /*
      if (this.orderType != undefined && this.orderType != '') {
        position = this.filtersValues.findIndex(x => x.id == 'orderType');
        if (position == -1) { this.filtersValues.push(...this.orderType) }
        else {
          this.filtersValues = this.filtersValues.filter(x => x.id != 'orderType');
          this.filtersValues.push(...this.orderType);
        }
      } else { this.filtersValues = this.filtersValues.filter(x => x.id != 'orderType') }
      */
      this.filtersValues = this.filtersValues.filter(x=>x.id !== "orderType")
      this.filtersValues.push({ "id": "orderType", "name": "Trading", "value": "TRAD" })

      //MATERIAL TYPE FILTER
      if (this.materialTypeSelected) {
        position = this.filtersValues.findIndex(x => x.id == 'materialType');
        if (this.materialTypeSelected.id == 'All' && position != -1) {
          this.filtersValues.splice(position, 1);
        } else if (this.materialTypeSelected.id != 'All') {
          if (position == -1) {
            this.filtersValues.push({ id: 'materialType', value: this.materialTypeSelected.value });
          } else {
            this.filtersValues[position].value = this.materialTypeSelected.value
          }
        }
      }

      //PLANTS FILTER
      /*
      if (this.plantsSelected != undefined && this.plantsSelected != '') {
        position = this.filtersValues.findIndex(x => x.id == 'plant');
        let plantsSelectedFormated = this.plantsSelected.map(x => ({ id: 'plant', value: x.value }))
        if (position == -1) { this.filtersValues.push(...plantsSelectedFormated) }
        else {
          this.filtersValues = this.filtersValues.filter(x => x.id != 'plant');
          this.filtersValues.push(...plantsSelectedFormated);
        }
      } else { this.filtersValues = this.filtersValues.filter(x => x.id != 'plant') }
      */
      this.filtersValues.push({ id: 'plant', value: this.$store.state.deliveriesPlant.code})

      //SOLDTO, SHIPTO, PONUMBER
      position = this.filtersValues.findIndex(x => x.id == 'soldto');
      if (this.soldToSelected.length >= 3) {
        if (position >= 0) {
          this.filtersValues[position].value = this.soldToSelected;
        } else {
          this.filtersValues.push({ id: 'soldto', value: this.soldToSelected });
        }
      } else if (position != -1) {
        this.filtersValues.splice(position, 1)
      }

      //SHIPTO
      position = this.filtersValues.findIndex(x => x.id == 'shipto');
      if (this.shipToSelected.length >= 3) {
        if (position >= 0) {
          this.filtersValues[position].value = this.shipToSelected;
        } else {
          this.filtersValues.push({ id: 'shipto', value: this.shipToSelected });
        }
      } else if (position != -1) {
        this.filtersValues.splice(position, 1)
      }

      //PONUMBER
      position = this.filtersValues.findIndex(x => x.id == 'PONumber');
      if (this.poNumberSelected.length >= 3) {
        if (position >= 0) {
          this.filtersValues[position].value = this.poNumberSelected;
        } else {
          this.filtersValues.push({ id: 'PONumber', value: this.poNumberSelected });
        }
      } else if (position != -1) {
        this.filtersValues.splice(position, 1)
      }

      //CREATED BY
      position = this.filtersValues.findIndex(x => x.id == 'createdBy');
      if (this.creatorSelected.length >= 3) {
        if (position >= 0) {
          this.filtersValues[position].value = this.creatorSelected;
        } else {
          this.filtersValues.push({ id: 'createdBy', value: this.creatorSelected });
        }
      } else if (position != -1) {
        this.filtersValues.splice(position, 1)
      }


      const check = this.filtersValues.some(filter => filter.id === "status") && this.filtersValues.some(filter => filter.id === "plant")
      if (!check) return 

      this.changeSavedFilters(this.filtersValues);
      this.getDeliveriesBySavedFilters();
      //lh-select##status.hydrated
    },
    async searchDelivery(e) {
      let searchTerm = { field: e.target['id'], value: e.target.value.replace(/[^a-zA-Z0-9]/g, ''), userPlants: this.plantsSelected.map(plant => plant.value) }
      if (searchTerm && searchTerm.value.length > 3 && searchTerm.field == "contract") {
        let cleanSearchTerm = Number(searchTerm.value)
        if (isNaN(cleanSearchTerm)) { return }
        const response = await deliveryService.searchDeliveryByContract(searchTerm, this.user)
        if (response && response.data.length > 0) {
          this.Deliveries = response.data;
          this.DeliveriesAux = this.Deliveries;
        } else {
          this.Deliveries = [];
        }
      } else if (searchTerm && searchTerm.value.length > 3) {
        const response = await deliveryService.searchDeliveryByNumber(searchTerm, this.user)
        if (response && response.data.length > 0) {
          this.Deliveries = response.data;
          this.DeliveriesAux = this.Deliveries;
        } else {
          this.Deliveries = [];
        }
      }
      if (searchTerm && searchTerm.value.length === 0) {
        this.getDeliveriesFiltered(this.filtersValues);
      }

      const userSearchPreferences = JSON.parse(localStorage.getItem('deliveriesSearchPreferences')) || [];
      if (searchTerm) {
        let position = userSearchPreferences.findIndex(filter => filter.field === searchTerm.field);
        if (position === -1) {
          userSearchPreferences.push(searchTerm);
        } else {
          userSearchPreferences[position] = searchTerm;
        }
      }
      this.changeSavedSearchFilters(userSearchPreferences);
    },
    onSelectDate() {
      const field = this.$refs.dateSelect.view;
      const values = this.value.filter((v) => v !== null);
      const e = { target: { id: field, detail: values } }
      const calendar = this.$refs.dateSelect;
      if (values && values.length >= 2) {
        calendar.overlayVisible = false;
      }
      //console.log('DATE -->', e)
      this.filterDeliveries(e);
    },
    sortedDeliveryMaterials(materials) {
      const sortedMaterials = [ ...materials ];
      sortedMaterials.sort((mat1, mat2) => {
				if (mat1.position && mat2.position) {
					return mat1.position - mat2.position
				} else if(mat1.externalId && mat2.externalId) {
					return mat1.externalId.localeCompare(mat2.externalId);
				} else {
					return 0;
				}
			});

      return sortedMaterials;
    },
    assignOrder(data) {
      this.currentOrder = data;
    },
    assignCancel(data) {
      this.getDeliveriesFiltered(this.filtersValues);
    },
    assignDelivery(data) {
      this.currentDelivery = data;
      this.dataDelivery = { deliveryNo: this.currentDelivery._source.deliveryNO, OrderNo: this.currentDelivery._source.OrderNo, OrderE1: this.currentDelivery._source.OrderE1, deliveryNoteNumber: this.currentDelivery._source.deliveryNoteNumber }
    },
    closeModal(data) {
      this.getDeliveriesFiltered(this.filtersValues);
      this.$notify({
        title: this.$t("Delivery status changed"),
        type: 'success',
      });
      if (data === 'exit') {
        this.displayDeliver = false;
      }
      if (this.isBlocked === false) {
        deliveryService.unblockDelivery(this.currentDelivery, this.user).then((response) => {
          //console.log('response: ', response);
        })
      }
    },
    showNewModal(id, date = null, status = null) {
      if (date != null && moment(date).format('L') != moment().format('L') && this.alertNotFromTodayStatus.includes(status)) {
        this.currentDeliveryId = id;
        this.showPastDateAlert = true;
      }
      else {
        this.showDeliveryModal(id);
      }
    },
    continueOnAlert(id) {
      this.showPastDateAlert = false;
      this.showDeliveryModal(id);
    },
    showDeliveryModal(id) {
      if (this.dataDelivery.deliveryNo !== '') {
        this.currentDelivery = null;
      }
      this.getDeliveriesList();
      deliveryService.getDelivery(id).then((response) => {
        this.currentDelivery = response.data[0];
        this.displayDeliver = true;
        this.dataDelivery = { deliveryNo: this.currentDelivery._source.deliveryNO, OrderNo: this.currentDelivery._source.OrderNo, OrderE1: this.currentDelivery._source.OrderE1, deliveryNoteNumber: this.currentDelivery._source.deliveryNoteNumber }
      })
    },
    async showSAPE1Modal(delivery) {
      const deliveryNote = delivery._source.document.sapDeliveryNote;
      const shipmentDoc = delivery._source.document.sapShipmentDoc;

      let data;
      if (deliveryNote.status === "P-OK" || deliveryNote.status === "P-ERROR") {
        const { data: erpMessage } = await deliveryService.getERPMessage(deliveryNote.correlationID);
        data = {
          _id: delivery._id,
          deliveryId: delivery._source.cnn ? delivery._source.cnn : delivery._source.deliveryNO,
          SAPinfoSP: shipmentDoc.status,
          correlationIdSP: shipmentDoc.correlationID,
          SAPinfoDN: deliveryNote.status,
          correlationIdDN: deliveryNote.correlationID,
        };
        if(erpMessage.length > 0) {
          data.upd_dt = JSON.parse(erpMessage[0].json_msg).updtimestamp;
          data.SAPmsgDN = erpMessage[0].json_msg;
        }
      } else if (deliveryNote.status === "e-OK") {
        data = {
          msg: this.$t('The delivery has been successfully sent to SAP.')
        }
      } else {
        data = {
          msg: this.$t('Delivery pending: The vehicle has been created in ROCKS but has not yet been sent to SAP.')
        }
      }
      this.SAPData = data;
      this.displaySAPInfo = true;
    },
    closeSAPModal() {
      this.displaySAPInfo = false;
    },
    assignBlocked(data) {
      //console.log('Assign ====> ', data);
      this.isBlocked = data;
    },
    closeNewModal() {
      // The delivery modal is only closed if the user hasn't changed any data or if the user has saved the changed data
      if (!this.hasDeliveryDataChanged) {
        this.displayDeliver = false;
        this.getDeliveriesBySavedFilters();
        if (this.isBlocked === false) {
          deliveryService.unblockDelivery(this.currentDelivery, this.user).then((response) => {
            //console.log('response: ', response);
          })
        }
      } else {
        this.showUnsavedChangesWarning = true;
      }
    },
    exportCSV() {
      try {
        // linea para el export necesario por ser array u objeto
        // se modifica directamente el campo value del modelo del data table
        let foundIndex = -1;
        // Loop through the array in reverse to find the last occurrence
        for (let i = this.$refs.dt.columns.length - 1; i >= 0; i--) {
            if (this.$refs.dt.columns[i].props.field === "_source.material") {
                foundIndex = i;
                break;
            }
        }
        if (foundIndex !== -1) {
            this.$refs.dt.columns[foundIndex].props.field = "_source.materialId";
        }
        this.$refs.dt.value.forEach(v => {
          const materialCodes = v._source.material.map(v => v.materialID)
          v._source.materialId = materialCodes.length > 0 ? materialCodes.join(' -- ') : ' -- ' 
        })
        this.$refs.dt.value.forEach((v) => v._source.material = v._source.material[0].descriptions[0].text);
        this.$refs.dt.value.forEach((v) => v._source.deliveryNO = v._source.deliveryNoteNumber ? v._source.deliveryNoteNumber : v._source.deliveryNO);
        this.$refs.dt.exportCSV()
        setTimeout(() => this.getDeliveriesFiltered(this.filtersValues), "1500");
      } catch (error) {
        console.log('error: ', error);
      }
    },
    onRowClick() {
      // Not implemented
    },
    redirectToTracking(delivery) {
      let loader = this.$loading.show();

      let user = JSON.parse(sessionStorage.getItem("user"));
      let filters = {
        "dn_number": null,
        "id_order": null,
        "plant": delivery._source.material[0].plant,
        "truck": delivery._source.logistic.truck.plateNumber,
        "plants": user.plants.map(plant => plant.code)
      };

      TrackingService.getTrackingUrl(filters).then((response) => {
        window.open(response, "_blank");
        loader.hide();
      }).catch(error => {
        loader.hide();
        this.$notify({
          title: this.$t("Something went wrong, try again later"),
          type: "error",
          duration: -1,
          closeOnClick: true
        })
      });

    },
    closeUnsavedChangesModal() {
      this.showUnsavedChangesWarning = false;
    },
    closeDelAndUnsavedModals() {
      this.closeUnsavedChangesModal();
      this.closeNewModal();
    },
    async cloneOrder(delivery) {
      if (delivery._source.status !== 'Loading') { 
        try {

          // this.$store.commit('setOrderClonedFrom', delivery._source.OrderNo)
          // this.$store.commit('setDeliveryClonedFrom', delivery._source.deliveryNO)
  
          // CHECK VEHICLE
          if (delivery._source.logistic.truck.vehicleId && delivery._source.logistic.truck.vehicleId.length > 0) {
            this.$store.commit('setVehicle', {
              vehicleId: delivery._source.logistic.truck.vehicleId
            })
          }
  
          // CHECK TRAILER
          if (delivery._source.logistic.trailer.vehicleId && delivery._source.logistic.trailer.vehicleId.length > 0) {
            this.$store.commit('setTrailer', {
              vehicleId: delivery._source.logistic.trailer.vehicleId
            })
          }
  
          // TO ROUTE
          delivery._source.express && delivery._source.express === true ? this.$router.push(`/express-order?clone=${delivery._source.OrderNo}`) : this.$router.push('/new-order')
  
        } catch (err) {
  
          console.log (err)
        }
      } else {
        this.$notify({ 
          title: this.$t('Cannot clone orders with "Loading" status'),
          type: 'error',
          duration: -1,
          closeOnClick: true 
        })
      }
      
    }
  }
}
</script>

<template>
  <div class="app-content data-list orders">
    <div class="app-content__header">
      <div class="row">
        <h1 class="app-content__title"> Trading depot: {{ $store.state.deliveriesPlant.code }}</h1>

        <div class="app-content__header-action">
          <lh-button size="large" type="primary" @click="getDeliveriesFiltered(filtersValues)">{{ $t('REFRESH') }}</lh-button>
          <!-- <lh-button size="large" icon="file-text" @click="exportCSV($event)">{{ $t('EXPORT XLS') }}</lh-button> -->
          <lh-input type="search" id="globalSearch" v-model="lastSearch" :placeholder="$t('Search')"
            v-lhevent:lhInput="debouncedSearchTable" />
        </div>
      </div>
    </div>
    <div class="app-content__content">
      <div class="data-list__filters row-sticky">
        <div class="data-list__filters-tabs">
          <lh-button-tabs class="data-list__filters-btns buttons" :name=tabLinks :label=tabLinks active='Deliveries'
            size="large" v-lhevent:tabClicked="onTabClick"></lh-button-tabs>

          <div class="data-list__filters-search">
            <lh-input id="deliveryNO" border-type="light" v-model="deliveryNoSelected"
              :placeholder="$t('Order / Delivery ID')" v-lhevent:lhInput="searchDelivery" />
            <lh-input id="contract" border-type="light" v-model="contractNumberSelected"
              :placeholder="$t('Contract Number')" v-lhevent:lhInput="searchDelivery" />
          </div>
        </div>
        <div class="data-list__filters-top" style="align-items: center;">
          <Calendar ref="dateSelect" id="date" v-model="value"
            :placeholder="'On:   ' + moment(currentDate).format(displayMomentFormat)" @dateSelect="onSelectDate"
            selectionMode="range" :dateFormat="calendarFormat"  selectOtherMonths="true" 
            :minDate="moment(currentDate).subtract(3, 'months').toDate()" :maxDate="moment(currentDate).add(3, 'months').toDate()" />
          <Dropdown v-model="truckSelected" :options="filters['truck']" filter :placeholder="$t('Truck')"
            v-on:change="filterDeliveries" optionLabel="value">
            <template #option="slotProps" v-if="filters['truck'][0].length > 1">
              <span>{{ slotProps.value }}</span>
            </template>
            <template #option="slotProps" v-if="filters['truck'][0].length == 1">
              <span>{{ slotProps['value'] }}</span>
            </template>
          </Dropdown>
          <Dropdown v-model="carrierSelected" id="carrier" :options="filters['carrier']" filter
            :placeholder="$t('Haulier')" v-on:change="filterDeliveries" optionLabel="value">
            <template #option="slotProps" v-if="filters['carrier'][0].length > 1">
              <span>{{ slotProps.value }}</span>
            </template>
            <template #option="slotProps" v-if="filters['carrier'][0].length == 1">
              <span>{{ slotProps['value'] }}</span>
            </template>
          </Dropdown>
          <Dropdown v-model="deliveryTypeSelected" id="deliveryType" :options="filters['deliveryType']"
            optionLabel="name" filter :placeholder="$t('Delivery Type')" v-on:change="filterDeliveries" />

          <!--MultiSelect id="orderType" placeholder="Order Types" v-if="filters != null" :options="filters['orderType']" optionLabel="name" v-model="orderType"
            v-on:change="filterDeliveries" aria-label="Options" :showToggleAll="true" :maxSelectedLabels="2" selectionMode="multiple">
          </MultiSelect-->

          <MultiSelect id="Status" :maxSelectedLabels="2" placeholder="-" :options="filters['status']"
            optionLabel="value" v-model="status" v-on:change="filterDeliveries" aria-label="Options"
            :showToggleAll="true" selectionMode="multiple">
          </MultiSelect>

          <!--MultiSelect v-model="plantsSelected" v-if="filters != null" :options="filters['plant']" optionGroupLabel="cluster" optionGroupChildren="items" filter
            optionLabel="value" :placeholder="$t('userPlants')" :maxSelectedLabels="3" v-on:change="filterDeliveries">
            <template #optiongroup="slotProps">
              <div class="pointer" @click="clusterFilterClick(slotProps.option.cluster)">{{ slotProps.option.cluster }}</div>
            </template>
          </MultiSelect-->

          <lh-button size="large" type="ghost" class="all-filters" suffix="chevron-down"
            @click="() => allFiltersVisible = !allFiltersVisible">{{ $t('All filters') }}</lh-button>
          <i class="fa-solid fa-filter-circle-xmark" style="max-width: max-content; cursor: pointer;"
            @click="resetFilters"></i>
        </div>
        <div class="data-list__filters-all" v-if="allFiltersVisible">
          <div class="columns">
            <div class="column">
              <InputText type="text" v-model="soldToSelected" id="soldto" :placeholder="$t('Sold to')"
                @input="filterDeliveries" />
              <!--<lh-input border-type="light" id="soldto" :placeholder="$t('Sold to')" v-lhevent:lhInput="filterDeliveries">
              </lh-input>-->
            </div>
            <div class="column">
              <InputText type="text" v-model="shipToSelected" id="shipto" :placeholder="$t('Ship to')"
                @input="filterDeliveries" />
              <!-- <lh-input border-type="light" id="shipto" :placeholder="$t('Ship to')" v-lhevent:lhInput="filterDeliveries">
              </lh-input> -->
            </div>
            <div class="column">
              <InputText type="text" v-model="poNumberSelected" id="PONumber" :placeholder="$t('PO Number')"
                @input="filterDeliveries" />
              <!-- <lh-input border-type="light" id="po" :placeholder="$t('PO Number')" v-lhevent:lhInput="filterDeliveries">
              </lh-input> -->
            </div>
            <div class="column">
              <Dropdown v-model="materialTypeSelected" id="materialType" :options="filters['materialType']" filter
                :placeholder="$t('Material Type')" v-on:change="filterDeliveries" optionLabel="value">
                <template #option="slotProps">
                  <span>{{ slotProps.option.value }}</span>
                </template>
              </Dropdown>
            </div>
            <div class="column">
              <InputText type="text" v-model="creatorSelected" id="createdBy" :placeholder="$t('Created by')"
                @input="filterDeliveries" />
            </div>
          </div>
        </div>
      </div>

      <div class="data-list__table filters-sticky row__orderList row">
        <DataTable stripedRows :value="Deliveries" sortField="_source.deliveryLocalDate" :sortOrder="1" @rowClick="onRowClick"
          v-model:expandedRows="expandedRows" :paginator="true" :rows="rows" :rowsPerPageOptions="[3, 5, 10, 20, 50]"
          paginatorTemplate="RowsPerPageDropdown CurrentPageReport PrevPageLink PageLinks NextPageLink"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" contextMenu
          v-model:contextMenuSelection="selectedDelivery" scrollDirection="horizontal" ref="dt">
          <template #empty>
            <p>{{ $t('No matches') }}</p>
          </template>
          <Column :style="{ width: '13%' }" field="_source.status" :header="$t('Status')" :sortable="true">
            <template #body="slotProps">
              <div class="flex">
                <div class="status-type__container">
                <Status v-if="slotProps.data._source.status" :name="slotProps.data._source.status" />
                <div class="status-type__type">
                  {{ slotProps.data._source.orderType }}
                </div>
              </div>
              <div class="" style="padding: 5px;">
                <HandWritingIcon v-if="slotProps.data._source.retro"/>
              </div>
              
              <!--i v-if="slotProps.data._source.retro" class="zmdi zmdi-time-restore-setting" style="font-size: 30px;"></i-->
            </div>
              
            </template>
          </Column>
          <Column :style="{ width: '40px' }" field="_source.document.sapDeliveryNote.status" :header="$t('SAP')">
            <template #body="slotProps">
              <span class="order-number-btn"
                @click="showSAPE1Modal(slotProps.data)">
                <i v-if="slotProps.data._source.document.sapDeliveryNote.status === 'P-OK'"
                  :style="{ 'font-size': '25px' }" class="zmdi zmdi-check-all"></i>
                <i v-else-if="slotProps.data._source.document.sapDeliveryNote.status === 'P-ERROR'"
                  :style="{ 'font-size': '25px', 'color': 'red' }" class="zmdi zmdi-check"></i>
                <i v-else-if="slotProps.data._source.document.sapDeliveryNote.status === 'e-OK'"
                  :style="{ 'font-size': '25px' }" class="zmdi zmdi-check"></i>
                <i v-else-if="slotProps.data._source.document.sapDeliveryNote.status == 'hold-veh'"
                  :style="{ 'font-size': '25px' }" class="zmdi zmdi-time"></i>
                <i v-else-if="slotProps.data._source.holdOrder == '1'"
                  :style="{ 'font-size': '25px', 'color': '#FF2000' }" class="fas fa-hand ml-2"></i>
              </span>
            </template>
          </Column>
          <Column :style="{ width: '80px' }" field="_source.OrderNo" :header="$t('Order')" :sortable="true">
            <template #body="slotProps">
              <span class="order-number-btn" @click="showModalOrder(slotProps.data._source.OrderNo)">
                {{ slotProps.data._source.OrderE1 ? slotProps.data._source.OrderE1 : slotProps.data._source.OrderNo
                }}</span>
            </template>
          </Column>
          <Column :style="{ width: '140px' }" field="_source.deliveryNO" :header="$t('Delivery')" :sortable="true">
            <template #body="slotProps">
              <div>
                <span class="order-number-btn" @click="handleGoToDispatching(slotProps.data)">
                  {{
                    slotProps.data._source.deliveryNoteNumber ? slotProps.data._source.deliveryNoteNumber :
                      slotProps.data._source.deliveryNO
                  }}
                </span>
                <i v-if="slotProps.data._source.document && slotProps.data._source.document.s3.bucketName !== ''
                  && slotProps.data._source.document.s3.fileName !== ''" @click="getDeliveryNote(slotProps.data)"
                  class="zmdi zmdi-file-text zmdi-hc-lg delivery-icon" v-tooltip="'Download ePOD'">
                </i>
                <i v-if="slotProps.data._source.paperTicket" class="zmdi zmdi-print zmdi-hc-lg delivery-icon"
                  v-tooltip="'Paper Ticket'">
                </i>
              </div>
              <div class="order-number-nametag">
                By {{ slotProps.data._source.auditData.createdBy }}
              </div>
            </template>
          </Column>
          <Column :style="{ width: '70px' }" field="_source.deliveryType" :header="$t('Type')" :sortable="true">
            <template #body="slotProps">
              <i v-if="slotProps.data._source.deliveryType === 'Delivered'" class="zmdi zmdi-truck zmdi-hc-lg"
                v-tooltip="'Delivered'"></i>
              <i v-else class="zmdi zmdi-city-alt zmdi-hc-lg" v-tooltip="'Ex-works'"></i>
              <i v-if="slotProps.data._source.notes.DispatcherInstructions" class="zmdi zmdi-comment-text zmdi-hc-lg delivery-icon" 
                style="font-size: 1.2rem" v-tooltip="`${slotProps.data._source.notes.DispatcherInstructions}`"></i>
              <i v-if="Array.isArray(slotProps.data._source.notes) && slotProps.data._source.notes.find(n=>n.category===commonClasses.noteCategories.DispatcherInstructions)" 
                class="zmdi zmdi-comment-text zmdi-hc-lg delivery-icon" style="font-size: 1.2rem" 
                v-tooltip="`${slotProps.data._source.notes.find(n=>n.category===commonClasses.noteCategories.DispatcherInstructions).text}`"></i>
            </template>
          </Column>
          <Column :style="{ width: '140px' }" field="_source.deliveryLocalDate" :header="$t('Date')" :sortable="true">
            <template #body="slotProps">
              <span v-if="slotProps.data._source.deliveryType === 'Delivered'" v-tooltip="`${getMomentInTimezone((slotProps.data._source.status.toLowerCase() == 'scheduled' 
                                  ? slotProps.data._source.Tour.planned.unloadingLocationEntered 
                                  : getETA(slotProps.data._source.Tour)), getTimeZone())
                                .format('HH:mm')} ETA`">{{
                getMomentInTimezone(slotProps.data._source.deliveryLocalDate, getTimeZone()).format(displayDateTimeFormat)
              }}</span>
              <span v-if="slotProps.data._source.deliveryType !== 'Delivered'">{{
                getMomentInTimezone(slotProps.data._source.deliveryLocalDate, getTimeZone()).format(displayDateTimeFormat)
              }}</span>
            </template>
          </Column>
          <Column :style="{ width: '12%' }" field="_source.soldto.name" :header="$t('Sold to')" :sortable="true">
            <template #body="slotProps">
              <div class="nowrap">
                {{ slotProps.data._source.soldto.name }}
              </div>
            </template>
          </Column>
          <Column :style="{ width: '15%' }" field="_source.shipto.address.name" :header="$t('Ship to')"
            :sortable="true">
            <template #body="slotProps">
              <div class="nowrap">
                {{ slotProps.data._source.shipto.address.name }}, {{ slotProps.data._source.shipto.address.transportationZone }} 
                <br />
                {{ slotProps.data._source.shipto.address.city }} 
              </div>
            </template>
          </Column>
          <Column :style="{ width: '20%' }" field="_source.material" :header="$t('Material')" :sortable="true">
            <template #body="slotProps">
              <template v-if="slotProps.data._source.material.every(m => materialTypeExclusion.includes(m.materialType))">
                <div v-for="material in sortedDeliveryMaterials(slotProps.data._source.material).slice(0,1)" 
                  :class="{nowrap: slotProps.data._source.material.length != 1}">
                  {{                
                    material.material && material.material.description ?
                      materialDescription(material.material.description, locale)
                      : materialDescription(material.descriptions, locale)
                  }}
                  <!-- {{
                    material.material && material.material.description ? material.material.description :
                    material.descriptions.find(p => p.language == "EN").text
                  }} -->
                </div>
                <div v-if="slotProps.data._source.material.length > 1">
                  +{{ slotProps.data._source.material.length - 1 }}
                </div>
              </template>
              <template v-else>
                <div v-for="material in sortedDeliveryMaterials(slotProps.data._source.material).filter(m => !materialTypeExclusion.includes(m.materialType)).slice(0,3)" 
                  :class="{nowrap: slotProps.data._source.material.length != 1}">
                  {{                
                    material.material && material.material.description ?
                      materialDescription(material.material.description, locale)
                      : materialDescription(material.descriptions, locale)
                  }}
                  <!-- {{
                    material.material && material.material.description ? material.material.description :
                    material.descriptions.find(p => p.language == "EN").text 
                  }} -->
                </div>
                <div v-if="slotProps.data._source.material.some(m => materialTypeExclusion.includes(m.materialType)) || slotProps.data._source.material.length > 3">
                  +{{ slotProps.data._source.material.filter(m => materialTypeExclusion.includes(m.materialType)).length + slotProps.data._source.material.filter(m => !materialTypeExclusion.includes(m.materialType)).slice(3).length }}
                </div>
              </template>
            </template>
          </Column>
          <Column :style="{ width: '200px' }" field="_source.material" :header="$t('Code')" :sortable="true">
            <template #body="slotProps">
              <template v-if="slotProps.data._source.material.every(m => materialTypeExclusion.includes(m.materialType))">
                <div v-for="material in sortedDeliveryMaterials(slotProps.data._source.material).slice(0,1)" 
                  :class="{nowrap: slotProps.data._source.material.length != 1}">
                  {{                
                    material && (material.material.externalmaterialID || material.materialID)
                  }}
                </div>
                <div v-if="slotProps.data._source.material.length > 1">
                  +{{ slotProps.data._source.material.length - 1 }}
                </div>
              </template>
              <template v-else>
                <div v-for="material in sortedDeliveryMaterials(slotProps.data._source.material).filter(m => !materialTypeExclusion.includes(m.materialType)).slice(0,3)" 
                  :class="{nowrap: slotProps.data._source.material.length != 1}">
                  {{                
                    material && (material.material.externalmaterialID || material.materialID)
                  }}
                </div>
                <div v-if="slotProps.data._source.material.some(m => materialTypeExclusion.includes(m.materialType)) || slotProps.data._source.material.length > 3">
                  +{{ slotProps.data._source.material.filter(m => materialTypeExclusion.includes(m.materialType)).length + slotProps.data._source.material.filter(m => !materialTypeExclusion.includes(m.materialType)).slice(3).length }}
                </div>
              </template>
            </template>
          </Column>
          <Column :style="{ width: '110px' }" field="_source.netWeight" :header="$t('Q')" :sortable="true">
            <template #body="slotProps">
              <template v-if="slotProps.data._source.splitLoad" class="nowrap">
                <div v-for="material in slotProps.data._source.material.every(m => materialTypeExclusion.includes(m.materialType)) 
                  ? sortedDeliveryMaterials(slotProps.data._source.material).slice(0,1) 
                  : sortedDeliveryMaterials(slotProps.data._source.material).filter(m => !materialTypeExclusion.includes(m.materialType)).slice(0,3)">
                  {{ WeightUnit.weightCoversion(material.baseUoMquantity, !material.baseUoM ? 'T' : material.baseUoM, !material.baseUoM ? 'T' : material.baseUoM) }}
                </div>
                <!-- This element adds a bottom space when there is more than 1 material shown so the weights can be aligned with their materials -->
                <div v-if="slotProps.data._source.material.every(m => materialTypeExclusion.includes(m.materialType)) 
                  ? sortedDeliveryMaterials(slotProps.data._source.material).slice(1).length > 1 
                  : sortedDeliveryMaterials(slotProps.data._source.material).filter(m => !materialTypeExclusion.includes(m.materialType)).slice(3).length > 3 || 
                    sortedDeliveryMaterials(slotProps.data._source.material).some(m => materialTypeExclusion.includes(m.materialType))"
                  style="padding-bottom: 1.2rem">
                </div>
              </template>
              <div v-else class="nowrap">
                {{ WeightUnit.weightCoversion(slotProps.data._source.netWeight, !slotProps.data._source.material[0].baseUoM ? 'T' : slotProps.data._source.material[0].baseUoM, !slotProps.data._source.material[0].baseUoM ? 'T' : slotProps.data._source.material[0].baseUoM) }}
              </div>
            </template>
          </Column>
          <Column :style="{ width: '8%' }" field="_source.logistic.truck.plateNumber" :header="$t('Truck')"
            :sortable="true">
            <template #body="slotProps">
              <div v-if="slotProps.data._source.logistic.truck && slotProps.data._source.logistic.truck.plateNumber"
                class="nowrap">
                <i v-if="slotProps.data._source.deliveryType == 'Delivered' && slotProps.data._source.status == 'Shipping'
          && !slotProps.data._source.paperTicket && !slotProps.data._source.signedOnPaper"
                  @click="redirectToTracking(slotProps.data)" class="zmdi zmdi-smartphone zmdi-hc-lg truck-icon"
                  v-tooltip="'myTruck Available'"></i>
                <span>{{ slotProps.data._source.logistic.truck.plateNumber }}</span>
              </div>
            </template>
          </Column>
          <Column :style="{ width: '12%' }" field="_source.logistic.carrier.name" :header="$t('Haulier ')"
            :sortable="true">
            <template #body="slotProps">
              <div v-if="slotProps.data._source.logistic.forwardingAgent && slotProps.data._source.logistic.forwardingAgent.name"
                class="nowrap">
                {{ slotProps.data._source.logistic.forwardingAgent.name }}
              </div>
            </template>
          </Column>
          <Column header="" class="data-list__column-button">
            <template #body="slotProps">
              <lh-button-icon type="ghost" name="more" @click="(e) => onContextMenu(e, slotProps)" />
            </template>
          </Column>
        </DataTable>

        <ContextMenu :model="menuModel" ref="cm" />
        <!-- <lh-dialog :style="{ width: '1200px' }" position="center" :title="'Order #' + dataDelivery.OrderNo + ' - Delivery #' +
              dataDelivery.deliveryNo" modal ref="modalDelivery" :key="keyRender">
                <DeliveryModalVue v-if="currentDelivery !== null" @checkData="closeModal($event)"
                  @checkDelivery="assignDelivery($event)" :delivery="currentDelivery" :deliveriesList="deliveriesList" />
              </lh-dialog> -->
        <Dialog v-model:visible="displayDeliver" :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
          :style="{ width: '985px' }" :modal="true" :draggable="false" :closable="false">
          <template #header>
            <span class="p-dialog-title">{{ `Order # ${dataDelivery.OrderE1 ? dataDelivery.OrderE1 :
          dataDelivery.OrderNo} - Delivery # ${dataDelivery.deliveryNoteNumber ? dataDelivery.deliveryNoteNumber : dataDelivery.deliveryNo}` }}</span>
            <div class="p-dialog-header-icons">
              <button class="p-dialog-header-icon p-dialog-header-close p-link" aria-label="Close" type="button"
                @click="closeNewModal">
                <span class="p-dialog-header-close-icon pi pi-times"></span>
              </button>
            </div>
          </template>
          <DeliveryModalVue v-if="currentDelivery !== null" @checkData="closeModal($event)"
            @checkDelivery="assignDelivery($event)" :delivery="currentDelivery" 
            @checkBlockedD="assignBlocked($event)" :showUnsavedChangesWarning="showUnsavedChangesWarning"
            @dataChanged="(value) => hasDeliveryDataChanged = value"
            @closeUnsavedChangesModal="closeUnsavedChangesModal" @closeAllModals="closeDelAndUnsavedModals" />
        </Dialog>

        <Dialog :header="$t('ERP Information')" v-model:visible="displaySAPInfo" :style="{ width: '972px' }"
          :breakpoints="{ '960px': '75vw', '640px': '90vw' }" :dismissable-mask="true" closeOnEscape closable
          :modal="true" @hide="closeSAPModal" class="SAPModal">
          <SAPModal :data="SAPData" />
        </Dialog>

        <Dialog v-model:visible="showPastDateAlert" modal header="Not from today" :style="{ width: '25rem' }">
          <div class="alert_past_date">
            <span class="p-text-secondary block mb-5">{{ $t('This delivery is not from today') }}</span>
            <div class="alert_past_date_actions">
              <lh-button style="width: 150px;" @click="showPastDateAlert = false">
                {{ $t('Cancel') }}
              </lh-button>
              <lh-button style="width: 150px;" type="primary" @click="continueOnAlert(currentDeliveryId)">
                {{ $t('Continue') }}
              </lh-button>
            </div>
          </div>
        </Dialog>
        <Dialog
          v-model:visible="displayDeliveryLog" modal :breakpoints="{ '960px': '75vw', '640px': '90vw' }" :style="{ width: '972px' }">
            <template #header>
              <span class="p-dialog-title">{{ `Delivery Log # ${selectedDelivery._source ? selectedDelivery._source.deliveryNO : ''}` }}</span>
              <div class="p-dialog-header-icons">
                <!-- <button class="p-dialog-header-icon p-dialog-header-close p-link" aria-label="Close" type="button"
                  @click="closeNewModal">
                  <span class="p-dialog-header-close-icon pi pi-times"></span>
                </button> -->
              </div>
            </template>
          <HistoryLog v-if="selectedDelivery !== null"
            :delivery="selectedDelivery" />
        </Dialog>

        <lh-dialog v-if="currentOrder !== null" position="center"
          :title="`Order # ${currentOrder._source.OrderNo_E1 ? currentOrder._source.OrderNo_E1 : currentOrder._source.OrderNo}`"
          modal ref="modalOrder">
          <OrderModal @checkOrder="assignOrder($event)" :order="currentOrder" :deliveries="currentOrderDeliveries"
            @cancelOrder="assignCancel" />
        </lh-dialog>

      </div>
    </div>
  </div>
</template>

<style lang="scss">
@use '@/assets/css/lh-ds/core/functions' as *;
@use '@/assets/css/hodim/layout/data-list';
@use '@/assets/css/hodim/layout/data-list/ordercard';
@use '@/assets/css/hodim/layout/data-list/multiselect';
@import '@/assets/css/hodim/layout/data-list/calendar';

.alert_past_date {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .alert_past_date_actions {
    padding: 20px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.SAPModal {
  .p-dialog-content {
    overflow: hidden;
  }
}

.orders {

  .p-paginator-rpp-options {
    margin-left: 0;
    margin-right: auto;
  }

  lh-dialog {
    &::part(body) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .row__orderList {
    .p-datatable .p-datatable-tbody > tr > td {
      padding: 1.2rem 0.75rem;
    }
  }
}

.status-type {
  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__type {
    font-size: 0.8rem; 
    text-transform: uppercase;
  }
}

.order-number-btn {
  cursor: pointer;
  font-weight: bold;
}

.order-number-nametag {
  position: absolute; 
  font-size: 0.8rem;
}

// in order to nowrap work, table needs to have a width
.p-datatable table {
  min-width: auto;
  width: 100%;
}

.p-dialog-title {
  margin: auto;
}

.p-dropdown {
  border: 1px solid rgb(216, 216, 216) !important;
}

.data-list__filters {
  &.row-sticky {
    position: sticky;
    top: 0;
    z-index: 2;
    width: 100%;
    padding-right: 4.375rem;
    padding-left: 1.875rem;
    display: inline-block;
    background-color: #FFF;
  }

  &-tabs {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.7rem;
  }

  &-btns {
    width: 15%;
  }

  &-search {
    display: flex;
    gap: 0.5rem;
  }

  &-sticky {
    margin-top: 0 !important;
    padding-top: 0.2rem;
  }
}

lh-button-tabs.data-list__filters-btns {
  &.buttons {
    width: 25%;
    cursor: pointer;
  }
}

.p-tooltip {
  background-color: #383838;
  color: white;
  border-radius: 5px;
  padding: 7px !important;
  font-size: 0.9rem !important;
  margin-left: 5px;
}

.p-tooltip .p-tooltip-arrow {
  border-width: 5px 5px 5px 0;
  border-color: transparent #383838 transparent transparent;
  margin-left: -4px;
}

.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even) {
    background: #ebebeb;
}

.data-list__table .delivery-icon {
  margin-left: 4px;

  &.zmdi-file-text {
    cursor: pointer;
  }
}

.data-list__table .truck-icon {
  margin-right: 4px;
  cursor: pointer;
}

.p-multiselect-panel {
  max-width: 15rem;
}

.cl {
  $border-color: #D8D8D8;

  &-content {
    .row {
      display: flex;
      flex-direction: column;
    }
  }

  &-search {
    width: 30%;
  }

  &-results {
    flex: 1;

    margin-top: rem(38px);
    border: 1px solid $border-color;
    background-color: white;

    &__wrapper {
      height: 100%;
      overflow: auto;
    }
  }

  &-result {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: rem(12px) rem(20px);

    cursor: pointer;

    +.cl-result {
      border-top: 1px solid $border-color;
    }

    &__name {
      font-weight: bold;
      margin-bottom: rem(1px);
    }

    &__ref {
      color: rgba(black, .5);
    }

    &__right {
      display: flex;
      align-items: center;

      *+* {
        margin-left: rem(16px);
      }
    }

    &__tag {
      padding: rem(4px) rem(8px);
      color: #FF2000;
      border: 1px solid;
      border-radius: 2px;
      font-size: rem(12px);
      line-height: rem(20px);
    }
  }
}
</style>