<script setup>
import deliveryService from "@/services/api/delivery";
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import moment from 'moment';
import StatusSap from '@/components/common/StatusSap';
import commonClasses from "@/store/commonClasses.js";
</script>
<script>
export default {
    name: "SAPModal",
    components: {},
    data: function () {
        return {
            showDelJSON: false,
            showShipJSON: false,
            displayMomentFormat: JSON.parse(localStorage.getItem("dateFormat")) ? JSON.parse(localStorage.getItem("dateFormat")).moment : 'DD/MM/YYYY'
        }
    },
    props: {
        data: {},
    },
    mounted() {
    },
    methods: {
        showJSON(e) {
            if (e.target['id'] === 'del') {
                this.showDelJSON = true
            } else if (e.target['id'] === 'ship') {
                this.showShipJSON = true
            }
        },
        setStatusToShow(status) {
            let aux = ''
            if (status === 'P-OK') {
                aux = 'Accepted';
            } else if (status === 'e-OK') {
                aux = 'Sent';
            } else if (status === 'P-ERROR') {
                aux = 'Error';
            } else {
                aux = status;
            }
            return aux;
        },
        async reprocessMessage() {
            try {
                await deliveryService.updateDeliveryDispatchStatus(this.data._id, 0);
            } catch (error) {
                console.log(error);
            }
        },
    },

}
</script>

<template>
    <div v-if="data && data.msg" class="modal__content-msg">
        {{ data.msg }}
    </div>
    <div v-else-if="data" class="columns">
        <div class="column">
            <h3 class="title">{{ $t('DELIVERY NOTE') }}</h3>
            <p>
                <span class="slots">
                    <b>{{ $t('Status') }}: </b>
                    <StatusSap :name="setStatusToShow(data.SAPinfoDN)" />
                </span>
            </p>
            <p>
                <span class="slots">
                    <b>{{ $t('Date') }}: </b>{{ moment(data.upd_dt).format('DD/MM/yyyy HH:mm') }}
                </span>
            </p>
            <p>
                <span class="slots">
                    <b>{{ $t('Delivery Number') }}: </b>{{ data.deliveryId }}
                </span>
            </p>
            <p>
                <span class="slots">
                    <b>{{ $t('Correlation ID') }}: </b>
                    <p class="CIDText">{{ data.correlationIdDN }}</p>
                </span>
            </p>

            <div class="buttons" v-if="(setStatusToShow(data.SAPinfoDN) === 'Accepted' || setStatusToShow(data.SAPinfoDN) === 'Error') && data.SAPmsgDN">
                <Button class="p-button btn btn--secondary" id="del" @click="showJSON" :style="{ width: '150px' }">{{ $t('Show Message') }}</Button>
                <Button class="p-button btn btn--secondary" @click="reprocessMessage" :style="{ width: '150px' }">{{ $t('Reprocess') }}</Button>

                <Dialog v-model:visible="showDelJSON" :dismissable-mask="true" closeOnEscape closable header=" ">
                    <div class="jsonmodal">
                        <pre>
                            {{ JSON.parse(data.SAPmsgDN) }}
                        </pre>
                    </div>
                </Dialog>
            </div>

        </div>
        <div v-if="data.SAPinfoSP.toLowerCase() !== commonClasses.deliveryType.PickUp.toLowerCase()" class="column">
            <h3 class="title">{{ $t('SHIPMENT DOCUMENT') }}</h3>
            <p>
                <span class="slots">
                    <b>{{ $t('Status') }}: </b>
                    <StatusSap :name="setStatusToShow(data.SAPinfoSP)" />
                </span>
            </p>
            <p>
                <span class="slots">
                    <b>{{ $t('Date') }}: </b>{{ moment(data.upd_dt).format(displayMomentFormat) }}
                </span>
            </p>
            <p>
                <span class="slots">
                    <b>{{ $t('Delivery Number') }}: </b> {{ data.deliveryId }}
                </span>
            </p>
            <p>
                <span class="slots">
                    <b>{{ $t('Correlation ID') }}: </b>
                    <p class="CIDText"> {{ data.correlationIdSP }}</p>
                </span>
            </p>
            <div class="buttons" v-if="(setStatusToShow(data.SAPinfoSP) === 'Accepted' || setStatusToShow(data.SAPinfoSP) === 'Error') && data.SAPmsgSP">
                <Button class="p-button btn btn--secondary" id="ship" @click="showJSON" :style="{ width: '150px' }">{{
                    $t('Show Message')
                }}</Button>
                <Dialog v-model:visible="showShipJSON" :dismissable-mask="true" closeOnEscape closable header=" ">
                    <div class="jsonmodal">
                        <pre>
                            {{ data.SAPmsgSP }}
                        </pre>
                    </div>
                </Dialog>
            </div>
        </div>
        <div v-if="data.SAPinfoSP.toLowerCase() === commonClasses.deliveryType.PickUp.toLowerCase()" class="column">
            <h3 class="title">{{ data.SAPinfoSP }}</h3>
        </div>
    </div>
</template>

<style lang="scss" scoped>
@use '@/assets/css/lh-ds/core/functions' as *;
@use '@/assets/css/hodim/layout/data-list';

.columns {
    padding: 10px;
    height: 350px;
}

.title {
    text-align: center;
    margin-bottom: 15px;
}

.buttons {
    text-align: center;
    margin-top: 10px;

    .btn {
        margin-inline: 10px;
    }
}

.slots {
    padding: 7px;
}

.CIDText {
    padding-left: 7px;
}

.jsonmodal {
    padding: 15px;
}

.modal {
    &__content-msg {
        padding: 1rem 1.8rem 2rem; 
        font-size: 1.05rem;
        font-weight: bold; 
        text-align: center; 
    }
}
</style>
