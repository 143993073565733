<template>
  <div class="destination-types m-6">
    <DataTable
      :value="dapEasyResponse"
      scrollDirection="horizontal"
      :loading="loading"
      :rows="7"
    >
      <Column :header="$t('DAP')" style="width: 12%;"> 
        <template #body="slotProps">
          {{ slotProps.data.erpCode }}
        </template>
      </Column>

      <Column :header="$t('DAP Quantity')" :style="{ width: '12%' }"> 
        <template #body="slotProps">
          {{ slotProps.data.estimateTonnage.toString() }}
          {{ slotProps.data.extraData ? slotProps.data.extraData.dap_uom : 'TNE' }}
        </template>
      </Column>

      <Column :header="$t('DAP Quantity Acum.')" :style="{ width: '15%' }"> 
        <template #body="slotProps">
          {{ slotProps.data.extraData ? slotProps.data.extraData.dap_quantity_acum : '0' }} 
          {{ slotProps.data.extraData ? slotProps.data.extraData.dap_uom : 'TNE' }}
          <span>
                <i v-if="slotProps.data.isFull == 'Y'" class="zmdi zmdi-info pl-2" style="color:red; font-size:x-large" @click="openInfoDAPNotValidModal('DAP_full')" ></i>
          </span>
        </template>
      </Column>

      
      <Column :header="$t('Validation Date From') + ' - ' + $t('To')" > 
        <template #body="slotProps">
         {{ slotProps.data.startValidationDate }} <br>
         {{ slotProps.data.endValidationDate }}
          <span>
              <i v-if="slotProps.data.isExpired == 'Y'" class="zmdi zmdi-info pl-2" style="color:red; font-size:x-large" @click="openInfoDAPNotValidModal('DAP_expired')" ></i>
          </span>
        </template>
      </Column>
      
      <!--<Column :header="$t('Validation Date To')"> 
        <template #body="slotProps">
          {{ slotProps.data.endValidationDate }}
          <span>
              <i v-if="slotProps.data.isExpired == 'Y'" class="zmdi zmdi-info pl-2" style="color:red; font-size:x-large" @click="openInfoDAPNotValidModal('DAP_expired')" ></i>
          </span>
        </template>
      </Column>-->
      
      <Column :header="$t('Shipto') +' ' + $t('name')" > 
        <template #body="slotProps">
          {{ slotProps.data.jobSiteName }}
        </template>
      </Column>

      <Column :header="$t('Shipto')  +' ' + $t('address')"> 
        <template #body="slotProps">
          {{ slotProps.data.jobSiteAddress }}
          {{ slotProps.data.jobSitePostalCode }}
           {{ slotProps.data.jobSiteCity }}
        </template>
      </Column>

      <Column :header="$t('Select')" :style="{ width: '10%' }" class="data-list__column-button">
        <template #body="slotProps">
          <Button
            :disabled="slotProps.data.isValid === 'N'"
            class="p-button btn"
            icon="zmdi zmdi-arrow-right zmdi-hc-lg"
            @click="selectDAP(slotProps)"
          />
        </template>
      </Column>
    </DataTable>
    <Dialog v-model:visible="showInfoDetailsModal"  :header="detailsModalErrorMsgType" modal :style="{ width: '25rem' }">
      <div class="p-4">
        {{$t(detailsModalErrorMsg.errorMsg)}}
      </div>
    </Dialog>
  </div>
</template>

<script setup>
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
</script>

<script>
export default {
  name: "DAPEasySelector",
  props: ['dapEasyResponse'],
  components: {
    DataTable,
    Column,
    Button,
    Dialog,
  },
  data: function () {
    return {
      destinations: [],
      loading: false,
      showInfoDetailsModal:false,
      detailsModalErrorMsg: '',
      detailsModalErrorMsgType: '',
    };
  },
  mounted() {
    console.log(this.dapEasyResponse)
  },
  methods: {
    selectDAP(e) {
      this.$emit("setDAPEasy", e);
    },
    openInfoDAPNotValidModal(type){
      this.showInfoDetailsModal = true; 
      this.detailsModalErrorMsgType=  this.$t(type);
      this.detailsModalErrorMsg = {
        type:type,
        errorMsg: type + '_msg'
      }
    },
  },
};
</script>

<style lang="scss">
@use "@/assets/css/lh-ds/core/functions" as *;
@use "@/assets/css/hodim/layout/data-list";

.destination-types {
  .p-datatable {
    .p-datatable-thead > tr > th {
      background: #eee;
    }
  }
}
</style>
