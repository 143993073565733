<script>
export default {
  props: {
    title: String,
    cols: Number,
    completed: Boolean,
    next: Boolean,
    sectionId : String
  },
  methods: {
    edit(){
      this.$emit('editSection', this.sectionId)
    }
  },
}
</script>

<template>
  <div class="new-order__step" :class="{ 'is-next': next, 'is-completed': completed }">
    <div class="new-order__step-head">
      <h2>{{ title }}</h2>
      <div class="new-order__step-head-action" v-if="completed">
        <!-- <lh-button-icon name="edit"></lh-button-icon> -->
        <i class="zmdi zmdi-edit zmdi-hc-lg" @click="edit"></i>
      </div>
    </div>
    <div class="new-order__step-content">
      <div class="new-order__step-content-wrapper" :class="{ 'cols-2': next }">
        <slot/>
      </div>
    </div>
    <div class="new-order__step-footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<style lang="scss">
@use '@/assets/css/lh-ds/core/icon';
@use '@/assets/css/lh-ds/core/color';
@use '@/assets/css/lh-ds/core/font';
@use '@/assets/css/lh-ds/core/functions' as *;
@use '@/assets/css/hodim/core/mixin';

// lh-radiobutton::part(base) {
//   margin-bottom: 60px;
// }

.new-order__step {
  position: relative;
  padding: rem(36px) rem(40px);
  background-color: white;

  // step head
  &-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // min-height: rem(37px); // icon button height

    h2 {
      font-size: rem(24px);
    }

    &-action {
      position: absolute;
      top: rem(22px);
      right: rem(20px);
      display: flex;
      align-items: center;

      & > * + * {
        margin-left: rem(8px);
      }
    }
  }

  // step content
  &-content {
    $grid-item-min-size: rem(220px);

    // max-width: rem(535px);
    margin-top: rem(32px);

    // &-wrapper {
      // display: grid;
      // grid-template-columns: 1fr 1fr;
      // // grid-template-columns: repeat(auto-fill, minmax($grid-item-min-size, 1fr));
      // gap: rem(32px);

      // responsive grid for small screens
      // @media (max-width: rem(1400px)) {
      //   grid-template-columns: repeat(auto-fill, minmax($grid-item-min-size, 1fr));
      // }
    // }
  }

  &-footer {
    margin-top: rem(35px);

    &:empty {
      display: none;
    }

    * + * {
      margin-left: rem(32px);
    }

    lh-button {
      display: inline-block;
    }
  }

  // variations
  &.is-next {
    .new-order__step {
      &-head {
        opacity: .5;
      }

      &-content,
      &-footer {
        display: none;
      }
    }
  }

  &.is-completed {
    .new-order__step {
      &-footer {
        display: none;
      }
    }
  }
}
</style>
