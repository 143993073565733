<template>
  <Step :title="$t('Attachments')" class="attachments-step" >
    <FileUploader class="mb-5" :filesList="attachments" :maxFileSize="MAX_FILE_SIZE" :lovType="attachmentsLovType" :attachmentsCategoryBlocked="attachments.filter(el => el.category == 'DAP').length >0? 'DAP': ''"
      :uploadFiles="false" @updateFilesList="updateAttachmentsToUpload" @deleteFile="handleDeleteFile"  
    />
    <Dialog :header="$t('Delete attachment')" v-model:visible="alertModal"
      :dismissable-mask="true" 
      :style="{'width': '40rem'}">
      <div class="alertModal">
          <p>{{ $t('You are going to delete the atachment') + ` ${fileToDelete ? fileToDelete.file ? fileToDelete.file.name : fileToDelete.filename : ''} ` + $t(', are you sure?') }}</p>
          <div class="alertButtons">
              <lh-button size="medium" @click="closeModalAlert">{{ $t('NO') }}</lh-button>
              <lh-button size="medium" type="primary" @click="deleteAttachment">{{ $t('YES') }}</lh-button>
          </div>
      </div>
    </Dialog>
  </Step>
</template>

<script setup>
import Dialog from 'primevue/dialog';
import Step from "@/components/dispatch/order/utils/step";
import FileUploader from "../../common/FileUploader.vue";
</script>

<script>
export default {
  components: {
    Dialog,
    Step,
    FileUploader
  },
  emits: ['update:attachments', 'deleteAttachment'],
  data: function () {
    return {
      MAX_FILE_SIZE: 10 * 1024 * 1024,
      attachmentsLovType: 'DELIVERY_ATTACHMENT_CATEGORIES',
      attachmentsCategoryBlocked: null,
      fileToDelete: null,
      alertModal: false,
      attachments: [],
    }
  },
  created() {
    this.attachmentsCategoryBlocked = this.attachments.filter(el => el.category == 'DAP').length >0? 'DAP': ''
    this.attachments = this.delivery._source.attachments ? [...this.delivery._source.attachments] : []
  },
  computed: {
    delivery() {
      return this.$store.state.dispatch.delivery;
    }
  },
  watch: {
    '$store.state.dispatch.delivery._source.attachments'(newVal) {
      this.attachments = newVal
    }
  },
  methods: {
    async updateAttachmentsToUpload(attachments) {
      this.$store.commit('dispatch/setAttachmentsToUpload', attachments.filter(a => a.file));
    },
    handleDeleteFile(index) {
      this.attachments = [...this.attachments];
      this.fileToDelete = this.attachments[index];
      this.alertModal = true;
    },
    closeModalAlert() {
      this.alertModal = false;
      this.fileToDelete = null;
    },
    deleteAttachment() {
      if(this.fileToDelete.filepath) {
        this.$store.commit('dispatch/addAttachmentToDelete', this.fileToDelete);
        let loader = this.$loading.show()
        s3Service.deleteFile(this.fileToDelete.filepath).then((response) => { 
          this.attachments = this.attachments.filter(a => !a.filename || a.filename != this.fileToDelete.filename);
          this.$notify({
            title: this.$t('File Deleted'),
            text: this.$t('The file has been deleted correctly'),
            type: 'success',
            duration: -1
          });
          loader.hide();
        }).catch(error => {
          loader.hide();
          this.$notify({
            title: 'Deleting Error',
            text: 'An error occurred when deleting the file. Please, try again.',
            type: 'error',
            duration: -1
          });
        })
      } else {
        this.$store.commit('dispatch/setAttachmentsToUpload', this.$store.state.dispatch.attachmentsToUpload.filter(a => a.file.name != this.fileToDelete.file.name));
        this.attachments = this.attachments.filter(a => !a.file || a.file.name != this.fileToDelete.file.name);
        this.$notify({
          title: this.$t('File Deleted'),
          text: this.$t('The file has been deleted correctly'),
          type: 'success',
          duration: -1
        });
      }
      this.alertModal = false;
      this.fileToDelete = null;
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/hodim/index.scss';
@import '@/assets/css/lh-ds/core/functions';

.attachments-step {
  &.overlay::before {
    content: '';
    position: absolute;
    inset: 0;
    z-index: 1;
    background-color: #fff;
    opacity: 0.5;
  } 
}
</style>