<template>
  <Step :title="$t('Cash Sale')" class="cash-sales-step">
    <div class="columns">
      <div class="column is-one-fifth is-flex is-flex-direction-column">
        <!-- <div> -->
          <label for="lastDelivey"> {{$t('Total TTC')}} </label>
          <InputText class="mt-1" v-model="totalTTC" disabled />
        <!-- </div> -->
      </div>

      <div class="column is-one-fifth is-flex is-flex-direction-column">
        <label> {{$t('Accumulated')}} </label>
        <InputText class="mt-1" v-model="accumulated" disabled />
      </div>

      <div class="column is-one-fifth is-flex is-flex-direction-column">
        <label> {{$t('Currency')}} </label>
        <InputText class="mt-1" v-model="currency" disabled />
      </div>

      <div class="column is-one-fifth">
        <div class="is-flex is-align-items-center mb-5">
          <label for="showInputs" class="mr-3"> {{$t('reg. with the drive')}} </label>
          <Checkbox inputId="showInputs" v-model="showInputs" :binary="true" @change="savePaymentInfo"/>
        </div>
      </div>

      <div class="column is-one-fifth">
        <div class="is-flex is-align-items-center mb-5">
          <label for="lastDelivey" class="mr-3"> {{$t('Is last delivery')}} </label>
          <Checkbox inputId="lastDelivey" v-model="lastDelivey" :binary="true" @change="savePaymentInfo"/>
        </div>
      </div>
    </div>

    <div v-if="!showInputs" class="columns">
      <div class="column is-one-fifth is-flex is-flex-direction-column mr-3">
        <label> {{$t('Type payment')}} </label>
        <Dropdown class="mt-1" v-model="paymentMethod" optionLabel="name" :options="options.paymentMethods" @change="savePaymentInfo"/>
      </div>

      <div class="column is-one-fifth is-flex is-flex-direction-column pl-0">
        <label class="mr-3"> {{$t('Date')}} </label>
        <Calendar v-model="date" dateFormat="dd/mm/yy" @change="savePaymentInfo"/>
      </div>
      
      <div class="column is-one-fifth is-flex is-flex-direction-column pl-0">
        <label class="mr-3"> {{$t('Cheque number')}} </label>
        <InputText class="cheque" v-model="chequeNo" @change="savePaymentInfo"/>
      </div>

      <div class="column is-one-fifth is-flex is-flex-direction-column pl-0">
        <label class="mr-3"> {{$t('Name')}} </label>
        <InputText v-model="name" @change="savePaymentInfo"/>
      </div>

      <div class="column is-one-fifth is-flex is-flex-direction-column pl-0">
        <label class="mr-3"> {{$t('Bank')}} </label>
        <Dropdown class="mb-3" v-model="bank" optionLabel="name" :options="options.banks" @change="savePaymentInfo"/>
      </div>
    </div>
  </Step>
</template>

<script setup>
import Step from "@/components/dispatch/order-outbound/utils/step.vue";
import Dropdown from "primevue/dropdown";
import Checkbox from 'primevue/checkbox';
import InputText from "primevue/inputtext";
import Calendar from "primevue/calendar";

import CountriesService from "@/services/api/countries";
import PriceService from "@/services/api/price";
</script>

<script>
export default {
  components: {
    Step,
    Dropdown,
    Checkbox,
    InputText,
    Calendar,
  },
  data: function () {
    return {
      options: {
        paymentMethods: null,
        banks: null
      },

      showInputs: false,
      netWeight: 0,

      currency: 'Euro',
      lastDelivey: false,
      paymentMethod: null,
      date: new Date(),
      chequeNo: '',
      name: '',
      bank: null,

      totalTTC: 0,
      accumulated: 0
    };
  },
  computed: {
    // orderType() {
    //   return this.$store.state.waste.orderType;
    // },
    delivery() {
      return this.$store.state.dispatch.delivery;
    }
  },
  watch: {
    '$store.state.dispatch.netWeight': {
      handler: function (after, before) {
        this.netWeight = this.$store.state.dispatch.netWeight;
        this.getPrice();
      },
      deep: true
    }
  },
  async mounted() {
    await this.getBankingInfo();
    await this.getPaymentInfo();
    this.savePaymentInfo();
  },
  methods: {
    getPlantInfo() {
      return JSON.parse(sessionStorage.getItem("user")).plant;
    },
    async getBankingInfo() {
      const { data } = await CountriesService.getCountryLovByName('Available_Banks')
      this.options.banks = data;
      this.bank = this.options.banks[0];
    },
    async getPaymentInfo() {
      const { data } = await CountriesService.getCountryLovByName('PAYMENTS')
      this.options.paymentMethods = data;
      this.paymentMethod = this.options.paymentMethods[0];
    },
    async getPrice() {
      // const { data } = await PriceService.getCashSalePrice({ deliveryId: this.delivery._id,  quantity: this.netWeight})
      // const { price } = data;
      // this.totalTTC = price;
      // this.$store.commit('dispatch/setCashSalePrice', price);

      const { data } = await PriceService.getAllCashSalePrices({ deliveryId: this.delivery._id, quantity: this.netWeight})
      const { prices } = data;
      this.totalTTC = prices.header.pricing.totalAmount;
      this.$store.commit('dispatch/setCashSalePrice', this.totalTTC);
      this.$store.commit('dispatch/setDeliveryCashSalePrices', prices.items[0])

      const response = await PriceService.getOrderCummulativePrice({ OrderNo: this.delivery._source.OrderNo })
      const accumulativePrice = response.data.price;
      this.accumulated = Number(accumulativePrice.toFixed(2)) + Number(this.totalTTC.toFixed(2));
    },
    savePaymentInfo() {
      this.$store.commit('dispatch/setPaymentInfo', {
        paid: !this.showInputs,
        currency: this.currency,
        lastDelivery: this.lastDelivey,
        paymentMethod: this.paymentMethod,
        date: this.date,
        chequeNo: this.chequeNo,
        name: this.name,
        bank: this.bank
      });
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/hodim/index.scss";
@import "@/assets/css/lh-ds/core/functions";

.cash-sales-step {
  &__table-header {
    font-size: 1rem;
    font-weight: bold;
  }

  &.overlay::before {
    content: "";
    position: absolute;
    inset: 0;
    z-index: 1;
    background-color: #fff;
    opacity: 0.5;
  }
}

label {
  font-size: 14px;
}
</style>
