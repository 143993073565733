<script setup>
import FormControl from "@/components/common/FormControl.vue";
import Status from '@/components/common/Status.vue';
import deliveryService from "@/services/api/delivery.js";
import 'primeicons/primeicons.css';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import Dialog from 'primevue/dialog';
import * as Socket from '@/plugins/socket_client';
import * as WeightUnit from '@/helper/weightUnit';
import masterDataService from "@/services/api/masterData.js";
import commonClasses from "@/store/commonClasses.js";
import { fabric } from 'fabric';
import { app } from '@/main.js';
</script>

<script>
export default {
  components: {
    Dialog,
    Dropdown
  },
  data: function () {
    return {
      user: null,
      deliveryAux: null,
      currentVehicle: null,
      currentFWAgent: null,
      currentDriver: null,
      currentFirstWeight: null,
      currentExitWeight: null,
      manuallyWeightEntry: 0,
      manuallyWeightExit: 0,
      tare: null,
      weightLast7Days: false,
      isExactQuantityCheck: false,
      deliverySignStuff: {
        signedNow: false,
        modalVisible: this.openSignModal,
        signCanvas: null,
        signEmail: null,
        signName: null,
        deliverySigned: !(((typeof this.delivery._source.signPending !== 'undefined' ? this.delivery._source.signPending : true) && this.delivery._source.deliveryType == 'PickUp') && this.delivery._source.status == 'Shipping')
      },
      uom: 'TON',
      phase2Enabled: app.config.globalProperties.$config.general.phase2Enabled == '1',
      signPositions: [
        { name: this.$t('Carrier'), value: 'carrier'},
        { name: this.$t('Customer'), value: 'customer'}
      ],
      signPosition: 'carrier',
    }
  },
  props: {
    delivery: {},
    deliveriesList: [],
    openSignModal: false
  },
  created() {

    Socket.connect()

    this.checkFields(this.delivery);
    this.user = JSON.parse(sessionStorage.getItem("user"));
    this.getLocationShipTo()

  },
  methods: {
    async checkFields(delivery) {
      if (delivery._source.exactQuantityCheck && delivery._source.exactQuantityCheck === true) {
        this.isExactQuantityCheck = true;
      }

      if (delivery._source.firstWeight.weight !== 0) {
        this.currentFirstWeight = delivery._source.firstWeight.weight;
        if (delivery._source.firstWeight.manual === 'true') {
          this.manuallyWeightEntry = this.currentFirstWeight;
        }
      } else this.currentFirstWeight = null;

      if (delivery._source.secondWeight.weight !== null) {
        this.currentExitWeight = delivery._source.secondWeight.weight
        if (delivery._source.secondWeight.manual === 'true') {
          this.manuallyWeightExit = this.currentExitWeight;
        }
      } else this.currentExitWeight = null;

      if (this.currentFirstWeight > 0 && this.currentExitWeight > 0) {
        this.getTare(this.currentFirstWeight, this.currentExitWeight)
      } else this.tare = null;

      if (delivery._source.logistic.truck.vehicleId !== "") {
        this.currentVehicle = {
          vehicleId: delivery._source.logistic.truck.vehicleId,
          licensePlateNumber: delivery._source.logistic.truck.plateNumber,
          carrierName: delivery._source.logistic.carrier.vendorID,
          isLocal: delivery._source.logistic.truck.isLocal || false
        }
      } else this.currentVehicle = null;

      if (delivery._source.logistic.forwardingAgent.vendorID !== "") {
        this.currentFWAgent = {
          vendor: delivery._source.logistic.forwardingAgent.vendorID,
          general: {
            name: delivery._source.logistic.forwardingAgent.name
          }
        }
      } else this.currentFWAgent = null;

      this.currentDriver = {
        driverNumberId: delivery._source.logistic.driver.driverID,
        address: {
          name: delivery._source.logistic.driver.name
        }
      }

    },
    getTare(weigthEntry, weightExit) {
      if (this.isExactQuantityCheck === true) {
        this.tare = this.deliveryAux !== null ? this.deliveryAux._source.netWeight : this.delivery._source.netWeight;
      } else {
        this.tare = weightExit - weigthEntry;
      }
    },
    getMaterialCashSaleValue(material) {
      let cashSaleValue = '';
      if(this.delivery._source.products){
        const product = this.delivery._source.products.find(p => material.materialID ? p.item.code === material.materialID : p.item.code === material.material.id);
        cashSaleValue = product && product.item.cashSaleValue ? product.item.cashSaleValue : '';
      }
      return cashSaleValue;
    },
    async getLocationShipTo() {
      try {
        const shipto = await masterDataService.getShipto(this.delivery._source.shipto.shipto);
        if (shipto.data.length > 0) {
          const shiptoFind = shipto.data.find(s => s._source.shipto.shipto == this.delivery._source.shipto.shipto);
          this.delivery._source.shipto.location = shiptoFind._source.location
          console.log('SHIPTO FINDED ==>', shiptoFind)
        }
      } catch (error) {
        console.log(error)
      }
    },
    showSignModal(clearEmail) {

      this.deliverySignStuff.modalVisible = true;

      if (this.deliverySignStuff.signCanvas) {
        this.deliverySignStuff.signCanvas.dispose();
      }

      if (clearEmail) {
        this.deliverySignStuff.signEmail = null;
        this.deliverySignStuff.signName = this.delivery._source.logistic.driver.name;
      }

      setTimeout(() => { 
        let myCanvas = document.getElementById('deliverySignCanvas');
        this.deliverySignStuff.signCanvas = new fabric.Canvas("deliverySignCanvas", {
          width: myCanvas.offsetWidth,
          height: myCanvas.offsetHeight * (clearEmail ? 0.7 : 1),
          backgroundColor: "white",
          isDrawingMode: true,
        });
      }, 1000);
    },
    signDelivery(deliverySign, user) {
      this.deliverySignStuff.modalVisible = false;
      let loader = this.$loading.show();
      deliveryService.signDelivery(deliverySign, user).then((response) => {
        loader.hide();
        this.deliverySignStuff.signedNow = true;
        this.deliverySignStuff.signEmail = null;
        this.deliverySignStuff.signName = null;
        this.deliverySignStuff.deliverySigned = true;
        this.$notify({
          title: this.$t("Delivery Signed Successfully!"),
          type: "success"
        })
      }).catch(err => {
          loader.hide();
          this.deliverySignStuff.signedNow = true;
          this.deliverySignStuff.signEmail = null;
          this.deliverySignStuff.signName = null;
          this.deliverySignStuff.deliverySigned = true;
          this.$notify({
            title: this.$t("Delivery Signed Successfully!"),
            type: "success"
          })
        })
    },
    saveDeliverySign() {

      const dataURL = this.deliverySignStuff.signCanvas.toDataURL({
        width: this.deliverySignStuff.signCanvas.width,
        height: this.deliverySignStuff.signCanvas.height,
        left: 0,
        top: 0,
        format: 'png',
      });

      this.signDelivery({ name: this.deliverySignStuff.signName, email: this.deliverySignStuff.signEmail, sign: dataURL, signPosition: this.signPosition, deliveryId: this.delivery._id }, this.user);
    },
    getWeight(w) {
      return this.phase2Enabled 
              ? w >= 100 ? w / 1000 : w 
              : w;
    }
  },
  computed: {
    console: () => console,
    weight() {
      return this.$store.getters.getWiegths.length > 0
      ? this.$store.getters.getWiegths.find(w => w.bascula == "entry&exit").weight
      : 0
    },
    totalCashSaleValue() {
      if(this.delivery._source.products && this.delivery._source.products.some(p => p.item.cashSaleValue)) {
        return this.delivery._source.products.reduce((sum, p) => {
          if(p.item.cashSaleValue) {
            return sum + parseFloat(p.item.cashSaleValue);
          }
          return sum;
        }, 0);
      } else {
        return '';
      }
    }
  },
};
</script>

<template>
  <div class="modal-delivery">
    <!-- Administración del modal -->

    <div v-if="deliverySignStuff.signedNow" class="delivery_signed">
      {{ $t('Delivery signed') }}
    </div>

    <div class="modal-delivery__summary">
      <div v-if="!deliverySignStuff.signedNow" class="modal-delivery__summary-actions">
        <div class="modal-delivery__summary-actions-left">
          <div>
            <lh-button icon="edit" @click="showSignModal(true)">
              {{ $t('Sign Delivery') }}
            </lh-button>
          </div>
        </div>
      </div>

      <div class="columns">
        <div class="column is-two-fifth"
          v-if="deliveryAux === null ? (delivery._source.orderType === undefined || delivery._source.orderType === commonClasses.orderTypes.Outbound) : (deliveryAux._source.orderType === undefined || deliveryAux._source.orderType === commonClasses.orderTypes.Outbound)">
          <!-- Client -->
          <FormControl :label="$t('Customer')">
            {{ deliveryAux === null ? delivery._source.soldto.customer : deliveryAux._source.soldto.customer }}<br />
            {{ deliveryAux === null ? delivery._source.soldto.name1 : deliveryAux._source.soldto.name1 }}
          </FormControl>
          <FormControl :label="$t('Vehicle')" style="margin-top: 12px">
            {{ deliveryAux === null ? delivery._source.logistic.truck.plateNumber : deliveryAux._source.logistic.truck.plateNumber }}
          </FormControl>
        </div>
        <div class="column is-two-fifths"> <!-- Ship to -->
          <FormControl :label="$t('Ship to')"
            v-if="deliveryAux === null ? delivery._source.orderType === commonClasses.orderTypes.Outbound || delivery._source.orderType === undefined : deliveryAux._source.orderType === commonClasses.orderTypes.Outbound || deliveryAux._source.orderType === undefined">
            {{ deliveryAux === null ? delivery._source.shipto.shipto : deliveryAux._source.shipto.shipto }}<br />
            {{ deliveryAux === null ? delivery._source.shipto.address.name : deliveryAux._source.shipto.address.name }}
          </FormControl>
          <FormControl :label="$t('Driver Name')" style="margin-top: 12px">
            {{ currentDriver === null ? ' ' : currentDriver.address.name }}
          </FormControl>
        </div>
        <div class="column"> <!-- Contract -->
          <FormControl :label="$t('Contract')"
            v-if="deliveryAux === null ? delivery._source.orderType === commonClasses.orderTypes.Outbound || delivery._source.orderType === undefined : deliveryAux._source.orderType === commonClasses.orderTypes.Outbound || deliveryAux._source.orderType === undefined">
            {{ deliveryAux === null ? delivery._source.contract.contract : deliveryAux._source.contract.contract || ''
            }}
          </FormControl>
          <FormControl :label="$t('External Id')"
            v-if="deliveryAux === null ? delivery._source.externalId : deliveryAux._source.externalId"
            style="margin-top: 12px">
            {{ deliveryAux === null ? delivery._source.externalId : deliveryAux._source.externalId || ''
            }}
          </FormControl>
          <FormControl :label="$t('Haulier')" style="margin-top: 12px">
            {{ currentVehicle.carrierName + '|' }} <span v-if="currentFWAgent && currentFWAgent.general &&
                currentFWAgent.general.name">{{ currentFWAgent.general.name }}</span>
          </FormControl>
        </div><!-- Plant -->
        <div class="column" v-if="deliveryAux === null ? delivery._source.material : deliveryAux._source.material">
          <FormControl :label="$t('Plant')">
            {{ deliveryAux === null ? delivery._source.material[0].plant : deliveryAux._source.material[0].plant }}
          </FormControl>
        </div>
        <div class="column"> <!-- Status -->
          <FormControl v-if="deliveryAux === null ? delivery._source.status : deliveryAux._source.status"
            :label="$t('Status')" disableGrid>
            <Status
              :name="deliveryAux === null ? delivery._source.status : deliveryAux._source.status" />
          </FormControl>
        </div>
      </div>
    </div>

    <!-- Panel WEIGHT -->
    <lh-collapse
      v-if="deliveryAux === null ? delivery._source.status !== 'Deleted' : deliveryAux._source.status !== 'Deleted'"
      summary="WEIGHT" open>

      <div class="columns">
        <div class="column">
          <div class="control is-horizontal">
            <div class="control__label" style="width: 110px;">{{ $t('Entry Weight') }}</div>
            <div class="control__input">
              <InputText :style="{
                width: '120px', height: '50px', 'color': weightLast7Days === true ? '#0089FF' : manuallyWeightEntry > 0 ? '#e08600' : '#000',
                'border-color': weightLast7Days === true ? '#0089FF' : manuallyWeightEntry > 0 ? '#e08600' : '#d8d8d8'
              }" :disabled="true" id="Entry"
                :value="currentFirstWeight > 0 ? (getWeight(currentFirstWeight) + ' ' +  uom) : manuallyWeightEntry" />
            </div>
          </div>
        </div>
        <div class="column">
          <div class="control is-horizontal">
            <div class="control__label" style="width: 100px;">{{ $t('Exit Weight') }}</div>
            <div class="control__input">
              <InputText :style="{
                width: '120px', height: '50px', 'color': manuallyWeightExit > 0 ? '#e08600' : '#000',
                'border-color': manuallyWeightExit > 0 ? '#e08600' : '#d8d8d8'
              }" :disabled="true" id="Exit"
                :value="currentExitWeight > 0 ? (getWeight(currentExitWeight) + ' ' +  uom) : manuallyWeightExit" />
            </div>
          </div>
        </div>
        <div class="column">
          <div class="control is-horizontal">
            <div class="control__label" style="width: 110px;">{{ $t('Net Weight') }}</div>
            <div class="control__input">
              <InputText :style="{ width: '120px', height: '50px' }" :disabled="true"
                :value="(Math.round((getWeight(tare) + Number.EPSILON) * 100) / 100 + ' ' +  uom)" />
              <p v-if="isExactQuantityCheck === true" :style="{ 'color': '#e08600', 'margin-top': '15px' }">{{ $t('Exact Quantity Delivery') }}</p>
            </div>
          </div>
        </div>
      </div>

    </lh-collapse>

    <!-- Panel MATERIALS AND SERVICES -->
		<lh-collapse :summary="$t('MATERIALS AND SERVICES')" class="materials-services">

			<div class="header">
				<div class="c-material">{{ $t('Material') }}</div>
				<div class="c-weight">{{ $t('Quantity') }}</div>
        <div class="c-cash">{{ $t('Cash Sale Value') }}</div>
			</div>

			<div class="body"
				v-for="(material) in delivery._source.material">

				<div class="c-material body-item">
					{{ material.descriptions[0].text }}
				</div>

				<div class="c-weight body-item">
					{{ (material.baseUoMquantity + ' ' + WeightUnit.getUnitISOCode(material.baseUoM || unitMeasurement || 'TNE', lovs)) }}
				</div>

        <div class="c-cash body-item">
					{{ getMaterialCashSaleValue(material) }}
				</div>

			</div>

      <div class="footer" v-if="delivery._source.products && delivery._source.products.some(p => p.item.cashSaleValue)">
				<div class="c-material body-item"></div>
				<div class="c-weight body-item">TOTAL</div>
        <div class="c-cash body-item">
					{{ totalCashSaleValue }}
				</div>
			</div>
		</lh-collapse>

    <!-- Panel NOTES -->
    <lh-collapse summary="NOTES">
      <div class="columns" v-if="delivery._source.notes">
        <div class="column">
          <FormControl label="Delivery Note Instructions">
            {{
              deliveryAux === null ?
              delivery._source.notes.DeliveryNoteInstructions :
              deliveryAux._source.notes.DeliveryNoteInstructions
            }}
          </FormControl>
        </div>
        <div class="column">
          <FormControl label="Dispatcher Instructions">
            {{
              deliveryAux === null ?
              delivery._source.notes.DispatcherInstructions :
              deliveryAux._source.notes.DispatcherInstructions
            }}
          </FormControl>
        </div>
      </div>
    </lh-collapse>

    <Dialog :header="'Sign Delivery'"
        v-model:visible="deliverySignStuff.modalVisible" :style="{ width: '90%', height: '90%' }"
        :dismissable-mask="true" closable :modal="true" @hide="closeNewModal">
        <div class="modal-delivery__signModalContainer">
          <div class="modal-delivery__signModalForm">
            <div class="modal-delivery__signModalFormInput">
              <div class="label__input">
                <div class="control__label">{{ $t('Email') }}</div>
              </div>
              <div class="control__input">
                <InputText :style="{ width: '100%', height: '50px' }" v-model="deliverySignStuff.signEmail" />
              </div>
            </div>
            <div class="modal-delivery__signModalFormInput">
              <div class="label__input">
                <div class="control__label">{{ $t('Name') }}</div>
              </div>
              <div class="control__input">
                <InputText :style="{ width: '100%', height: '50px' }" v-model="deliverySignStuff.signName" />
              </div>
            </div>

            <div v-if="user.country.code == 'FR'" class="modal-delivery__signModalFormInput" style="width: 25%;">
              <div class="label__input">
                <div class="control__label">{{ $t('Print as') }}</div>
              </div>
              <Dropdown v-model="signPosition" :options="signPositions" optionLabel="name" optionValue="value" @change="selectSignPostion()" />

            </div>
            
          </div>

          <div class="label__input">
            <div class="control__label">{{ $t('Sign') }}</div>
          </div>
          <canvas id="deliverySignCanvas" style="border: 1px solid #d8d8d8; margin: 10px 0px 10px 0px; width: 100%; height: 50%;" />
          <div class="modal-delivery__summary-actions-bottom">
            <lh-button icon="edit" @click="showSignModal(false)">
              {{ $t('Clear sign') }}
            </lh-button>
            <lh-button icon="save" @click="saveDeliverySign()">
              {{ $t('Save') }}
            </lh-button>
          </div>
        </div>
    </Dialog>

  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/css/hodim/index.scss';
@import '@/assets/css/lh-ds/core/functions';

.delivery_signed {
  background-color: aquamarine;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  font-weight: bold;
}

.materials-services {

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: bold;
    font-size: 1rem;
    margin-bottom: 10px;
  }

  .body, .footer {
    border-top: solid 1px #ccc;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .body-item {
    height: 50px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .c-material {
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: start;
    padding-right: 15px;
  }

  .footer .c-material {
    width: 71%;
  }

  .c-weight {
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: start;
    padding-right: 15px;
  }

  .footer .c-weight {
    width: 9%;
    font-weight: bold;
  }

  .c-cash {
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: start;
    padding-right: 15px;
  }
}

.modal-delivery {

  &__signModalContainer {
    padding: 20px;
  }

  &__signModalForm {
    display: flex;
    align-items: center;
    justify-content: start;
  }

  &__signModalFormInput {
    margin-right: 20px;
    width: 100%;

    .p-dropdown.p-component.p-inputwrapper.p-inputwrapper-filled {
      border: 1px solid #d8d8d8;
    }
  }

  $bg-color: #F6F6F6;
  //width: 1200px;

  &__summary,
  lh-collapse::part(header),
  lh-collapse::part(body) {
    padding-left: var(--padding-h);
    padding-right: var(--padding-h);
    padding: 15px;
  }

  &__summary {
    padding-top: rem(14px);
    padding-bottom: rem(38px);
    background-color: $bg-color;
    padding: 15px;

    &-actions {
      display: flex;
      justify-content: space-between;

      margin-bottom: rem(42px);

      &-left {
        *+* {
          margin-right: rem(16px);
        }

        lh-button {
          display: inline-block;
          margin-right: rem(16px);
          margin-bottom: rem(16px);
        }
      }

      &-right {
        width: 145px;
        margin-left: rem(16px);

        button {
          display: inline-block;
          margin-right: rem(16px);
        }
      }

      &-bottom {
        display: flex;
        justify-content: space-between;
        margin: 20px 0px 20px 0px;
      }

    }
  }

  .label__input {
    width: 60px;
    margin-top: 12px;
  }

  lh-collapse {
    border-top: 2px solid #EEEEEE;

    &::part(header) {
      box-sizing: border-box;
      height: rem(56px);
      background-color: $bg-color;
      font-size: rem(14px);
    }
  }

  .form-control {
    font-size: rem(14px);
  }

  .control {
    &__label {
      display: flex;
      align-items: center;
      font-weight: bold;
      width: 60px;
    }

    &.is-horizontal {
      display: flex;

      .control {
        &__input {
          display: flex;
          margin-left: rem(20px);
          font-size: 0.875rem;

          *+* {
            margin-left: rem(20px);
          }

        }
      }
    }
  }


}
</style>