
'use strict';
import { app } from '@/main.js'


const LogService = {
    getDeliveryLog: async (deliveryId) => {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.get(`/delivery/getDeliveryLog/${deliveryId}`).then(response => {
                resolve(response)
            }).catch(error => {
                reject({ ret: error.response.status, msg: error.response.data });
            });
        })
    },
    getOrderLog: async (orderId) => {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.get(`/order/getOrderHistory/${orderId}`).then(response => {
                resolve(response)
            }).catch(error => {
                reject({ ret: error.response.status, msg: error.response.data });
            });
        })
    }
}

export default LogService;