<script setup>
import moment from 'moment';
import Button from 'primevue/button';
import Checkbox from 'primevue/checkbox';
import 'primeicons/primeicons.css';

import DeliveryService from "@/services/api/delivery.js";
import PlantsService from '@/services/api/plants';

import FormControl from "@/components/common/FormControl.vue";
import MapRoutes from '@/components/Deliveries/map-position/MapRoutes.vue';
import ETAComponent from "@/components/common/ETA.vue";
import Step from "@/components/dispatch/order-outbound/utils/step.vue";
import { DeliveryTour, Tour } from '@/components/Deliveries/planner/core/tour';
import { subtractMinutesNoFormat, addMinutesNoFormat, addMinutesTourFormat } from "@/components/Deliveries/planner/helpers/time";

</script>

<script>

export default {
    props: ["delivery"],
    data: function () {
        return {
            loadMap: false,
            route: null,
            calendar: JSON.parse(localStorage.getItem('dateFormat')).moment || 'DD/MM/YYYY',
            routeToCalculate: null,
            calculatingRoute: false,
            milesConversion: 0.621371,
            routeMiles: null,
            distanceUoM : 'KM',
            hotStorageParam: null,
            isHotStorage: false,
            userTimezone: JSON.parse(sessionStorage.getItem('user')).country.timezone,
            country : JSON.parse(sessionStorage.getItem('user')).country,
            plant: null
        }
    },
    mounted() {
        setTimeout(() => {
            this.initParams();
            console.log('PLANT ==>', this.plant)
            this.route = this.delivery._source.Tour.awayTrip.route

            this.loadMap = true;
            this.calculateDistance(this.delivery._source.Tour.awayTrip.distance)
        }, 700);
    },
    components: {
        MapRoutes,
        Button,
        Checkbox,
        Step,
        ETAComponent,
        DeliveryTour,
        Tour,
        FormControl
    },
    methods: {
        calculateDistance(distance) {
            console.log('DISTANCE === ', distance)
            console.log('country === ', this.country)
            if(this.country.code == 'GB'){
                this.routeMiles = this.delivery._source.Tour.awayTrip.distance !== undefined ? 
                (this.delivery._source.Tour.awayTrip.distance.toFixed(2) * this.milesConversion).toFixed(2) 
                : "0";
                this.distanceUoM = 'M'
            }else {
                this.routeMiles = this.delivery._source.Tour.awayTrip.distance !== undefined ? 
                (this.delivery._source.Tour.awayTrip.distance.toFixed(2))
                : "0";
                this.distanceUoM = 'KM'
            }
        },
        async initParams() {
            try {
                // Cargar la planta de la delivery
                let plantParams = await PlantsService.getPlantByCode(this.delivery._source.material[0].plant);
			    this.plant = plantParams.data;
                // lo otro
                const user = JSON.parse(sessionStorage.getItem("user"));
                if((this.plant && user.plant.id !== this.plant.id) || user.plant.code !== this.delivery._source.material[0].plant) {
                    const { data: params } = await PlantsService.getPlantParameterByPlantCode(this.delivery._source.material[0].plant, 'hot_storage');
                    this.hotStorageParam = params[0][0] || null;
                } else {
                    this.hotStorageParam = user.plant.plant_parameters.find(p => p.code === 'hot_storage');
                }
                this.isHotStorage = this.delivery._source.hotStorageTime ? true : false;
            } catch (error) {
                console.log(error);
            }
        },
        removeTimestamp(time) {
            return time.replace('Z', '');
        },
        recalculateRoute() {
            this.route = 0;
            this.calculatingRoute = true
            this.routeToCalculate = null;

            let lon, lat;
            if (this.delivery._source.location) {
                if(this.delivery._source.location.lat && this.delivery._source.location.lon ){
                    lon = this.delivery._source.location.lon;
                    lat = this.delivery._source.location.lat;
                }
            } else {
                if(this.delivery._source.Tour.shiptoLocation){
                    lon = this.delivery._source.Tour.shiptoLocation.lon;
                    lat = this.delivery._source.Tour.shiptoLocation.lat;
                }
            }

            if (!lon || !lat) {
                this.$notify({
                    title: this.$t('Shipto location'),
                    text: this.$t('There`s no shipto selected'),
                    type: 'error',
                    duration: 5000
                })
                return
            }
            this.routeToCalculate = {
                origin: { lat: this.plant.lat, lng: this.plant.lon },
                destination: { lat: lat, lng: lon },
                siteArrival: this.delivery._source.Tour.planned.unloadingLocationEntered,
                returnDeparture: this.delivery._source.Tour.planned.unloadingLocationLeft,
            }

            console.log('NEW ROUTE  ', this.routeToCalculate);
        },
        routeCalculated(ev) {
            if (ev === undefined) {
                this.route = ''
                this.$notify({
                    title: this.$t('Shipto location is no valid'),
                    text: this.$t('Check the shipto selected'),
                    type: 'error',
                    duration: 5000
                })
            } else {
                this.route = ev.sections[0].polyline
                const tourRouteToSave = {
                    id: this.delivery._id,
                    awaitTripRoute: ev.sections[0].polyline,
                    duration: ev.sections[0].travelSummary.duration / 60,
                    distance: ev.sections[0].travelSummary.length / 1000,
                    returnDuration: ev.returnDuration,
                }

                this.routeMiles = (tourRouteToSave.distance.toFixed(2) * this.milesConversion).toFixed(2);

                let tourRecalculation = {
                    id: this.delivery._source.deliveryNO,
                    previous_tour: this.delivery._source.Tour,
                    updated_tour: this.changeTripDuration(tourRouteToSave.duration, tourRouteToSave.returnDuration, this.delivery._source.Tour.awayTrip.duration)
                }

                this.delivery._source.Tour.planned = {...tourRecalculation.updated_tour.planned};
                this.delivery._source.Tour.customer = {...tourRecalculation.updated_tour.customer};

                DeliveryService.saveTourRecalculation(tourRecalculation).then(res => {
                    console.log(res)
                }).catch(err => console.log(err))

                DeliveryService.updateDeliveryTourRoute(tourRouteToSave).then(res => {
                    console.log(res)
                }).catch(err => console.log(err))

                this.delivery._source.Tour.awayTrip.route = tourRouteToSave.awaitTripRoute;
                this.delivery._source.Tour.awayTrip.duration = tourRouteToSave.duration;
                this.delivery._source.Tour.awayTrip.distance = tourRouteToSave.distance;

            }
            this.calculatingRoute = false
        },
        changeTripDuration(duration, returnDuration, previousDuration) {

            let newDeliveryTour = new DeliveryTour(new Tour(), new Tour());
            newDeliveryTour.actual = {...this.delivery._source.Tour.actual};
            newDeliveryTour.planned = {...this.delivery._source.Tour.planned};
            newDeliveryTour.customer = {...this.delivery._source.Tour.customer};
            newDeliveryTour.fixedStartTime = true;

            let changeTripDuration = (tour, previousDuration, duration, returnDuration) => {
                let tourSchedule = new Tour();
                tourSchedule = {...tour};
                tourSchedule.deliveryStarted = subtractMinutesNoFormat(addMinutesNoFormat(tourSchedule.deliveryStarted, previousDuration), duration);
                tourSchedule.loadStarted = subtractMinutesNoFormat(addMinutesNoFormat(tourSchedule.loadStarted, previousDuration), duration);
                tourSchedule.loadEnded = subtractMinutesNoFormat(addMinutesNoFormat(tourSchedule.loadEnded, previousDuration), duration);
                tourSchedule.loadingLocationLeft = subtractMinutesNoFormat(tourSchedule.unloadingLocationEntered, duration);
                tourSchedule.loadingLocationEntered = addMinutesNoFormat(tourSchedule.unloadingLocationLeft, returnDuration);
                return tourSchedule;
            };

            newDeliveryTour.planned = changeTripDuration(newDeliveryTour.planned, previousDuration, duration, returnDuration);
            newDeliveryTour.customer = changeTripDuration(newDeliveryTour.customer, previousDuration, duration, returnDuration);

            return newDeliveryTour;
        },
        isSameDay(firstDate, secondDate) {
            const fDate = new Date(firstDate);
            const sDate = new Date(secondDate)
            return fDate.getDate() === sDate.getDate();
        },
        recalculateTourDates(hotStorage) {
            let hotStorageMinutes = hotStorage ? this.hotStorageParam.value : this.hotStorageParam.value * -1;

            const newDeliveryTour = new DeliveryTour(new Tour(), new Tour(), new Tour());
            newDeliveryTour.planned = this.addHotStorage(this.delivery._source.Tour.planned, hotStorageMinutes);
            newDeliveryTour.customer = this.addHotStorage(this.delivery._source.Tour.customer, hotStorageMinutes);

            DeliveryService.updateDeliveryTourDates(this.delivery._id, newDeliveryTour, hotStorage ? hotStorageMinutes : null).then(res => {
                this.delivery._source.Tour.planned = newDeliveryTour.planned;
                this.delivery._source.Tour.customer = newDeliveryTour.customer;
            }).catch(err => console.log(err));
        },
        addHotStorage(tour, minutes) {
            const updatedTour = { ...tour };
            updatedTour.loadingLocationLeft = addMinutesTourFormat(tour.loadingLocationLeft, minutes);
            updatedTour.unloadingLocationEntered = addMinutesTourFormat(tour.unloadingLocationEntered, minutes);
            updatedTour.unloadingStarted = addMinutesTourFormat(tour.unloadingStarted, minutes);
            updatedTour.unloadingEnded = addMinutesTourFormat(tour.unloadingEnded, minutes);
            updatedTour.unloadingLocationLeft = addMinutesTourFormat(tour.unloadingLocationLeft, minutes);
            updatedTour.deliveryAccepted = addMinutesTourFormat(tour.deliveryAccepted, minutes);
            return updatedTour;
        }
    },
    computed: {
        realETA() {
            const { actual: actualTour, planned: plannedTour } = this.delivery._source.Tour;
            const dates = {
                loadingLocationLeft: actualTour.loadingLocationLeft,
                loadEnded: actualTour.loadEnded,
                loadStarted: actualTour.loadStarted,
                deliveryStarted: actualTour.deliveryStarted,
            }
            let plannedETAminutes;
            let lastDate;
            for (const [key, value] of Object.entries(dates)) {
                if(value) {
                    plannedETAminutes = moment(plannedTour.unloadingLocationEntered).diff(moment(plannedTour[key]), 'minutes');
                    lastDate = value;
                    break;
                }
            }
            return moment(lastDate).add(plannedETAminutes, 'minutes');
        }
    }
}
</script>

<template>
    <Step :title="$t('Tour')">
        <div class="columns" v-if="delivery._source.abandoned">
            <div class="column">
                <div class="columnWrapper">
                    <FormControl class="dateAndTime" :label="$t('No Signature')">
                        <span class="ml-2"></span>
                    </FormControl>
                </div>
            </div>
        </div>
        <div class="columns" v-if="hotStorageParam">
            <div class="column" style="padding-bottom: 0px">
                <div class="columnWrapper">
                    <FormControl class="form-control--reverse" :label="$t('Hot Storage')">
                        <Checkbox class="p-checkbox-m0" v-model="isHotStorage" :binary="true" @update:modelValue="recalculateTourDates"/>
                    </FormControl>
                </div>
            </div>
        </div>
        <div class="columns">
            <div class="column">
                <div class="columnWrapper">
                    <FormControl class="dateAndTime" :label="$t('Date')">
                        <span class="ml-2">{{ moment(delivery._source.Tour.planned.deliveryStarted).format(calendar) }}</span>
                    </FormControl>
                    <FormControl class="dateAndTime" :label="$t('Distance')">
                        <span class="ml-2">{{ routeMiles }} {{distanceUoM}}</span>
                    </FormControl>
                </div>
                <div class="columnMap ml-3">
                    <MapRoutes v-if="loadMap && (route !== 'noRoute' && route !== '' && route !== undefined)" :route="route"
                        :routeToCalculate="routeToCalculate" @routeCalculate="routeCalculated" />
    
                    <lh-button type="primary" @click="recalculateRoute" :disabled="calculatingRoute" style="margin-top: 20px;">
                        {{ $t('Recalculate Route') }}
                    </lh-button>
                </div>
            </div>
            <div class="column">
                <div class="columnWrapper">
                    <table class="table">
                        <tr>
                            <td></td>
                            <td>
                                <ETAComponent :block="true"
                                    :time="moment(delivery._source.Tour.customer.unloadingLocationEntered).tz(userTimezone).format('HH:mm')" />
                            </td>
                            <td>
                                <ETAComponent :block="false"
                                    :time="moment(delivery._source.Tour.planned.unloadingLocationEntered ).tz(userTimezone).format('HH:mm')" />
                            </td>
                            <td>
                                <ETAComponent :block="false" v-if="Object.values(delivery._source.Tour.actual).some(value => value)"
                                    :time="moment(delivery._source.Tour.actual.unloadingLocationEntered !== null ? delivery._source.Tour.actual.unloadingLocationEntered : 
                                    realETA).tz(userTimezone).format('HH:mm')" />
                            </td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>
                                <b>{{ $t('Customer') }}</b>
                            </td>
                            <td>
                                <b>{{ $t('Planned') }}</b>
                            </td>
                            <td>
                                <b>{{ $t('Real') }}</b>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="stepColor stepColor--deliveryStarted" /><b>Site Entered</b>
                            </td>
                            <td class="tableContent">
                                {{ delivery._source.Tour.customer &&
                                    moment(delivery._source.Tour.customer.deliveryStarted).tz(userTimezone).format('HH:mm') }}
                            </td>
                            <td class="tableContent">
                                {{ moment(delivery._source.Tour.planned.deliveryStarted).tz(userTimezone).format('HH:mm') }}
                            </td>
                            <td class="tableContent">
                                {{ delivery._source.Tour.actual.deliveryStarted !== null ?
                                    moment(delivery._source.Tour.actual.deliveryStarted).tz(userTimezone).format('HH:mm') : '' }} 
                                <i v-if="delivery._source.Tour.actual.deliveryStarted !== null && delivery._source.logistic.isPreloaded === 'true'" 
                                    class="fa-solid fa-moon"/>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="stepColor stepColor--loadStarted" /><b>Loading Start</b>
                            </td>
                            <td class="tableContent">
                                {{ delivery._source.Tour.customer &&
                                    moment(delivery._source.Tour.customer.loadStarted).tz(userTimezone).format('HH:mm') }}
                            </td>
                            <td class="tableContent">
                                {{ moment(delivery._source.Tour.planned.loadStarted).tz(userTimezone).format('HH:mm') }}
                            </td>
                            <td class="tableContent">
                                {{ delivery._source.Tour.actual.loadStarted !== null ?
                                    moment(delivery._source.Tour.actual.loadStarted).tz(userTimezone).format('HH:mm') : '' }} 
                                <i v-if="delivery._source.Tour.actual.loadStarted !== null && delivery._source.logistic.isPreloaded === 'true'" 
                                    class="fa-solid fa-moon" />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="stepColor stepColor--loadEnded" /><b>Loading End</b>
                            </td>
                            <td class="tableContent">
                                {{ delivery._source.Tour.customer &&
                                    moment(delivery._source.Tour.customer.loadEnded).tz(userTimezone).format('HH:mm') }}
                            </td>
                            <td class="tableContent">
                                {{ moment(delivery._source.Tour.planned.loadEnded).tz(userTimezone).format('HH:mm') }}
                            </td>
                            <td class="tableContent">
                                {{ delivery._source.Tour.actual.loadEnded !== null ?
                                    moment(delivery._source.Tour.actual.loadEnded).tz(userTimezone).format('HH:mm') : '' }} 
                                <i v-if="delivery._source.Tour.actual.loadEnded !== null && delivery._source.logistic.isPreloaded === 'true' 
                                    && !isSameDay(delivery._source.Tour.planned.loadEnded, delivery._source.Tour.actual.loadEnded)" 
                                    class="fa-solid fa-moon" />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="stepColor stepColor--loadingLocationLeft" /><b>Site Exit</b>
                            </td>
                            <td class="tableContent">
                                {{ delivery._source.Tour.customer &&
                                    moment(delivery._source.Tour.customer.loadingLocationLeft).tz(userTimezone).format('HH:mm') }}
                            </td>
                            <td class="tableContent">
                                {{ moment(delivery._source.Tour.planned.loadingLocationLeft).tz(userTimezone).format('HH:mm') }}
                            </td>
                            <td class="tableContent">
                                {{ delivery._source.Tour.actual.loadingLocationLeft !== null ?
                                    moment(delivery._source.Tour.actual.loadingLocationLeft).tz(userTimezone).format('HH:mm') : '' }} 
                                <i v-if="delivery._source.Tour.actual.loadingLocationLeft !== null && delivery._source.logistic.isPreloaded === 'true' 
                                    && !isSameDay(delivery._source.Tour.planned.loadingLocationLeft, delivery._source.Tour.actual.loadingLocationLeft)" 
                                    class="fa-solid fa-moon" />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="stepColor stepColor--unloadingLocationEntered" /><b>Customer Arrival</b>
                            </td>
                            <td class="tableContent">
                                {{ delivery._source.Tour.customer &&
                                    moment(delivery._source.Tour.customer.unloadingLocationEntered).tz(userTimezone).format('HH:mm') }}
                            </td>
                            <td class="tableContent">
                                {{ moment(delivery._source.Tour.planned.unloadingLocationEntered).tz(userTimezone).format('HH:mm') }}
                            </td>
                            <td class="tableContent">
                                <div v-if="delivery._source.Tour.actual.unloadingLocationEntered == null" style="width: 60px;">
                                </div>
                                {{ delivery._source.Tour.actual.unloadingLocationEntered !== null ?
                                    moment(delivery._source.Tour.actual.unloadingLocationEntered).tz(userTimezone).format('HH:mm') : '' }}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="stepColor stepColor--unloadingStarted" /><b>Unloading Start</b>
                            </td>
                            <td class="tableContent">
                                {{ delivery._source.Tour.customer &&
                                    moment(delivery._source.Tour.customer.unloadingStarted).tz(userTimezone).format('HH:mm') }}
                            </td>
                            <td class="tableContent">
                                {{ moment(delivery._source.Tour.planned.unloadingStarted).tz(userTimezone).format('HH:mm') }}
                            </td>
                            <td class="tableContent">
                                {{ delivery._source.Tour.actual.unloadingStarted !== null ?
                                    moment(delivery._source.Tour.actual.unloadingStarted).tz(userTimezone).format('HH:mm') : '' }}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="stepColor stepColor--unloadingEnded"/><b>Unloading End</b>
                            </td>
                            <td class="tableContent">
                                {{ delivery._source.Tour.customer &&
                                    moment(delivery._source.Tour.customer.unloadingEnded).tz(userTimezone).format('HH:mm') }}
                            </td>
                            <td class="tableContent">
                                {{ moment(delivery._source.Tour.planned.unloadingEnded).tz(userTimezone).format('HH:mm') }}
                            </td>
                            <td class="tableContent">
                                {{ delivery._source.Tour.actual.unloadingEnded !== null ?
                                    moment(delivery._source.Tour.actual.unloadingEnded).tz(userTimezone).format('HH:mm') : '' }}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="stepColor stepColor--deliveryAccepted" /><b>Signature</b>
                            </td>
                            <td class="tableContent">
                                {{ delivery._source.Tour.customer &&
                                    moment(delivery._source.Tour.customer.deliveryAccepted).tz(userTimezone).format('HH:mm') }}
                            </td>
                            <td class="tableContent">
                                {{ moment(delivery._source.Tour.planned.deliveryAccepted).tz(userTimezone).format('HH:mm') }}
                            </td>
                            <td class="tableContent">
                                {{ delivery._source.Tour.actual.deliveryAccepted !== null ?
                                    moment(delivery._source.Tour.actual.deliveryAccepted).tz(userTimezone).format('HH:mm') : '' }}
                            </td>
                        </tr>
    
                    </table>
                </div>
            </div>
        </div>

    </Step>
</template>

<style lang="scss" scoped>
@import "@/assets/css/lh-ds/core/functions";
@import "@/assets/css/hodim/layout/data-list";

.dateAndTime {
    display: flex;
    padding: 5px;

    .content {
        padding-left: 7px;
    }
}

.form-control--reverse {
    display: flex; 
    flex-direction: row-reverse; 
    gap: 0.5rem;
}

table tr td:first-child {
    text-align: left !important;
}

table tr td {
    text-align: center !important;
    padding: 0 !important
}

table tr,
table td {
    background-color: white;
    height: 30px !important;
}

.columnWrapper {
    padding: 7px;
    display: flex;
    justify-content: space-between;
}

.columnMap {
    width: 100%;
    height: 80%;
}


.table {
    border-collapse: separate;
    border-spacing: 10px;
}

.tableContent {
    padding: 3px;
    text-align: right;
    margin-left: 5px;
}

.totalCell {
    margin-left: 20px;
}

.stepColor {
    display: inline-block;
    border: 1px solid;
    padding: 6px 6px;
    margin-right: 5px;

    &--deliveryStarted {
        background: #B4C8E6;
        border-color: #B4C8E6;
    }

    &--loadStarted {
        background: #006EBE;
        border-color: #006EBE;
    }

    &--loadEnded {
        background: #325596;
        border-color: #325596;
    }

    &--loadingLocationLeft {
        background: #6E32A0;
        border-color: #6E32A0;
    }

    &--unloadingLocationEntered {
        background: #FFFF00;
        border-color: #FFFF00;
    }

    &--unloadingStarted {
        background: #FFD764;
        border-color: #FFD764;
    }

    &--unloadingEnded {
        background: #FFD764;
        border-color: #FFD764;
    }

    &--deliveryAccepted {
        background: #00AF50;
        border-color: #00AF50;
    }

    &--unloadingLocationLeft {
        background: #9B55CD;
        border-color: #9B55CD;
    }

    &--loadingLocationEntered {
        background: #B4C8E6;
        border-color: #B4C8E6;
    }
}
</style>