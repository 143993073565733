import moment from "moment-timezone";
import { getTimeZone } from "@/helper/rocks-time";

const SCHEDULER_DATE_FORMAT = "YYYY-MM-DDTHH:mm:ss";
const SCHEDULER_TIME_FORMAT = "HH:mm:ss";
const SCHEDULER_UTF_FORMAT = "HH:mm:ssZ";
const TOUR_DATE_FORMAT = "YYYY-MM-DDTHH:mm:ssZ";

function parseDate(date) {
  if(!moment(date).isValid()){
    return null
  }
  const timeZone = getTimeZone();
  return moment.tz(date, timeZone);
}

function getCurrentDate() {
  const today = moment().format(SCHEDULER_DATE_FORMAT);
  return today
}

function getCurrentTZDate() {
  const today = moment().tz(getTimeZone());
  return today.format(SCHEDULER_DATE_FORMAT);
}

function getCurrentTime() {
  const timeZone = getTimeZone();
  const currentHour = moment.tz(new Date(), timeZone).format(SCHEDULER_TIME_FORMAT);
  return currentHour
}

function getCurrentMinute() {
  const timeZone = getTimeZone();
  const currentMinute = moment.tz(new Date(), timeZone).format("mm");
  return currentMinute
}

function formatDate(date) {
  if(!moment(date).isValid()){
    return null
  }

  const timeZone = getTimeZone();
  const formatedDate = moment.tz(date, timeZone).format(SCHEDULER_DATE_FORMAT);
  return formatedDate
}

function formatTime(date) {
  if(!moment(date).isValid()){
    return null
  }

  const timeZone = getTimeZone();
  const formatedDate = moment.tz(date, timeZone).format(SCHEDULER_TIME_FORMAT);
  return formatedDate
}

function toUTFHour(date) {
  if(!moment(date).isValid()){
    return null
  }
  const timeZone = getTimeZone();
  const formatedDate = moment.tz(date, timeZone).format(SCHEDULER_UTF_FORMAT);
  return formatedDate
}

function addHours(date, hours) {
  const newDate = addTime(date, hours, "hours");
  return newDate;
}

function addMinutes(date, mins) {
  const newDate = addTime(date, mins, "minutes");
  return newDate;
}

function addSeconds(date, secs) {
  const newDate = addTime(date, secs, "seconds");
  return newDate;
}

function addMilliseconds(date, milis) {
  const newDate = addTime(date, milis, "milliseconds");
  return newDate;
}

function addTime(date, time, type) {
  if(!moment(date).isValid()){
    return null
  }

  const timeZone = getTimeZone();
  const newDate = moment.tz(date, timeZone).add(time, type).format(SCHEDULER_DATE_FORMAT);
  return newDate;
}

function addMinutesTourFormat(date, time) {
  if(!moment(date).isValid()){
    return null
  }

  const timeZone = getTimeZone();
  const newDate = moment.tz(date, timeZone).add(time, "minutes").format(TOUR_DATE_FORMAT);
  return newDate;
}

function addMinutesNoFormat(date, time) {
  if(!moment(date).isValid()){
    return null
  }

  const timeZone = getTimeZone();
  const newDate = moment.tz(date, timeZone).add(time, "minutes").format();
  return newDate;
}

function addMillisecondsNoFormat(date, time) {
  if(!moment(date).isValid()){
    return null
  }

  const timeZone = getTimeZone();
  let newDate;
  if(Math.abs(time) > 14 * 60 * 60 * 1000 && time > 0) {
    newDate = moment.tz(date, timeZone).add(time, "milliseconds").subtract(1, 'days').format();
  } else if(Math.abs(time) > 14 * 60 * 60 * 1000 && time < 0){
    newDate = moment.tz(date, timeZone).add(time, "milliseconds").add(1, 'days').format();
  } else {
    newDate = moment.tz(date, timeZone).add(time, "milliseconds").format();
  }
  return newDate;
}

function subtractTime(date, time, type) {
  if(!moment(date).isValid()){
    return null
  }

  const timeZone = getTimeZone();
  const newDate = moment.tz(date, timeZone).subtract(time, type).format(SCHEDULER_DATE_FORMAT);
  return newDate;
}

function subtractMinutesNoFormat(date, time) {
  if(!moment(date).isValid()){
    return null
  }

  const timeZone = getTimeZone();
  const newDate = moment.tz(date, timeZone).subtract(time, "minutes").format();
  return newDate;
}

function snapMinutesTo15(date) {
  return snapMinutesTo(date, 15);
}

function snapMinutesTo(date, snapTo) {
  if(!moment(date).isValid()){
    return null
  }
  const timeZone = getTimeZone();
  const minutes = moment.tz(date, timeZone).minutes();
  const snappedMinutes = Math.round(minutes / snapTo) * snapTo;
  const cleanDate = moment.tz(date, timeZone).set("minutes", snappedMinutes).format("YYYY-MM-DDTHH:mm:00");
  return cleanDate
}

function getMinsFromNow(date) {
  const now = getCurrentDate();
  const minutes = getMinsBetweenDates(date, now);
  return minutes;
}

function getMillisecondsBetweenDates(start, end) {
  const timeZone = getTimeZone();
  const startDate = moment.tz(start, timeZone);
  const endDate = moment.tz(end, timeZone);
  if(!startDate.isValid() || !endDate.isValid()){
    return 0
  }
  const milliseconds = moment.duration(endDate.diff(startDate)).asMilliseconds();
  return milliseconds;
}


function getMinsBetweenDates(start, end) {
  const timeZone = getTimeZone();
  const startDate = moment.tz(start, timeZone);
  const endDate = moment.tz(end, timeZone);
  if(!startDate.isValid() || !endDate.isValid()){
    return 0
  }
  const minutes = moment.duration(endDate.diff(startDate)).asMinutes();
  const floorMinutes = Math.floor(minutes);
  return floorMinutes
}

function getHoursBetweenHours(start, end) {
  if(!moment(start).isValid() || !moment(end).isValid()){
    return 0
  }
  const hours = moment.duration(moment(end).diff(start)).asHours();
  return hours
}

export {
  parseDate,
  getCurrentDate,
  getCurrentTZDate,
  getCurrentTime,
  getCurrentMinute,
  formatDate,
  formatTime,
  toUTFHour,
  addHours,
  addMinutes,
  addSeconds,
  addMilliseconds,
  snapMinutesTo15,
  getMinsBetweenDates,
  getHoursBetweenHours,
  getMinsFromNow,
  getMillisecondsBetweenDates,
  addMinutesNoFormat,
  addMillisecondsNoFormat,
  subtractMinutesNoFormat,
  addMinutesTourFormat
};
