<script setup>
import FormControl from "@/components/common/FormControl.vue";
import Status from '@/components/common/Status.vue';
import InputText from 'primevue/inputtext';
import { fabric } from 'fabric';
import { app } from '@/main.js';
import moment from 'moment';
import deliveryService from "@/services/api/delivery.js";
import Dialog from 'primevue/dialog';
import Dropdown from 'primevue/dropdown';

</script>

<script>
export default {
  name: "DeliveryCard",
  props: {
    delivery: JSON,
    deliveryID: String
  },
  components: {
    Dialog,
    InputText,
    Dropdown
  },
  data: function () {
    return {
      user: JSON.parse(sessionStorage.getItem("user")),
      signCanvas: null,
      uom: 'TON',
      signPositions: [
        { name: this.$t('Carrier'), value: 'carrier'},
        { name: this.$t('Customer'), value: 'customer'}
      ],
      signPosition: 'carrier',
      phase2Enabled: app.config.globalProperties.$config.general.phase2Enabled == '1',
      driverData: {
        name: '',
        email: ''
      },
      signModal: false
    }
  },
  mounted() {
    if (this.signCanvas) {
      this.signCanvas.dispose();
    }
    this.initSignCanvas();
  },
  methods: {
    initSignCanvas() {
      let myCanvas = document.getElementById('deliverySignCanvas');
      this.signCanvas = new fabric.Canvas("deliverySignCanvas", {
        width: myCanvas.offsetWidth,
        height: myCanvas.offsetHeight,
        backgroundColor: "white",
        isDrawingMode: true,
      });
    },
    getWeight(w) {
      return this.phase2Enabled ? w >= 100 ? w / 1000 : w : w;
    },
    clearSign() {
      if (this.signCanvas) {
        this.signCanvas.dispose();
        this.initSignCanvas();
      }
    },
    signDelivery(deliverySign, user) {
      let loader = this.$loading.show();
      deliveryService.signDelivery(deliverySign, user).then((response) => {
        loader.hide();
        this.$emit('deliverySigned', true);
        this.$notify({
          title: this.$t("Delivery Signed Successfully!"),
          type: "success"
        })
      }).catch(err => {
          loader.hide();
          this.$emit('deliverySigned', true);
          this.$notify({
            title: this.$t("Delivery Signed Successfully!"),
            type: "success"
          })
        })
    },
    saveDeliverySign() {

      const dataURL = this.signCanvas.toDataURL({
        width: this.signCanvas.width,
        height: this.signCanvas.height,
        left: 0,
        top: 0,
        format: 'png',
      });

      this.signDelivery({ name: this.driverData.name, email: this.driverData.email, sign: dataURL, signPosition: this.signPosition, deliveryId: this.deliveryID }, this.user);
    },
  }
}
</script>

<template>
  <div class="delivery_card">
    <div class="delivery_info">
      <div class="info_container">
        <div class="info_section">
          <div class="info">
            <FormControl :label="$t('Customer')" class="info_item">
              {{ delivery.soldto.customer }}<br />
              {{ delivery.soldto.name1 }}
            </FormControl>
            <FormControl :label="$t('Vehicle')" class="info_item">
              {{ delivery.logistic.truck.plateNumber }}
            </FormControl>
          </div>
          <div class="info">
            <FormControl :label="$t('Shipto')" class="info_item">
              {{ delivery.shipto.shipto }}<br />
              {{ delivery.shipto.address.name }}
            </FormControl>
            <FormControl :label="$t('Driver')" class="info_item">
              {{ delivery.logistic.driver.name }}
            </FormControl>
          </div>
          <div class="info">
            <FormControl :label="$t('Contract')" class="info_item">
              {{ delivery.contract.contract }}
            </FormControl>
            <FormControl :label="$t('Haulier')" class="info_item">
              {{ delivery.logistic.carrier.vendorID }} | {{ delivery.logistic.forwardingAgent.name }}
            </FormControl>
          </div>
          <div class="info">
            <FormControl :label="$t('Plant')" class="info_item">
              {{ delivery.material[0].plant }}
            </FormControl>
            <FormControl :label="$t('Status')" class="info_item">
              <Status :name="delivery.status" />
            </FormControl>
          </div>
        </div>
        <div class="materials_section">
          <FormControl :label="$t('Materials')" class="info_item">
            <div v-for="material in delivery.material">{{ material.descriptions[0].text }}</div><br />
          </FormControl>
        </div>
        <div class="weight_section">
          <FormControl :label="$t('Entry Weight')" class="info_item">
            <InputText :disabled="true" :value="getWeight(delivery.firstWeight.weight) + ' ' +  uom"
              :style="{ width: '120px' }"></InputText>
          </FormControl>
          <FormControl :label="$t('Exit Weight')" class="info_item">
            <InputText :disabled="true" :value="getWeight(delivery.secondWeight.weight) + ' ' +  uom"
              :style="{ width: '120px' }"></InputText>
          </FormControl>
          <FormControl :label="$t('Net Weight')" class="info_item">
            <InputText :disabled="true"
              :value="(Math.round((getWeight(delivery.secondWeight.weight - delivery.firstWeight.weight) + Number.EPSILON) * 100) / 100 + ' ' +  uom)"
              :style="{ width: '120px' }"></InputText>
          </FormControl>
        </div>
        <div class="time_section">
          <FormControl :label="$t('Exit time')" class="info_item">
            {{ moment(delivery.secondWeight.date).format('HH:mm') }}
          </FormControl>
        </div>
      </div>

      <div class="actions">
        <lh-button size="large" type="primary" @click="signModal = true">{{ $t('Sign Delivery') }}</lh-button>
        <lh-button size="large" type="secondary" class="button" @click="clearSign()">{{ $t('Clear Sign') }}</lh-button>
      </div>
    </div>
    <div class="sign_area">
      <canvas id="deliverySignCanvas"
        style="border: 1px solid #d8d8d8; padding: 10px 10px 10px 10px; width: 100%; height: 100%;" />
    </div>
  </div>

  <Dialog :header="'Sign Delivery'" v-model:visible="signModal" :dismissable-mask="true" closable :modal="true" @hide="signModal = false">
    <div class="confirm_sign_container">
      <FormControl :label="$t('Email')" class="input_confirm">
        <InputText :style="{ width: '100%', height: '50px' }" v-model="driverData.email" />
      </FormControl>
      <FormControl :label="$t('Name')" class="input_confirm">
        <InputText :style="{ width: '100%', height: '50px' }" v-model="driverData.name" />
      </FormControl>
      <FormControl :label="$t('Print as')" class="input_confirm">
        <Dropdown v-model="signPosition" :options="signPositions" optionLabel="name" optionValue="value" />
      </FormControl>
      <lh-button icon="save" type="primary" class="button_confirm" @click="saveDeliverySign()">
        {{ $t('Save') }}
      </lh-button>
    </div>

  </Dialog>

</template>

<style lang="scss">

.delivery_card {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-top: 1px solid rgb(211, 211, 211);
  border-bottom: 1px solid rgb(211, 211, 211);

  .delivery_info {
    width: 30%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;

    .info_container {

      width: 100%;

      .info_section {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;

        .info {
          width: 25%;
          display: flex;
          align-items: center;
          justify-content: start;
          flex-direction: column;
          text-align: center;
          font-size: 13px;
          height: 200px;

          .info_item {
            height: 100px;
            overflow-y: scroll;
            -ms-overflow-style: none;
            scrollbar-width: none;
          }

          .info_item::-webkit-scrollbar {
            display: none;
          }

        }

      }

      .materials_section {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: start;
        padding-left: 10px;
      }

      .weight_section {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding-right: 30px;
        padding-left: 10px;
        margin-top: 20px;
      }

      .time_section {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: start;
        padding-left: 10px;
        margin-top: 20px;
      }
    }

    .actions {
      width: 70%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .button {
        margin-left: 20px;
      }
    }

  }

  .sign_area {
    width: 70%;
    height: 100%;
    border: 1px solid rgb(211, 211, 211);
  }

}

.confirm_sign_container {
  width: 500px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;

  .input_confirm {
    width: 100%;
    margin-bottom: 20px;
  }

  .button_confirm {
    width: 100%;
    margin-top: 20px;
  }
}

</style>
