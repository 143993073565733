<script>
import { defineComponent } from "vue";
import Dialog from "primevue/dialog";

export default defineComponent( {
  components: {
    Dialog,
  },
  emits: ["show", "hide"],
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "989px",
    }
  },
  data (){
    return {
      visible: false,
    }
  },
  methods: {
    close() {
      this.$emit("hide");
    },
    open() {
      this.$emit("show");
    },
  },
  watch: {
    isVisible(){
      this.visible = this.isVisible;
    }
  },
});
</script>

<template>
  <Dialog
    v-model:visible="visible"
    :style="{ width: size }"
    position="top"
    :modal="true"
    :draggable="false"
    @hide="close"
    @show="open"
    >
    <template #header>
      <div class="header">
        <slot name="header"></slot>
      </div>
    </template>
    <slot></slot>
    <template #footer>
      <slot name="footer"></slot>
    </template>
  </Dialog>
</template>

<style lang="scss" scoped>
.header {
  margin: auto
}
</style>