<template>
    <div id="bg" :style="`background-color: ${block ? '#FFD764' : '#ffe595'}`">
        <b>{{ time }}</b>
        <span v-if="block">{{ " " }}<i class="zmdi zmdi-lock"></i></span>
        <span v-else> ETA</span>
    </div>
</template>
<script>
export default {
    props: {
        time: String,
        block: Boolean
    }
}
</script>
<style lang="scss" scoped>
div {
    border-radius: 5px;
    padding: 2px;
}
</style>