'use strict';
import { app } from '@/main.js'

const WeighbridgesService = {

    getWeighbridges(){
        return new Promise((resolve, reject) => {
          
            //Vue.prototype.$http.get('/master/plant/', plant)
            app.config.globalProperties.$axios.get(`/masterData/weighbridges`)
                .then(response => {
                    return resolve(response)
                })
                .catch(error => 
                    { reject({ ret: error.response.status
                    , msg: error.response.data }); });
                
        });
    },
    getWeighbridgesByPlant(plant){
        return new Promise((resolve, reject) => {
          
            //Vue.prototype.$http.get('/master/plant/', plant)
            app.config.globalProperties.$axios.get(`/masterData/weighbridgesByPlant/${plant}`)
                .then(response => {
                    return resolve(response)
                })
                .catch(error => 
                    { reject({ ret: error.response.status
                    , msg: error.response.data }); });
                
        });
    },
    setWeighbridgeStatus(wb_status, weighbridge_id){
        return new Promise((resolve, reject) => {
          
            //Vue.prototype.$http.get('/master/plant/', plant)
            app.config.globalProperties.$axios.post(`/masterData/setWeighbridgeStatus`, { wb_status: wb_status, weighBridgeId: weighbridge_id})
                .then(response => {
                    return resolve(response)
                })
                .catch(error => 
                    { reject({ ret: error.response.status
                    , msg: error.response.data }); });
                
        });
    },
    getWeighbridge(weighbridge) {
        return new Promise((resolve, reject) => {
          
            //Vue.prototype.$http.get('/master/plant/', plant)
            app.config.globalProperties.$axios.get(`/masterData/weighbridge/${weighbridge}`)
                .then(response => {
                    return resolve(response)
                })
                .catch(error => 
                    { reject({ ret: error.response.status
                    , msg: error.response.data }); });
                
        });
    },
    getWeighbridgeToken(wb){
        return new Promise((resolve, reject) => {
          
            //Vue.prototype.$http.get('/master/plant/', plant)
            app.config.globalProperties.$axios.post(`/security/GetWeigthterminalToken`, wb)
                .then(response => {
                    return resolve(response)
                })
                .catch(error => 
                    { reject({ ret: error.response.status
                    , msg: error.response.data }); });
                
        });
    },
    getWeighbridgeSelectors() {
        return new Promise((resolve, reject) => {
          
            //Vue.prototype.$http.get('/master/plant/', plant)
            app.config.globalProperties.$axios.get(`/masterData/weighbridgeSelectsInfo`)
                .then(response => {
                    return resolve(response)
                })
                .catch(error => 
                    { reject({ ret: error.response.status
                    , msg: error.response.data }); });
                
        });
    },
    getWeighbridgeByTerminal(terminal) {
        return new Promise((resolve, reject) => {
          
            app.config.globalProperties.$axios.get(`/masterData/weighbridgeTerminal/${terminal}`)
                .then(response => {
                    return resolve(response)
                })
                .catch(error => 
                    { reject({ ret: error.response.status
                    , msg: error.response.data }); });
                
        });
    },
    getWeighbridgeByTerminalAndPlant(terminal, plant) {
        return new Promise((resolve, reject) => {
          
            //Vue.prototype.$http.get('/master/plant/', plant)
            app.config.globalProperties.$axios.post(`/masterData/weighbridgeTerminalAndPlant`, { terminal: terminal, plant: plant})
                .then(response => {
                    return resolve(response)
                })
                .catch(error => 
                    { reject({ ret: error.response.status
                    , msg: error.response.data }); });
                
        });
    },
    getWeighbridgeById(id){
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.get(`/masterData/weighbridge/${id}`)
            .then(response => {
                return resolve(response)
            })
            .catch(error => 
                { reject({ ret: error.response.status
                , msg: error.response.data }); });
        })
    },
    getWeighbridgesAvailable(idterminal) {
        return new Promise((resolve, reject) => {
          
            app.config.globalProperties.$axios.get(`/masterData/weighbridgesAvailable/${idterminal}`)
                .then(response => {
                    return resolve(response)
                })
                .catch(error => 
                    { reject({ ret: error.response.status
                    , msg: error.response.data }); });
                
        });
    },
    createWeighbridge(weighbridge) {
        return new Promise((resolve, reject) => {
          
            app.config.globalProperties.$axios.post(`/masterData/createWeighbridge`, weighbridge)
                .then(response => {
                    return resolve(response)
                })
                .catch(error => 
                    { reject({ ret: error.response.status
                    , msg: error.response.data }); });
                
        });
    },
    createWeighbridgeTerminal(weighbridgeterminal){
        return new Promise((resolve, reject) => {
          
            app.config.globalProperties.$axios.post(`/masterData/createWeighbridgeTerminal`, weighbridgeterminal)
                .then(response => {
                    return resolve(response)
                })
                .catch(error => 
                    { reject({ ret: error.response.status
                    , msg: error.response.data }); });
                
        });
    },
    editWeighbridge(weighbridge) {
        return new Promise((resolve, reject) => {
          
            app.config.globalProperties.$axios.put(`/masterData/editWeighbridge`, weighbridge)
                .then(response => {
                    return resolve(response)
                })
                .catch(error => 
                    { reject({ ret: error.response.status
                    , msg: error.response.data }); });
                
        });
    },
    deleteSingleWeighbridge(weighbridge) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post(`/masterData/deleteSingleWeighbridge`, weighbridge)
                .then(response => {
                    return resolve(response)
                })
                .catch(error => 
                    { reject({ ret: error.response.status
                    , msg: error.response.data }); });
                
        });
    },
    deleteWeighbridgeTerminal(weighbridgeterminal) {
        return new Promise((resolve, reject) => {
          
            app.config.globalProperties.$axios.post(`/masterData/deleteWeighbridge`, weighbridgeterminal)
                .then(response => {
                    return resolve(response)
                })
                .catch(error => 
                    { reject({ ret: error.response.status
                    , msg: error.response.data }); });
                
        });
    },
    deleteTerminalsOfWeighbridge(weighbridge) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post(`/masterData/deleteTerminalsOfWeighbridge`, weighbridge)
                .then(response => {
                    return resolve(response)
                })
                .catch(error => 
                    { reject({ ret: error.response.status
                    , msg: error.response.data }); });
                
        });
    },
}

export default WeighbridgesService;