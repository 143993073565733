<template>
  <div class="is-flex is-justify-content-center mb-5">
    <div class="is-flex is-flex-direction-column" style="width: 75%;">
      <div>
        <div v-if="!isNewDapRocksModal" class="is-flex is-flex-direction-column">
          <label for="code" class="font-bold mb-1"> {{ $t('Code') }} </label>
          <InputText v-model="DAPform.code.value" inputId="code" class="mb-3" :disabled="!enableCreateDAP || !isNewDapRocksModal"/>
        </div>
      </div>
      <div class="is-flex is-flex-direction-row is-justify-content-space-between">
        <div class="is-flex is-flex-direction-column">
          <label for="fromDate" class="font-bold mb-1"> {{ $t('From Date') }} </label>
          <Calendar  :dateFormat="calendarFormat" v-model="DAPform.fromDate.value" inputId="fromDate" class="mb-3" :disabled="!enableCreateDAP || DAPform.fromDate ? DAPform.fromDate.readOnly : ''"/>
        </div>

        <div class="is-flex is-flex-direction-column">
          <label for="toDate" class="font-bold mb-1"> {{ $t('To Date') }} </label>
          <Calendar  :dateFormat="calendarFormat" v-model="DAPform.toDate.value" inputId="toDate" class="mb-3" :disabled="!enableCreateDAP || DAPform.toDate ? DAPform.toDate.readOnly : ''"/>
        </div>
      </div>

      <div class="is-flex is-flex-direction-row is-justify-content-space-between" v-if="enableCreateDAP">
        <div class="is-flex is-flex-direction-column">
          <label for="tonnage" class="font-bold mb-1"> {{ $t('Tonnage') }} </label>
          <InputNumber v-model="DAPform.tonnage.value" inputId="tonnage" class="mb-3" :disabled="!enableCreateDAP || DAPform.tonnage ? DAPform.tonnage.readOnly : ''"/>
        </div>
        <div class="is-flex is-flex-direction-column">
          <label for="category" class="font-bold mb-1"> {{ $t('Category') }} </label>
          <InputText v-model="DAPform.category.value" inputId="category" :disabled="DAPform.category ? DAPform.category.readOnly : ''" class="mb-5"/>
        </div>
      </div>

      <div class="is-flex is-flex-direction-row is-justify-content-space-between" v-if="enableCreateDAP">
         <div class="is-flex is-flex-direction-column">
          <label for="ewc" class="font-bold mb-1"> {{ $t('EWC') }} </label>
          <InputText v-model="DAPform.ewc.value" inputId="ewc" :disabled="DAPform.ewc ? DAPform.ewc.readOnly : ''" class="mb-5"/>
        </div>
        <div class="is-flex is-flex-direction-column">
          <label for="ewc" class="font-bold mb-1"> {{ $t('EWC Name') }} </label>
          <InputText v-model="DAPform.ewc_name.value" inputId="ewc_name" :disabled="DAPform.ewc_name ? DAPform.ewc_name.readOnly : ''" class="mb-5"/>
        </div>
      </div>

      <div class="columns is-flex is-flex-direction-row is-justify-content-space-between mb-0" v-if="enableCreateDAP">
         <div class="column is-two-fifth is-flex is-flex-direction-column">
          <label for="product" class="font-bold mb-1"> {{ $t('Product') }} </label>
          <InputText v-model="DAPform.product.value" inputId="product" :disabled="DAPform.product ? DAPform.product.readOnly : ''" class="mb-3"/>
        </div>
        <div class="column is-three-fifth is-flex is-flex-direction-column">
          <label for="product" class="font-bold mb-1"> {{ $t('Product Name') }} </label>
          <InputText v-model="DAPform.product_name.value" inputId="product_name" :disabled="DAPform.product_name ? DAPform.product_name.readOnly : ''" class="mb-3"/>
        </div>
      </div>


      <div v-if="enableCreateDAP">
        <div class="is-flex is-flex-direction-column">
          <label class="font-bold mb-1"> {{ $t('Topology') }} </label>
          <InputSwitch v-model="DAPform.topology.value" :disabled="DAPform.topology ? DAPform.topology.readOnly : ''"  class="mb-5"/>
        </div>
      </div>
      
      <div class="is-flex is-flex-direction-row mt-3 " style="float:right">
          <!--<div class="is-flex is-flex-direction-column">
            <Button @click="downloadDAP_Blank" class="btn-download-dap" label="DAP Blank Download" />
          </div>-->
          <div class="is-flex is-flex-direction-column">
            <Button @click="downloadDAP_EASY(DAPform.pdf)" class="btn-download-dap mb-2" label="DAP EASY Download" v-if="!enableCreateDAP"/>
          </div>
          <div class="is-flex is-flex-direction-column mr-3">
            <Button @click="downloadDAP_ROCKS(delivery)" class="btn-download-dap mb-2" label="Print DAP/Data"  v-if="enableCreateDAP && !isNewDapRocksModal"/> <!--:disabled="isNewDapRocksModal"-->
          </div>
          <div class="is-flex is-flex-direction-column">
            <FileUpload class="mb-2" style="background-color:white; color: black; border-radius: 3px; border-color:black;    padding-block: 10px;" mode="basic" @uploader="onLoadDAP" customUpload :auto="true" :multiple="true" :chooseLabel="$t('Import DAP/Driver')" :disabled=" (filesList.filter(el => el.category == 'DAP')).length > 0" v-if="enableCreateDAP && !isNewDapRocksModal"/> <!--:disabled="isNewDapRocksModal || (filesList.filter(el => el.category == 'DAP')).length > 0"-->
            <!--<Button @click="importDAP(delivery)" class="btn-download-dap" label="Import DAP/Driver" :disabled="isNewDapRocksModal" v-if="enableCreateDAP"/>-->
            <div v-if="(filesList.filter(el => el.category == 'DAP')).length > 0">
              <div v-for="(item, index) in (filesList.filter(el => el.category == 'DAP'))" :key="index">
                <div>
                  <span class="file-link mr-2" @click="downloadFileLink(item)"><b>{{ item.file ? item.file.name : item.filename }}</b></span>
                    <span>({{ item.file ? formatBytes(item.file.size) : formatBytes(item.filesize) }})</span>
                  </div>
              </div>
            </div>
          </div>
      </div>
      <div class="is-flex is-flex-direction-row is-justify-content-space-between mt-3" v-if="enableCreateDAP && !readOnlyDapModal">
          <div class="is-flex is-flex-direction-column">
            <Button @click="createDAP" class="btn-dap" :label="isNewDapRocksModal ? $t('Create DAP') : $t('Edit DAP')" />
          </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { app } from '@/main.js';
import InputText from "primevue/inputtext";
import InputNumber from "primevue/inputnumber"; 
import InputSwitch from 'primevue/inputswitch';
import Button from "primevue/button";
import Calendar from 'primevue/calendar';
import FileUpload from 'primevue/fileupload';
import moment from 'moment';
import WasteService from "@/services/api/waste";
</script>

<script>
export default {
  name: "CreateDAPModal",
  components: {
    InputText,
    InputNumber,
    InputSwitch,
    Button,
    Calendar,
    FileUpload
  },
  props: [
    'shipto',
    'soldto',
    'delivery',
    'deliveryQuantity',
    'enableCreateDAP',
    'DAPform',
    'isNewDapRocksModal',
    'readOnlyDapModal',
    'filesList',
    'calendarFormat'
  ],
  data: function () {
    return {
      loading: true,
      isNewDapRocksModal: (this.DAPform.code.value ==null || this.DAPform.code.value=='null' || this.DAPform.code.value=='') ? true: false, 
      
      hasCategories: {
        type: Boolean,
        default: true
      },
      maxFileSize: 10 * 1024 * 1024,
   };
  },

  methods: {
    async createDAP() {
     
     console.log('createDAP DAPform >>> ',this.DAPform);

      if (!this.isValidForm(this.DAPform)) return
      
      const formParams = this.createRequest(this.DAPform)

      try {
        const res = await WasteService.createDAP(formParams)
        if (res.status !== 200) {
          return this.showNotification(this.$t('DAP'), this.$t('Error Creating DAP'), 'error', 5000)
        }

        const { DAPRocksCode } = res.data.data

        this.DAPform.code.value = DAPRocksCode

        const delivery = this.$store.state.dispatch.delivery

        delivery._source.material[0].dap = {
          ...delivery._source.material[0].dap,
          estimateTonnage: this.DAPform.tonnage.value,
          startValidationDate: this.DAPform.fromDate.value,
          firstDeliveryDate: this.DAPform.toDate.value,
          topology: this.DAPform.topology.value,
          from: 'DAP_ROCKS'
        }
        this.$store.commit('dispatch/setDelivery', delivery)
        
        let dapCreatedData={
          dapNumber:DAPRocksCode,
          isNewDapRocksModal:this.isNewDapRocksModal
        }

        this.$emit('dapCreated',dapCreatedData);
        let messageOperation =  this.isNewDapRocksModal ? 'DAP Created': 'DAP Edited';
        this.showNotification(this.$t('DAP'), this.$t(messageOperation), 'success', 5000)
      } catch (err) {
        console.log(err)
        const { error } = err
        if (error.from === 'DeliveryQuantity' || error.from === 'DAPDates' ) {
          this.$emit('disableScreen')
          return this.showNotification(this.$t('DAP'), this.$t(error.message + '. Please chage DAP data'), 'error', -1)
        }
        this.showNotification(this.$t('DAP'), this.$t('Error Creating DAP'), 'error', 5000)
      } finally {
        //this.$emit('closeModal')
      }
    },
    downloadDAP_Blank(){
      let bucket_name = app.config.globalProperties.$config.aws.AWS_S3_BUCKET_NAME
      console.log("downloadDAP_Blank AWS Bucket data >>> ",bucket_name);
          
      let code_country= JSON.parse(sessionStorage.getItem('user')).country.code
      let data ={
          fileName: code_country + "/documents/DAP_VF13.pdf",
          bucket_name:bucket_name
      }
      WasteService.getWasteBlankPDF(data).then((res) => {
        let dataPDF = { file: res.data, fileName: data.fileName }
        //console.log("downloadDAP_Blank >>>",dataPDF)
        this.downloadPDF(dataPDF);
        this.$notify({
          title: this.$t("DAP Download"),
          text: this.$t(`Downloaded correctly`),
          type: 'success',
          duration: 3000
        });
                       
       }).catch((error) => {
        console.log('ERROR getWasteBlankPDF -->', error);
                        
        this.$notify({
          title: this.$t("DAP Download"),
          text: this.$t(`Error downloading PDF`,),
          type: 'error',
           duration: 3000
        });    
      });
    },
    downloadDAP_EASY(data){
      console.log('downloadDAP_EASY data');
      if(data && data!=null && data !=''){
        this.downloadPDF(data);
      }else{
         this.$notify({
          title: this.$t("DAP Download Error"),
          text: this.$t(`There is no file data for current DAP`),
          type: 'error',
          duration: 3000
        });    
      }
    },
    downloadDAP_ROCKS(deliveryData){
      console.log('downloadDAP_ROCKS >>', deliveryData);
      
      let bucket_name = app.config.globalProperties.$config.aws.AWS_S3_BUCKET_NAME
      console.log("downloadDAP_ROCKS AWS Bucket data >>> ",bucket_name);
          
      let code_country= JSON.parse(sessionStorage.getItem('user')).country.code;
      let user= JSON.parse(sessionStorage.getItem('user')).id_user;
      
      let data ={
          fileName: code_country + "/documents/DAP_VF13.pdf",
          bucket_name:bucket_name,
          user:user,
          deliveryData: deliveryData,
          dapData: this.DAPform
      }

      WasteService.getWasteDapRocksPDF(data).then((res) => {
        let dataPDF = res.data;
        //console.log("downloadDAP_Blank >>>",dataPDF)
        this.downloadPDF(dataPDF);
        this.$notify({
          title: this.$t("DAP Download"),
          text: this.$t(`Downloaded correctly`),
          type: 'success',
          duration: 3000
        });
                       
       }).catch((error) => {
        console.log('ERROR downloadDAP_ROCKS -->', error);
                        
        this.$notify({
          title: this.$t("DAP Download"),
          text: this.$t(`Error downloading PDF`,),
          type: 'error',
           duration: 3000
        });     
      });
    },
    downloadPDF(data) {
        console.log('downloadPDF',data);
        const url = `data:application/pdf;base64,${data.file}`;
        const a = document.createElement('a');
        a.download = data.fileName ;//? data.fileName : `${this.currentDelivery._source.deliveryNO}.pdf`;
        a.href = url;
        a.target = '_self';

        a.click();
        setTimeout(function () {
          a.remove();
          URL.revokeObjectURL(url);
        }, 100);
    },
    isValidForm(form) {
      let isValid = true;
      
      Object
        .values(form)
        .forEach(content => {
          if (content && content.required && (content.value === null || content.value === "")) {
            isValid = false;
            this.showNotification(this.$t(`Field ${content.name} is required`), null, 'error', -1)
          }
        });

      return isValid;
    },
    createRequest() {
      const { code, tonnage, fromDate, toDate, product,product_name, ewc,ewc_name, category, topology, dapEasyTotalResponse} = this.DAPform
      const plant = this.delivery.material[0].plant;//JSON.parse(sessionStorage.getItem("user")).plant.code
      let isEdit = this.isNewDapRocksModal ? 'N': 'Y'
      let calendarFormat= JSON.parse(localStorage.getItem("dateFormat")) ? JSON.parse(localStorage.getItem("dateFormat")).moment : 'dd/mm/yy';
      console.log('createRequest is Edit >>>', isEdit);
      console.log('createRequest fromDate', moment(fromDate.value).format(calendarFormat));
      console.log('createRequest toDate', moment(toDate.value).format(calendarFormat));
      console.log('createRequest topology', topology);

      return {
        op: 'I_waste_dap',
        isEdit: isEdit,
        shipto: this.shipto,
        soldto: this.soldto,
        plant,
        data: {
          code: code.value,
          type: 'DAP_ROCKS',
          quantity: tonnage.value,
          uom: 'TNE',
          fromDate: moment(fromDate.value).format("yyyy-MM-DD"),
          toDate: moment(toDate.value).format("yyyy-MM-DD"),
          product: product.value,
          product_name: product_name.value,
          ewc: ewc.value,
          ewc_name: ewc_name.value,
          category: category.value,
          topology: topology.value ? 'Y' : 'N',
          dapEasyTotalResponse: dapEasyTotalResponse
        },
        deliveryQuantity: this.deliveryQuantity
      }
    },
    showNotification(title, text, type, duration) {
      this.$notify({
        title,
        text,
        type,
        duration,
        closeOnClick: true
      })
    },
    onLoadDAP(event) {
      for (let file of event.files) {
        if (file.size < this.maxFileSize) {
          let attachmentDAP = { file: file, comments: '', category: 'DAP',disabled:true } //Automatically select DAP as Category
          this.$emit('addDAPAttachment',attachmentDAP);
        } else {
          console.log('event', event)
          this.$notify({
            title: this.$t("File Size Exceeded"),
            text:  this.$t('The uploaded file is too large. Please upload a file smaller than ' + `${maxFileSize}`+ 'B.'),
            type: "error",
            duration: -1,
            closeOnClick: true
          })
        }
      }
    },
    formatBytes(bytes, decimals = 2) {
      if (!+bytes) return '0 Bytes'

      const k = 1024
      const dm = decimals < 0 ? 0 : decimals
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

      const i = Math.floor(Math.log(bytes) / Math.log(k))
      return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
    },
    downloadFileLink(file){
      let loader = this.$loading.show();
      try {
        if(file.filepath) {
          s3Service.getFile(file.filepath).then((response) => {
            if(response.data) {
              const blob = new Blob([response.data], { type: 'application/octet-stream' });
              const url = URL.createObjectURL(blob);
              const link = document.createElement('a');
              link.href = url;
              link.download = file.filename;
              link.click();
              URL.revokeObjectURL(url); 
            }
            loader.hide();
          }).catch(err => {
            loader.hide();
            this.$notify({
              title: this.$t('Downloading Error'),
              text: this.$t('An error occurred when downloading the file. Please, try again.'),
              type: 'error',
            });
          });
        } else {
          const fileUrl = URL.createObjectURL(file.file);
          const link = document.createElement('a');
          link.href = fileUrl;
          link.download = file.file.name;
          link.click();
          URL.revokeObjectURL(fileUrl);
        }
      } catch (error) {
        this.$notify({
          title: this.$t('Downloading Error'),
          text: this.$t('An error occurred when downloading the file. Please, try again.'),
          type: 'error',
        });
      } finally {
        loader.hide();
      }
    }
  },
  
};
</script>

<style lang="scss" scoped>
@use "@/assets/css/lh-ds/core/functions" as *;
@use "@/assets/css/hodim/layout/data-list";

.btn-dap {
  background-color: black;
  color: white;
  border: none;
  border-radius: 3px;
  padding-block: 10px;
  padding-inline: 20px;
  margin-right: 2px;
}

.btn-download-dap {
  background-color: white;
  color: black;
  border-radius: 3px;
  padding-block: 13px;
  padding-inline: 20px;
  margin-right: 2px;
}

.action {
  padding-block: 15px;
  padding-inline: 30px;
}
.is-two-fifth {
  flex: none;
  width: 30%;
}
.is-three-fifth {
  flex: none;
  width: 70%;
}

</style>
