import moment from "moment-timezone";

const FORMATS = {
  UTC: "YYYY-MM-DDTHH:mm:ssZ",
  DATETIME: "YYYY-MM-DDTHH:mm:ss",
  DATE: "YYYY-MM-DD",
  TIME: "HH:mm:ss",
};

class RocksDate {
  constructor(date = new Date(), timezone = getTimeZone()) {
    this.date = moment.tz(date, timezone);
  }

  // ------------------ CLASS ------------------

  static fromTime(time) {
    const date = moment().tz(getTimeZone()).set({
      hour: time.split(":")[0],
      minute: time.split(":")[1],
      second: time.split(":")[2],
    });
    return new RocksDate(date);
  }

  static UTC_FORMAT() {
    return FORMATS.UTC;
  }

  static DATETIME_FORMAT() {
    return FORMATS.DATETIME;
  }

  static DATE_FORMAT() {
    return FORMATS.DATE;
  }

  static TIME_FORMAT() {
    return FORMATS.TIME;
  }

  // ------------------ INSTANCE ------------------

  in(timezone) {
    this.date.tz(timezone);
    return this;
  }

  clone() {
    return new RocksDate(this.date);
  }

  // set hours, minutes, seconds, milliseconds
  setHours(hours) {
    this.date.hours(hours);
    return this;
  }

  setMinutes(minutes) {
    this.date.minutes(minutes);
    return this;
  }

  setSeconds(seconds) {
    this.date.seconds(seconds);
    return this;
  }

  setMilliseconds(milliseconds) {
    this.date.milliseconds(milliseconds);
    return this;
  }

  // add hours, minutes, seconds, milliseconds
  addHours(hours) {
    this.date.add(hours, "hours");
    return this;
  }

  addMinutes(minutes) {
    this.date.add(minutes, "minutes");
    return this;
  }

  addSeconds(seconds) {
    this.date.add(seconds, "seconds");
    return this;
  }

  addMilliseconds(milliseconds) {
    this.date.add(milliseconds, "milliseconds");
    return this;
  }

  // set hours, minutes, seconds, milliseconds to 0
  zeroHours() {
    this.date.hours(0);
    return this;
  }

  zeroMinutes() {
    this.date.minutes(0);
    return this;
  }

  zeroSeconds() {
    this.date.seconds(0);
    return this;
  }

  zeroMilliseconds() {
    this.date.milliseconds(0);
    return this;
  }

  zeroTime() {
    this.zeroHours();
    this.zeroMinutes();
    this.zeroSeconds();
    this.zeroMilliseconds();
    return this;
  }

  // To string

  toUTC() {
    return this.date.format(FORMATS.UTC);
  }

  toDateTime() {
    return this.date.format(FORMATS.DATETIME);
  }

  toDate() {
    return this.date.format(FORMATS.DATE);
  }

  toTime() {
    return this.date.format(FORMATS.TIME);
  }

  toAbsoluteTime() {
    return this.date.format(FORMATS.TIME);
  }

  toFormat(format) {
    return this.date.format(format);
  }
}

// AUXILIAR FUNCTIONS
function getDateFormat() {
  const dateFormat = getStorageItem("dateFormat");
  if (dateFormat) {
    return dateFormat;
  }
  return null;
}

function getTimeZone() {
  const country = getSessionItem("country");
  return country ? country.timezone : "Europe/London";
}

function getUtcAndOffsetDate() {
  let date = moment().tz(getTimeZone()).format("HH:mm:ssZ");
	return moment().utc().format("YYYY-MM-DDTHH:mm:ss") + date.slice(-6);
}

function getSessionItem(key) {
  const user = sessionStorage.getItem("user");
  if (user) {
    const data = JSON.parse(user);
    return data[key];
  }
  return null;
}

function getStorageItem(key) {
  const string = localStorage.getItem(key);
  if (string && string.length > 0) {
    return JSON.parse(string);
  }
  return null;
}

function getMomentInTimezone(date, timezone) {
  if(!timezone) timezone = 'Europe/London';

  if (!moment(date).isValid()) {
    return moment(date);
  }

  let parsedDate;
  if (!date.includes("+") && !date.includes("Z")) {
    const offset = moment.tz(date, timezone).format('Z');
    date += offset;
    parsedDate = moment(date);
  } else {
    parsedDate = moment(date);
  }

  return parsedDate.tz(timezone);
};
// END AUXILIAR FUNCTIONS

export {
  RocksDate,
  getTimeZone,
  getUtcAndOffsetDate,
  getMomentInTimezone
}