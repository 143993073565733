<template>
    <div class="modal-order">
        <div class="modal-order__summary">
            <div class="columns">
                <div class="column">
                    <div class="input">
                        <FormControl :label="$t('Customer')">
                            {{ delivery._source.soldto.customer }}<br>
                            {{ delivery._source.soldto.name}}
                        </FormControl>
                    </div>
                </div>
                <div class="column">
                    <div class="input">
                        <FormControl :label="$t('Ship To')">
                            {{ delivery._source.shipto.shipto }}<br>
                            {{ delivery._source.shipto.address.name}}
                        </FormControl>
                    </div>
                </div>
                <div class="column">
                    <div class="input">
                        <FormControl :label="$t('Material')">
                            {{ delivery._source.material.map(mat => {
                                if (mat.descriptions && mat.descriptions.length > 0) {
                                    return mat.descriptions[0].text;
                                }
                                    return "";
                                }).join("\n")
                            }}
                            - REF: {{
                                delivery._source.material.map(mat => {
                                return mat.materialID;
                                }).join("\n")
                            }}
                        </FormControl>
                    </div>
                </div>
                <div class="column">
                    <div class="input">
                        <FormControl :label="$t('Quantity')">
                            {{ delivery._source.quantity }} TNE
                        </FormControl>
                    </div>
                </div>
            </div>
        </div>
        <span class="duplicate_order">{{ $t('Possible Deliveries') }}</span>
            <DataTable :value="deliveries" scrollable :loading="loading" :rows="5">
                <Column :style="{ 'min-width': '6%' }" :header="$t('Order')">
                    <template #body="slotProps">
                    {{ slotProps.data._source.OrderNo  }}  
                    </template>
                </Column>
                <Column :style="{ 'min-width': '6%' }" :header="$t('Delivery')">
                    <template #body="slotProps">
                    {{ slotProps.data._source.deliveryNO  }}  
                    </template>
                </Column>
                <Column :style="{ 'min-width': '30%' }" :header="$t('Material')">
                    <template #body="slotProps">
                    {{ slotProps.data._source.material.map(mat => {
                        if (mat.descriptions && mat.descriptions.length > 0) {
                            return mat.descriptions[0].text;
                        }
                            return "";
                        }).join("\n")
                    }}
                    - REF: {{
                        slotProps.data._source.material.map(mat => {
                        return mat.materialID;
                        }).join("\n")
                    }}
                    </template>
                </Column>
                <Column :style="{ 'min-width': '3%' }" field="_source.soldto.name" :header="$t('SoldTo')">
                    
                </Column>
                <Column :style="{ 'min-width': '3%' }" field="_source.shipto.address.name" :header="$t('ShipTo')">
                    
                </Column>
                <Column :style="{ 'min-width': '3%' }" field="_source.quantity" :header="$t('Quantity')">
                    <template #body="slotProps">
                    {{ slotProps.data._source.quantity + ' TNE' }}
                    </template>
                </Column>
                <Column :style="{ 'max-width': '10%' }" :header="$t('Actions')">
                    <template #body="slotProps">
                        <Button @click="save(slotProps.data)" :label="$t('Select')" icon="pi pi-check" class="p-button btn btn--primary"></Button>
                    </template>
                </Column>
            </DataTable>
    </div>
</template>
<script setup>
import FormControl from "@/components/common/FormControl.vue";
import Dropdown from 'primevue/dropdown'
import InputText from 'primevue/inputtext'
import deliveryService from "@/services/api/delivery.js";
import DataTable from "primevue/datatable";
import Column from 'primevue/column';
import Button from 'primevue/button';
</script>
<script>
export default {
    components: {
        FormControl,
        Dropdown,
        DataTable,
        Column,
        InputText,
        Button
    },
    data: function() {
        return {
            deliveries : [],
        }
    },
    props: ['delivery'],
    mounted() {
        this.getDivertDeliveries()
    },
    methods: {
        save(data){
            let divert = {
                originalDelivery : this.delivery._id,
                divertDelivery : data._id
            }
            deliveryService.saveDivert(divert).then(response => {
                this.$emit('close')
            })
        },
        getDivertDeliveries(){
            console.log(this.delivery)
            let divert = {
                materials : this.delivery._source.material.map(m => m.materialID),
            }
            deliveryService.getDivertDeliveries(divert).then(response => {
                this.deliveries = response.data
            })
        },
    }
}
</script>

<style>
    .duplicate_order{
        box-sizing: border-box;
        height: 3.5rem;
        background-color: #F6F6F6;
        font-size: 1.25rem;
        font-weight: 700;
        position: relative;
        display: flex;
        padding: 15px;
    }
</style>