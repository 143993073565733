const orderTypes = {
    Outbound: "OUT",
    Inbound: "IN",
    Transfer: "IntraCo",
    Trading: "TRAD"
}

const wasteOrderTypes = {
  Outbound: "W_OUT",
  Inbound: "W_IN",
  Transfer: "W_IntraCo",
  Trading: "W_TRAD"
}

const orderStatus = {
    New : "New",
    OnHold : "OnHold",
    Requested: "Requested",
    Released : "Released",
    Draft : "Draft",
    Completed : "Completed",
    Rejected : "Rejected",
    Cancelled : "Cancelled",
    Blocked : "Blocked",
    Provisional : "Provisional",
}

const deliveryType = {
    Delivered: 'Delivered',
    PickUp: 'PickUp'
}

const deliveryStatus = {
    Arrived: 'Arrived',
    Blocked: 'Blocked',
    Deleted: 'Deleted',
    Finished: 'Finished',
    Loading: 'Loading',
    OnHold: 'Onhold',
    Ready: 'Ready',
    Returned: 'Returned',
    Shipping: 'Shipping',
    Scheduled: 'Scheduled',
    SplitLoad: 'SplitLoad'
}

const noteCategories = {
    DeliveryNoteInstructions: 1,
    DriverInstructions: 2,
    OrderInstructions: 3,
    InvoiceInstructions: 4,
    DispatcherInstructions: 5,
    SchedulingInstructions: 6
}

const frameContracts = ['ZCF']

export default {
    orderTypes,
    wasteOrderTypes,
    orderStatus,
    deliveryType,
    deliveryStatus,
    noteCategories,
    frameContracts
}