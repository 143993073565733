// let vehicle = { tare: 8000, mwa: 34000 } // Puede ser truck o trailer
// let weight = 36000; // Peso que se checkea en ese momento
// let type = 'MWA' // Puede ser TARE, NET o MWA
// let direction = 'OUT'; // Puede ser IN o OUT
import countriesService from '@/services/api/countries';
import { transformToUnit } from '@/helper/weightUnit';

const user = JSON.parse(sessionStorage.getItem("user"));
const plant_params = user && user['plant'].plant_parameters ? user['plant'].plant_parameters : '';
const country_params = user && user['country'].country_parameters ? user['country'].country_parameters : '';
export const validate = (vehicle, weight, type, direction, trailer, deliveryNetW, uom, netUom, numMaterials, vehicleTare) => {
    // console.log('trailer: ', trailer);
    // console.log('vehicle: ', vehicle);
    // console.log('weight: =======> ', weight);
    let tolerance = 0;
    let validation = new Promise( async (resolve, reject) => {
        switch (direction) {
            case 'IN':
                switch (type) {
                    case 'NET':
                        tolerance = 2;
                        resolve(checkInNetWeight(weight, deliveryNetW, tolerance, vehicleTare));
                        break;
                }
                break;
            case 'OUT':
                switch (type) {
                    case 'TARE':
                        tolerance = getTareTolerance(vehicle);
                        const mwaValue = getMWAFactor(vehicle);
                        resolve(checkTareWeight(vehicle, weight, tolerance, uom, mwaValue));
                        break;
                    case 'NET':
                        tolerance = getNetTolerance(deliveryNetW, netUom);
                        resolve(checkNetWeight(deliveryNetW, weight, tolerance, uom, netUom));
                        break;
                    case 'MWA':
                        let mwaFactor = getMWAFactor(vehicle, trailer);
                        tolerance = getMaxTolerance(mwaFactor, vehicle);
                        resolve(checkMaxWeight(mwaFactor, vehicle, weight, tolerance, uom));
                        break;
                    case 'CONT':
                        let tareTolerance = getTareTolerance(vehicle);
                        let mwa = getMWAFactor(vehicle, trailer);
                        let mwaTolerance = getMaxTolerance(mwa, vehicle);
                        let vehicleCapacity = getVehicleCapacity(mwa, vehicle.tare);
                        let contFactor = await getContaminationFactor(vehicleCapacity, numMaterials);
                        resolve(checkCapacity(vehicle, vehicleCapacity, mwaTolerance, tareTolerance, weight, uom, contFactor));
                        break;
                    default:
                        reject('Wrong Type');
                }
                break;
            default:
                reject('Wrong Direction');
        }
    });
    return validation;
}

export const validateMWA = (vehicle, trailer) => {
    // console.log('vehicle: ', vehicle);
    // console.log('trailer: ', trailer);

    const mwaMode = country_params.find(p => p.code == "Vehicle_MWA").value;
    let tare = 0;
    let mwa = 0;
    if (mwaMode === 'Tare and MWA from Trailer' && trailer !== null && trailer !== undefined) {
        tare = Number(trailer.tare);
        mwa = Number(trailer.mwa);
    } else if (mwaMode === 'Tare and MWA from Tractor head + Trailer' && trailer !== null && trailer !== undefined) {
        tare = Number(vehicle.tare) + Number(trailer.tare)
        mwa = Number(trailer.mwa);
    } else { // 'Tare and MWA from Tractor head'
        tare = Number(vehicle.tare);
        mwa = Number(vehicle.mwa);
    }

    return {tare: tare.toString(), mwa: mwa.toString()}
}

const checkTareWeight = (vehicle, weight, tolerance, uom, mwa) => {
    const mwaValue = unitConversionToKGM(Number(mwa), vehicle.unit);
    const vehicleTare = unitConversionToKGM(Number(vehicle.tare), vehicle.unit);
    const realWeightTare = unitConversionToKGM(weight, uom);
    // console.log('vehicleTare: ', vehicleTare);
    const firstWeightIsMoreThanMWA = realWeightTare >= mwaValue
    if (firstWeightIsMoreThanMWA) {
        return returnWeightOverMWANotification(mwaValue)
    }
    const check = isBetween(realWeightTare, vehicleTare, tolerance);
    // console.log('check ======> ', check);
    if (check.isBetween === true) {
        return returnCorrectNotification()
    } else {
        return returnTareIncorrectNotification(check.AbOrUn, transformToUnit(check.realWeight, 'KGM', uom), transformToUnit(check.teoricWeight, 'KGM', uom))
    }
}

const checkNetWeight = (netWeight, weight, tolerance, uom, netUom) => {
    const teoricWeight = Number(unitConversionToKGM(netWeight, netUom));
    const realWeight = Number(unitConversionToKGM(weight, uom));
    const check = isBetween(realWeight, teoricWeight, tolerance);
    if (check.isBetween === true) {
        return returnCorrectNotification()
    } else {
        return returnNetIncorrectNotification(check.AbOrUn, transformToUnit(check.realWeight, 'KGM', uom), transformToUnit(check.teoricWeight, 'KGM', uom))
    }
}

const checkInNetWeight = (mwa, netWeight, tolerance, vehicleTare) => {
    const teoricWeight = Number(netWeight);
    const realWeight = Number(mwa) - Number(vehicleTare);
    const check = isBetween(realWeight, teoricWeight, tolerance);
    if (check.isBetween === true) {
        return returnCorrectNotification()
    } else {
        return returnInNetIncorrectNotification(check.AbOrUn, check.realWeight, teoricWeight, mwa)
    }
}

const checkMaxWeight = (mwaFactor, vehicle, weight, tolerance, uom) => {
    const vehicleMwa = unitConversionToKGM(mwaFactor, vehicle.unit);
    const realMwaWeight = unitConversionToKGM(weight, uom);
    const check = isBetween(realMwaWeight, vehicleMwa, tolerance);
    if (check.isBetween === true) {
        return returnCorrectNotification()
    } else {
        return returnMaxIncorrectNotification(check.AbOrUn, transformToUnit(check.realWeight, 'KGM', uom), transformToUnit(check.teoricWeight, 'KGM', uom))
    }
}

const checkCapacity = (vehicle, capacity, mwaTolerance, tareTolerance, weight, uom, contaminationFactor) => {
    const vehicleCapacity = unitConversionToKGM(capacity - contaminationFactor, vehicle.unit);
    const weightKgm = unitConversionToKGM(weight, uom);
    const tolerance = mwaTolerance - tareTolerance;
    const check = isLess(weightKgm, vehicleCapacity, tolerance);
    if (check.isLess === true) {
        return returnCorrectNotification()
    } else {
        return returnCapacityIncorrectNotification(transformToUnit(check.realWeight, 'KGM', uom), transformToUnit(check.teoricWeight, 'KGM', uom));
    }
}

const isBetween = (realWeight, teoricWeight, tolerance) => {
    let isBetween = false;
    // Above Or Under de la cantidad si no se cumple, si sí es Between
    let AbOrUn = 'between';
    const min = teoricWeight - tolerance;
    // console.log('min: ', min);
    const max = teoricWeight + tolerance;
    // console.log('max: ', max);
    if (min <= realWeight && realWeight <= max) {
        isBetween = true;
    } else if (min > realWeight) {
        AbOrUn = 'under';
        teoricWeight = min;
    } else if (realWeight > max) {
        AbOrUn = 'above';
        teoricWeight = max;
    }
    return ({ isBetween: isBetween, AbOrUn: AbOrUn, realWeight: realWeight, teoricWeight: teoricWeight });
}

const isLess = (realWeight, teoricWeight, tolerance) => {
    let isLess = false;
    let AbOrUn = 'under';
    const max = teoricWeight + tolerance;
    // console.log('max: ', max);
    if (realWeight <= max) {
        isLess = true;
    } else if (realWeight > max) {
        AbOrUn = 'above';
        teoricWeight = max;
    }
    return ({ isLess: isLess, AbOrUn: AbOrUn, realWeight: realWeight, teoricWeight: teoricWeight });
}

const getTareTolerance = (vehicle) => {
    let tolerance = 0;
    let plant_tare_tolerance = plant_params.find(p => p.code == "tare_tolerance").value;
    if(Number.isNaN(parseFloat(plant_tare_tolerance))) {
        plant_tare_tolerance = 0;
    }
    const plant_tare_tolerance_unit = plant_params.find(p => p.code == "tare_tolerance").unit_measure === 'KGM' ? plant_params.find(p => p.code == "tare_tolerance").unit_measure : '%';
    if (plant_tare_tolerance_unit === '%') {
        tolerance = unitConversionToKGM(Number(vehicle.tare), vehicle.unit) * Number(plant_tare_tolerance) / 100;
    } else {
        tolerance = Number(plant_tare_tolerance);
    }
    return tolerance;
}
const getNetTolerance = (netWeight, netUom) => {
    let tolerance = 0;
    let net = netWeight;
    let plant_delivery_tolerance = plant_params.find(p => p.code == "delivery_tolerance").value;
    if(Number.isNaN(parseFloat(plant_delivery_tolerance))) {
        plant_delivery_tolerance = 0;
    }
    const plant_delivery_tolerance_unit = plant_params.find(p => p.code == "delivery_tolerance").unit_measure === 'KGM' ? plant_params.find(p => p.code == "delivery_tolerance").unit_measure : '%';
    if (plant_delivery_tolerance_unit === '%') {
        tolerance = unitConversionToKGM(net, netUom) * Number(plant_delivery_tolerance) / 100;
    } else {
        tolerance = Number(plant_delivery_tolerance);
    }
    // console.log('tolerance: ', tolerance);
    return tolerance;
}
const getMaxTolerance = (mwaFactor, vehicle) => {
    let tolerance = 0;
    let plant_mwa_tolerance = plant_params.find(p => p.code == "mwa_tolerance").value;
    if(Number.isNaN(parseFloat(plant_mwa_tolerance))) {
        plant_mwa_tolerance = 0;
    }
    const plant_mwa_tolerance_unit = plant_params.find(p => p.code == "mwa_tolerance").unit_measure === 'KGM' ? plant_params.find(p => p.code == "mwa_tolerance").unit_measure : '%';
    if (plant_mwa_tolerance_unit === '%') {
        tolerance = unitConversionToKGM(mwaFactor, vehicle.unit) * Number(plant_mwa_tolerance) / 100;
    } else {
        tolerance = Number(plant_mwa_tolerance);
    }
    return tolerance;
}
const getVehicleCapacity = (mwa, tare) => {
    return Math.floor((mwa - tare) * 100) / 100;
}
const getContaminationFactor = async (weight, numMaterials) => {
    const response = await countriesService.getCountryLovByName("CONTAMINATION");
    const contaminationLov = response.data;
    const contaminationFactor = contaminationLov.find(value => value.name == numMaterials && value.active === '1');
    let contaminationValue = contaminationFactor ? contaminationFactor.value : 0;
    return weight * contaminationValue / 100;
}

const getMWAFactor = (vehicle, trailer) => {
    // parameterMWA = JSON.parse(sessionStorage.getItem("user").country.country_parameters
    // console.log('vehicle, trailer: ', vehicle, trailer);
    const mwaMode = country_params.find(p => p.code == "Vehicle_MWA").value;
    let factor = 0;
    if (mwaMode === 'Tare and MWA from Tractor head') {
        factor = Number(vehicle.mwa);
    } else if (mwaMode === 'Tare and MWA from Trailer' && trailer !== null && trailer !== undefined) {
        factor = Number(trailer.mwa);
    } else if (mwaMode === 'Tare and MWA from Tractor head + Trailer' && trailer !== null && trailer !== undefined) {
        factor = Number(vehicle.mwa) + Number(trailer.mwa);
    } else {
        factor = Number(vehicle.mwa);
    }
    // console.log('mwaMode', mwaMode, 'factor', factor);
    return factor;
}

const returnCorrectNotification = () => {
    return ({ title: 'Weighing', text: 'Weight in between limits', type: 'success', stop: false })
}

const returnTareIncorrectNotification = (AbOrUn, realWeight, teoricWeight) => {
    return ({ title: 'Warning', text: `The weight in first weighing (${realWeight / 1000}) is ${AbOrUn} the limit (${teoricWeight / 1000})`, type: 'warn', stop: false })
}

const returnWeightOverMWANotification = (mwa) => {
    return ({ title: 'Error', text: `The weight in first weighing is above the MWA (${mwa})`, type: 'error', stop: true })
}

const returnNetIncorrectNotification = (AbOrUn, realWeight, teoricWeight) => {
    if (AbOrUn === 'under') {
        return ({ title: 'Warning', text: `The net weight (${realWeight / 1000}) is ${AbOrUn} the limit (${teoricWeight / 1000})`, type: 'warn', stop: true })
    } else {
        return ({ title: 'Warning', text: `The net weight (${realWeight / 1000}) is ${AbOrUn} the limit (${teoricWeight / 1000})`, type: 'warn', stop: true })
    }
}

const returnInNetIncorrectNotification = (AbOrUn, realWeight, teoricWeight, mwa) => {
    if (AbOrUn === 'under') {
        return ({ title: 'Error', text: `The net weight measured with the weighbridge (${realWeight}) is significantly smaller than the net weight declared (${teoricWeight})`, type: 'error', stop: true })
    } else {
        return ({ title: 'Warning', text: `The net weight measured with the weighbridge (${realWeight}) is above the net weight declared (${teoricWeight})`, type: 'warning', stop: false })
    }
}

const returnMaxIncorrectNotification = (AbOrUn, realWeight, teoricWeight) => {
    if (AbOrUn === 'under') {
        return ({ title: 'Warning', text: `The truck weight (${realWeight / 1000}) is ${AbOrUn} the limit (${teoricWeight / 1000})`, type: 'warning', stop: false })
    } else if (AbOrUn === 'above' && user.country.code == 'FR') {
        return ({ title: 'Warning', text: `The truck weight (${realWeight / 1000}) is ${AbOrUn} the limit (${teoricWeight / 1000})`, type: 'warn', stop: false })
    } else {
        return ({ title: 'Error', text: `The truck weight (${realWeight / 1000}) is ${AbOrUn} the limit (${teoricWeight / 1000})`, type: 'error', stop: true })
    }
}

const returnCapacityIncorrectNotification = (realWeight, teoricWeight) => {
    return ({ title: 'Warning', text: `The net weight (${realWeight / 1000}) is above the permissible limit set by the contamination parameter (maximum capacity: (${teoricWeight / 1000}))`, type: 'error', stop: false })
}

const unitConversionToKGM = (quantity, unit) => {
    // console.log('unit: ', unit);
    if (unit === 'KGM') {
        return quantity
    } else {
        return quantity * 1000;
    }
}