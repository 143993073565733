<template>
  <Step :title="$t('Notes')" class="notes-step" >
    <div class="columns">
      <div class="column">
        <NoteField v-for="(note, index) in notes" :key="index" v-model:category="note.category"
        v-model:note_text="note.text" v-model:note_description="note.description" :note_categories="note_categories" :index="index" :enableDeleteButton="enableDeleteButton"
        @delete="deleteNote" @change="changeNotes" />
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <div class="add_note">
          <lh-button class="add_note_btn" type="primary" @click="addNote" size="large" :disabled="disableAddNote">{{ $t('Add Note') }}</lh-button>
        </div>
      </div>
    </div>

  </Step>

  <Dialog :header="$t('Dispatcher Instructions')" v-model:visible="showDispatcherInstructionsAlert" :breakpoints="{ '640px': '90vw' }" 
    :style="{ width: '750px' }" :dismissable-mask="true" :modal="true" :draggable="false" closable closeOnEscape>
    <ModalMessage :msg="deliveryDispatcherInstructions" />
  </Dialog>
</template>

<script setup>
import Step from "@/components/dispatch/order/utils/step";
import NoteField from '@/components/dispatch/order/steps/notes_contact/utils/note_component';
import ModalMessage from '@/components/common/ModalMessage';
import Dialog from 'primevue/dialog';
import commonClasses from "@/store/commonClasses.js";
</script>

<script>
export default {
  comments: {
    Step,
    NoteField,
    ModalMessage,
    Dialog
  },
  data: function () {
    return {
      notes: {},
      note_categories: [],
      deliveryDispatcherInstructions: '',
      showDispatcherInstructionsAlert: false,
      enableDeleteButton: true
    }
  },
  created() {
    this.notes = Array.isArray(this.delivery._source.notes) ? [...this.delivery._source.notes] : []
    this.showDispatcherInstructions()
    this.disabledOption()
  },
  computed: {
    delivery() {
      return this.$store.state.dispatch.delivery;
    },
    disableAddNote() {
      return this.notes.length >= this.note_categories.length;
    },
    orderType() {
      return this.$store.state.dispatch.orderType
    }
  },
  methods: {
    changeNotes() {
      this.$store.commit('dispatch/setNotes', this.notes);
    },
    addNote() {
      if(this.notes.length < this.note_categories.length) {
        this.notes.push(
          {
            description: null,
            category: null,
            text: null
          }
        );
      }
    },
    deleteNote(index) {
      this.notes.splice(index, 1);
      this.$store.commit('dispatch/setNotes', this.notes);
    },
    showDispatcherInstructions() {
      if (this.notes.length > 0) {
        let note = this.notes.find(n => n.category == 5)
        if (note) {
          this.deliveryDispatcherInstructions = note.text
          this.showDispatcherInstructionsAlert = true 
        }
      }
    },
    disabledOption() {
      if (this.orderType == 'Inbound') {
        this.note_categories = [
          { name: this.$t('Delivery note instructions'), value: commonClasses.noteCategories.DeliveryNoteInstructions, disabled: false },
          { name: this.$t('Dispatcher instructions'), value: commonClasses.noteCategories.DispatcherInstructions, disabled: false }
        ],
        this.enableDeleteButton = false
      } else {
        this.note_categories = [
          { name: this.$t('Delivery note instructions'), value: commonClasses.noteCategories.DeliveryNoteInstructions, disabled: false},
          { name: this.$t('Driver instructions'), value: commonClasses.noteCategories.DriverInstructions, disabled: false},
          { name: this.$t('Order instructions'), value: commonClasses.noteCategories.OrderInstructions, disabled: false},
          { name: this.$t('Invoice instructions'), value: commonClasses.noteCategories.InvoiceInstructions, disabled: false},
          { name: this.$t('Dispatcher instructions'), value: commonClasses.noteCategories.DispatcherInstructions, disabled: false},
          { name: this.$t('Scheduling instructions'), value: commonClasses.noteCategories.SchedulingInstructions, disabled: false},
        ],
        this.enableDeleteButton = true
      }
    }
  },
}
</script>

<style lang="scss">
@import '@/assets/css/lh-ds/core/functions';
@import '@/assets/css/hodim/core/mixin';

.notes-step {
  &.overlay::before {
    content: '';
    position: absolute;
    inset: 0;
    z-index: 1;
    background-color: #fff;
    opacity: 0.5;
  } 

  .add_note {
    border-top: solid 1px #a7a7a7;
    display: flex;
    align-items: center;
    justify-content: start;
    padding: 20px;
    .add_note_btn {
      width: 10%;
    }
  }
}
</style>
