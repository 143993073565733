<script setup>
import Dropdown from 'primevue/dropdown';
import FormControl from "@/components/common/FormControl.vue";
import RolesService from '@/services/api/roles';
import PlantsService from '@/services/api/plants'
</script>

<script>
export default {
  components: {
    Dropdown
  },
  data: function () {
    return {
      plants: null,
      apps: null,
      continueAllowed: true,
      selectedApp: null,
      selectedPlant: null,
      plantsDisabled: false,
      appsDisabled: false
    }
  },
  beforeMount() {

  },
  mounted() {

    let user = JSON.parse(sessionStorage.getItem("user"));

    this.plants = user.plants;
    this.apps = [];
    this.selectedApp = null;
    this.selectedPlant = user.plant;

    this.apps.push({ name: "Deliveries Dispatching", code: "deliveries" })

    if (user.plant.menu.App.find((e) => e.name_menu == "Sign Delivery") != null && user.plant.menu.App.find((e) => e.name_menu == "Sign Delivery").visible == 1) {
      this.apps.push({ name: 'Pick-up Delivery', code: 'pendingToSign' });
      this.selectedApp = { name: 'Pick-up Delivery', code: 'pendingToSign' };
    }

    if (user.plant.menu.App.find((e) => e.name_menu == "Shovel App") != null && user.plant.menu.App.find((e) => e.name_menu == "Shovel App").visible == 1) {
      this.apps.push({ name: 'Shovel App', code: 'shovelApp' });
      this.selectedApp = { name: 'Shovel App', code: 'shovelApp' };
    }

    user.currentApp = this.selectedApp;
    sessionStorage.setItem("user", JSON.stringify(user));

    this.plantsDisabled = this.plants.length == 1;
    this.appsDisabled = this.apps.length == 1;

    if (this.plants.length == 1 && this.apps.length == 1) {
      this.$router.push('/');
    }
  },
  created() {

  },
  computed: {

  },
  methods: {
    validate() {
      this.continueAllowed = this.selectedApp && this.selectedPlant;
    },
    selectPlant() {
      this.$store.commit("setDeliveriesPlant", this.selectedPlant)

      PlantsService.getPlantParameters(this.selectedPlant.id).then(({ data: plantParameters }) => {
        localStorage.setItem('userPlant', JSON.stringify({ ...this.selectedPlant, plant_parameters: plantParameters }))
      })

      if (this.selectedPlant.menu == undefined) {
        this.getFullPlant(this.selectedPlant.id);
      }
      else {
        this.insertFullPlant(this.selectedPlant.id, this.selectedPlant.menu);
      }
    },
    selectApp() {
      this.validate();
      let user = JSON.parse(sessionStorage.getItem("user"));
      user.currentApp = this.selectedApp;
      sessionStorage.setItem("user", JSON.stringify(user));
    },
    handleContinue() {

      if (this.selectedApp.code != "deliveries") {
        this.goTo("/")
        return
      }

      this.goTo("/deliveries")
    },
    goTo(route) {
      if (route) this.$router.push(route);
    },
    logout() {
      sessionStorage.removeItem('user');
      this.$router.push('/login');
    },
    getFullPlant(plantId) {
      RolesService.getPlantMenu(plantId, 7).then(response => {
        this.insertFullPlant(plantId, response.data);
      }).catch(error => {
        console.log(error);
      });
    },
    insertFullPlant(plantId, menu) {

      let user = JSON.parse(sessionStorage.getItem("user"));
      user.plants[user.plants.findIndex((e) => e.id == plantId)].menu = menu;
      user.plant = this.selectedPlant;
      user.plant.menu = menu;
      user.menu = menu;
      sessionStorage.setItem("user", JSON.stringify(user));
      this.checkEnabledApps(menu);
    },
    checkEnabledApps(menu) {

      this.apps = [];
      this.selectedApp = null;

      if (menu.App.find((e) => e.name_menu == "Sign Delivery") != null && menu.App.find((e) => e.name_menu == "Sign Delivery").visible == 1) {
        this.apps.push({ name: 'Pick-up Delivery', code: 'pendingToSign' });
        this.selectedApp = { name: 'Pick-up Delivery', code: 'pendingToSign' };
      }

      if (menu.App.find((e) => e.name_menu == "Shovel App") != null && menu.App.find((e) => e.name_menu == "Shovel App").visible == 1) {
        this.apps.push({ name: 'Shovel App', code: 'shovelApp' });
        this.selectedApp = { name: 'Shovel App', code: 'shovelApp' };
      }

      this.apps.push({ name: "Deliveries Dispatching", code: "deliveries" })

      this.selectApp();

      this.appsDisabled = this.apps.length == 1;

      this.validate();

      if (this.apps.length == 0) {
        this.$notify({
          title: this.$t("Lack of Permissions"),
          text: this.$t("You do not have access to any app on this plant"),
          type: 'error',
          duration: 10000
        });
      }

    }
  }
}
</script>

<template>
  <div class="app-content data-list orders">
    <div ref="header" class="app-content__header">
      <div class="row">
        <img src="img/logo.svg" alt class="app-header__logo" />
        <lh-button size="large" icon="sign-in" type="primary" @click="logout()">{{ $t('Logout') }}</lh-button>
      </div>
    </div>
    <div class="app-content__content">
      <div class="plant-app-selector">
        <h1 class="app-content__title">{{ $t('Select plant and app') }}</h1>
        <div class="selector-box">

          <FormControl :label="$t('Plant')" class="select-group">
            <Dropdown v-model="selectedPlant" :options="plants" optionLabel="name" @change="selectPlant()"
              :placeholder="$t('Select plant to continue')">
              <template #option="slotProps">
                <div>
                  <span><b>{{ slotProps.option.code }}</b></span><br />
                  <span>{{ slotProps.option.name }}</span>
                </div>
              </template>
            </Dropdown>
          </FormControl>

          <FormControl :label="$t('App')" class="select-group">
            <Dropdown v-model="selectedApp" :disabled="appsDisabled" :options="apps" optionLabel="name"
              @change="selectApp()" :placeholder="$t('Select app to continue')" />
          </FormControl>

        </div>
        <lh-button size="large" type="primary" :disabled="!continueAllowed" @click="handleContinue">{{
          $t('Continue')
        }}</lh-button>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@use '@/assets/css/lh-ds/core/functions' as *;
@use '@/assets/css/hodim/layout/data-list';
@use '@/assets/css/hodim/layout/data-list/ordercard';
@use '@/assets/css/hodim/layout/data-list/multiselect';
@import '@/assets/css/hodim/layout/data-list/calendar';

.plant-app-selector {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.selector-box {
  margin: 40px;
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.select-group {
  width: 100%;
  margin: 10px;
}
</style>
