<script setup>
import vendorsService from "@/services/api/vendors.js";
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
</script>
<script>
export default {
  name: "FWAgentList",
  components: {},
  data: function () {
    return {
      vendors: [],
      vendorsAux: [],
      vendor: null,
      loading: true,
      country: JSON.parse(sessionStorage.getItem("user")).country.code
    }
  },
  mounted() {
    this.getVendors()
  },
  methods: {
    getVendors(searchTerm = '') {
      vendorsService.getVendors(searchTerm, this.country).then(response => {
        this.vendors = response.data;
        this.vendorsAux = this.vendors;
        this.loading = false;
      }).catch(err => console.log('ERROR GETTING VENDORS -->', err));
    },
    selectVendor(e) {
      this.vendor = {
        vendor: e.data.id,
        general: {
          title: '',
          name: e.data.name,
          taxNumber: '',
          accountGroup: 'ZNOR',
          fiscalNumber: e.data.fiscalNumber
        },
        address: {
          telephone: e.data.telephone,
          address: e.data.street,
          language: 'E',
          city: e.data.city,
          postalCode: e.data.postalCode,
          country: e.data.country
        }
      }
      this.$emit('checkFWAgent', this.vendor)
    },
    searchVendors(e) {
      let searchTerm = e.target.value
      this.vendors = this.getVendors(searchTerm, "ZNOR");
    }
  },

}
</script>

<template>
  <div class="app-content">
    <div class="app-content__content cl-content">
      <div class="row">
        <div class="cl-search">
          <InputText class="text-input border-light" placeholder="Search" @input="searchVendors" />
        </div>
        <div class="cl-results">
          <div class="cl-results__wrapper">
            <DataTable :value="vendors" scrollDirection="horizontal" :loading="loading" :paginator="true" :rows="7"
              paginatorTemplate="CurrentPageReport PrevPageLink PageLinks NextPageLink"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords}">
              <Column field="id" header="Id" :sortable="true">
              </Column>
              <Column field="name" :header="$t('Name')" :sortable="true">
              </Column>
              <Column field="street" :header="$t('Address')" :sortable="true">
              </Column>
              <Column :style="{ width: '10%' }" class="data-list__column-button">
                <template #body="slotProps">
                  <Button class="p-button btn btn--primary" label="SELECT" @click="selectVendor(slotProps)" />
                </template>
              </Column>
            </DataTable>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/assets/css/lh-ds/core/functions' as *;
@use '@/assets/css/hodim/layout/data-list';

.cl {
  $border-color: #D8D8D8;

  &-content {
    .row {
      display: flex;
      flex-direction: column;
    }
  }

  &-search {
    width: 30%;
    margin-top: 1.5rem;
  }

  &-results {
    flex: 1;

    margin-top: rem(38px);
    border: 1px solid $border-color;
    background-color: white;

    &__wrapper {
      height: 100%;
      overflow: auto;
    }
  }

  &-result {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: rem(12px) rem(20px);

    cursor: pointer;

    +.cl-result {
      border-top: 1px solid $border-color;
    }

    &__name {
      font-weight: bold;
      margin-bottom: rem(1px);
    }

    &__ref {
      color: rgba(rgba(0, 0, 0, 0.678), .5);
    }

    &__right {
      display: flex;
      align-items: center;

      *+* {
        margin-left: rem(16px);
      }
    }

    &__tag {
      padding: rem(4px) rem(8px);
      color: #FF2000;
      border: 1px solid;
      border-radius: 2px;
      font-size: rem(12px);
      line-height: rem(20px);
    }
  }
}
</style>
