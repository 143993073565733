'use strict';
import { app } from '@/main.js';
const VendorsService = {
    // VENDORS FOR VEHICLES ---> 
    getVendors(search, country) {
        return new Promise((resolve, reject) => {
            let body = { search, country };
            app.config.globalProperties.$axios.post("/masterData/Vendors", body).then((response) => {
                return resolve(response);
            }).catch(error => { reject({ ret: error.response.status, msg: error.response.data }); });
        })
    },
    getVendor(id) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.get(`/masterData/vendor/${id}`).then((response) => {
                return resolve(response);
            }).catch(error => { reject({ ret: error.response.status, msg: error.response.data }); });
        })
    },
    getVendorByName(vendorName){
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post(`/masterData/getVendorByName`, { vendorName }).then((response) => {
                return resolve(response);
            }).catch(error => { reject({ ret: error.response.status, msg: error.response.data }); });
        })
    },
    // VENDORS FOR INBOUNDS --->   
    getVendorsList(data) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post('/masterData/VendorsList/', data)
                .then(response => {
                    return resolve(response);
                })
                .catch(err => { 
                    reject({ ret: err.response.status, msg: err.response.data })
                })
        })
    },
    getVendorById(id) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.get(`/masterData/vendorById/${id}`)
                .then(response => {
                    return resolve(response);
                })
                .catch(err => { 
                    reject({ ret: err.response.status, msg: err.response.data })
                })
        })
    },
}

export default VendorsService;