<template>
	<div class="m-5">
		<!-- FRANCE -->
		<div class="columns" v-if="user.country.code == 'FR'">
			<div class="column is-one-third">
				<label for="category">{{ $t(vehicle_form.category.name) }}
					<span v-if="vehicle_form.category.required">*</span>
				</label><br>
				<Dropdown v-model="vehicle_form.category.value" :options="truckCategories" filter optionLabel="name"
					@change="selectCategory" @update:modelValue="elementUpdated(vehicle_form.category)"
					:class="vehicle_form.category.validation_status" />
			</div>
			<div class="column is-one-third">
				<label for="vehicleType">{{ $t(vehicle_form.vehicletype.name) }}
					<span v-if="vehicle_form.vehicletype.required">*</span>
				</label><br>
				<Dropdown v-model="vehicle_form.vehicletype.value" :options="vehicleTypesList" optionLabel="name"
					@change="selectVehicleType" filter @update:modelValue="elementUpdated(vehicle_form.vehicletype)"
					:class="vehicle_form.vehicletype.validation_status" />
			</div>
			<div class="column is-one-third">
				<label for="licensePlate">{{ $t(vehicle_form.licensePlateNumber.name) }}
					<span v-if="vehicle_form.licensePlateNumber.required">*</span>
				</label><br>
				<InputText id="licensePlate" v-model="vehicle_form.licensePlateNumber.value"
					:class="vehicle_form.licensePlateNumber.validation_status"
					@update:modelValue="elementUpdated(vehicle_form.licensePlateNumber)" />
			</div>
		</div>
		<!-- UK -->
		<div class="columns" v-if="user.country.code == 'GB'">
			<div class="column is-one-third">
				<label for="licensePlate">{{ $t(vehicle_form.licensePlateNumber.name) }}
					<span v-if="vehicle_form.licensePlateNumber.required">*</span>
				</label><br>
				<InputText id="licensePlate" v-model="vehicle_form.licensePlateNumber.value"
					:class="vehicle_form.licensePlateNumber.validation_status"
					@update:modelValue="elementUpdated(vehicle_form.licensePlateNumber)" />
			</div>
			<div class="column is-one-third">
				<label for="carriers">{{ $t(vehicle_form.carrier.name) }}
					<span v-if="vehicle_form.carrier.required">*</span>
				</label><br>
				<Dropdown v-model="vehicle_form.carrier.value" :options="carrierList" optionLabel="name"
					:class="vehicle_form.carrier.validation_status" filter :disabled="vehicle_form.carrier.read_only"
					:required="vehicle_form.carrier.required"
					@update:modelValue="elementUpdated(vehicle_form.carrier)" />
			</div>
			<div class="column is-one-third">
				<label for="fwa">{{ $t(vehicle_form.FWAgent.name) }}
					<span v-if="vehicle_form.FWAgent.required">*</span>
				</label><br>
				<Dropdown v-model="vehicle_form.FWAgent.value" :options="FWAgentList" optionLabel="name"
					:class="vehicle_form.FWAgent.validation_status" filter :disabled="vehicle_form.FWAgent.read_only"
					:required="vehicle_form.FWAgent.required" 
					@update:modelValue="elementUpdated(vehicle_form.FWAgent)" />
			</div>
		</div>
		<!-- FRANCE -->
		<div class="columns" v-if="user.country.code == 'FR'">
			<div class="column">
				<label for="carriers">{{ $t(vehicle_form.carrier.name) }}
					<span v-if="vehicle_form.carrier.required">*</span>
				</label><br>
				<Dropdown v-model="vehicle_form.carrier.value" :options="carrierList" optionLabel="name"
					:class="vehicle_form.carrier.validation_status" filter :disabled="vehicle_form.carrier.read_only"
					:required="vehicle_form.carrier.required"
					@update:modelValue="elementUpdated(vehicle_form.carrier)" />
			</div>
			<!--<div class="column is-one-third">
				<label for="fwa">{{ $t(vehicle_form.FWAgent.name) }}
					<span v-if="vehicle_form.FWAgent.required">*</span>
				</label><br>
				<Dropdown v-model="vehicle_form.FWAgent.value" :options="FWAgentList" optionLabel="name"
					:class="vehicle_form.FWAgent.validation_status" filter :disabled="vehicle_form.FWAgent.read_only"
					:required="vehicle_form.FWAgent.required"
					@update:modelValue="elementUpdated(vehicle_form.FWAgent)" />
			</div>-->
			<div class="column is-one-third">
				<label for="natureOfTrailer">{{ $t(vehicle_form.natureofTrailer.name) }}
					<span v-if="vehicle_form.natureofTrailer.required">*</span>
				</label><br>
				<Dropdown v-model="vehicle_form.natureofTrailer.value" :options="natureOfTrailerList" optionLabel="name"
					:class="vehicle_form.natureofTrailer.validation_status" filter
					:disabled="vehicle_form.natureofTrailer.read_only" :required="vehicle_form.natureofTrailer.required"
					@update:modelValue="elementUpdated(vehicle_form.natureofTrailer)" />
			</div>
		</div>
		<!-- UK -->
		<div class="columns" v-if="user.country.code == 'GB'">
			<div class="column is-one-third">
				<label for="category">{{ $t(vehicle_form.category.name) }}
					<span v-if="vehicle_form.category.required">*</span>
				</label><br>
				<InputText id="category" v-model="vehicle_form.category.value.name"
					:class="vehicle_form.category.validation_status" :disabled="vehicle_form.category.read_only"
					@update:modelValue="elementUpdated(vehicle_form.category)" />
			</div>
			<div class="column is-one-third">
				<label for="vehicle_contract_type">{{ $t(vehicle_form.vehicle_contract_type.name) }}
					<span v-if="vehicle_form.vehicle_contract_type.required">*</span>
				</label><br>
				<Dropdown v-model="vehicle_form.vehicle_contract_type.value" :options="vehicleContractTypesList" optionLabel="name"
					@change="selectVehicleContractTypes" filter @update:modelValue="elementUpdated(vehicle_form.vehicle_contract_type)" 
					:class="vehicle_form.vehicle_contract_type.validation_status" :required="vehicle_form.vehicle_contract_type.required" 
					:disabled="vehicle_form.vehicle_contract_type.read_only" />
			</div>
			<div class="column is-one-third">
				<label for="vehicleType">{{ $t(vehicle_form.vehicletype.name) }}
					<span v-if="vehicle_form.vehicletype.required">*</span>
				</label><br>
				<Dropdown v-model="vehicle_form.vehicletype.value" :options="vehicleTypesList" optionLabel="name"
					@change="selectVehicleType" filter @update:modelValue="elementUpdated(vehicle_form.vehicletype)"
					:class="vehicle_form.vehicletype.validation_status" />
			</div>
		</div>
		<div class="columns">
			<div class="column is-one-third">
				<label for="emptyWeight">{{ $t(vehicle_form.emptyWeight.name) }}
					<span v-if="vehicle_form.emptyWeight.required">*</span>
				</label><br>
				<InputNumber v-model="vehicle_form.emptyWeight.value"  type="number" :max="vehicle_form.emptyWeight.max_value"
					:class="vehicle_form.emptyWeight.validation_status" :min="vehicle_form.emptyWeight.min_value"
					@update:modelValue="elementUpdated(vehicle_form.emptyWeight)" :maxFractionDigits="2"
					:locale="numberMask" />
			</div>
			<div class="column is-one-third">
				<label for="maxGrossWeight">{{ $t(vehicle_form.maxGrossWeight.name) }}
					<span v-if="vehicle_form.maxGrossWeight.required">*</span>
				</label><br>
				<InputNumber v-model="vehicle_form.maxGrossWeight.value"
					:max="moment(vehicle_form.firstDate.value).year() > '2013' ? 44 : 40"
					:class="vehicle_form.maxGrossWeight.validation_status" :min="vehicle_form.maxGrossWeight.min_value"
					@update:modelValue="elementUpdated(vehicle_form.maxGrossWeight)" :maxFractionDigits="2"
					:locale="numberMask" />
			</div>
			<div class="column is-one-third">
				<label for="firstDate">{{ $t(vehicle_form.firstDate.name) }}
					<span v-if="vehicle_form.firstDate.required">*</span>
				</label><br>
				<Calendar v-model="vehicle_form.firstDate.value" hourFormat="24" :dateFormat="dateFormat"
					:class="vehicle_form.firstDate.validation_status"
					@update:modelValue="elementUpdated(vehicle_form.firstDate)" />
			</div>
		</div>
		<div v-if="user.country.code == 'GB'" class="columns">
			<div class="column is-one-third">
				<label for="driver">{{ $t(vehicle_form.driver.name) }}
					<span v-if="vehicle_form.driver.required">*</span>
				</label><br>
				<!-- <InputText v-model="vehicle_form.driver.value" type="text" :class="vehicle_form.driver.validation_status"  /> -->
				<!-- <Dropdown v-model="vehicle_form.driver.value" :options="driverList" optionLabel="name"
					:placeholder="$t('Search a driver')" style="width: 100% !important;" :required="vehicle_form.driver.required"
					:class="vehicle_form.driver.validation_status" filter :disabled="vehicle_form.driver.read_only"
					@filter="searchDrivers" /> -->

				<AutoComplete v-model="vehicle_form.driver.value" :suggestions="driverList" optionLabel="name"
					@complete="searchDrivers" completeMethod="search" :required="vehicle_form.driver.required"
					:placeholder="$t('Search a driver')" :class="vehicle_form.driver.validation_status" :disabled="vehicle_form.driver.read_only" 
					inputStyle="width: 100% !important" dropdown inputClass="custom-autocomplete-input" />
					
				<i :style="{ 'margin-top': '10px', 'margin-left': '10px', 'position': 'absolute' }" class="zmdi zmdi-edit"
					@click="() => this.modalVisible = true"></i>
			</div>
		</div>
		<div  v-if="user.country.code == 'FR'">
			<label>{{ $t(vehicle_form.comments.name) }}</label><br>
			<Textarea v-model="vehicle_form.comments.value" class="w-100 mb-3 mt-2"></Textarea><br>
		</div>
		<label for="attachments">{{ $t(vehicle_form.attachments.name) }}</label><br>
		<fileUploader class="mt-2 w-100 mb-3" :filesList="vehicle_form.attachments.value" :maxFileSize="MAX_FILE_SIZE"
			:lovType="attachmentsLovType" @updateFilesList="updateFilesList" :hasCategories="false"></fileUploader>

		<div style="float: right;" class="pt-3 pb-4">
			<Button :label="$t('Save').toUpperCase()" class="p-button btn btn--primary" @click="save"></Button>
		</div>
	</div>

	<DriverModal :isVisible="modalVisible" @hide="closeDriverModal" @setDriver="setNewDriver"/>
</template>

<script setup>
import moment from 'moment';
import JSZip from 'jszip';

import * as fileHelper from '@/helper/files';
import s3Service from '@/services/api/s3';
import vehicleService from "@/services/api/vehicles.js";
import countriesService from "@/services/api/countries";
import DriversService from '@/services/api/drivers';
import InputText from "primevue/inputtext";

import Button from "primevue/button";
import Dropdown from 'primevue/dropdown';
import InputNumber from "primevue/inputnumber";
import Calendar from "primevue/calendar";
import Textarea from 'primevue/textarea';
import AutoComplete from 'primevue/autocomplete';

import DriverModal from '@/components/Deliveries/modals/DriverModal.vue'
import FileUploader from '@/components/common/FileUploader'
import commonClasses from "@/store/commonClasses.js";
</script>

<script>
export default {
	name: "CreateVehicleModal",
	components: {
		Button,
		InputNumber,
		InputText,
		Dropdown,
		AutoComplete,
		Calendar,
		Textarea,
		FileUploader,
		DriverModal
	},
	props: {
		deliveryType: {
			type: String,
			default: commonClasses.deliveryType.Delivered
    	}
	},
	data: function () {
		return {
			user: JSON.parse(sessionStorage.getItem('user')),
			dateFormat: JSON.parse(localStorage.getItem("dateFormat")) ? JSON.parse(localStorage.getItem("dateFormat")).calendar : 'dd/mm/yyyy',
			vehicle_form: {
				licensePlateNumber: {
					value: '', required: true, read_only: false, name: 'License Plate Number', validation_status: 'none'
				},
				vehicletype: {
					value: '', required: true, read_only: false, name: 'Vehicle Type', validation_status: 'none'
				},
				category: {
					value: '', required: true, read_only: false, name: 'Category', validation_status: 'none'
				},
				carrier: {
					value: '', required: true, read_only: false, name: 'Carrier', validation_status: 'none'
				},
				FWAgent: {
					value: '', required: true, read_only: false, name: 'FWA', validation_status: 'none'
				},
				natureofTrailer: {
					value: '', required: false, read_only: true, name: 'Nature Of Trailer', validation_status: 'none'
				},
				emptyWeight: {
					value: 0, required: true, read_only: false, name: 'Tare Weight', validation_status: 'none',
					max_value: 100, min_value: 0
				},
				maxGrossWeight: {
					value: 0, required: true, read_only: false, name: 'Gross Weight', validation_status: 'none',
					max_value: 100, min_value: 0
				},
				planning_capacity: {
					value: 0, required: true, read_only: true, name: 'Planning Capacity', validation_status: 'none',
					max_value: 100, min_value: 0
				},
				min_load: {
					value: 0, required: true, read_only: true, name: 'Min Load', validation_status: 'none',
					max_value: 100, min_value: 0
				},
				firstDate: {
					value: new Date(), required: true, read_only: false, name: 'First Date', validation_status: 'none'
				},
				attachments: {
					value: [], required: false, read_only: false, name: 'Attachments', validation_status: 'none'
				},
				vehicle_contract_type: {
					value: '', required: false, read_only: false, name: 'Contract Type', validation_status: 'none'
				},
				driver: {
					value: '', required: true, read_only: false, name: 'Driver', validation_status: 'none'
				}
			},
			truckCategories: [
				{ name: 'Tractor', value: 'PM207028' },
				{ name: 'Trailer', value: 'PM207029' },
				{ name: 'Non-articulated truck', value: 'PM207032' },
				{ name: 'Car', value: 'PM207032' },
				{ name: 'Train', value: 'PM207021' },
				{ name: 'Boat', value: 'PM207004' },
			],
			carrierList: [],
			FWAgentList: [],
			natureOfTrailerList: [],
			vehicleTypes: [],
			vehicleTypesList: [],
			vehicleContractTypesList: [],
			MAX_FILE_SIZE: 10 * 1024 * 1024,
			attachmentsLovType: "VEHICLES_ATTACHMENT_CATEGORIES",
			numberMask: JSON.parse(localStorage.getItem("numberFormat")) || "de-DE",
			driverList: [],
			modalVisible: false
		};
	},
	created(){
		if (this.user.country.code == 'GB') {
			this.vehicle_form.category.value = { name: 'Truck', value: 'PM207032' }
			this.vehicle_form.category.read_only = true;
			this.vehicle_form.FWAgent.value = { name: "EX-WORKS VEHICLE", value: "88888888" }
			this.vehicle_form.FWAgent.read_only = this.deliveryType === commonClasses.deliveryType.PickUp
			this.vehicle_form.carrier.value = { name: "EX-WORKS VEHICLE", value: "88888888" }
			this.vehicle_form.carrier.read_only = this.deliveryType === commonClasses.deliveryType.PickUp
			this.vehicle_form.vehicle_contract_type.value = { name: "Ex Works", value: "Ex Works"}
			this.vehicle_form.vehicle_contract_type.read_only = this.deliveryType === commonClasses.deliveryType.PickUp
			this.vehicle_form.natureofTrailer.required = false;

		} else if (this.user.country.code == 'FR') {

			this.vehicle_form = {
				category: {
					value: '', required: true, read_only: false, name: 'Category', validation_status: 'none'
				},
				vehicletype: {
					value: '', required: false, read_only: false, name: 'Vehicle Type', validation_status: 'none'
				},
				licensePlateNumber: {
					value: '', required: true, read_only: false, name: 'License Plate Number', validation_status: 'none'
				},
				carrier: {
					value: '', required: true, read_only: false, name: 'Carrier', validation_status: 'none'
				},
				natureofTrailer: {
					value: '', required: false, read_only: true, name: 'Nature Of Trailer', validation_status: 'none'
				},
				emptyWeight: {
					value: 0, required: true, read_only: false, name: 'Empty Weight', validation_status: 'none',
					max_value: 1000, min_value: 0
				},
				maxGrossWeight: {
					value: 0, required: true, read_only: false, name: 'Gross Weight', validation_status: 'none',
					max_value: 1000, min_value: 0
				},
				firstDate: {
					value: new Date(), required: true, read_only: false, name: 'First Date', validation_status: 'none'
				},
				comments: {
					value: '', required: false, read_only: false, name: 'Comments', validation_status: 'none'
				},
				attachments: {
					value: [], required: false, read_only: false, name: 'Attachments', validation_status: 'none'
				}
			},

			this.vehicle_form.category.value = ''
			this.vehicle_form.category.read_only = false;
			this.vehicle_form.carrier.value = ''
			this.vehicle_form.natureofTrailer.required = true;
		}
	},
	mounted() {
		this.getVehicleTypes();
		this.getCarriers();
		this.getFWAgents();
		this.getNatureOfTrailerLOV();
		this.getVehicleContractTypes();
		this.getDriverList();
	},
	methods: {
		getVehicleTypes() {
			vehicleService.getVehicleTypesOpts(this.user.country.code).then((response) => {
				let data = response.data.map((element) => {
					return {
						name: element.vehicleType + ' - ' + element.description, code: element.vehicleType, maxGrossWeight: element.max_gross_weight,
						planning_capacity: element.planning_capacity
					}
				});
				this.vehicleTypes = data;
				this.vehicleTypesList = data;
			})
		},
		parseData(rawData) {
			let companyCode = this.user.plant.plant_parameters.find(p => p.code == 'company_code').value;
			let myObj = {
				audit_data: {
					country: this.user.country.code
				},
				isLocal: 1,
				vehicleId: this.vehicle_form.licensePlateNumber.value.toUpperCase(),
				cluster: this.user.plantsInCluster[0].cluster,
				base_plant: this.user.plant.code,
				addressId: this.user.plant.code,
				departmentCode: this.user.plant.code,
				companyCode: companyCode ? companyCode : this.user.plant.code,
				weight_uom: 'TNE',
				euro_standard: "EU6"
			};
			Object.entries(rawData).forEach((field) => {
				const [key, value] = field;
				if (key == 'category') {
					myObj['fleetCategory'] = value.value.value;
				} else if (key == 'FWAgent') {
					myObj['fwd_agent'] = value.value.name;
					myObj['fwd_agent_id'] = value.value.value;
				} else if (key == 'carrier') {
					myObj['subcontractorName'] = value.value.name;
					myObj['subcontractorCode'] = value.value.value;
					myObj['subcontractorId'] = value.value.value;
					myObj['haulier'] = value.value.name;
					myObj['haulierId'] = value.value.value;
				} else if (key == 'natureofTrailer') {
					myObj[key] = value.value.value;
				} else if (key == 'licensePlateNumber') {
					myObj['license_plate'] = value.value.toUpperCase();
				} else if (key == 'maxGrossWeight') {
					myObj['max_gross_weight'] = Number(value.value);
				} else if (key == 'planning_capacity') {
					myObj['planning_capacity'] = Number(value.value);
				} else if (key == 'min_load') {
					myObj['min_load'] = Number(value.value);
				} else if (key == 'emptyWeight') {
					myObj['emptyWeight'] = Number(value.value);
					myObj['tare_weight'] = Number(value.value);
				} else if (key == 'vehicletype') {
					myObj['vehicle_type'] = value.value.code
				} else if (key == 'vehicle_contract_type') {
					myObj['vehicle_contract_type'] = value.value.name
				} else if (key == 'driver') {
					const min = Math.ceil(100);
					const max = Math.floor(1000);
					const driverId = Math.floor(Math.random() * (max - min + 1)) + min
					myObj['driver'] = value.value.value // driverId // MPC
					myObj['driver_autoId'] = value.value.autoId //driverId // number
					myObj['driver_name'] = value.value.name // name
				} else {
					myObj[key] = value.value.code != undefined ? value.value.code : value.value;
				}
			});
			if(myObj['fwd_agent'] == undefined){
				myObj['fwd_agent'] = myObj['subcontractorName'];
				myObj['fwd_agent_id'] = myObj['subcontractorId'];
			}
			myObj['firstDate'] = moment(myObj['firstDate']).format('YYYY-MM-DD');
			return myObj;
		},
		async save() {
			try {
				let valid = this.validateForm();
				if (valid) {
					let resp = await vehicleService.getVehicleById(this.vehicle_form.licensePlateNumber.value);
					if (resp.data.length > 0) {
						this.$notify({
							title: this.$t("Unable to create vehicle"),
							text: this.$t("Vehicle already exists"),
							type: 'error',
							duration: -1,
							closeOnClick: true
						});
					} else {
						await this.createAndUpload();
					}
				}
			} catch (err) {
				console.error("Error during save operation:", err);
				this.$notify({
					title: this.$t("Error"),
					text: this.$t("An error occurred while saving the vehicle"),
					type: 'error',
					duration: -1,
					closeOnClick: true
				});
			}
		},
		async createAndUpload() {
			let loader = this.$loading.show();
			try {
				let newVehicleCreated = await this.createVehicle();
				await this.uploadAttachments();
				// Cerrar el modal solo después de que ambas operaciones se hayan completado.
				this.$emit('close', { ...newVehicleCreated, fromCreateVehicle: true })
			} catch (err) {
				console.error("Error during create and upload operation:", err);
			} finally {
				loader.hide();
			}
		},
		async createVehicle() {
			let vehicle = this.parseData(this.vehicle_form);
			try {
				const newVehicle = await vehicleService.insertVehicleFromRocks(vehicle)
				this.$notify({
					title: this.$t("New Vehicle Created"),
					text: this.$t(`Saved correctly`),
					type: 'success',
					duration: 3000
				});
				return this.formatVehicleList(newVehicle.data)
			} catch (err) {
				console.log('ERROR CREATING VEHICLES -->', err);
			}
		},
		async uploadAttachments() {
			if (this.vehicle_form.attachments.value.length > 0) {
				try {
					const zip = new JSZip();
					for (let file of this.vehicle_form.attachments.value) {
						zip.file(fileHelper.sanitizeFilename(file.file.name), file.file);
					}
					const zipFile = await zip.generateAsync({ type: 'base64' });

					const numChars = 100000;
					const zipArray = fileHelper.splitFileInChunks(zipFile, numChars);
					const tempDir = ['vehicles', this.vehicle_form.licensePlateNumber.value];
					await Promise.all(zipArray.map((chunk, index) => s3Service.uploadAttachmentsChunk(tempDir, chunk, index)));

					const s3Prefix = `${this.user.country.code}/vehiclesAttach/${this.vehicle_form.licensePlateNumber.value}`;
					await s3Service.uploadAttachments(tempDir, s3Prefix);
					// const responseFiles = await s3Service.uploadAttachments(tempDir, s3Prefix);
					// console.log('FILES UPLOADED ---->', responseFiles.data);
				} catch (error) {
					console.error('ERROR UPLOADING THE ATTACHMENTS -->', error)
				}
			}
		},
		getCarriers() {
			vehicleService.getCarriers().then((response) => {
				let data = response.data[0].map((element) => { return { name: element.name, value: element.id } });
				this.carrierList = data;
			})
		},
		getFWAgents() {
			vehicleService.getVendors().then((response) => {
				let data = response.data[0].map((element) => { return { name: element.name, value: element.id } });
				this.FWAgentList = data;
			})
		},
		getDriverList() {
			if (this.user.country.code == 'GB') {
				DriversService.getDriversMysql(10).then((resp) => {
          			let drivers = resp.data;
          			this.driverList = drivers.map(d => ({ name: d.internalDriverName, autoId: d.id, value: d.MPQCId }));
				})
			}
		},
		async searchDrivers(e) {
			if (e.query.length > 3) {
				try {
					let response = await DriversService.searchDriversMysql(e.query)
					console.log (response)
					this.driverList = response.data[0].length > 0
						? response.data[0].map(d => ({ name: d.internalDriverName, autoId: d.id, value: d.MPQCId }))
						: []

				} catch (err) {
					console.error (err)
				}
			}
		},
		async getNatureOfTrailerLOV() {
			const { data: LOV } = await countriesService.getCountryLovByName('TRAILER_NATURE');
			this.natureOfTrailerList = LOV.map((element) => { return { name: element.name, value: element.value } });
		},
		async getVehicleContractTypes() {
			const { data: LOV } = await countriesService.getCountryLovByName('VEHICLE CONTRACT TYPES');
			this.vehicleContractTypesList = LOV.map((element) => { return { name: element.name, value: element.value } });
		},
		selectCategory(event) {
			console.log('SELECTED -->', event.value.name)
			let filters = [];

			if (event.value.name == 'Trailer') {
				this.vehicle_form.natureofTrailer.read_only = false;
				this.vehicle_form.natureofTrailer.required = true;
			} else {
				this.vehicle_form.natureofTrailer.read_only = true;
				this.vehicle_form.natureofTrailer.required = false;
				this.vehicle_form.natureofTrailer.value = '';
			}

			switch (event.value.name) {
				case 'Tractor':
					filters = this.vehicleTypes.filter(x => x.code == 'Z019');
					break;
				case 'Trailer':
					filters = this.vehicleTypes.filter(x => x.code == 'T037' || x.code == 'T038' || x.code == 'T039' || x.code == 'T040' || x.code == 'T010');
					break;
				case 'Non-articulated truck':
					filters = this.vehicleTypes.filter(x => x.code == 'Z003' || x.code == 'Z004' || x.code == 'Z005' );
					break;
				case 'Boat':
					filters = this.vehicleTypes.filter(x => x.code == 'T042' || x.code == 'T046' || x.code == 'T043');
					break;
				case 'Train':
					filters = this.vehicleTypes.filter(x => x.code == 'T044');
					break;
				case 'Car':
					filters = this.vehicleTypes.filter(x => x.code == 'T045');
					break;
				default:
					filters = this.vehicleTypes;
					break;
			}
			this.vehicleTypesList = filters;
		},
		selectVehicleType(event) {
			if(this.user.country.code != 'FR'){
				this.vehicle_form.maxGrossWeight.value = event.value.maxGrossWeight;
				this.vehicle_form.planning_capacity.value = event.value.planning_capacity;
				this.vehicle_form.min_load.value = event.value.planning_capacity;
			}
		},
		selectVehicleContractTypes(event) {
			this.vehicle_form.vehicle_contract_type.value = event.value
		},
		validateForm() {
			let isValid = true;
			Object.entries(this.vehicle_form).forEach((field) => {
				const [key, value] = field;
				if (value.required && (value.value == null || value.value == '')) {
					this.vehicle_form[key].validation_status = 'field_is_not_valid';
					isValid = false;
					this.$notify({
						title: this.$t("Field " + value.name + " is required"),
						type: "error",
						duration: -1,
						closeOnClick: true
					})
				}
			})
			return isValid;
		},
		formatVehicleList(db) {
			if (db == undefined) { return undefined }
			//console.log("FORMAT VEHICLE LIST ----------------->", db)
			let vehicle = {
				country: db.country,
				licensePlateNumber: db.licensePlateNumber,
				numberOfAxels: 0,
				maximumLoadWeight: db.maxGrossWeight,
				vehicleId: db.vehicleId,
				unitWeight: 'TNE',
				active: 1,
				fleetCategory: db.fleetCategory != undefined && db.fleetCategory != null ? db.fleetCategory : 'PM207032',
				carrierCode: db.subcontractorId,
				carrierId: db.subcontractorId,
				carrierName: db.subcontractorName,
				createdforpickup: 1,
				haulierType: db.resource_categoryCode,
				driverId: db.driverId,
				driverName: db.driverName,
				fwdAgent: db.fwd_agent,
				fwdAgentId: db.fwd_agent_id,
				general: {
					equipmentCategory: 'Z',
					description: db.license_plate,
					status: true
				},
				details: {
					carrierName: db.subcontractorName,
					availability: {
						basePlantCode: db.addressId,
						basePlantName: db.plantName,
						basePlantShift: {
							shiftname: db.shiftName,
							startHour: moment(db.shiftStart).format('HH:mm'),
							startDate: moment(db.shiftStart).format('YYYY-MM-DD'),
							startTime: moment(db.shiftStart).format('HH:mm'),
							endHour: moment(db.shiftEnd).format('HH:mm'),
							endDate: moment(db.shiftEnd).format('YYYY-MM-DD'),
							endTime: moment(db.shiftEnd).format('HH:mm'),
							shiftColor: db.shiftColor,
						},
						truckBlocker: db.truckBlocker,
						truckPlanning: db.truckPlanning
					},
					category: {
						vehicleType: db.resource_kindCode,
					},
					organization: {
						vehicleContractType: db.vehicle_contract_type
					},
					characteristics: {
						chapter8: db.Chapter_8,
						chutedVehicle: db.Chute,
						insulated: db.Insulated,
						crossRail: db.Cross_Rail,
						euroStandard: db.EU5 == "true" ? "EU5" : "EU6",
						londonEmissionZone: db.Low_Emission_Zone,
						steelBody: db.Steel_Body,
					},
				},
				technicalInfo: {
					capacity: parseFloat(db.maxGrossWeight) - parseFloat(db.tareWeight == null ? 0 : db.tareWeight),
					fleetCategory: db.fleetCategory != undefined && db.fleetCategory != null ? db.fleetCategory : 'PM207032',
					tare: db.tareWeight == null ? 0 : db.tareWeight,
					maximumLoadWeight: parseFloat(db.maxGrossWeight),
					unitWeight: db.weight_uom
				},
				characteristics: {
					chapter8: db.Chapter_8,
					chutedVehicle: db.Chute,
					insulated: db.Insulated,
					crossRail: db.Cross_Rail,
					euroStandard: db.EU5 == "true" ? "EU5" : "EU6",
					londonEmissionZone: db.Low_Emission_Zone,
					steelBody: db.Steel_Body,
				},
				isFavourite: db.isFavourite,
				localVehicle: db.isLocal != undefined ? db.isLocal : 0
			}
			return vehicle
		},
		elementUpdated(element) { element.validation_status = 'none' },
		updateFilesList(files) { this.vehicle_form.attachments.value = files },
		closeDriverModal(value) {
            this.modalVisible = false;
			if(value == 'CREATE') {
				this.$notify({
					title: this.$t("Driver created"),
					type: 'success',
				});
			}
        },
		setNewDriver(driver) {
			if(driver.value != '' && driver.name != '') {
				this.vehicle_form.driver.value = driver
			}
		}
	},
};
</script>

<style lang="scss" scoped>
.p-inputtext,
.p-dropdown,
.p-inputnumber,
.p-calendar,
Button {
	width: 100% !important
}

.field_is_valid {
	border: 1px solid rgb(31, 236, 31) !important;
}

.field_is_not_valid {
	border: 1px solid rgb(236, 62, 31) !important;
}

.p-dropdown {
	border: 1px solid #d8d8d8 !important;
}
</style>
