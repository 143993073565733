<template>
  <div class="m-5">
    <div class="head_vehicle_list">
      <div class="cl-change">
        <div class="columns ml-3 mt-0"><!--  v-if="delivery._source.deliveryType !== 'Delivered'"  -->
          <div class="mb-3 mt-4 pr-0 column-3"><label><b>{{ $t('Is local') }}</b></label></div>
          <div class="column pl-0">
            <InputSwitch v-model="localSearch" class="ml-5"></InputSwitch>
          </div>
        </div>
      </div>

      <div class="cl-search">
        <Button class="p-button-secondary create-button" :label="$t('New Vehicle')" @click="displayModalcreateVehicle = true"></Button>
        <span class="p-input-icon-left">
          <i class="pi pi-search"></i>
          <InputText type="text" :placeholder="$t('Search')" @keydown="inputKeyDown($event)" @input="searchVehicles" />
        </span>

      </div>
    </div>
    <div class="cl-results">
      <div class="cl-results__wrapper">
        <DataTable :value="vehiclesAux" scrollDirection="horizontal" :loading="loading" :paginator="true" :rows="7"
          paginatorTemplate="CurrentPageReport PrevPageLink PageLinks NextPageLink" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}">
          <Column field="licensePlateNumber" :style="{ width: '130px' }" :header="$t('License Plate Number')" :sortable="true">
            <template #body="slotProps">
              <!-- <span>{{ slotProps.data.licensePlateNumber }}</span> -->
              <strong :style="{ cursor: 'pointer' }" v-if="vehicleSelected == true">
                {{ slotProps.data.licensePlateNumber }}
              </strong>
              <span v-else>{{ slotProps.data.licensePlateNumber }}</span>
            </template>
          </Column>
          <Column field="vehicleId" :header="$t('Vehicle Id')" :style="{ width: '15%' }" :sortable="true">
            <template #body="slotProps">
              <span v-if="vehicleSelected == true">
                {{ formatVehicleLocalId(slotProps.data.vehicleId) }}
              </span>
              <span v-else>{{ slotProps.data.vehicleId }}</span>
            </template>
          </Column>
          <Column field="carrierName" :style="{ width: '15%' }" :header="$t('Haulier')" :sortable="true">
          </Column>
          <Column field="details.category.vehicleType" :header="$t('Vehicle Type SAP')" :sortable="true">
          </Column>
          <Column field="details.category.vehicleType" v-if="user.country.code != 'FR'" :header="$t('Vehicle Type E1')" :sortable="true">
            <template #body="slotProps">
              <span v-if="slotProps.data.details.category.vehicleType == 'Z005'
                || slotProps.data.details.category.vehicleType == 'Z004'
                || slotProps.data.details.category.vehicleType == 'Z003'">Non-articulated truck</span>

              <span v-if="slotProps.data.details.category.vehicleType == 'T037'
                || slotProps.data.details.category.vehicleType == 'T038'
                || slotProps.data.details.category.vehicleType == 'T039'
                || slotProps.data.details.category.vehicleType == 'T040'
                || slotProps.data.details.category.vehicleType == 'T010'">Trailer</span>

              <span v-if="slotProps.data.details.category.vehicleType == 'Z019'">Tractor</span>

              <span v-if="slotProps.data.details.category.vehicleType == 'T042'
                || slotProps.data.details.category.vehicleType == 'T046'
                || slotProps.data.details.category.vehicleType == 'T043'">Boat</span>

              <span v-if="slotProps.data.details.category.vehicleType == 'T044'">Train</span>

              <span v-if="slotProps.data.details.category.vehicleType == 'T045'">Car</span>

            </template>
          </Column>
          <Column field="capacity" :header="$t('Truck Capacity')" :sortable="true" dataType="numeric">
            <template #body="slotProps">
              {{ formatNumber(slotProps.data.technicalInfo.capacity) }} {{ (slotProps.data.unitWeight)
                ? slotProps.data.unitWeight : 'KGM' }}
            </template>
          </Column>
          <Column field="maximumLoadWeight" :header="$t('Max Gross Weight')" :sortable="true">
            <template #body="slotProps">
              {{ formatNumber(slotProps.data.maximumLoadWeight) }} {{ (slotProps.data.unitWeight)
                ? slotProps.data.unitWeight : 'KGM' }}
            </template>
          </Column>
          <Column field="localVehicle" :header="$t('is Local')" :sortable="true">
            <template #body="slotProps">{{ slotProps.data.localVehicle == 1 ? 'true' : 'false' }}</template>
          </Column>
          <Column :style="{ width: '10%' }" class="data-list__column-button">
            <template #body="slotProps">
              <Button class="p-button btn btn--primary" :label="$t('SELECT')" @click="selectVehicle(slotProps)" />
            </template>
          </Column>
        </DataTable>
      </div>
    </div>

    <Dialog :style="{ width: '40vw' }" :breakpoints="{ '960px': '75vw', '640px': '90vw' }" v-model:visible="displayModalcreateVehicle" closable :draggable="false"
      :dismissable-mask="true" :header="$t('New Vehicle')">
      <CreateVehicle @close="closeCreateVehicleModal" />
    </Dialog>
  </div>
</template>


<script setup>
import VehicleService from "@/services/api/vehicles.js";
import DeliveryService from "@/services/api/delivery.js";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import InputText from "primevue/inputtext";
import InputSwitch from 'primevue/inputswitch';
import Button from "primevue/button";
import CreateVehicle from './CreateVehicleModal.vue';
import Dialog from "primevue/dialog";

</script>

<script>
export default {
  name: "VehicleListExpressModal",
  props: ["delivery"],
  components: {
    DataTable,
    Column,
    InputText,
    Button,
    Dialog,
    CreateVehicle,
    InputSwitch
  },
  data: function () {
    return {
      user: JSON.parse(sessionStorage.getItem("user")),
      vehicles: [],
      vehiclesAux: [],
      vehicle: null,
      loading: true,
      vehicleSelected: false,
      displayModalcreateVehicle: false,
      localSearch: false,
      vehicleTypesOpts: []
    };
  },
  watch: {
    localSearch(newValue, oldValue) {
      if (newValue) {
        this.vehiclesAux = this.getLocals();
        this.sortVehicles('localVehicle');
      } else {
        this.vehiclesAux = this.vehicles
        this.sortVehicles('localVehicle');
      }
    }
  },
  async mounted() {
    await this.getVehicleTypes();
    await this.getVehicles()
  },
  methods: {
    async getVehicles() {
      let user = JSON.parse(sessionStorage.getItem("user"))
      let filters = {
        cluster: '\"' + user.plantsInCluster[0].cluster + '\"',
        country: user.country.code,
        fleetCategory: ['PM207032', 'PM207028'],
        searchTerm: ""
      }
      try {
        let response = await VehicleService.filterVehicles(filters)
        if (response.data) {
          this.vehiclesAux = response.data
          this.formatVehicleData();
          this.vehicles = this.vehiclesAux;
          this.sortVehicles('localVehicle');
          this.loading = false
          if (this.localSearch) { this.getLocals() }
        }
      } catch (err) { console.log('Error getting vehicles:', err) }
    },
    formatVehicleData() {
      for (let vehicle of this.vehiclesAux) {
        let vehicleType = this.vehicleTypesOpts.data.find(x => x.vehicleType == vehicle.details.category.vehicleType);
        if (vehicleType) { vehicle.details.category.vehicleType = vehicleType.description }
      }
    },
    async selectVehicle(e) {
      this.vehicle = e.data ? e.data : e;
      if (this.$store.state.dispatch) {
        // SI estoy en la pantalla de dispatch (por que existe el store) no hace falta checkVehicleAvailability
        this.$emit("checkVehicle", this.vehicle)
        return
      }
      try {
        let response = await DeliveryService.checkVehicleAvailability(this.vehicle.vehicleId)
        if (response.data.length === 0) {
          this.$emit("checkVehicle", this.vehicle)
        } else {
          this.$notify({
            title: "This truck is already in service",
            type: "error"
          })
        }
      } catch (err) {
        console.error(err)
      }
    },
    sortVehicles(sortField, sortOrder = 1) {
      this.vehiclesAux.sort((a, b) => {
        if (a[sortField] === b[sortField]) { return 0 };
        return a[sortField] < b[sortField] ? -1 * sortOrder : 1 * sortOrder;
      });
    },
    getLocals() { return this.vehicles.filter(x => x.localVehicle == 1) },
    inputKeyDown(e) { if (/[*|\":<>[\]{}`\\()';@&$·%//!?¿=]/.test(e.key)) { e.preventDefault() } },
    searchVehicles(e) {
      let searchTerm = e.target.value;
      if (searchTerm != null && searchTerm.length >= 3) {
        let term = { search: searchTerm, filters: { fleetCategory: ['PM207032', 'PM207028'] } };

        VehicleService.findVehicles(term).then((response) => {
          this.vehicles = response.data;
          this.vehiclesAux = response.data;
          this.sortVehicles('localVehicle');
        }).catch((err) => { console.log('ERROR FINDING VEHICLES -->', err) });
      }
      if (searchTerm.length === 0) { this.vehicles = this.getVehicles() }
    },
    formatNumber(number) {
      const format = JSON.parse(localStorage.getItem('numberFormat')) || 'de-DE';
      const numberFormat = new Intl.NumberFormat(format, { maximumSignificantDigits: 2 }).format(number >= 0 ? number : 0)
      return numberFormat
    },
    formatVehicleLocalId(id) { return `ROCKS - ${id.substring(0, 5)}` },
    closeCreateVehicleModal(vehicle) {
      if (vehicle !== null) { this.selectVehicle(vehicle) }
      else { this.getVehicles() }
      this.displayModalcreateVehicle = false
    },
    async getVehicleTypes() { this.vehicleTypesOpts = await VehicleService.getVehicleTypesOpts(this.user.country.code) }
  },
};
</script>

<style lang="scss" scoped>
@use "@/assets/css/lh-ds/core/functions" as *;
@use "@/assets/css/hodim/layout/data-list";

.cl {
  $border-color: #d8d8d8;

  &-content {
    .row {
      display: flex;
      flex-direction: column;
    }

    &.app-content--vehicles {
      overflow-y: visible;
    }
  }

  &-change {
    width: 20%;
  }

  &-search {
    width: 80%;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }

  &-results {
    flex: 1;

    margin-top: rem(38px);
    border: 1px solid $border-color;
    background-color: white;

    &__wrapper {
      height: 100%;
      overflow: auto;
    }
  }

  &-result {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: rem(12px) rem(20px);

    cursor: pointer;

    +.cl-result {
      border-top: 1px solid $border-color;
    }

    &__name {
      font-weight: bold;
      margin-bottom: rem(1px);
    }

    &__ref {
      color: rgba(black, 0.5);
    }

    &__right {
      display: flex;
      align-items: center;

      *+* {
        margin-left: rem(16px);
      }
    }

    &__tag {
      padding: rem(4px) rem(8px);
      color: #ff2000;
      border: 1px solid;
      border-radius: 2px;
      font-size: rem(12px);
      line-height: rem(20px);
    }
  }
}

.modal_footer-buttons {
  display: flex;
  align-items: flex-start;
  width: 50%;
  padding: 15px;

  lh-button {
    margin-right: 5px;
  }
}

.head_vehicle_list {
  display: flex;
}

.container {
  min-height: 800px !important
}
</style>
