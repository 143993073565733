'use strict';
import { app } from '@/main.js'

const S3Service = {
    getFile(filepath) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post(`/integration/s3/getFile`, { filepath }, { responseType: 'blob' }).then((response) => {
                return resolve(response);
            }).catch(error => { reject({ ret: error.response.status, msg: error.response.data }); });
        });
    },
    uploadAttachmentsChunk(tempDir, chunk, index) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post(`/integration/s3/uploadAttachmentsChunk`, { tempDir, chunk, index }).then((response) => {
                return resolve(response);
            }).catch(error => { reject({ ret: error.response.status, msg: error.response.data }); });
        });
    },
    uploadAttachments(tempDir, s3Prefix) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post(`/integration/s3/uploadAttachments`, { tempDir, s3Prefix }).then((response) => {
                return resolve(response);
            }).catch(error => { reject({ ret: error.response.status, msg: error.response.data }); });
        });
    },
    deleteFile(filepath) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post(`/integration/s3/deleteFile`, { filepath }).then((response) => {
                return resolve(response);
            }).catch(error => { reject({ ret: error.response.status, msg: error.response.data }); });
        });
    },
    getFilesOfFolder(filesPath){
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post(`/integration/s3/getFilesOfFolder`, { filesPath }).then((response) => {
                return resolve(response);
            }).catch(error => { reject({ ret: error.response.status, msg: error.response.data }); });
        });
    }
}
export default S3Service;