<template>
  <SendingPlant />
  <ReceivingPlant />
  <Control />
</template>

<script setup>

import Control from "./Control.vue";
import ReceivingPlant from "./ReceivingPlant.vue";
import SendingPlant from "./SendingPlant.vue";

</script>

<script>
export default {
  components: {
    SendingPlant,
    ReceivingPlant,
    Control,
  },
  methods: {
    getPlantInfo() {
      return JSON.parse(sessionStorage.getItem("user")).plant;
    },

    showNotification(title, text, type, duration) {
      this.$notify({
        title,
        text,
        type,
        duration,
        closeOnClick: true
      })
    },
    getDeliveryMock() {
      return {
        _index: ".ds-hodim-delivery-hodim_qa-2024.06.17-000003",
        _id: "CNR9KZABAllAV3TTRWjB",
        _version: 5,
        _score: 1,
        _source: {
          shipto: {
            address: {
              country: "FR",
              transportationZone: "",
              city: "SARLAT LA CANEDA",
              street: "ZI DE MADRAZES - LE PECH DE MADRAZE",
              postalcode: null,
              name: "M+ 24 SARLAT M+ 24 SARLAT"
            },
            contract: "2520601",
            contracts: [
              {
                incoterm: "DDP",
                contract: "2520601"
              }
            ],
            fullSearch: "M+ 24 SARLAT M+ 24 SARLAT SARLAT LA CANEDA 2520601",
            shipto: "101033"
          },
          netWeight: 5,
          material: [
            {
              productHierarchy: "A10AG20SG10",
              materialID: "15606304",
              purchaseUoM: "",
              descriptions: [
                {
                  language: "EN",
                  text: "ITC 1 4/8 Cal C BDM Préfa",
                },
                {
                  language: "FR",
                  text: "ITC 1 4/8 Cal C BDM Préfa",
                },
              ],
              baseUoM: "TNE",
              volume: {
                unit: "",
                value: 0,
              },
              userQuantity: 5,
              materialGroup: "011605",
              plant: "F3S4",
            },
          ],
          incoterm: "DDP",
          DeliveryNo: "",
          isOffline: false,
          deliveryNoteNumber: "",
          status: "Scheduled",
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/hodim/index.scss";
@import "@/assets/css/lh-ds/core/functions";

.materials-step {
  &__table-header {
    font-size: 1rem;
    font-weight: bold;
  }

  &.overlay::before {
    content: "";
    position: absolute;
    inset: 0;
    z-index: 1;
    background-color: #fff;
    opacity: 0.5;
  }
}

.table-header {
  font-size: 1rem;
  font-weight: bold;
  background-color: #eee;
  height: 40px;
}
</style>
