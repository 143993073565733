<template>
  <!-- TABLE HEADER -->
  <div class="columns table-header is-align-items-center">
    <div class="column" :style="{'flex-grow': '0', 'flex-basis': '262px'}">
      {{ $t("Material") }}
    </div>
    <div class="column">
      {{ $t("EWC/category") }}
    </div>
    <div class="column" :style="{ marginLeft: '52%' }">
      {{ $t("Quantity") }}
    </div>
  </div>

  <div v-if="material && material.descriptions">
    <div class="columns">
      <div class="column" :style="{'flex-grow': '0', 'flex-basis': '262px'}">
        <div class="is-flex is-flex-direction-column">
          <span>{{ material.materialID }}</span>
          <span>{{ material.descriptions[0].text }}</span>
        </div>
      </div>

      <div class="column">
        <div v-if="material && fireQInfo" class="is-flex is-flex-direction-column">
          <span>{{ fireQInfo.EuropeanWasteCode }}</span>
          <span>{{ fireQInfo.InertWasteCategoriesCode }}</span>
          <span>{{ fireQInfo.EcomineroCode }}</span>
        </div>
      </div>

      <div v-if="delivery" class="column" :style="{ marginLeft: '52%'}">
        <div class="is-flex">
          <span class="mr-2"> {{ delivery._source.netWeight }}</span>
          <span> TNE </span>
        </div>
      </div>
    </div>
  </div>

  <div v-if="material">
    <div class="columns">

      <!-- Origin -->
      <div class="column is-2">
        <div class="label__input">
          <div class="form-control__label">{{ $t("Origin") }}</div>
        </div>

        <div v-if="alreadyHaveWasteInfo" class="column is-one-fifth is-flex is-flex-direction-row pl-0">
          <span> {{ availableOriginSelected && availableOriginSelected.code }} </span>
          <!--<span> &nbsp;- </span>
          <span> &nbsp; </span>
          <span> {{ availableOriginSelected && availableOriginSelected.type}}</span>-->
          <span> &nbsp;- </span>
          <span> &nbsp; </span>
          <span> {{ availableOriginSelected && availableOriginSelected.description}}</span>
        </div>

        <div v-else class="is-flex">
          <InputText
            v-if="originSelected !== null"
            :value="displayAvailableDestination"
            @input="() => {
              originSelected = null
              availableOriginSelected = null
              availableOrigins = []
            }"
            :style="{width: '100%', border: '1px solid black'}"
            :disabled="disableInputs || disableDestinationTypes || alreadyHaveWasteInfo"
          />

          <v-select
            v-if="originSelected === null"
            select
            label="type"
            item-text="number"
            item-value="number"
            class="seeker__wrapper"
            :style="{width: '100%'}"
            v-model="originSelected"
            :options="originTypes"
            :clearable="false"
            @search="searchDestinationTypes"
            @update:modelValue="selectOrigin({data:originSelected})"
            :disabled="disableInputs || disableDestinationTypes"
          >
            <template #no-options="{ search, searching, loading }">
              {{ $t("Type at least 2 characters") }}
            </template>

            <template #option="option">
              <div class="seeker-item">
                <div class="seeker-item__name">{{ option.type }}</div>

                <!--<div class="seeker-item__desc">
                  {{ option.code }}
                </div>-->
              </div>
            </template>
          </v-select>

          <Button
            class="p-button btn btn--secondary ml-1"
            icon="zmdi zmdi-assignment zmdi-hc-lg"
            :style="{ border: 'none' }"
            @click="openOriginModal()"
            :disabled="disableInputs || disableDestinationTypes || alreadyHaveWasteInfo"
          />
        </div>
      </div>

      <div v-if="availableOriginSelected !== null" class="column is-2">
        <div class="is-flex is-flex-direction-column">
          <span>{{$t('Reference')}}</span>
          <span @click="openReferenceModal" class="btn-link pl-1">{{$t('See reference')}}</span>
        </div>

        <table>
          <thead>
            <tr>
              <th>{{$t('DN')}}</th>
              <th>{{$t('Qty')}}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
             <tr v-for="reference in referenceFiltered">
              <td>{{ reference.dn_number }}</td>
              <td>{{ reference.quantity }}</td>
              <td class="info-ic"><i class="zmdi zmdi-info zmdi-hc-lg" @click="openReferenceExtraData(reference)"></i></td>
            </tr>
           
          </tbody>
        </table> 
      </div>

      <!-- Treatment -->
      <div class="column is-3">
        <FormControl :label="$t('Treatment Code')">
          <div v-if="alreadyHaveWasteInfo" class="column is-one-fifth is-flex is-flex-direction-row pl-0" style="width: 180px">
            <span> {{ treatmentSelected.value }} </span>
            <span> &nbsp;- </span>
            <span> &nbsp; </span>
            <span> {{ treatmentSelected.name}}</span>
          </div>

          <div v-else class="is-flex">
            <InputText
              :style="{ width: '100%', border: '1px solid black' }"
              :value="displayTreatment"
              readonly
              :disabled="disableInputs || disableTreatmentCode || !(availableOriginSelected !='' && availableOriginSelected !=null)"
            />

            <Button
              class="p-button btn btn--secondary"
              icon="zmdi zmdi-assignment zmdi-hc-lg"
              :style="{ border: 'none' }"
              @click="openTreatmentModal()"
              :disabled="disableInputs || disableTreatmentCode || !(availableOriginSelected !='' && availableOriginSelected !=null)"
            />
          </div>
        </FormControl>
      </div>

      <!-- Qualification Treat Final -->
      <div class="column is-3">
        <FormControl :label="$t('Qualification Treat Final')">
          <div v-if="alreadyHaveWasteInfo" class="column is-one-fifth is-flex is-flex-direction-row pl-0" style="width: 180px">
            <span> {{ qualificationSelected.value }} </span>
          </div>

          <div v-else class="is-flex">
            <InputText
              :style="{ width: '100%', border: '1px solid black' }"
              :value="qualificationSelected.value"
              readonly
              :disabled="disableInputs || !(availableOriginSelected !='' && availableOriginSelected !=null) || alreadyHaveWasteInfo"
            />

            <Button
              class="p-button btn btn--secondary"
              icon="zmdi zmdi-assignment zmdi-hc-lg"
              :style="{ border: 'none' }"
              @click="openQualificationModal"
              :disabled="disableInputs || !(availableOriginSelected !='' && availableOriginSelected !=null) || alreadyHaveWasteInfo"
            />
          </div>
        </FormControl>
      </div>

      <div v-if="!soldtoLafarge" class="column is-3">
        <FormControl 
          :label="$t('Pick-up Location')" 
          :class="{ 'disable': !treatmentSelected.value || !qualificationSelected.value || disableSelectPickUp }"
        >
          <div v-if="pickUpLocationInfo !== null" class="column is-one-fifth is-flex is-flex-direction-row pl-0" style="width: 180px">
            <span> {{ pickUpLocationInfo.address + ', ' + pickUpLocationInfo.postal_code }} </span>
          </div>

          <!-- <Button @click="openPickUpLocationModal" class="pickup-btn" :class="{ 'mt-5': pickUpLocationInfo === null }" label="Add pick-up location" /> -->
          <span
            @click="openPickUpLocationModal"
            class="is-inline-block"
            :class="{
              'mt-2': pickUpLocationInfo === null, 
              'btn-link': treatmentSelected.value && qualificationSelected.value,
              'disable': !treatmentSelected.value || !qualificationSelected.value || disableSelectPickUp
            }" >
            Add pick-up location
          </span>
        </FormControl>
      </div>
    </div>
  </div>
 
  <Dialog
    v-model:visible="showOriginModal"
    :header="$t('Origin')"
    :draggable="false"
    style="width: 75rem"
  >
    <OriginModal @setOrigin="selectOrigin" :flowType="flowType"/>
  </Dialog>

  <Dialog
    v-model:visible="showAvailableOriginsModal"
    :header="$t('Available Origin') + ' (' + $t(originTypeSelectedValue) + ')'"
    :draggable="false"
    style="width: 85rem"
  >
    <AvailableOriginModal
      @setAvailableDestination="selectAvailableOrigin"
      @backToAvailableTypes="backToAvailableTypes"
      :destinationType="originSelected.type"
      :materialEWC ="fireQInfo != undefined ? fireQInfo.EuropeanWasteCode: null"
      :deliveryQuantity="$store.state.dispatch.netWeight"
      :materialID="material.materialID"
      :flowType="flowType"
    />
  </Dialog>
  
  <Dialog
    v-model:visible="showReferenceModal"
    :header="$t('Reference')"
    :draggable="false"
    style="width: 75rem"
  >
    <ReferenceModal
      :references="references"
    />
  </Dialog>

  <Dialog
    v-model:visible="showTreatmentModal"
    :header="$t('Treatment')"
    :draggable="false"
    style="width: 75rem"
  >
    <TreatmentModal @setTreatment="selectTreatment" @disableTreatment="disableTreatment" :treatments="treatments" />
  </Dialog>

  <Dialog
    v-model:visible="showQualificationModal"
    :header="$t('Qualification Treat')"
    :draggable="false"
    style="width: 75rem"
  >
    <QualificationModal @setQualification="selectQualification" :qualificationsFinal="qualificationsFinal" />
  </Dialog>

  <Dialog
    v-model:visible="showPickUpLocationModal"
    :header="$t('Pick up Location')"
    :draggable="false"
    style="width: 75rem"
  >
    <PickUpLocationModal
      @updateLocation="updateLocation" 
      :alreadyHaveWasteInfo="alreadyHaveWasteInfo" 
      :locationFromDelivery="outboundFromDelivery !== null ? outboundFromDelivery.location : {}"
      :defaultLocation="delivery._source.shipto.address"
    />
  </Dialog>
  <Dialog
    v-model:visible="showDAPExtraDataModal"
    :header="$t('Reference Extra Info')"
    :draggable="false"
    :style="dapExtraInfoStyle"
  >
    <ReferenceExtraInfoData :dapExtraInfoData="dapExtraInfoData"/>
  </Dialog>
</template>

<script setup>
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import FormControl from '@/components/common/FormControl.vue';
import InputText from 'primevue/inputtext';

import OriginModal from '@/components/Deliveries/data-table/OriginModal.vue';
import AvailableOriginModal from '@/components/Deliveries/data-table/AvailableOriginModal.vue';
import TreatmentModal from '@/components/Deliveries/data-table/TreatmentModal.vue';
import QualificationModal from '@/components/Deliveries/data-table/QualificationModal.vue';
import CountriesService from "@/services/api/countries";
import WasteService from '@/services/api/waste';
import PickUpLocationModal from '@/components/Deliveries/data-table/PickUpLocationModal.vue';
import ReferenceModal from '@/components/Deliveries/data-table/ReferenceModal.vue';
import ReferenceExtraInfoData from '@/components/Deliveries/data-table/ReferenceExtraDataModal.vue';
import moment from 'moment';
</script>

<script>
export default {
  name: "MaterialOutbound",
  props: ['delivery', 'material', 'fireQInfo', 'alreadyHaveWasteInfo', 'outboundFromDelivery'],
  data: function() {
    return {
      originTypes: [],
      originSelected: null,
      showOriginModal: false,
  
      treatmentSelected: {},
      showTreatmentModal: false,
      showQualificationModal: false,
      qualificationSelected: { value: '' },
      qualificationsFinal: [],
      dataWaste:{},
      treatments: [],
      availableOrigins: [],
      availableOriginSelected: null,
      showAvailableOriginsModal: false,
      showPickUpLocationModal: false,
      showReferenceModal: false,

      disableDestinationTypes: false,
      disableTreatmentCode: true,
      references:[],
      referenceFiltered:[],
      pickUpLocationInfo: null,
      showDAPExtraDataModal: false,
      dapExtraInfoData:[],
      dapExtraInfoStyle:'width: 30rem;',
      calendarFormat: JSON.parse(localStorage.getItem("dateFormat")) ? JSON.parse(localStorage.getItem("dateFormat")).calendar : 'dd/mm/yy',
      momentFormat: JSON.parse(localStorage.getItem("dateFormat")) ? JSON.parse(localStorage.getItem("dateFormat")).moment : 'DD/MM/YYYY',
      originTypeSelectedValue:'',
      flowType: 'OUTBOUND',
      typeOrder: 'W_OUT'
    }
  },
  computed: {
    soldtoLafarge() {
      const customer = this.delivery._source.soldto.customer
      const firstLetter = customer.charAt(0).toUpperCase()

      return firstLetter === 'C'
    },
    displayTreatment() {
      const { value, name } = this.treatmentSelected;

      return value && name;
    },
    displayAvailableDestination() {
      const { code, type,description } = this.availableOriginSelected !== null ? this.availableOriginSelected : {};

      //return code && `${code} - ${type} - ${description}`;
      return code && `${code} - ${description}`;
    },
    disableSelectPickUp() {
      const { value: treatmentValue } = this.treatmentSelected;
      const { value: qualificationValue } = this.qualificationSelected;

      if (treatmentValue === 'R5' && qualificationValue.toUpperCase() === 'VALORIZATION') {
        return false
      }

      return true;
    },
  },
  mounted() {
    if (this.alreadyHaveWasteInfo) {
      // Fill destination from delivery
      this.availableOriginSelected = this.outboundFromDelivery.originInfo;
      this.originSelected = {}

      // Fill reference from delivery
      const references = this.outboundFromDelivery.references;
      const referencesArray = references.map(item => {
        return {...item, isChecked: item.isDeliveryUsed === 'Y' ? true : false};
      });
      this.references = referencesArray;
      this.referenceFiltered = references.filter((r) => r.isDeliveryUsed == 'Y');

      // Fill treatments from delivery
      this.treatmentSelected = this.outboundFromDelivery.treatmentInfo

      // Fill qualification from delivery
      this.qualificationSelected = this.outboundFromDelivery.qualification
    }
  },
  methods: {
    async searchDestinationTypes(search) {
      if (!search || search.length < 2) return;
      this.loading = true;

      try {
        this.originTypes = await WasteService.getDestinationsTypesByFlowAndSearch(this.flowType,search);
      } catch (error) {
        console.log("error searchDestinationTypes %O", error);
        console.error("Error getting Destinations Types");
      } finally {
        this.loading = false;
      }
    },

    async searchAvailableDestinations(search) {
      if (!search || search.length < 2) return;

      const plant = JSON.parse(sessionStorage.getItem("user")).plant.code;
      const materialEWC = this.material.ewxCategoryDAP != undefined ? this.material.ewxCategoryDAP.EuropeanWasteCode: null;
      const deliveryQuantity = this.delivery._source.netWeight;
      const materialID = this.material.materialID;
      
      const params = {
        type: this.originSelected.type,
        orderType: this.flowType,
        plant,
        materialEWC,
        deliveryQuantity,
        materialID,
        isSearching:true,
        search
      }
      
      this.loading = true;
      
      try {
        this.availableOrigins = await WasteService.getAvailableDestinations(params);
      } catch (error) {
        console.error("Error getting Available Destinations");
      } finally {
        this.loading = false;
      }
    },
    async getReferences(availableOrigin) {
      try {
        const plant = JSON.parse(sessionStorage.getItem("user")).plant.code;
        const body = {
          data: {
            flow: this.typeOrder,
            quantity: this.$store.state.dispatch.netWeight,
            plant: plant,
            origin: availableOrigin.data
          }
        }

        //this.references = ( await WasteService.getWasteRefenceInfo(body) ).data
        WasteService.getWasteRefenceInfo(body).then( (response) => {
          let responseData = response.data;
          console.log('getWasteRefenceInfo response',responseData)
          let referencesArray = responseData.map(item => {
                    return {...item,isChecked: item.isDeliveryUsed === 'Y' ? true : false};
                });
          
          this.references = referencesArray;
          this.referenceFiltered=responseData.filter((r) => r.isDeliveryUsed == 'Y');

          // Update delivery
          if (this.$store.state.dispatch) {
            const delivery = this.$store.state.dispatch.delivery
            delivery._source.material[0].referenceOutbound = this.referenceFiltered

            this.$store.commit('dispatch/setDelivery', delivery)
          }

          console.log('referenceFiltered',this.referenceFiltered)
        }).catch((error) =>{
            console.log("error %O", error);
              this.$notify({
                title: this.$t("Result action"),
                text: this.$t(`Error when getting Reference Info`),
                type: 'warn',
                duration: 3000
            });
        });

      } catch (error) {
        console.error('Error getting References', error)
      } finally {
        this.loading = false
      }
    },
    async getTreatments() {

      console.log('getTreatments')
        try {
          const plant = JSON.parse(sessionStorage.getItem("user")).plant.code;
          const customerCode = this.delivery._source.soldto.customer;

           //IN CASE OF OUTBOUND flow, SEND AS FILTER THE CUSTOMER (IN THE SP THE SYSTEM WILL CHECK THE LOGIC, 
           /* 
              * If the customer starts with C it is an INTERNAL CUSTOMER (LAFARGE)
              * If the customer don't starts with C it is a NON INTERNAL CUSTOMER (NON LAFARGE)
           */

          let dataWaste={
            filterData:{
             type: customerCode
            },
            //dapData: this.material.dap,
            //material: this.material,
            plant:plant,
            flowType:this.flowType
          }

          const { data } = await CountriesService.getCountryLovWasteTreatmentData('WASTE_TREATMENT_CODES', dataWaste)

          this.treatments = data
          console.log('getTreatments',this.treatments[0])

          //AutoSelect value in case of getting defaultValueFrom BBDD (All values will have the same defaulValue, so we can take the first position of the array)

          if(this.treatments[0].defaultValue !='' && this.treatments[0].defaultValue !='null'&& this.treatments[0].defaultValue !=null) {
            let automaticTreatmentObj = this.treatments.find(((e) => e.value = this.treatments[0].defaultValue))
            let automaticTreatment={
              data: automaticTreatmentObj
            }
            this.selectTreatment(automaticTreatment)
          }
          if(this.treatments[0].disabled == '1' || this.treatments[0].disabled == 1) {
              this.disableTreatment(true)
          }
        } catch (error) {
          console.error('Error getting Treatments',error)
        } finally {
          this.loading = false
        }
    },
    async getQualifications() {
      try {
        const plant = JSON.parse(sessionStorage.getItem("user")).plant.code;
        let dataWaste={
            treatmentData:this.treatmentSelected,
            //dapData: this.material.dap,
            //material: this.material,
            plant: plant,
            flowType: this.flowType
        }
        this.qualificationsFinal = ( await CountriesService.getCountryLovWasteTreatmentData('WASTE_TREATMENT_FINAL',dataWaste) ).data
      } catch (error) {
        console.log('Error Qualification Treat Final')
      }
    },
    async selectOrigin(origin) {
      console.log('originSelected >> ', origin)
      // Update delivery
      // if (this.$store.state.dispatch) {
      //   const delivery = this.$store.state.dispatch.delivery
      //   const materialUpdated = { originOutbound: { id: origin.data.id, type: origin.data.type,  } }
      //   delivery._source.material[0].dap = materialUpdated
        
      //   this.$store.commit('dispatch/setDelivery', delivery)
      // }

      this.originSelected = origin.data;
      this.originTypeSelectedValue=origin.data.type;
      this.availableOriginSelected = null;
      this.showAvailableOriginsModal = true;
      this.disableTreatmentCode = true;
      
      //Reset following values
      this.references = [];
      this.referenceFiltered = [];
      this.treatmentSelected = {};
      this.qualificationSelected = { value: '' }
      this.disableTreatmentCode = false;

      this.closeOriginModal();
    },

    async selectTreatment(treatment) {
      // Update delivery
      const { value, name } = treatment.data
      if (this.$store.state.dispatch) {
        const delivery = this.$store.state.dispatch.delivery
        const treatmentOutboundUpdated = { value, name }
        delivery._source.material[0].treatmentOutbound = treatmentOutboundUpdated

        this.$store.commit('dispatch/setDelivery', delivery)
      }

      this.treatmentSelected = treatment.data;
      
      this.qualificationSelected = { value: '' };
      this.getQualifications();

      this.closeTreatmentModal();
    },
    async selectQualification(qualification) {
      // Update delivery
      if (this.$store.state.dispatch) {
        const { value } = qualification.data
        const delivery = this.$store.state.dispatch.delivery
        const qualificationUpdated = { value }
        delivery._source.material[0].qualificationOutbound = qualificationUpdated
        
        this.$store.commit('dispatch/setDelivery', delivery)
      }

      this.qualificationSelected = qualification.data;

      this.closeQualificationModal();
    },
    async disableTreatment(value){
      this.disableTreatmentCode=value
    },
    async selectAvailableOrigin(availableOrigin) {
      // Update delivery
      if (this.$store.state.dispatch) {
        const { id, code, type, description } = availableOrigin.data
        const delivery = this.$store.state.dispatch.delivery
        const materialUpdated = { id, type, code, description }
        delivery._source.material[0].originOutbound = materialUpdated
        delivery._source.material[0].treatmentOutbound = undefined
        delivery._source.material[0].qualificationOutbound = undefined

        this.$store.commit('dispatch/setDelivery', delivery)
      }

      this.availableOriginSelected = availableOrigin.data;
      this.treatmentSelected = {};
      this.qualificationSelected = { value: '' }
      this.disableTreatmentCode = false;
      this.closeAvailableOriginModal();
      this.getReferences(availableOrigin);
      this.getTreatments();
    },

    // async selectRefence(reference) {
    //   // Update delivery
    //   // if (this.$store.state.dispatch) {
    //   //   const { id, code, type } = reference.data
    //   //   const delivery = this.$store.state.dispatch.delivery
    //   //   const materialUpdated = { ...delivery._source.material[0].dap, destinationInfo: { id, code, type } }
    //   //   delivery._source.material[0].dap = materialUpdated

    //   //   this.$store.commit('dispatch/setDelivery', delivery)
    //   // }

    //   this.availableOriginSelected = reference.data;
    //   this.disableTreatmentCode = false;
    //   this.closeAvailableOriginModal();
    //   this.getTreatments();
    // },

    updateLocation(location) {
      this.pickUpLocationInfo = location;
      console.log(this.pickUpLocationInfo)

      if (this.$store.state.dispatch) {
        const delivery = this.$store.state.dispatch.delivery
        delivery._source.material[0].locationOutbound = this.pickUpLocationInfo

        this.$store.commit('dispatch/setDelivery', delivery)
      }

      this.closePickUpLocationModal();
    },

    openOriginModal() {
      this.showOriginModal = true;
    },
    closeOriginModal() {
      this.showOriginModal = false;
    },

    openAvailableDestinationModal() {
      this.showAvailableOriginsModal = true;
    },
    closeAvailableOriginModal() {
      this.showAvailableOriginsModal = false;
    },

    openReferenceModal() {
      this.showReferenceModal = true;
    },
    closeReferenceModal() {
      this.showReferenceModal = false;
    },

    openTreatmentModal() {
      this.showTreatmentModal = true;
    },
    closeTreatmentModal() {
      this.showTreatmentModal = false;
    },

    openQualificationModal() {
      this.showQualificationModal = true;
    },
    closeQualificationModal() {
      this.showQualificationModal = false;
    },

    openPickUpLocationModal() {
      if(this.treatmentSelected.value && this.qualificationSelected.value && !this.disableSelectPickUp) {
        this.showPickUpLocationModal = true;
      }
    },
    closePickUpLocationModal() {
      this.showPickUpLocationModal = false;
    },

    backToAvailableTypes() {
      this.closeAvailableOriginModal()
      this.openOriginModal()
    },
    async openReferenceExtraData(reference) {
      const plant = JSON.parse(sessionStorage.getItem("user")).plant.code;
      console.log('openReferenceExtraData reference >>>', reference)
      this.showDAPExtraDataModal=true;
    
      this.dapExtraInfoData=reference;
    }
  },
}
</script>

<style lang="scss">
@use '@/assets/css/lh-ds/core/functions' as *;
.option-disabled {
  color: red;
  pointer-events: none;
}
.disable {
  cursor: default;
  opacity: .5;
}
.btn-mat {
  background-color: #eee;
  padding: 10px;
  border: none;
  border-radius: 3px;
}

.table-header {
  font-size: 1rem;
  font-weight: bold;
  background-color: #eee;
  height: 40px;
}

.pickup-btn {
  padding: 8px;
}

.btn-link {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 14px;
  text-align: left;
}

table thead {
  background-color: #eaeaea;
}

table th, table td {
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

table th {
  font-weight: bold;
}
</style>