<template>
  <div class="destination-available m-6">
    <DataTable
      :value="references"
      scrollDirection="horizontal"
      :loading="loading"
      :rows="7"
    >
      <Column :header="$t('Select')">
        <template #body="slotProps">
          <div>
            <Checkbox v-model="slotProps.data.isChecked" binary disabled/>
          </div>
        </template>
      </Column> 

      <Column :header="'Qty'">
        <template #body="slotProps">
          <div v-if="slotProps.data.quantity > 0">{{ $t(slotProps.data.quantity) }}</div>
        </template>
      </Column>

      <Column :header="$t('DN')">
        <template #body="slotProps">
          {{ $t(slotProps.data.dn_number) }}
        </template>
      </Column>

      <Column :header="$t('DAP Ref')">
        <template #body="slotProps">
          {{ $t(slotProps.data.dap) }}
        </template>
      </Column>

      <Column :header="$t('Sold to')">
        <template #body="slotProps">
          {{ $t(slotProps.data.soldto) }}
        </template>
      </Column>

      <Column :header="$t('Ship to')">
        <template #body="slotProps">
          {{ $t(slotProps.data.shipto) }}
        </template>
      </Column>

      <Column :header="$t('Net')">
        <template #body="slotProps">
          {{ $t(slotProps.data.net_quantity) }}
        </template>
      </Column>

      <Column :header="$t('Cons')">
        <template #body="slotProps">
          {{ $t(slotProps.data.quantity_cons) }}
        </template>
      </Column>

      <Column :header="$t('Treatment code')">
        <template #body="slotProps">
          {{ $t(slotProps.data.treatment_code) }}
        </template>
      </Column>
    </DataTable>
    
     <Dialog v-model:visible="showInfoDetailsModal" modal :style="{ width: '25rem' }">
      <div class="p-4">
        <h3> {{$t(detailsModalErrorMsg.type)}}</h3>
        {{$t(detailsModalErrorMsg.errorMsg)}}
      </div>
    </Dialog>
  </div>
</template>

<script setup>
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import WasteService from "@/services/api/waste";
import Checkbox from "primevue/checkbox";
</script>

<script>
export default {
  name: "AvailableOriginModal",
  components: {
    DataTable,
    Column,
    InputText,
    Button,
    Dialog,
    Checkbox,
  },
  props: ['references'],
  data: function () {
    return {
      //references: [],
      loading: false,
      showInfoDetailsModal: false,
      detailsModalErrorMsg: '',
      isChecked: true,
    };
  },
  methods: {
    openInfoDetailsModal(type){
      this.showInfoDetailsModal = true; 
      this.detailsModalErrorMsg = {
        type:type,
        errorMsg: type + '_msg'
      }
    },
    markCheck(check) {
      if (check === 'Y') {
        this.isChecked = true;
        return
      }
      this.isChecked = false;
    },
  },
};
</script>

<style lang="scss">
@use "@/assets/css/lh-ds/core/functions" as *;
@use "@/assets/css/hodim/layout/data-list";

.btn-back {
  padding-inline: 30px;
  padding-block: 10px;
  background-color: transparent;
}

.destination-available {
  .p-datatable {
    .p-datatable-thead > tr > th {
      background: #eee;
    }
  }
}
</style>
