<template>
  <Step :title="$t('Materials')" class="materials-step">
    <MaterialInbound 
      v-if="orderType === 'Inbound' && material.ewxCategoryDAP"
      :delivery="delivery" 
      :material="material"
      :fireQResponse="material.ewxCategoryDAP"
      :disableInputs="disableInputs"
      :enableCreateDAP="enableCreateDAP"
      :disableDAPRocks="disableDAPRocks"
      :disableDAPEasy="disableDAPEasy" 
      :dapEasyConnectionError="dapEasyConnectionError"
      :invalidDapEasyDataError="invalidDapEasyDataError"
      :readOnlyDapModal="readOnlyDapModal"
      :readonlyFields="readonlyFields"
      :showDAPModalFromParent="showDAPRocksModal"
      :isNewDapRocksModal="isNewDapRocksModal"
      :alreadyHaveWasteInfo="alreadyHaveWasteInfo"
      :attachments="attachments"
      @dapCreated="DAPCreated"
      @disableScreen="disableScreen"
      @addDAPAttachment="addDAPAttachment"
      @closeDAPModal="closeDAPROCKSModal"/>
    
    <MaterialOutbound 
      v-if="orderType === 'Outbound' && $store.state.dispatch.exitWeight.weight > 0"
      :delivery="delivery"
      :material="material"
      :fireQInfo="fireQInfo"
      :alreadyHaveWasteInfo="alreadyHaveWasteOutbound"
      :outboundFromDelivery="outboundFromDelivery"
    />

    <Dialog v-model:visible="showInfoModal" modal :header="$t(error.type)" :style="{ width: '25rem' }">
      <div class="p-4">
        <!--<div class="is-flex is-align-items-center mb-6">-->
        <div class="is-align-items-center mb-6">
          <i class="pi pi-exclamation-triangle is-size-3 mr-3 mb-3" style="width:100%; text-align:center"></i>
          <div>
            <span v-html="error.message" class="p-text-secondary is-block"> </span>
          </div>
        </div>
        <div v-if="error.type === 'InvalidDapEasyData'">
          <div>
            <div><strong> {{errorInvalidDapEasyData.dap}}</strong></div>
            <div v-if="errorInvalidDapEasyData.isExpired">
              <div>
                {{$t('DAP_expired_msg')}}
              </div>
              <div>
                <span>&#8226; {{$t('Date From')}}: </span>
                <span>{{errorInvalidDapEasyData.fromDate}}</span>
              </div>
               <div>
                <span>&#8226; {{$t('Date To')}}: </span>
                <span>{{errorInvalidDapEasyData.toDate}}</span>
              </div>
            </div>
            <div v-if="errorInvalidDapEasyData.isFull">
              <div>
                {{$t('DAP_full_msg')}}
              </div>
              <div>
                <span>&#8226; {{$t('DAP Tonnage')}}: </span>
                <span v-if="errorInvalidDapEasyData.DAPEasyHasExtraData">{{errorInvalidDapEasyData.estimateTonnage}} {{errorInvalidDapEasyData.extraData.dap_uom}}</span>
                <span v-if="!errorInvalidDapEasyData.DAPEasyHasExtraData">{{errorInvalidDapEasyData.estimateTonnage}} TNE</span>
              </div>
              <div>
                <span>&#8226; {{$t('DAP Quantity Acum')}}: </span>
                <span v-if="errorInvalidDapEasyData.DAPEasyHasExtraData">{{errorInvalidDapEasyData.extraData.dap_quantity_acum}} {{errorInvalidDapEasyData.extraData.dap_uom}}</span>
                <span v-if="!errorInvalidDapEasyData.DAPEasyHasExtraData">0 TNE</span>
              </div>
            </div>
          </div>
        </div>
        <div v-if="error.type === 'DAPNoData'">
          <Button @click="openDAPROCKSModal" class="btn-mat mr-3" label="DAP ROCKS"/>
        </div>
        
        <div v-if="error.type === 'FireQ' || error.type === 'FireQConnection' || error.type === 'DAPEasyConnection'" class="is-flex is-justify-content-end">
          <Button class="p-button btn mr-2" type="button" label="Cancel" severity="secondary" @click="showInfoModal = false"></Button>
          <Button class="btn btn--primary" type="button" label="Retry" @click="getDataInbound"></Button>
        </div>
      </div>
    </Dialog>

    <Dialog v-model:visible="showDAPEasySelectorModal" modal header="DAP Easy Data" :style="{ width: '90rem' }">
      <DAPEasySelector @setDAPEasy="selectDAPEasy" :dapEasyResponse="dapEasyResponse" />
    </Dialog>
  </Step>
</template>

<script setup>

import Dialog from 'primevue/dialog';
import Button from "primevue/button";
import Step from "@/components/dispatch/order/utils/step";
import WasteService from "@/services/api/waste";
import MaterialInbound from "@/components/dispatch/material-inbound/MaterialInbound.vue";
import MaterialOutbound from '@/components/dispatch/material-outbound/MaterialOutbound.vue';
import DAPEasySelector from '@/components/Deliveries/data-table/DAPEasySelector.vue';
import moment from 'moment';
</script>

<script>
export default {
  components: {
    Step,
    WasteService,
    MaterialInbound,
    MaterialOutbound,
    DAPEasySelector,
    Dialog,
    Button,
  },
  data: function () {
    return {
      alreadyHaveWasteInfo: false,
      alreadyHaveWasteOutbound: false,
      outboundFromDelivery: null,
      disableInputs: false,
      enableCreateDAP: false,
      readonlyFields: true,

      disableDAPRocks: false,
      disableDAPEasy: false,
      dapEasyConnectionError:false,
      invalidDapEasyDataError:false,
      readOnlyDapModal:false,
      dapEasyResponse: null,

      delivery: {},
      material: {},

      showInfoModal: false,
      error: {
        type: '',
        message: '',
      },
      errorInvalidDapEasyData: {
      },
      showDAPRocksModal:false,
      isNewDapRocksModal: false,
      showDAPEasySelectorModal: false,
      calendarFormat: JSON.parse(localStorage.getItem("dateFormat")) ? JSON.parse(localStorage.getItem("dateFormat")).calendar : 'dd/mm/yy',
      momentFormat: JSON.parse(localStorage.getItem("dateFormat")) ? JSON.parse(localStorage.getItem("dateFormat")).moment : 'DD/MM/YYYY',
      fireQInfo: {},
      loading:false,
      attachments: [],
    };
  },
  computed: {
    orderType() {
      return this.$store.state.dispatch ? this.$store.state.dispatch.orderType : 'Inbound';
    },
  },
  watch: {
    '$store.state.dispatch.delivery._source.attachments'(newVal) {
      this.attachments = newVal
    }
  },
  beforeMount() {
    this.delivery = this.$store.state.dispatch ? this.$store.state.dispatch.delivery : getDeliveryMock();
    this.material = this.delivery._source.material[0];
    this.attachments = this.delivery._source.attachments ? [...this.delivery._source.attachments] : []

    switch (this.orderType) {
      case 'Inbound':
        if (this.delivery._source.material[0].wasteInfo) {
          this.alreadyHaveWasteInfo = true
          this.wasteInfo = this.delivery._source.material[0].wasteInfo

          this.delivery._source.material[0].secondWeightDataInbound = this.delivery._source.material[0].wasteInfo
          this.$store.commit('dispatch/setDelivery', this.delivery)
        }

        this.getDataInbound()
        break;
      case 'Outbound':
        if (this.delivery._source.material[0].wasteOutboundInfo) {
          this.alreadyHaveWasteOutbound = true
          this.outboundFromDelivery = this.delivery._source.material[0].wasteOutboundInfo
          this.fireQInfo = {
            EuropeanWasteCode: this.outboundFromDelivery.fireQ.ewc,
            EuropeanWasteDescription:this.outboundFromDelivery.fireQ.ewc_name,
            InertWasteCategoriesCode: this.outboundFromDelivery.fireQ.category,
            EcomineroCode: this.outboundFromDelivery.fireQ.ecomineroCode,
          }
          return
        }
        this.getDataOutbound()
        break;
    
      default:
        break;
    }
  },
  methods: {
    getPlantInfo() {
      return JSON.parse(sessionStorage.getItem("user")).plant;
    },
    getDataInbound() {
      let loader = this.$loading.show()
      if (this.alreadyHaveWasteInfo) {
        loader.hide();
        this.fillScreenFromWasteInfo(this.wasteInfo)
        return
      }
      this.showInfoModal = false;
      
      //Hide controlSection the system have all data
      this.$emit('showControlSection', false);

      WasteService.getMaterialDAP(this.delivery)
        .then((response) => {
          loader.hide();

          const { data } = response;
          if (data.from === 'DAP_EASY_TO_SELECT') {
            this.dapEasyResponse = data.dap
            this.showDAPEasySelectorModal = true;
            this.$emit('showControlSection', true);
            return
          }

          const fireQFromDelivery = this.delivery._source.material[0].materialClassification
          const { wasteCode, category, ecominero } = fireQFromDelivery.characteristics
          
          console.log('getMaterialDAP fireQFromDelivery >> ', fireQFromDelivery);

          this.material.ewxCategoryDAP = fireQFromDelivery ? {
                                            EuropeanWasteCode: wasteCode ? wasteCode.characteristicValue : '',
                                            EuropeanWasteDescription: wasteCode ? wasteCode.description : '',
                                            InertWasteCategoriesCode: category ? category.characteristicValue : '',
                                            EcomineroCode: ecominero ? ecominero.characteristicValue : '',
                                          }
                                        : data.ewxCategory;
          this.material.dap = data.dap;

          console.log('getDataInbound data dap >>>',data.dap)
          console.log('getDataInbound data this.material.dap >>>',data.dap)

          //this.material.dap.startValidationDate = this.material.dap.startValidationDate.split('T')[0]
          //this.material.dap.firstDeliveryDate = this.material.dap.endValidationDate.split('T')[0]

          this.storeFireQDataInDelivery(data.ewxCategory)

         
          console.log('getDataInbound MOMENT format >>>',this.momentFormat)

          if (data.dap.from === 'DAP_ROCKS') {
            this.disableInputs = false;
            this.disableDAPRocks = false;
            this.disableDAPEasy = true;
            this.dapEasyConnectionError=false;
            this.invalidDapEasyDataError=false;
            this.isNewDapRocksModal = false; //Is edit
            this.enableCreateDAP = true;
            
            this.material.dap.startValidationDate = this.material.dap.startValidationDate.split('T')[0]
            this.material.dap.firstDeliveryDate = this.material.dap.firstDeliveryDate.split('T')[0]  //In case of DAP_ROCKS the field is named as 'firstDeliveryDate'

            //let startValidationDateValue=data.dap.startValidationDate;
            //let endValidationDate=data.dap.endValidationDate;
            let startValidationDateValue=data.dap.startValidationDate;
            let endValidationDate=data.dap.firstDeliveryDate;

            this.material.dapRocksData = {
              ewc:       { value: data.ewxCategory.EuropeanWasteCode, name: this.$t('ewc'), required: true, maxLength: null, readOnly: true },
              ewc_name: { value: data.ewxCategory.EuropeanWasteDescription, name: this.$t('ewc Name'), required: true, maxLength: null, readOnly: true },
              category:  { value: data.ewxCategory.InertWasteCategoriesCode, name: this.$t('Category'), required: true, maxLength: null, readOnly: true },
              ecominero: { value: data.ewxCategory.EcomineroCode, name: this.$t('Ecominero'), required: true, maxLength: null, readOnly: true },
              product:   { value: this.material.materialID, name: this.$t('Product'), required: true, maxLength: null, readOnly: true },
              product_name:   { value: this.material.descriptions[0].text, name: this.$t('Product Name'), required: true, maxLength: null, readOnly: true },
              topology:  { value: false, name: this.$t('Topology'), required: true, maxLength: null },

              code:     { value: data.dap.erpCode, name: this.$t('Code'), required: true, maxLength: null },
              tonnage:  { value: data.dap.estimateTonnage, name: this.$t('Tonnage'), required: true, maxLength: null },
              fromDate: { value: moment(startValidationDateValue,'YYYY-MM-DD').format(this.momentFormat), name: this.$t('From Date'), required: true, maxLength: null },
              toDate:   { value: moment(endValidationDate,'YYYY-MM-DD').format(this.momentFormat), name: this.$t('To Date'), required: true, maxLength: null },
              //pdf: data.pdf,
            };
            
            //Show controlSection the system have all data
            this.$emit('showControlSection', true);

          } else {

            let valid_DAP_EASY = data.dap.isValid == 'Y' ? true : false;
            let full_DAP_EASY = data.dap.isFull == 'Y' ? true : false;
            let expired_DAP_EASY = data.dap.isExpired == 'Y' ? true : false;

            this.material.dap.startValidationDate = this.material.dap.startValidationDate.split('T')[0]
            this.material.dap.endValidationDate = this.material.dap.endValidationDate.split('T')[0] //In case of DAP_EASY the field is named as 'endValidationDate'

            let startValidationDateValue=data.dap.startValidationDate;
            let endValidationDate=data.dap.endValidationDate;

            if(valid_DAP_EASY){

              this.disableInputs = false;
              this.disableDAPRocks = true;
              this.disableDAPEasy = false;
              this.dapEasyConnectionError=false;
              this.invalidDapEasyDataError=false;

              this.material.dapEasyData = {
                code:     { value: data.dap.erpCode, name: this.$t('Code'), required: true, maxLength: null },
                tonnage:  { value: data.dap.estimateTonnage, name: this.$t('Tonnage'), required: true, maxLength: null },
                fromDate: { value: moment(startValidationDateValue,'YYYY-MM-DD').format(this.momentFormat), name: this.$t('From Date'), required: true, maxLength: null },
                toDate:   { value: moment(endValidationDate,'YYYY-MM-DD').format(this.momentFormat), name: this.$t('To Date'), required: true, maxLength: null },
                pdf: data.pdf
              };

              //Show controlSection the system have all data
              this.$emit('showControlSection', true);

            }else{
              console.log('Invalid Data Easy isValid >> ',valid_DAP_EASY )
              console.log('Invalid Data Easy isFull >> ',full_DAP_EASY )
              console.log('Invalid Data Easy isExpired >> ',expired_DAP_EASY )
              this.material.dap ={};

              this.disableInputs = true
              this.enableCreateDAP = false

              this.disableDAPRocks = true
              this.disableDAPEasy = true
                
              this.error.type = 'InvalidDapEasyData'
              this.error.message = this.$t('InvalidDapEasyData_msg');

              this.errorInvalidDapEasyData= {
                dap:data.dap.erpCode,
                fromDate:moment(startValidationDateValue,'YYYY-MM-DD').format(this.momentFormat),
                toDate:moment(endValidationDate,'YYYY-MM-DD').format(this.momentFormat),
                estimateTonnage:data.dap.estimateTonnage,
                isFull:full_DAP_EASY,
                isExpired:expired_DAP_EASY,
                DAPEasyHasExtraData: data.dap.DAPEasyHasExtraData,
                extraData: data.dap.DAPEasyHasExtraData? data.dap.extraData: {}
              }
              this.showInfoModal = true;
              this.invalidDapEasyDataError=true;
            }
          }
        })
        .catch((err) => {
          loader.hide();
          console.log('err getMaterialDAP',err)
          if(err.from){
            switch (err.from) {
              case 'FireQConnection':
                this.material.ewxCategoryDAP = { EuropeanWasteCode: '', InertWasteCategoriesCode: '' ,EcomineroCode:''}
                this.disableInputs = true
                this.enableCreateDAP = false

                this.disableDAPRocks = true
                this.disableDAPEasy = true
                this.dapEasyConnectionError=false;
                this.invalidDapEasyDataError=false;

                this.error.type = err.from
                this.error.message =  this.$t(err.msg + ' Do you want to try it again?')
                this.showInfoModal = true;
                this.storeFireQDataInDelivery(this.material.ewxCategoryDAP)
                break;
              case 'FireQ':
                this.material.ewxCategoryDAP = err.ewxCategory;

                this.disableInputs = true
                this.enableCreateDAP = false

                this.disableDAPRocks = true
                this.disableDAPEasy = true
                this.dapEasyConnectionError=false;
                this.invalidDapEasyDataError=false;

                this.error.type = err.from
                this.error.message = this.$t(err.msg + ' Do you want to try it again?')
                this.showInfoModal = true;
                this.storeFireQDataInDelivery(this.material.ewxCategoryDAP)
                break;
              
              case 'DAPNoData':
                this.material.dapRocksData = undefined
                this.material.dapEasyData = undefined

                this.material.ewxCategoryDAP = err.ewxCategory;
                this.material.dapEasyTotalResponse = err.dapEasyTotalResponse;

                this.disableInputs = true
                this.enableCreateDAP = true
                this.readonlyFields = true

                this.disableDAPRocks = false
                this.disableDAPEasy = true
                this.dapEasyConnectionError=false;
                this.invalidDapEasyDataError=false;

                this.isNewDapRocksModal=true;
                // this.showNotification(this.$t("DAP"), this.$t(err.msg), 'warn', -1)
                this.error.type = err.from
                this.error.message = this.$t(err.msg)
                
                this.showInfoModal = true;
                this.storeFireQDataInDelivery(this.material.ewxCategoryDAP)

                this.$emit('showControlSection', true);
                break;

              case 'DAPEasyConnection':
                this.material.ewxCategoryDAP = err.ewxCategory;
                this.disableInputs = true
                this.enableCreateDAP = false

                this.disableDAPRocks = true
                this.disableDAPEasy = true
                this.dapEasyConnectionError=true;
                this.invalidDapEasyDataError=false;
                
                // this.showNotification(this.$t("DAP"), this.$t(err.msg), 'error', -1)
                this.error.type = err.from
                this.error.message = this.$t(err.msg)
                this.showInfoModal = true;
                this.storeFireQDataInDelivery(this.material.ewxCategoryDAP)

                this.$emit('showControlSection', true);
                break;

              case 'DAPEasyNoData':
                // this.material.dapRocksData = undefined
                // this.material.dapEasyData = undefined

                this.material.ewxCategoryDAP = err.ewxCategory;
                // this.material.dapEasyTotalResponse = err.dapEasyTotalResponse;

                // this.disableInputs = true
                // this.enableCreateDAP = true
                // this.readonlyFields = true

                // this.disableDAPRocks = false
                // this.disableDAPEasy = true
                // this.dapEasyConnectionError=false;

                this.isNewDapRocksModal=true;
                this.invalidDapEasyDataError=false;
                // this.showNotification(this.$t("DAP"), this.$t(err.msg), 'warn', -1)
                this.error.type = err.from
                this.error.message = this.$t(err.msg)
                
                this.showInfoModal = true;
                this.storeFireQDataInDelivery(this.material.ewxCategoryDAP)

                this.$emit('showControlSection', true);
                break;
            }
          }else{
            this.$notify({
              title: this.$t("Error"),
              text: this.$t(`Error when getting DAP`,),
              type: 'error',
              duration: 3000
              });
            }
        })
    },
    async getDataOutbound() {
      const fireQFromDelivery = this.delivery._source.material[0].materialClassification

      this.fireQInfo = fireQFromDelivery ? {
                          EuropeanWasteCode: fireQFromDelivery.characteristics.wasteCode.characteristicValue,
                          EuropeanWasteDescription: fireQFromDelivery.characteristics.wasteCode.description,
                          InertWasteCategoriesCode: fireQFromDelivery.characteristics.category.characteristicValue,
                          EcomineroCode: fireQFromDelivery.characteristics.ecominero.characteristicValue,
                        }
                      : (await WasteService.getFireQInfo(this.delivery)).data.fireQResponse

      // Store Outbound FireQData in Delivery
      if (this.$store.state.dispatch) {
        const delivery = this.$store.state.dispatch.delivery
        const fireQUpdated = {
          ewc: this.fireQInfo.EuropeanWasteCode,
          ewc_name:this.fireQInfo.EuropeanWasteDescription,
          category: this.fireQInfo.InertWasteCategoriesCode,
          ecomineroCode: this.fireQInfo.EcomineroCode
        }
        delivery._source.material[0].fireQOutbound = fireQUpdated
        
        this.$store.commit('dispatch/setDelivery', delivery)
      }
    },
    fillScreenFromWasteInfo(wasteInfo) {
      console.log('fillScreenFromWasteInfo',wasteInfo);

      this.material.ewxCategoryDAP = { 
        EuropeanWasteCode: wasteInfo.fireQ.ewc,
        EuropeanWasteDescription:wasteInfo.ewc_name,
        InertWasteCategoriesCode: wasteInfo.fireQ.category,
        EcomineroCode: wasteInfo.fireQ.ecomineroCode 
      };

      this.material.dap = wasteInfo.dapInfo;

      console.log('fillScreenFromWasteInfo fromDate before >>> ',wasteInfo.dapInfo.startValidationDate);
      console.log('fillScreenFromWasteInfo toDate before >>> ',wasteInfo.dapInfo.firstDeliveryDate);
        
      let fromDateFormatted = moment(wasteInfo.dapInfo.startValidationDate).format(this.momentFormat);
      let toDateFormatted = moment(wasteInfo.dapInfo.firstDeliveryDate).format(this.momentFormat);
        
      console.log('fillScreenFromWasteInfo fromDateFormatted >>>',fromDateFormatted);
      console.log('fillScreenFromWasteInfo toDateFormatted >>>',fromDateFormatted);

      if (wasteInfo.dapInfo.from === "DAP_ROCKS") {
        this.disableDAPRocks = false;
        this.disableDAPEasy = true;
        this.dapEasyConnectionError=false;
        this.enableCreateDAP = true;
        this.readOnlyDapModal= true;
       
       this.material.dapRocksData = {
          ewc:      { value: wasteInfo.fireQ.ewc, name: this.$t('ewc'), required: true, maxLength: null, readOnly: true },
          ewc_name: { value: wasteInfo.fireQ.ewc_name, name: this.$t('ewc Name'), required: true, maxLength: null, readOnly: true },
          category: { value: wasteInfo.fireQ.category, name: this.$t('Category'), required: true, maxLength: null, readOnly: true },
          ecominero:{ value: wasteInfo.fireQ.ecomineroCode, name: this.$t('Ecominero'), required: true, maxLength: null, readOnly: true },
          product:  { value: this.material.materialID, name: this.$t('Product'), required: true, maxLength: null, readOnly: true },
          product_name:  { value: this.material.descriptions[0].text, name: this.$t('Product'), required: true, maxLength: null, readOnly: true },
          topology: { value: false, name: this.$t('Topology'), required: true, maxLength: null,readOnly: true  },

          code:     { value: wasteInfo.dapInfo.erpCode, name: this.$t('Code'), required: true, maxLength: null },
          tonnage:  { value: wasteInfo.dapInfo.estimateTonnage, name: this.$t('Tonnage'), required: true, maxLength: null, readOnly: true },
          fromDate: { value: fromDateFormatted, name: this.$t('From Date'), required: true, maxLength: null, readOnly: true },
          toDate:   { value: toDateFormatted, name: this.$t('To Date'), required: true, maxLength: null, readOnly: true },
        };
      } else {
        this.disableDAPRocks = true;
        this.disableDAPEasy = false;
        this.dapEasyConnectionError=false;

        this.material.dapEasyData = {
          code:     { value: wasteInfo.dapInfo.erpCode, name: this.$t('Code'), required: true, maxLength: null },
          tonnage:  { value: wasteInfo.dapInfo.estimateTonnage, name: this.$t('Tonnage'), required: true, maxLength: null },
          fromDate: { value: wasteInfo.dapInfo.startValidationDate, name: this.$t('From Date'), required: true, maxLength: null },
          toDate:   { value: wasteInfo.dapInfo.firstDeliveryDate, name: this.$t('To Date'), required: true, maxLength: null },
          // pdf: data.pdf
        };
      }
    },
    DAPCreated(dapCreatedData) {
      this.disableInputs = false

      this.showDAPRocksModal = false;
      this.showInfoModal = false;
      this.isNewDapRocksModal= false;
      this.material.dap = { ...this.material.dap, erpCode: dapCreatedData.dapNumber };
     
      if(dapCreatedData.isNewDapRocksModal){
        let loader = this.$loading.show()
        //Re-open DAP ROCKS modal, because it comes from a DAP creation --> Users should be able to download and import DAP
          setTimeout(() => {
            this.showDAPRocksModal = true;
            loader.hide();
          }, 500);
      }
    },
    addDAPAttachment(dapAttachment){
      console.log('addDAPAttachment Materials Component',dapAttachment)
      this.attachments.push(dapAttachment);
      //this.$store.commit('dispatch/setAttachmentsToUpload',  this.attachments.push(dapAttachment));
    },
    disableScreen() {
      this.disableInputs = true
    },
    showNotification(title, text, type, duration) {
      this.$notify({
        title,
        text,
        type,
        duration,
        closeOnClick: true
      })
    },
    async selectDAPEasy({ data: DAPEasy }) {
      const fireQFromDelivery = this.delivery._source.material[0].materialClassification

      this.material.ewxCategoryDAP = fireQFromDelivery ? {
                                        EuropeanWasteCode: fireQFromDelivery.characteristics.wasteCode.characteristicValue,
                                        EuropeanWasteDescription: fireQFromDelivery.characteristics.wasteCode.description,
                                        InertWasteCategoriesCode: fireQFromDelivery.characteristics.category.characteristicValue,
                                        EcomineroCode: fireQFromDelivery.characteristics.ecominero.characteristicValue,
                                      }
                                    : DAPEasy.ewxCategory;

      this.material.dap = DAPEasy;

      this.material.dap.startValidationDate = this.material.dap.startValidationDate.split('T')[0]
      this.material.dap.firstDeliveryDate = this.material.dap.endValidationDate.split('T')[0]

      this.storeFireQDataInDelivery(this.material.ewxCategoryDAP)

      this.disableInputs = false;
      this.disableDAPRocks = true;
      this.disableDAPEasy = false;
      this.dapEasyConnectionError = false;
      this.showDAPEasySelectorModal = false;

      let startValidationDateValue = DAPEasy.startValidationDate;
      let endValidationDate = DAPEasy.endValidationDate;
      this.material.dapEasyData = {
        code:     { value: DAPEasy.erpCode, name: this.$t('Code'), required: true, maxLength: null },
        tonnage:  { value: DAPEasy.estimateTonnage, name: this.$t('Tonnage'), required: true, maxLength: null },
        fromDate: { value: moment(startValidationDateValue,'YYYY-MM-DD').format(this.momentFormat), name: this.$t('From Date'), required: true, maxLength: null },
        toDate:   { value: moment(endValidationDate,'YYYY-MM-DD').format(this.momentFormat), name: this.$t('To Date'), required: true, maxLength: null },
        pdf: DAPEasy.pdf
      };

      const createDAPRequest = this.createDapRequest(DAPEasy, this.material.ewxCategoryDAP)
      const res = await WasteService.createDAP(createDAPRequest)

      if (res.status !== 200) {
        return this.showNotification(this.$t('DAP'), this.$t('Error Creating DAP'), 'error', 5000)
      }
    },
    createDapRequest(DAPEasy, fireQ) {
      const plant = this.delivery._source.material[0].plant;//JSON.parse(sessionStorage.getItem("user")).plant.code

      return {
        op: 'I_waste_dap',
        isEdit: 'N',
        shipto: this.delivery._source.shipto.shipto,
        soldto: this.delivery._source.soldto.customer,
        plant,
        data: {
          code: DAPEasy.erpCode,
          type: 'DAP_EASY',
          quantity: DAPEasy.estimateTonnage,
          uom: 'TNE',
          fromDate: DAPEasy.startValidationDate,
          toDate: DAPEasy.endValidationDate,
          product: this.delivery._source.material[0].materialID,
          product_name: this.delivery._source.material[0].descriptions[0].text,
          ewc: fireQ.EuropeanWasteCode,
          category: fireQ.InertWasteCategoriesCode,
        },
        deliveryQuantity: this.delivery._source.netWeight
      }
    },
    openDAPROCKSModal() {
      this.showDAPRocksModal = true;
      this.isNewDapRocksModal = true;
      this.enableCreateDAP= true;
    },
    closeDAPROCKSModal() {
      this.showDAPRocksModal = false;
      this.isNewDapRocksModal = false;
      this.enableCreateDAP= false;
    },
    storeFireQDataInDelivery(fireQData) {
      console.log(fireQData)
      if (this.$store.state.dispatch) {
        const delivery = this.$store.state.dispatch.delivery
        const fireQUpdated = {
          ...delivery._source.material[0].dap, 
          fireQ: { 
            ewc: fireQData.EuropeanWasteCode, 
            ewc_name: fireQData.EuropeanWasteDescription,
            category: fireQData.InertWasteCategoriesCode, 
            ecomineroCode: fireQData.EcomineroCode 
          } 
        }
        delivery._source.material[0].dap = fireQUpdated
        
        this.$store.commit('dispatch/setDelivery', delivery)
      }
    },
    getDeliveryMock() {
      return {
        _index: ".ds-hodim-delivery-hodim_qa-2024.06.17-000003",
        _id: "CNR9KZABAllAV3TTRWjB",
        _version: 5,
        _score: 1,
        _source: {
          shipto: {
            address: {
              country: "FR",
              transportationZone: "",
              city: "SARLAT LA CANEDA",
              street: "ZI DE MADRAZES - LE PECH DE MADRAZE",
              postalcode: null,
              name: "M+ 24 SARLAT M+ 24 SARLAT"
            },
            contract: "2520601",
            contracts: [
              {
                incoterm: "DDP",
                contract: "2520601"
              }
            ],
            fullSearch: "M+ 24 SARLAT M+ 24 SARLAT SARLAT LA CANEDA 2520601",
            shipto: "101033"
          },
          netWeight: 5,
          material: [
            {
              productHierarchy: "A10AG20SG10",
              materialID: "15606304",
              purchaseUoM: "",
              descriptions: [
                {
                  language: "EN",
                  text: "ITC 1 4/8 Cal C BDM Préfa",
                },
                {
                  language: "FR",
                  text: "ITC 1 4/8 Cal C BDM Préfa",
                },
              ],
              baseUoM: "TNE",
              volume: {
                unit: "",
                value: 0,
              },
              userQuantity: 5,
              materialGroup: "011605",
              plant: "F3S4",
            },
          ],
          incoterm: "DDP",
          DeliveryNo: "",
          isOffline: false,
          deliveryNoteNumber: "",
          status: "Scheduled",
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/hodim/index.scss";
@import "@/assets/css/lh-ds/core/functions";

.materials-step {
  &__table-header {
    font-size: 1rem;
    font-weight: bold;
  }

  &.overlay::before {
    content: "";
    position: absolute;
    inset: 0;
    z-index: 1;
    background-color: #fff;
    opacity: 0.5;
  }
}

.btn-cancel {
  background-color: red !important;
}
</style>
