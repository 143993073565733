'use strict';
import { app } from '@/main.js'
const CountriesService = {

    getCountryProps(country){
        console.log("getCountryProps");
        console.log(country.id_country);
        let url = "/masterData/countries/data/" + country.id_country;
        console.log(url);
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.get(url).then((response) => {
                return resolve(response);
            }).catch(error => { reject({ ret: error.response.status, msg: error.response.data }); });
        });
    },
    getCountries(){
        let url = "/masterData/countries";
        return new Promise((resolve, reject) =>{
            app.config.globalProperties.$axios.get(url).then((response) => {

                return resolve(response);
            }).catch(error => { reject({ ret: error.response.status, msg: error.response.data }); });
        })
    },
    getCountryById(idCountry){
        let url = `/masterData/country/${idCountry}`;
        return new Promise((resolve, reject) =>{
            app.config.globalProperties.$axios.get(url).then((response) => {

                return resolve(response);
            }).catch(error => { reject({ ret: error.response.status, msg: error.response.data }); });
        })
    },
    getLovByCountry(idCountry){
        let url = `/masterData/countryLov/${idCountry}`;
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.get(url).then((response) => {
         
                return resolve(response);
            }).catch(error => { reject({ ret: error.response.status, msg: error.response.data }); })
        })
    },
    getCountryLovType(idCountry, type){
        let url = `/masterData/countryLov/${idCountry}/${type}`;
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.get(url).then((response) => {
         
                return resolve(response);
            }).catch(error => { reject({ ret: error.response.status, msg: error.response.data }); })
        })
    },
    getCountryLovByName(type){
        let url = `/masterData/countryLovByName/${type}`;
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.get(url).then((response) => {
         
                return resolve(response);
            }).catch(error => { reject({ ret: error.response.status, msg: error.response.data }); })
        })
    },
    getCountryLovWasteTreatmentData(type,data){
        let url = `/masterData/getCountryLovWasteTreatmentData/${type}`;
        let param =data
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post(url,param).then((response) => {
         
                return resolve(response);
            }).catch(error => { reject({ ret: error.response.status, msg: error.response.data }); })
        })
    },
    getSalesAreaByCountry(idCountry){
        let url = `/masterData/countrySalesArea/${idCountry}`;
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.get(url).then((response) => {
         
                return resolve(response);
            }).catch(error => { reject({ ret: error.response.status, msg: error.response.data }); })
        })
    },
    getCountryIncoterms(idCountry){
        let url = `/masterData/countryPickUp/${idCountry}`;
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.get(url).then((response) => {
         
                return resolve(response);
            }).catch(error => { reject({ ret: error.response.status, msg: error.response.data }); })
        })
    },
    getCountryLanguage(idCountry){
        let url = `/masterData/countryLanguage/${idCountry}`;
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.get(url).then((response) => {
         
                return resolve(response);
            }).catch(error => { reject({ ret: error.response.status, msg: error.response.data }); })
        })
    },
    getCoutryData(idCountry) {
        let url = `/masterData/countryFull/${idCountry}`;
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.get(url).then((response) => {
         
                return resolve(response);
            }).catch(error => { reject({ ret: error.response.status, msg: error.response.data }); })
        })
    },
    getCoutriesPlant() {
        let url = `/masterData/countriesPlant`;
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.get(url).then((response) => {
         
                return resolve(response);
            }).catch(error => { reject({ ret: error.response.status, msg: error.response.data }); })
        })
    },
    getAllParametersCountry(idCountry){
        let url = `/masterData/allParams/country/${idCountry}`;
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.get(url).then((response) => {
         
                return resolve(response);
            }).catch(error => { reject({ ret: error.response.status, msg: error.response.data }); })
        })
    },
    getCoutryGenerals(id) {
        let url = `/masterData/countryGenerals/${id}`;
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.get(url).then((response) => {
         
                return resolve(response);
            }).catch(error => { reject({ ret: error.response.status, msg: error.response.data }); })
        })
    },
    getFacilitiesWeighbridgesByCountry(idCountry) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.get(`/masterData/facilitiesWeighbridgesByCountry/${idCountry}`).then((response) => {
                return resolve(response);
            }).catch(error => { reject({ ret: error.response.status, msg: error.response.data }); });
        })
    },
    getCountryHolidaysByYearRange(idCountry, lowerYear, upperYear) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post(`/masterData/getCountryHolidaysByYearRange/${idCountry}`, { lowerYear, upperYear })
                .then((response) => {
                    return resolve(response);
            }).catch(error => { reject({ ret: error.response.status, msg: error.response.data }); });
        })
    },
    createCountrySalesArea(salesArea) {
        
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post('/masterData/createCountrySalesArea',salesArea)
                .then(response => {
                    return resolve(response)
                })
                .catch(error => 
                    { reject({ ret: error.response.status
                    , msg: error.response.data }); });
        });
    },
    createCountryLov(lovCountry) {
        
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post('/masterData/createCountryLov',lovCountry)
                .then(response => {
                    return resolve(response)
                })
                .catch(error => 
                    { reject({ ret: error.response.status
                    , msg: error.response.data }); });
        });
    },
    createCountryPickUp(countryPickUp) {
        
        return new Promise((resolve, reject) => {
            Vue.prototype.$http.post('/masterData/createCountryPickUp',countryPickUp)
                .then(response => {
                    return resolve(response)
                })
                .catch(error => 
                    { reject({ ret: error.response.status
                    , msg: error.response.data }); });
        });
    },
    editCountry(country) {
        
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.put('/masterData/editCountry',country)
                .then(response => {
                    return resolve(response)
                })
                .catch(error => 
                    { reject({ ret: error.response.status
                    , msg: error.response.data }); });
        });
    },
    editCountryLov(lovCountry) {
        
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.put('/masterData/editCountryLov',lovCountry)
                .then(response => {
                    return resolve(response)
                })
                .catch(error => 
                    { reject({ ret: error.response.status
                    , msg: error.response.data }); });
        });
    },
    editCountrySalesArea(salesArea) {
        
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.put('/masterData/editCountrySalesArea',salesArea)
                .then(response => {
                    return resolve(response)
                })
                .catch(error => 
                    { reject({ ret: error.response.status
                    , msg: error.response.data }); });
        });
    },
    editCountryPickUp(countryPickUp) {
        
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.put('/masterData/editCountryPickUp',countryPickUp)
                .then(response => {
                    return resolve(response)
                })
                .catch(error => 
                    { reject({ ret: error.response.status
                    , msg: error.response.data }); });
        });
    },
    editCountryIncotermPickUp(countryIncotermsPickUp) {
        
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.put('/masterData/editCountryIcotermsPickUp',countryIncotermsPickUp)
                .then(response => {
                    return resolve(response)
                })
                .catch(error => 
                    { reject({ ret: error.response.status
                    , msg: error.response.data }); });
        });
    },
    editCountryLanguage(language) {
        
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.put('/masterData/editCountryLanguage',language)
                .then(response => {
                    return resolve(response)
                })
                .catch(error => 
                    { reject({ ret: error.response.status
                    , msg: error.response.data }); });
        });
    },
    editCountryParameters(parameters) {

        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.put('/masterData/editCountryParameters', parameters)
                .then(response => {
                    return resolve(response)
                })
                .catch(error => {
                    reject({
                        ret: error.response.status
                        , msg: error.response.data
                    });
                });
        });
    },
    editCountrySalesAreas(salesAreas) {
        
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.put('/masterData/editCountrySalesAreas',salesAreas)
                .then(response => {
                    return resolve(response)
                })
                .catch(error => 
                    { reject({ ret: error.response.status
                    , msg: error.response.data }); });
        });
    },
    editPlantSalesAreasFromCountry(salesAreas) {
        
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.put('/masterData/editPlantSalesAreasFromCountry',salesAreas)
                .then(response => {
                    return resolve(response)
                })
                .catch(error => 
                    { reject({ ret: error.response.status
                    , msg: error.response.data }); });
        });
    },
    editCountryGenerals(generals) {

        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.put('/masterData/editCountryGenerals', generals)
                .then(response => {
                    return resolve(response)
                })
                .catch(error => {
                    reject({
                        ret: error.response.status
                        , msg: error.response.data
                    });
                });
        });
    },
    editHolidays(id_country, holidays) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.put('/masterData/editCountryHolidays', { id_country, holidays })
                .then(response => {
                    return resolve(response)
                })
                .catch(error => {
                    reject({
                        ret: error.response.status
                        , msg: error.response.data
                    });
                });
        });
    },
    delCountryLov(countrylov) {

        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.delete(`/masterData/delCountryLov/${countrylov.id_lov}/${countrylov.id_country}`)
                .then(response => {
                    return resolve(response)
                })
                .catch(error => {
                    reject({
                        ret: error.response.status
                        , msg: error.response.data
                    });
                });
        });
    },
}

export default CountriesService;
