<script setup>
import FormControl from '@/components/common/FormControl.vue';
import Status from '../../common/Status.vue';
import InputSwitch from 'primevue/inputswitch';
import deliveryService from '@/services/api/delivery';
</script>
<script>
export default {
    computed:{
        delivery(){
            return this.$store.state.dispatch.delivery
        }
    },
    methods:{
        async updateDelHardPrint() {
			try {
				if (this.$store.state.mode === 'Edge') deliveryService.updateDeliveryHardPrintLocal(this.delivery._id, this.delivery._source.hardPrint).catch(error => console.log(error))
				deliveryService.updateDeliveryHardPrint(this.delivery._id, this.delivery._source.hardPrint)
					.then((response) => {
						if (response && response.data && response.data.msg === 'Updated') {
							this.$notify({
								title: this.$t("Delivery print"),
								text: `Delivery Note will ${this.delivery._source.hardPrint ? '' : 'NOT'} be printed automatically after dispatch`,
								type: 'success',
								duration: 3000
							})
						} else {
							this.$notify({
								title: this.$t("Delivery print"),
								text: `Couldn't set the delivery print parameter`,
								type: 'error',
								duration: 3000
							})
						}
					})
				.catch(error => console.log(error))
			} catch (error) { console.log('error on updateDelHardPrint', error) }
		},
    }
}
</script>
<template>
    <div class="" style="padding: 2.5rem;">
        <div class="columns" >
            <div class="column">
                <FormControl :label="$t('Order type')">
                    {{ $store.state.dispatch.orderType }}
                </FormControl>
            </div>
            <div class="column">
                <FormControl :label="$t('Client')">
                    {{ $store.state.dispatch.delivery._source.soldto.customer }}<br>
                    {{ $store.state.dispatch.delivery._source.soldto.name }}
                </FormControl>
            </div>
            <div class="column">
                <FormControl :label="$t('Ship to')">
                    <div>
                        {{ $store.state.dispatch.delivery._source.shipto.shipto }} <br/>
                        {{ $store.state.dispatch.delivery._source.shipto.address.name }}, {{ $store.state.dispatch.delivery._source.shipto.address.city }}
                    </div>
                </FormControl>
            </div>
            <div class="column" >
                <FormControl :label="$t('Contract')">
                    {{ $store.state.dispatch.delivery._source.contract.contract ? $store.state.dispatch.delivery._source.contract.contract : '-' }}
                </FormControl>
            </div>

            <!-- Exclusivos de delivery outbound -->
            <div class="column">
                <FormControl :label="$t('Plant')">
                    {{ $store.state.dispatch.delivery._source.material[0].plant ? $store.state.dispatch.delivery._source.material[0].plant : '-' }}
                </FormControl>
            </div>
            <div class="column">
                <FormControl :label="$t('PO Number')">
                    {{ $store.state.dispatch.delivery._source.PONumber ? $store.state.dispatch.delivery._source.PONumber : '-' }}
                </FormControl>
            </div>
            <div class="column">
                <FormControl :label="$t('Delivery Type')">
                    {{ $store.state.dispatch.delivery._source.deliveryType ? $store.state.dispatch.delivery._source.deliveryType : '-' }}
                </FormControl>
            </div>
            <div class="column">
                <FormControl :label="$t('Status')">
                    <Status style="max-width: max-content" :name="$store.state.dispatch.delivery._source.status ? $store.state.dispatch.delivery._source.status : '-'" />
                </FormControl>
            </div>
            <div class="column">
                <FormControl :label="$t('Paper DN')">
                    <InputSwitch v-model="$store.state.dispatch.delivery._source.hardPrint" @change="updateDelHardPrint"/>
                </FormControl>
            </div>
        </div>
    </div>
</template>