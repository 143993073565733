<template>
    <!-- For Outbounds -->
    <div class="columns assign" v-if="(orderType === 'Outbound') || (orderType === 'Inbound' && isWaste == true)">
        <div class="column">
            <div class="label__input">
                <div class="form-control__label">
                    <div class="text">{{ $t('Assignation for vehicle') }}</div>
                    <div :class="['p-inputtext', 'p-component', 'numberData']"
                        @click="showModalAssignmets(1)">
                        {{ countAssignments.countVehicleDeliveries ? countAssignments.countVehicleDeliveries : 0 }}
                    </div>
                
                </div>
            </div>
        </div>
        <div class="column">
            <div class="label__input">
                <div class="form-control__label">
                    <div class="text">{{ $t('Assignation for truck') }}</div>
                    <div :class="['p-inputtext', 'p-component', 'numberData']"
                        @click="showModalAssignmets(2)">
                        {{ countAssignments.countTruckDeliveries ? countAssignments.countTruckDeliveries : 0 }}
                    </div>
                </div>

            </div>
        </div>
        <div class="column">
            <div class="label__input">
                <div class="form-control__label">
                    <div class="text">{{ $t('Assignation for owner') }}</div>
                    <div :class="['p-inputtext', 'p-component', 'numberData']"
                        @click="showModalAssignmets(3)">
                        {{ countAssignments.countOwnerDeliveries ? countAssignments.countOwnerDeliveries : 0 }}
                    </div>
                </div>
            </div>

        </div>
        <div class="column">
            <div class="label__input">
                <div class="form-control__label">
                    <div class="text">{{ $t('Assignation for FWA') }}</div>
                    <div :class="['p-inputtext', 'p-component', 'numberData']"
                        @click="showModalAssignmets(4)">
                        {{ countAssignments.countFWADeliveries ? countAssignments.countFWADeliveries : 0 }}
                    </div>
                </div>
            </div>
        </div>

        <div class="column">
            <div class="label__input">
                <div class="form-control__label">
                    <div class="text">{{ $t('Unplanned') }}</div>
                    <div :class="['p-inputtext', 'p-component', 'numberData']"
                        @click="showModalAssignmets(6)">
                        {{ countAssignments.countUnplannedDeliveries ? countAssignments.countUnplannedDeliveries : 0 }}
                    </div>
                </div>
            </div>
        </div>

        <div class="column searchType">
            <FormControl :label="$t('Search type')">
                <SelectButton class="dataTabs" v-model="incoterm" 
                :options="optionsType" optionLabel="value" optionDisabled="disabled" 
                :unselectable="false" aria-labelledby="basic">
                    <template #option="slotProps">
                        <div :class="['p-button-label', { disabledTabs: slotProps.option.disabled }]" 
                                @click="toExpresOrder(slotProps.option)">
                                {{slotProps.option.value}}
                        </div>
                    </template>
                </SelectButton>
            </FormControl>            
        </div>
        <!-- <div class="column">
            <lh-button class="entryBtn" size="large" type="primary" @click="entryPlant">
                {{ $t('ENTRY PLANT') }}
            </lh-button>
        </div> -->
        <div class="column">
            <a class="link" @click="showModalAssignmets(5)">Last deliveries</a>
        </div>
        <div class="column"></div>
    </div>
    <!-- For Inbounds -->
    <div class="columns assign" v-else-if="orderType === 'Inbound' && isWaste == false">
        <div class="column">
            <div class="label__input">
                <div class="form-control__label">
                    <div class="text">{{ $t('Assignation for vehicle') }}</div>
                    <div :class="['p-inputtext', 'p-component', 'numberData']"
                        @click="showModalAssignmets(1)">
                        {{ countAssignments.countVehicleDeliveries ? countAssignments.countVehicleDeliveries : 0 }}
                    </div>
                
                </div>
            </div>
        </div>
        <div class="column">
            <div class="label__input">
                <div class="form-control__label">
                    <div class="text">{{ $t('Assignation for truck') }}</div>
                    <div :class="['p-inputtext', 'p-component', 'numberData']"
                        @click="showModalAssignmets(2)">
                        {{ countAssignments.countTruckDeliveries ? countAssignments.countTruckDeliveries : 0 }}
                    </div>
                </div>

            </div>
        </div>
        <div class="column">
            <div class="label__input">
                <div class="form-control__label">
                    <div class="text">{{ $t('Assignation for owner') }}</div>
                    <div :class="['p-inputtext', 'p-component', 'numberData']"
                        @click="showModalAssignmets(3)">
                        {{ countAssignments.countOwnerDeliveries ? countAssignments.countOwnerDeliveries : 0 }}
                    </div>
                </div>
            </div>

        </div>
        <div class="column">
            <div class="label__input">
                <div class="form-control__label">
                    <div class="text">{{ $t('Assignation for FWA') }}</div>
                    <div :class="['p-inputtext', 'p-component', 'numberData']"
                        @click="showModalAssignmets(4)">
                        {{ countAssignments.countFWADeliveries ? countAssignments.countFWADeliveries : 0 }}
                    </div>
                </div>
            </div>
        </div>
        <div class="column">
            <div class="label__input">
                <div class="form-control__label">
                    <div class="text">{{ $t('Unplanned') }}</div>
                    <div :class="['p-inputtext', 'p-component', 'numberData']"
                        @click="showModalAssignmets(6)">
                        {{ countAssignments.countUnplannedDeliveries ? countAssignments.countUnplannedDeliveries : 0 }}
                    </div>
                </div>
            </div>
        </div>
        <div class="column"></div>
        <div class="column"></div>
        <div class="column"></div>
    </div>

    <!-- For Transfers In -->
    <div class="columns assign" v-else-if="orderType === 'Transfer Out'">
        <div class="column">
            <div class="label__input">
                <div class="form-control__label">
                    <div class="text">{{ $t('Assignation for vehicle') }}</div>
                    <div :class="['p-inputtext', 'p-component', 'numberData']"
                        @click="showModalAssignmets(1)">
                        {{ countAssignments.countVehicleDeliveries ? countAssignments.countVehicleDeliveries : 0 }}
                    </div>
                
                </div>
            </div>
        </div>
        <div class="column">
            <div class="label__input">
                <div class="form-control__label">
                    <div class="text">{{ $t('Assignation for truck') }}</div>
                    <div :class="['p-inputtext', 'p-component', 'numberData']"
                        @click="showModalAssignmets(2)">
                        {{ countAssignments.countTruckDeliveries ? countAssignments.countTruckDeliveries : 0 }}
                    </div>
                </div>

            </div>
        </div>
        <div class="column">
            <div class="label__input">
                <div class="form-control__label">
                    <div class="text">{{ $t('Assignation for owner') }}</div>
                    <div :class="['p-inputtext', 'p-component', 'numberData']"
                        @click="showModalAssignmets(3)">
                        {{ countAssignments.countOwnerDeliveries ? countAssignments.countOwnerDeliveries : 0 }}
                    </div>
                </div>
            </div>

        </div>
        <div class="column">
            <div class="label__input">
                <div class="form-control__label">
                    <div class="text">{{ $t('Assignation for FWA') }}</div>
                    <div :class="['p-inputtext', 'p-component', 'numberData']"
                        @click="showModalAssignmets(4)">
                        {{ countAssignments.countFWADeliveries ? countAssignments.countFWADeliveries : 0 }}
                    </div>
                </div>
            </div>
        </div>
        <div class="column">
            <div class="label__input">
                <div class="form-control__label">
                    <div class="text">{{ $t('Unplanned') }}</div>
                    <div :class="['p-inputtext', 'p-component', 'numberData']"
                        @click="showModalAssignmets(6)">
                        {{ countAssignments.countUnplannedDeliveries ? countAssignments.countUnplannedDeliveries : 0 }}
                    </div>
                </div>
            </div>
        </div>
        <div class="column">
            <!-- <lh-button class="entryBtn" size="large" type="primary" @click="entryPlant">
                {{ $t('ENTRY PLANT') }}
            </lh-button> -->
        </div>
        <div class="column"></div>
        <div class="column"></div>
    </div>

    <!-- For Transfers Out -->
    <div class="columns assign" v-else>
    </div>

    <Dialog :header="'Assignments linked'" v-model:visible="alertModalNewOrder"
        :dismissable-mask="true" 
        :style="{'width': '40rem'}">
        <div class="alertModal">
            <p>There are assignments for the same customer and ship has priority to the select one, are you sure you want to create a new order?</p>
            <div class="alertButtons">
                <lh-button size="medium" @click="closeModalAlertNewOrder">{{ $t('NO') }}</lh-button>
                <lh-button size="medium" type="primary" @click="confirmActionNewOrder">{{ $t('YES') }}</lh-button>
            </div>
        </div>
    </Dialog>
</template>

<script setup>
import SelectButton from 'primevue/selectbutton';
import FormControl from "@/components/common/FormControl.vue";
import Dialog from 'primevue/dialog';
</script>

<script>
export default {
    name: "AssignmentsTable",
    components: {
        FormControl,
        SelectButton,
        Dialog
    },
    props: [
        'countAssignments',
        'exwork'
    ],
    computed: {
        orderType() {
            return this.$store.state.dispatch.orderType
        },
        isWaste() {
            return this.$store.state.dispatch.isWaste
        }
    },
    data: function () {
        return {
            incoterm: { value: 'DDP', disabled: false },
            optionsType: [
                { value: 'DDP', disabled: this.exwork },
                { value: 'EXW', disabled: false }
            ],
            alertModalNewOrder: false,
        }
    },
    methods: {
        toExpresOrder(value) {
            if (!value.disabled) {
                this.incoterm = value
                if ((this.countAssignments.countVehicleDeliveries > 0 
                    || this.countAssignments.countTruckDeliveries > 0
                    || this.countAssignments.countOwnerDeliveries > 0
                    || this.countAssignments.countFWADeliveries > 0) 
                    && value.value == 'DDP' )
                {
                    this.showModalAlertNewOrder()
                } else {
                    this.confirmActionNewOrder()
                }
            }
        },
        showModalAlertNewOrder() {
            this.alertModalNewOrder = true
        },
        closeModalAlertNewOrder() {
            this.alertModalNewOrder = false
        },
        showModalAssignmets(number) {
            this.$emit('showModalAssignmets',number)    
        },
        confirmActionNewOrder() {
            this.$store.commit('setPreloadVehicle', this.incoterm.value )
            // this.$store.commit('dispatch/setIncoterm', this.incoterm.value);
            this.closeModalAlertNewOrder()
            this.$emit('confirmActionNewOrder')
        },
        // entryPlant() {
        //     this.$emit('entryPlant')
        // }
    },
    watch: {
        exwork(newVal,oldVal) {
            this.optionsType[0].disabled = newVal
        }
    }
}

</script>

<style lang="scss">
.columns {

    &.assign {
        .searchType {
            .form-control__label {
                padding-top: 0.8rem;
            }
        }

        .form-control__label {

            .text {
                padding-top: 0.8rem;
            }

            .p-inputtext {
                width: 2rem;
                text-align: center;
                height: 2rem;
                margin-top: 10px;

                &.numberData {
                    cursor: pointer;
                    padding: 5px 5px 0 5px;
                    background-color: #1D4370;
                    color: white;
                    width: 3rem;
                    height: 2.5rem;
                }
            }
        }
    }
}

.entryBtn {
    button {
        width: 80%;
        margin-top: 2.8rem;
    }
}
</style>