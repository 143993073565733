<script>
export default {
  props: {
    name: String
  }
}
</script>

<template>
  <div :class="'label-status ' + 'label-status--' + this.name.toLowerCase()">
    {{ this.name }}
  </div>
</template>

<style lang="scss" scoped>
@use '@/assets/css/lh-ds/core/functions' as *;

.label-status {
  display: inline-block;
  border: 1px solid;
  padding: 4px 8px;
  border-radius: 2px;

  &--accepted {
    color: #00965E;
  }

  &--sent {
    color: #AAA;
  }

  &--error {
    color: #FF2000;
  }
}
</style>