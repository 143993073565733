<template>
  <div class="columns pl-6 pb-6">
    <div class="column is-two-thirds">
      <FormControl label="Search another direction" class="w-100">
        <AutoComplete 
          class="w-100" 
          v-model="heremaps_location_search" 
          optionLabel="address.label" 
          :suggestions="heremaps_locations" 
          :placeholder="$t('search')" 
          @complete="search" 
          @item-select="selectPlace($event)"
          completeMethod="search" 
          inputStyle="width: 100% !important" />
      </FormControl>

      <div class="mapContainer" ref="mapContainer"></div>

      <div class="mt-3 mb-3">
        <Button :label="$t('Save')" class="btn--primary btn btn--large w-25" @click="saveMapAddress"></Button>
      </div>
    </div>

    <div class="column is-flex is-flex-direction-column is-align-items-center">
      <div class="is-flex is-flex-direction-column inputs">
        <label for="sheet">{{ $t('Sheet') }}</label>
        <InputText id="sheet" v-model="sheet" />
      </div>

      <div class="is-flex is-flex-direction-column inputs pt-2">
        <label for="inseeCode">{{ $t('Insee code') }}</label>
        <InputText id="inseeCode" v-model="inseeCode" />
      </div>

      <div class="is-flex is-flex-direction-column inputs pt-2">
        <label for="sectionParcelle">{{ $t('Section / Parcelle N') }}</label>
        <InputText id="sectionParcelle" v-model="section" />
      </div>
    </div>
  </div>
</template>

<script setup>
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import FormControl from "@/components/common/FormControl.vue";
import AutoComplete from "primevue/autocomplete";

import { app } from '@/main.js'
</script>

<script>
export default {
  name: "PickUpLocationModal",
  components: {
    DataTable,
    Column,
    Button,
    Dialog,
    FormControl,
    AutoComplete,
  },
  props: ['alreadyHaveWasteInfo', 'locationFromDelivery', 'defaultLocation'],
  data: function () {
    return {
      user: JSON.parse(sessionStorage.getItem('user')),
      treatments: [],
      sheet: '',
      inseeCode: '',
      section: '',

      // for map
      apikey: app.config.globalProperties.$config.HERE_MAPS.API_KEY,
      heremaps_platform: null,
      heremaps_center: JSON.parse(sessionStorage.getItem('user')).country.code == 'FR' 
                         ? { lat: this.defaultLocation.lat , lng: this.defaultLocation.lon } 
                         : { lat: 53.32968, lng: -1.97961 },
      heremaps_marker: null,
      heremaps_map: null,
      heremaps_address: {
        name: 'Aix-en-Provence, Provence-Alpes-Côte d\'Azur, France',
        address: 'Aix-en-Provence, Provence-Alpes-Côte d\'Azur, France',
        region: 'Provence-Alpes-Côte d\'Azur',
        postal_code: '13100',
        city: 'Aix-en-Provence',
        lat: 43.52638,
        lon: 5.44614,
        isDefault: true
      },

      heremaps_location_search: '',
      heremaps_locations: [],
    };
  },
  mounted() {
    if (this.alreadyHaveWasteInfo) {
      this.sheet = this.locationFromDelivery.sheet
      this.inseeCode = this.locationFromDelivery.inseeCode
      this.section = this.locationFromDelivery.section
    }
    this.initializeHereMap()
  },
  methods: {
    initializeHereMap() {
      const platform = new window.H.service.Platform({ apikey: this.apikey });
      this.heremaps_platform = platform;

      const mapContainer = this.$refs.mapContainer;
      const H = window.H;
      
      const maptypes = this.heremaps_platform.createDefaultLayers();
      
      const map = new H.Map(mapContainer, maptypes.vector.normal.map, {
        zoom: 18,
        center: this.heremaps_center
      });
      this.heremaps_map = map;
      
      addEventListener("resize", () => map.getViewPort().resize());
      map.addEventListener('tap', this.handleMapClick);
      new H.mapevents.Behavior(new H.mapevents.MapEvents(map));
      H.ui.UI.createDefault(map, maptypes);

      this.addMarkerToMap(this.heremaps_center, true);

      setTimeout(() => { map.getViewPort().resize() }, 0);
    },
    handleMapClick(evt) {
      const pointer = evt.currentPointer;
      const pointerPosition = this.heremaps_map.screenToGeo(pointer.viewportX, pointer.viewportY);

      // Update marker position
      this.addMarkerToMap(pointerPosition, false);
      this.getLocationByCoords(`${pointerPosition.lat},${pointerPosition.lng}`);
    },
    addMarkerToMap(coords, newMarker){
      const H = window.H;
      if(!newMarker){ 
        this.heremaps_map.removeObject(this.heremaps_marker) 
      }

      this.heremaps_marker = new H.map.Marker(coords);
      this.heremaps_map.addObject(this.heremaps_marker);
    },
    getLocationByCoords(coords){
      const URL = `https://revgeocode.search.hereapi.com/v1/revgeocode?at=${coords}&apikey=${this.apikey}`;
      fetch(URL)
        .then((response) => response.json())
        .then((data) => {
          const addr = data.items[0];
          this.heremaps_address = {
            name: addr.title, 
            address: addr.address.label, 
            region: addr.address.state, 
            postal_code: addr.address.postalCode, 
            city: addr.address.city, 
            lat: addr.position.lat, 
            lon: addr.position.lng, 
            isDefault: true 
          };
        })
    },
    getCountryCodeFormatedISOAlpha3() {
      let countryCode = this.user.country.code;
      switch(countryCode) {
        case 'FR':
          countryCode = 'FRA';
          break;
        case 'GB':
          countryCode = 'GBR';
          break;
      }
      return countryCode;
    },
    async search(event) {
      let countryCode = this.getCountryCodeFormatedISOAlpha3();

      const URL = `https://autocomplete.search.hereapi.com/v1/autocomplete?q=${event.query}&apiKey=${this.apikey}&in=countryCode%3A${countryCode}`;
      await fetch(URL)
        .then((response) => response.json())
        .then((data) => { this.heremaps_locations = data.items });
    },
    selectPlace(event) {
      const URL = `https://lookup.search.hereapi.com/v1/lookup?id=${event.value.id}&apiKey=${this.apikey}`;
      fetch(URL)
        .then((response) => response.json())
        .then((data) => {
          this.heremaps_center = { lat: data.position.lat, lng: data.position.lng };
          this.heremaps_map.setCenter(this.heremaps_center);
          this.heremaps_map.setZoom(18)
          this.addMarkerToMap(this.heremaps_center, false);
          const address = {
            name: data.title, 
            address: data.address.label, 
            region: data.address.state, 
            postal_code: data.address.postalCode,
            city: data.address.city, 
            lat: data.position.lat, 
            lon: data.position.lng, 
            isDefault: true 
          }
          this.heremaps_address = address;
        });
    },
    saveMapAddress() {
      console.log(this.heremaps_address)
      let error = false;

      if (!this.sheet) {
        this.$notify({
          title: this.$t('Sheet field is mandatory'),
          type: 'error',
          duration: 5000,
          closeOnClick: true
        })
        error = true;
      }

      if (!this.inseeCode) {
        this.$notify({
          title: this.$t('InseeCode field is mandatory'),
          type: 'error',
          duration: 5000,
          closeOnClick: true
        })
        error = true;
      }

      if (!this.section) {
        this.$notify({
          title: this.$t('Section / Parcelle N field is mandatory'),
          type: 'error',
          duration: 5000,
          closeOnClick: true
        })
        error = true;
      }

      if (!error) {
        this.$emit("updateLocation", { ...this.heremaps_address, sheet: this.sheet, inseeCode: this.inseeCode, section: this.section });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@use "@/assets/css/lh-ds/core/functions" as *;
@use "@/assets/css/hodim/layout/data-list";

.inputs {
  width: 85%;
}

.mapContainer {
  width: 100%;
  height: 400px;
  position: relative;
  margin-top: 10px;
}

.mapContainer:hover { 
  cursor: pointer 
}
</style>
