<script setup>

import deliveryService from "@/services/api/delivery.js";
import * as Socket from '@/plugins/socket_client';
import DeliveryCard from "./DeliveryCard.vue";
</script>

<script>
export default {
  data: function () {
    return {
      Deliveries: [],
      deliveriesList: [],
      userPlant: null,
      user: null,
      currentIndex: {
        total: 0,
        current: 0
      }
    }
  },
  mounted() {
    this.getDeliveries(this.user);
    let userPlant = JSON.parse(sessionStorage.getItem("user")).plant.code;
    Socket.add("pickup", userPlant);
  },
  created() {
    this.userPlant = JSON.parse(sessionStorage.getItem("user")).plant.code;
    this.user = JSON.parse(sessionStorage.getItem("user"));
    const socket = Socket.connect();
    socket.on("pickup", this.onEventHandler);
    Socket.add("pickup", this.userPlant)
  },
  computed: {
    isTheFirst() {
      return this.currentIndex.current == 0;
    },
    isTheLast() {
      return this.currentIndex.current + 1 == this.currentIndex.total;
    }
  },
  methods: {
    onEventHandler(data) {
      if(typeof data.data.delivery !== 'undefined'){
        if(((typeof data.data.delivery._source.signPending !== 'undefined' ? data.data.delivery._source.signPending : true) && data.data.delivery._source.deliveryType == 'PickUp') && data.data.delivery._source.status == 'Shipping'){
          this.insertNewDelivery(data.data.delivery);
        }
      }
      
    },
    insertNewDelivery(newDelivery){
      let matches = this.Deliveries.filter( function(data){ return data._id == newDelivery._id } );
      if(matches.length == 0){
        this.Deliveries.push(newDelivery);
        this.currentIndex.total = this.Deliveries.length;
      }
    },
    getDeliveries(user) {
      deliveryService.getPendingDeliveries(user).then((response) => {
        this.Deliveries = response.data.data;
        this.currentIndex.total = this.Deliveries.length;
      })
    },
    getDeliveriesList() {
      this.deliveriesList = [];
      this.Deliveries.forEach((d) => {
        this.deliveriesList.push(d._id)
      })
    },
    goToDelivery(step) {
      this.currentIndex.current = this.currentIndex.current + step < 0 || this.currentIndex.current + step > this.currentIndex.total - 1 ? this.currentIndex.current : this.currentIndex.current + step;
    }
  }
}
</script>

<template>
  <div v-if="currentIndex.total > 0" class="sign_app">
    <div class="head">
      <lh-button size="large" type="secondary" @click="goToDelivery(-1)" :disabled="isTheFirst">{{ $t('Previous') }}</lh-button>
      <div class="sign_title">
        <div class="title">{{ $t('Truck') }} - #{{ Deliveries[currentIndex.current]._source.logistic.truck.plateNumber
          }}</div>
        <div class="counter">{{ currentIndex.total != 0 ? currentIndex.current + 1 : 0 }} / {{ currentIndex.total}}
        </div>
      </div>
      <lh-button size="large" type="secondary" @click="goToDelivery(1)" :disabled="isTheLast">{{ $t('Next') }}</lh-button>
    </div>
    <div class="body">
      <DeliveryCard :delivery="Deliveries[currentIndex.current]._source" :deliveryID="Deliveries[currentIndex.current]._id" @deliverySigned="getDeliveries(user)"></DeliveryCard>
    </div>
  </div>
  <div v-else class="empty-list">
    <i class="zmdi zmdi-edit zmdi-hc-3x"></i>
    <div>{{ $t('No deliveries left to sign') }}</div>
  </div>
</template>

<style lang="scss">

.sign_app {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .head {
    width: 100%;
    height: 10%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;

    .sign_title {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .title {
        font-weight: bold;
        font-size: 20px;
      }

      .counter {
        font-size: 12px;
        color: gray;
      }
    }
    
  }

  .body {
    width: 100%;
    height: 90%;
  }
}

.empty-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

</style>
