'use strict';
import { app } from '@/main.js';
const VehiclesService = {
    //vehicles graphQL
    getVehicles(search) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post("/masterData/vehicles", search).then((response) => {
                return resolve(response);
            }).catch(error => { reject({ ret: error.response.status, msg: error.response.data }); });
        })
    },
    getVehicle(id) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.get(`/masterData/vehicle/${id}`).then((response) => {
                return resolve(response);
            }).catch(error => { reject({ ret: error.response.status, msg: error.response.data }); });
        })
    },
    searchVehicle(term) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post("/masterData/searchVehicle", term).then((response) => {
                return resolve(response);
            }).catch(error => { reject({ ret: error.response.status, msg: error.response.data }); });
        })
    },
    insertVehicleFromRocks(vehicle) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post("/masterData/createVehicleFromRocks", vehicle).then((response) => {
                return resolve(response);
            }).catch(error => { reject({ ret: error.response.status, msg: error.response.data }); });
        })
    },
    insertVehicle(vehicle) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post("/masterData/createVehicle", vehicle).then((response) => {
                return resolve(response);
            }).catch(error => { reject({ ret: error.response.status, msg: error.response.data }); });
        })
    },
    getVehicleByPlate(plate) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.get(`/masterData/vehicleByPlate/${plate}`).then((response) => {
                return resolve(response);
            }).catch(error => { reject({ ret: error.response.status, msg: error.response.data }); });
        })
    },
    getVehicleByPlateNumber(plate) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.get(`/masterData/getVehicleByPlateNumber/${plate}`).then((response) => {
                return resolve(response);
            }).catch(error => { reject({ ret: error.response.status, msg: error.response.data }); });
        })
    },
    getLocalVehicles() {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.get(`/masterData/localVehicles`).then((response) => {
                return resolve(response);
            }).catch(error => { reject({ ret: error.response.status, msg: error.response.data }); });
        })
    },
    getLocalVehicleById(id) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.get(`/masterData/localVehicle/${id}`).then((response) => {
                return resolve(response);
            }).catch(error => { reject({ ret: error.response.status, msg: error.response.data }); });
        })
    },
    editVehicle(vehicle) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.put("/masterData/editVehicle", vehicle).then((response) => {
                return resolve(response);
            }).catch(error => { reject({ ret: error.response.status, msg: error.response.data }); });
        })
    },
    editVehicleTare(vehicle) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.put("/masterData/editVehicleTare", vehicle).then((response) => {
                return resolve(response);
            }).catch(error => { reject({ ret: error.response.status, msg: error.response.data }); });
        })
    },
    createVehicleAvailability(availability) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post("/masterData/createVehicleAvailability", availability).then((response) => {
                return resolve(response);
            }).catch(error => { reject({ ret: error.response.status, msg: error.response.data }); });
        })
    },
    getVehicleAvailability(id) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post(`/masterData/getVehicleAvailability/${id}`).then((response) => {
                return resolve(response);
            }).catch(error => { reject({ ret: error.response.status, msg: error.response.data }); });
        })
    },
    getVehicleFilters() {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post(`/masterData/getFilersVehicles`).then((response) => {
                return resolve(response);
            }).catch(error => { reject({ ret: error.response.status, msg: error.response.data }); });
        })
    },
    //vehicles MYSQL
    getAllVehicles() {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.get(`/masterData/getAllVehicles`).then((response) => {
                return resolve(response);
            }).catch(error => { reject({ ret: error.response.status, msg: error.response.data }); });
        })
    },
    getVehicleById(vehicleId) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.get(`/masterData/getVehicleById/` + vehicleId).then((response) => {
                return resolve(response);
            }).catch(error => { reject({ ret: error.response.status, msg: error.response.data }); });
        })
    },
    getVehicleTypes() {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.get(`/masterData/getVehicleTypes`).then((response) => {
                return resolve(response);
            }).catch(error => { reject({ ret: error.response.status, msg: error.response.data }); });
        })
    },
    getVehicleTypesOpts(country) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.get(`/masterData/getVehicleTypeOpts/` + country).then((response) => {
                return resolve(response);
            }).catch(error => { reject({ ret: error.response.status, msg: error.response.data }); });
        })
    },
    getVehiclesByHaulierName(haulier) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post(`/masterData/getVehiclesByHaulierName`, haulier).then((response) => {
                return resolve(response);
            }).catch(error => { reject({ ret: error.response.status, msg: error.response.data }); });
        })
    },
    filterVehicles(filters) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post(`/masterData/filterVehicles`, filters).then((response) => {
                return resolve(response);
            }).catch(error => { reject({ ret: error.response.status, msg: error.response.data }); });
        })
    },
    findVehicles(filters) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post(`/masterData/findVehicles`, filters).then((response) => {
                return resolve(response);
            }).catch(error => { reject({ ret: error.response.status, msg: error.response.data }); });
        })
    },
    getcapacityRange(filters) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.get(`/masterData/getcapacityRange`, filters).then((response) => {
                return resolve(response);
            }).catch(error => { reject({ ret: error.response.status, msg: error.response.data }); });
        })
    },
    // strict = false -> retrieves hauliers with that country and null. strict = true -> retrieves hauliers with only that country
    getHaulierNames(country, strict) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post(`/masterData/haulierNames`, { country, strict }).then((response) => {
                return resolve(response);
            }).catch(error => { reject({ ret: error.response.status, msg: error.response.data }); });
        })
    },
    getClusters() {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post(`/masterData/clusters`).then((response) => {
                return resolve(response);
            }).catch(error => { reject({ ret: error.response.status, msg: error.response.data }); });
        })
    },
    getHomeBranches() {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post(`/masterData/homeBranches`).then((response) => {
                return resolve(response);
            }).catch(error => { reject({ ret: error.response.status, msg: error.response.data }); });
        })
    },
    getFavouriteVehiclesByUser() {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.get(`/masterData/getFavouriteVehiclesByUser`).then((response) => {
                return resolve(response);
            }).catch(error => { reject({ ret: error.response.status, msg: error.response.data }); });
        })
    },
    addVehicleAsFavourite(vehicle) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post(`/masterData/addVehicleAsFavourite/${vehicle}`).then((response) => {
                return resolve(response);
            }).catch(error => { reject({ ret: error.response.status, msg: error.response.data }); });
        })
    },
    createVehicleTare(vehicleTareData) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post('/masterData/createVehicleTare/', vehicleTareData).then((response) => {
                return resolve(response);
            }).catch((error) => { reject({ ret: error.response.status, msg: error.response.data }) })
        })
    },
    getVehicleTareByVehicle(vehicleTareData) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post('/masterData/getVehicleTareByVehicle/', vehicleTareData).then((response) => {
                return resolve(response);
            }).catch((error) => { reject({ ret: error.response.status, msg: error.response.data }) })
        })
    },
    deleteVehicleAsFavourite(vehicle) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.delete(`/masterData/deleteVehicleAsFavourite/${vehicle}`).then((response) => {
                return resolve(response);
            }).catch(error => { reject({ ret: error.response.status, msg: error.response.data }); });
        })
    },

    countAssignmentsDeliveries(data) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post('/masterData/countAssignmentsDeliveries/', data).then((response) => {
                return resolve(response);
            }).catch(error => { reject({ ret: error.response.status, msg: error.response.data }); });
        })
    },
    assignedVehicleDeliveries(vehicle) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.get(`/masterData/assignedVehicleDeliveries/${vehicle.orderType}/${vehicle.truckId}/${vehicle.trailerId}`).then((response) => {
                return resolve(response);
            }).catch(error => { reject({ ret: error.response.status, msg: error.response.data }); });
        })
    },
    assignedTruckDeliveries(vehicle) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.get(`/masterData/assignedTruckDeliveries/${vehicle.orderType}/${vehicle.truckId}/${vehicle.trailerId}`).then((response) => {
                return resolve(response);
            }).catch(error => { reject({ ret: error.response.status, msg: error.response.data }); });
        })
    },
    assignedOwnerDeliveries(data) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.get(`/masterData/assignedOwnerDeliveries/${data.orderType}/${data.owner}/${data.truckId}/${data.trailerId}`).then((response) => {
                return resolve(response);
            }).catch(error => { reject({ ret: error.response.status, msg: error.response.data }); });
        })
    },
    assignedFWADeliveries(data) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.get(`/masterData/assignedFWADeliveries/${data.orderType}/${data.fwa}/${data.owner}`).then((response) => {
                return resolve(response);
            }).catch(error => { reject({ ret: error.response.status, msg: error.response.data }); });
        })
    },
    getCarriers() {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.get(`/masterData/getCarriers`).then((response) => {
                return resolve(response);
            }).catch(error => { reject({ ret: error.response.status, msg: error.response.data }); });
        })
    },
    getVendors() {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.get(`/masterData/getVendors`).then((response) => {
                return resolve(response);
            }).catch(error => { reject({ ret: error.response.status, msg: error.response.data }); });
        })
    },
    lastAssignedDeliveries(vehicle) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.get(`/masterData/lastAssignedDeliveries/${vehicle.orderType}/${vehicle.truckId}/${vehicle.trailerId}`).then((response) => {
                return resolve(response);
            }).catch(error => { reject({ ret: error.response.status, msg: error.response.data }); });
        })
    },
    assignedLoadingDelivery(vehicle) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.get(`/masterData/assignedLoadingDelivery/${vehicle.orderType}/${vehicle.truckId}/${vehicle.trailerId}/${vehicle.transferType}`).then((response) => {
                return resolve(response);
            }).catch(error => { reject({ ret: error.response.status, msg: error.response.data }); });
        })
    },
    assignedWasteDelivery(vehicle) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.get(`/masterData/assignedWasteDelivery/${vehicle.orderType}/${vehicle.truckId}/${vehicle.trailerId}`).then((response) => {
                return resolve(response);
            }).catch(error => { reject({ ret: error.response.status, msg: error.response.data }); });
        })
    },
    assignedVehicleSoldToShipTo(data) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.get(`/masterData/assignedVehicleSoldToShipTo/${data.soldto}/${data.shipto}/${data.truckId}/${data.trailerId}`).then((response) => {
                return resolve(response);
            }).catch(error => { reject({ ret: error.response.status, msg: error.response.data }); });
        })
    },
    assignedUnplanned(data) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.get(`/masterData/assignedUnplanned/${data.orderType}/${data.plantCode}/${data.today}/${data.truckId}/${data.trailerId}`).then((response) => {
                return resolve(response);
            }).catch(error => { reject({ ret: error.response.status, msg: error.response.data }); });
        })
    },
    getVehicleSpecialProperties(vehicleId) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post('/masterData/getVehicleSpecialProperties', { vehicleId })
                .then((resp) => { return resolve(resp) })
                .catch(error => reject({ ret: error.response.status, msg: error.response.data }))
        })
    },
    insertVehicleProperties(vehicleId, specialProperties) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post('/masterData/insertVehicleProperties', { vehicleId, specialProperties })
                .then((resp) => { return resolve(resp) })
                .catch(error => reject({ ret: error.response.status, msg: error.response.data }))
        })
    }
}


export default VehiclesService;