'use strict';
import { app } from '@/main.js'
const WasteService = {
    
    /* GET */

    /* FLOOR STORAGE */

    getWasteByTypeAndPlant(data) {
        return new Promise((resolve, reject) => {
            let type = data.type;
            let plant = data.plant;
            
            app.config.globalProperties.$axios.get(`/waste/getWasteData/${type}/${plant}`)
                .then(response => {
                    return resolve(response)
                })
                .catch(error => {
                    console.log(error)
                    reject({
                        ret: error.response
                        , msg: error.response
                    });
                });

        });
    },
    getWasteByTypeAndPlantSearch(data) {
        return new Promise((resolve, reject) => {
            let type = data.type;
            let plant = data.plant;
            let search = data.search;
            
            app.config.globalProperties.$axios.get(`/waste/getWasteDataSearch/${type}/${plant}/${search}`)
                .then(response => {
                    return resolve(response)
                })
                .catch(error => {
                    console.log(error)
                    reject({
                        ret: error.response
                        , msg: error.response
                    });
                });

        });
    },
    getWasteByTypeAndPlantStorageMov(data) {
      return new Promise((resolve, reject) => {
          let type = data.type;
          let plant = data.plant;
          let storageType = data.storageType;
          let fromMaterial = data.fromMaterial;
          
          app.config.globalProperties.$axios.get(`/waste/getWasteDataStorageMov/${type}/${plant}/${storageType}/${fromMaterial}`)
              .then(response => {
                  return resolve(response)
              })
              .catch(error => {
                  console.log(error)
                  reject({
                      ret: error.response
                      , msg: error.response
                  });
              });

      });
    },
    getWasteById(waste) {
        return new Promise((resolve, reject) => {
            //Vue.prototype.$http.get('/master/plant/', plant)
            app.config.globalProperties.$axios.get(`/waste/${waste}`)
                .then(response => {
                    return resolve(response)
                })
                .catch(error => {
                    reject({
                        ret: error.response.status
                        , msg: error.response.data
                    });
                });

        });
    },

    /* GET WASTE STORAGE MOVEMENT DELIVERIES - FOR ANALYSIS FLOOR STORAGE */

    async getWasteStorageMovDeliveries(body) {
      try {
        const response = await app.config.globalProperties.$axios.post('/waste/getWasteStorageMovDeliveries', body);
        return response;

      } catch (error) {
        const err = JSON.parse(error.request.response)
        throw err
      }
    },

    async getWasteStorageMovDeliveriesSearch(body) {
      try {
        const response = await app.config.globalProperties.$axios.post('/waste/getWasteStorageMovDeliveriesSearch', body);
        return response;

      } catch (error) {
        const err = JSON.parse(error.request.response)
        throw err
      }
    },
 
    /* GET WASTE STORAGE MOVEMENT REFERENCES */
    async getWasteStorageMovRefenceInfo(body) {
      try {
        const response = await app.config.globalProperties.$axios.post('/waste/getWasteStorageMovRefenceInfo', body);
        return response;

      } catch (error) {
        const err = JSON.parse(error.request.response)
        throw err
      }
    },

    async updateStorageMovDeliveries(body) {
      try {
        const response = await app.config.globalProperties.$axios.post('/waste/updateStorageMovDeliveries', body);
        return response;

      } catch (error) {
        const err = JSON.parse(error.request.response)
        throw err
      }
    },

    async updateStorageMovClientRefusal(body) {
      try {
        const response = await app.config.globalProperties.$axios.post('/waste/updateStorageMovClientRefusal', body);
        return response;

      } catch (error) {
        const err = JSON.parse(error.request.response)
        throw err
      }
    },

    /* LANDFILL ZONE AREA */

    async getLandFillZoneAreas({type, plant}) {
      try {
        const landfillZoneAreas = await app.config.globalProperties.$axios.get(`/waste/landfillZoneArea/${type}/${plant}`);
        return landfillZoneAreas;

      } catch (error) {
        return {
          ret: error.response.status,
          msg: error.response
        }
      }
    },

    async getLandFillFiltered({type, plant, search}) {
      try {
        const landfillZoneAreas = await app.config.globalProperties.$axios.get(`/waste/getLandfillSearch/${type}/${plant}/${search}`);
        return landfillZoneAreas;

      } catch (error) {
        return {
          ret: error.response.status,
          msg: error.response
        }
      }
    },

    /* TREATMENT LOGIC CONFIGURATION */

    getTreatmentLogicData(data) {
      return new Promise((resolve, reject) => {
          let plant = data.plant;
          
          app.config.globalProperties.$axios.get(`/waste/getTreatmentLogicData/${plant}`)
              .then(response => {
                  return resolve(response)
              })
              .catch(error => {
                  console.log(error)
                  reject({
                      ret: error.response
                      , msg: error.response
                  });
              });

        });
    },

    /* DAP RANGES CONFIGURATION */

    getDAPRangesData(data) {
      return new Promise((resolve, reject) => {
          let plant = data.plant;
          
          app.config.globalProperties.$axios.get(`/waste/getDAPRangesData/${plant}`)
              .then(response => {
                  return resolve(response)
              })
              .catch(error => {
                  console.log(error)
                  reject({
                      ret: error.response
                      , msg: error.response
                  });
              });

        });
    },
  
    /* STORAGE MOVEMENT CONFIGURATION DATA */
    
    getStorageMovementConfigData(data) {
      return new Promise((resolve, reject) => {
          let plant = data.plant;
          
          app.config.globalProperties.$axios.get(`/waste/getStorageMovementConfigData/${plant}`)
              .then(response => {
                  return resolve(response)
              })
              .catch(error => {
                  console.log(error)
                  reject({
                      ret: error.response
                      , msg: error.response
                  });
              });

        });
    },

    /* OPERATIONS (INSERT/UPDATE) FLOOR STORAGE */

    operationWaste(wasteData) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post('/waste/operationWaste', wasteData)
                .then(response => {
                    return resolve(response)
                })
                .catch(error => {
                    reject({
                        ret: error.response.status
                        , msg: error.response.data
                    });
                });
        });
    },

    /* DELETE FLOOR STORAGE */
    
    deleteWaste(form) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.delete(`/waste/delWaste/${form.id}/${form.plant}/${form.type}`)
                .then(response => {
                    return resolve(response)
                })
                .catch(error => {
                    reject({
                        ret: error.response.status
                        , msg: error.response.data
                    });
                });
        });
    },

    /* OPERATIONS (INSERT/UPDATE) LANDFILL ZONE AREA */
    
    async operationLandfill(landfillData) {
      return new Promise((resolve, reject) => {
        app.config.globalProperties.$axios.post('/waste/operationLandfill', landfillData)
            .then(response => {
                return resolve(response)
            })
            .catch(error => {
              console.log(error)
                reject({
                    ret: error.response.status
                    , msg: error.response.data
                });
          });
      });
    },

    /* DELETE LANDFILL ZONE AREA */

    async deleteLandFillZoneAreas({id, type, plant}) {
      try {
        const landfillZoneAreas = await app.config.globalProperties.$axios.delete(`/waste/deleteLandfillZoneArea/${id}/${type}/${plant}`);
        return landfillZoneAreas;

      } catch (error) {
        return {
          ret: error.response.status,
          msg: error.response
        }
      }
    },

    /* DELETE TREATMENT LOGIC */

    deleteTreatmentLogic(form) {
      return new Promise((resolve, reject) => {
        console.log('deleteTreatmentLogic',form)
        app.config.globalProperties.$axios.delete(`/waste/deleteTreatmentLogic/${form.id}/${form.plant}/${form.filter_type}/${form.flow_type}`)
          .then(response => {
              return resolve(response)
          })
          .catch(error => {
            reject({
              ret: error.response.status, 
              msg: error.response.data
            });
          });
      });
    },

  /* DELETE STORAGE MOVEMENT CONFIG */

  deleteStorageMovementConfig(form) {
    return new Promise((resolve, reject) => {
      console.log('deleteStorageMovementConfig',form)
        app.config.globalProperties.$axios.delete(`/waste/deleteStorageMovementConfig/${form.id}/${form.plant}/${form.storage_from_id}`)
            .then(response => {
                return resolve(response)
            })
            .catch(error => {
                reject({
                    ret: error.response.status
                    , msg: error.response.data
                });
            });
    });
  },

  /* OPERATION WASTE STORAGE MOVEMENT DELIVERIES - FOR ANALYSIS FLOOR STORAGE */
    
  async operationWasteStorageMovDeliveries(body) {
    try {
      const response = await app.config.globalProperties.$axios.post('/waste/operationWasteStorageMovDeliveries', body)
      return response
    } catch (error) {
      return Promise.reject({
        ret: error.response.status,
        msg: error.response.data
      });
    }
  },
    
  /* DOCUMENTS*/

  getWasteBlankPDF(waste) {
    console.log('getWasteBlankPDF data waste', waste)
    const bucket =  waste.bucket_name;
    const key = waste.fileName;
    const user = JSON.parse(sessionStorage.getItem("user"));
    var data = {
      bucket_name: bucket,
      fileName: key,
      user
    };
    return new Promise((resolve, reject) => {
      app.config.globalProperties.$axios
        .post(`/integration/s3/download`, data)
        .then((response) => {
          return resolve(response);
        })
        .catch((error) => {
          reject({
            ret: error.response.status,
            msg: error.response.data,
          });
        });
    });
  },

  getWasteDapRocksPDF(order) {
    console.log('getWasteDapRocksPDF')
    return new Promise((resolve, reject) => {
      app.config.globalProperties.$axios.post('/dap/getWasteDapRocksPDF', order)
        .then((response) => {
            return resolve(response);
        })
        .catch(error => {
          reject({
            ret: error.response.status,
            msg: error.response.data
          })
        })
    })
  },

  /* WASTE SCREENS */ 
 
  async getMaterialDAP(delivery) {
      try {
        const materialDAP = await app.config.globalProperties.$axios.post(`/waste/getMaterialDAP`, delivery);
        return materialDAP;

      } catch (error) {
        throw {
          status: error.response.status,
          msg: error.response.data.error.message,
          from: error.response.data.error.from,
          ewxCategory: error.response.data.error.ewxCategory,
          dapEasyTotalResponse:error.response.data.error.dapEasyTotalResponse
        }
      }
    },
    async createDAP(body) {
      try {
        const response = await app.config.globalProperties.$axios.post('/waste/operationDAPRocks', body);
        return response;

      } catch (error) {
        const err = JSON.parse(error.request.response)
        throw err
      }
    },
    
    async getWasteRefenceInfo(body) {
      try {
        const response = await app.config.globalProperties.$axios.post('/waste/getWasteRefenceInfo', body);
        return response;

      } catch (error) {
        const err = JSON.parse(error.request.response)
        throw err
      }
    },
    async getQualificationTreats(body) {
      try {
        const response = await app.config.globalProperties.$axios.post('/waste/getQualificationTreats', body);
        return response;

      } catch (error) {
        const err = JSON.parse(error.request.response)
        throw err
      }
    },
    getDAPExtraData(body) {
      return new Promise((resolve, reject) => {
          app.config.globalProperties.$axios.post('/waste/getDAPExtraData', body)
              .then(response => {
                  return resolve(response)
              })
              .catch(error => {
                //console.log(error)
                  reject({
                      ret: error.response.status
                      , msg: error.response.data
                  });
              });
      });

    },
    async getDestinationsTypes() {

      try {
        const { data } = await app.config.globalProperties.$axios.get(`/waste/getAllWasteDestinationTypes`);
        return data;

      } catch (error) {
        return {
          msg: error.response
        }
      }
    },
    async getDestinationsTypesByStorageType(storageType) {
      try {
        const { data } = await app.config.globalProperties.$axios.get(`/waste/getDestinationsTypesByStorageType/${storageType}`);
        return data;

      } catch (error) {
        return {
          msg: error.response
        }
      }
    },
    async getDestinationsTypesByFlow(flowType) {
      try {
        const { data } = await app.config.globalProperties.$axios.get(`/waste/getWasteDestinationTypesByFlow/${flowType}`);
        return data;

      } catch (error) {
        return {
          msg: error.response
        }
      }
    },
    async getDestinationsTypesByFlowAndSearch(flowType,search) {
      try {
        const { data } = await app.config.globalProperties.$axios.get(`/waste/getWasteDestinationTypesByFlowAndSearch/${flowType}/${search}`);
        return data;

      } catch (error) {
        return {
          msg: error.response
        }
      }
    },
    async getDestinationPlantTypes(type_paramenter) {
      try {
        const { data } = await app.config.globalProperties.$axios.get(`/waste/getDestinationPlantTypes/${type_paramenter}`);
        return data;

      } catch (error) {
        return {
          msg: error.response
        }
      }
    },
    
    async getAvailableDestinations(data) {
      
      const {isSearching, search} = data
      
      if (isSearching) {
        if (!search) return
      }

      try {
        console.log('getAvailableDestinations',data)
        const availableDestinations = await app.config.globalProperties.$axios.post(`/waste/getAvailableDestinations`, data);
        
        console.log('availableDestinations >>>',availableDestinations)
          
        return availableDestinations.data;

      } catch (error) {
        console.log('error', error)
        return {
          ret: error.response.status,
          msg: error.response
        }
      }
    },

    async getFireQInfo(delivery) {
      try {
        const getFireQInfo = await app.config.globalProperties.$axios.post(`/waste/getFireQInfo`, delivery);
        console.log(getFireQInfo)
        return getFireQInfo;

      } catch (error) {
        console.log(error)
        throw {
          status: error.response.status,
          msg: error.response.data.error.message,
          from: error.response.data.error.from,
          ewxCategory: error.response.data.error.ewxCategory,
          dapEasyTotalResponse:error.response.data.error.dapEasyTotalResponse
        }
      }
    }
}

export default WasteService;