<template>
  <div class="app-content">
    <div class="app-content__content cl-content app-content--vehicles">
      <div class="row app-content--assigment">
        <div class="cl-results assignment">
          <div class="cl-results__wrapper">
            <DataTable :value="deliveries" :filters="filters" filterDisplay="row" scrollable :loading="loading" :rows="20">

              <Column :style="{ 'max-width': '9%' }" :header="$t('Delivery Date')">
                <template #body="slotProps">
                  {{ moment(slotProps.data._source.deliveryLocalDate).format(displayMomentFormat) }}  
                </template>
              </Column>

              <Column :style="{ 'max-width': '9%' }" :header="$t('Loading date')">
                <template #body="slotProps">
                  {{ moment(slotProps.data._source.Tour.planned.loadStarted).format(displayMomentFormat) }}
                  {{ moment(slotProps.data._source.Tour.planned.loadStarted).format("HH:mm") }}    
                </template>
              </Column>

              <Column :style="{ 'min-width': '2%' }" field="_source.logistic.truck.plateNumber" :header="$t('Truck')">
                <template #body="slotProps">
                  {{ slotProps.data._source.logistic.truck.plateNumber }}
                  {{ slotProps.data._source.logistic.trailer.vehicleId != '' 
                      ? slotProps.data._source.logistic.trailer.plateNumber 
                      : ''
                  }}

                </template>
              </Column>
          
              <Column :style="{ 'min-width': '5%' }" field="_source.logistic.carrier.name" :header="$t('Owner')">
              </Column>

              <!-- NON UNPLANNED -->
              <Column v-if="assignmentType != 'unplanned'" :style="{ 'min-width': '15%' }" :header="headerSoldTo" >
                <template #body="slotProps">
                  {{ bodySoldTo(slotProps.data) }}  
                </template>
              </Column>

              <Column v-if="assignmentType != 'unplanned'" :style="{ 'min-width': '15%' }" :header="headerShipTo" >
                <template #body="slotProps">
                  {{ bodyShipTo(slotProps.data) }}  
                </template>
              </Column>

              <!-- ONLY != W_IN UNPLANNED-->
              <Column v-if="assignmentType == 'unplanned' && assignmentData.orderType != commonClasses.wasteOrderTypes.Inbound" 
                :style="{ 'min-width': '15%' }" :header="headerSoldTo" 
                field="_source.soldto.name" :showFilterMenu="false" :showClearButton="false">
                <template #filter="{ filterModel, filterCallback }">
                  <InputText 
                    v-model="filters['_source.soldto.name'].value" 
                    @input="filterCallback" 
                    class="p-column-filter" 
                    :placeholder="`Search by ${headerSoldTo}`" 
                  />
                </template>
              </Column>

              <Column v-if="assignmentType == 'unplanned' && assignmentData.orderType != commonClasses.wasteOrderTypes.Inbound" 
                :style="{ 'min-width': '15%' }" :header="headerShipTo" 
                field="_source.shipto.address.name" :showFilterMenu="false" :showClearButton="false">
                <template #filter="{ filterModel, filterCallback }">
                  <InputText 
                    v-model="filters['_source.shipto.address.name'].value" 
                    @input="filterCallback" 
                    class="p-column-filter" 
                    :placeholder="`Search by ${headerShipTo}`"
                  />
                </template>
              </Column>

              <!-- ONLY == W_IN UNPLANNED-->

              <Column v-if="assignmentType == 'unplanned' && assignmentData.orderType == commonClasses.wasteOrderTypes.Inbound" 
                :style="{ 'min-width': '15%' }" :header="headerSoldTo" 
                field="_source.shipto.address.name" :showFilterMenu="false" :showClearButton="false">
                <template #filter="{ filterModel, filterCallback }">
                  <InputText 
                    v-model="filters['_source.shipto.address.name'].value" 
                    @input="filterCallback" 
                    class="p-column-filter" 
                    :placeholder="`Search by ${headerSoldTo}`" 
                  />
                </template>
              </Column>

              <Column v-if="assignmentType == 'unplanned' && assignmentData.orderType == commonClasses.wasteOrderTypes.Inbound" 
                :style="{ 'min-width': '15%' }" :header="headerShipTo" 
                field="_source.soldto.name" :showFilterMenu="false" :showClearButton="false">
                <template #filter="{ filterModel, filterCallback }">
                  <InputText 
                    v-model="filters['_source.soldto.name'].value" 
                    @input="filterCallback" 
                    class="p-column-filter" 
                    :placeholder="`Search by ${headerShipTo}`"
                  />
                </template>
              </Column>

              <!-- END ONLY == W_IN -->

              <Column :style="{ 'min-width': '12%' }" :header="$t('Material')">
                <template #body="slotProps">
                  {{ slotProps.data._source.material.map(mat => {
                      if (mat.descriptions && mat.descriptions.length > 0) {
                        return mat.descriptions[0].text;
                      }
                        return "";
                    }).join("\n")
                  }}
                </template>
              </Column>

              <Column :style="{ 'min-width': '3%' }" field="_source.quantity" :header="$t('Quantity')">
                <template #body="slotProps">
                  {{ slotProps.data._source.quantity + (slotProps.data._source.material[0].baseUoM ? ` ${slotProps.data._source.material[0].baseUoM}` : " TNE") }}
                </template>
              </Column>


              <Column :style="{ 'min-width': '3%' }" :header="$t('Rotation')">
                <template #body="slotProps">
                  <i v-if="slotProps.data._source.material[0].userUoM === 'ROT'"
                  :style="{ 'font-size': '25px' }" class="zmdi zmdi-check"></i>
                </template>
              </Column>

              <Column :style="{ 'min-width': '11%' }" :header="$t('Order')">
                <template #body="slotProps">
                  {{ slotProps.data._source.externalId ? slotProps.data._source.externalId : slotProps.data._source.OrderNo  }}  
                </template>
              </Column>


              <!-- BOTONES ACCION -->
              <Column v-if="assignmentType == 'vehicle'" :style="{ 'max-width': '6%', 'padding': '1rem 0.5rem'}" :header="$t('Dispatch')" class="data-list__column-button">
                <template #body="slotProps">
                  <Button class="assignmentButton" severity="secondary" icon="pi pi-reply" @click="checkDeliverySelected(slotProps.index, 'dispatch')" />
                </template>
              </Column>

              <Column v-else-if="assignmentType == 'unplanned'" :style="{ 'max-width': '6%', 'padding': '1rem 0.5rem'}" :header="$t('Dispatch')" class="data-list__column-button">
                <template #body="slotProps">
                  <Button class="assignmentButton" severity="secondary" icon="pi pi-reply" @click="checkDeliverySelected(slotProps.index, 'setTruck')" />
                </template>
              </Column>
              
              <Column v-else-if="assignmentType == 'last'" :style="{ 'max-width': '6%', 'padding': '1rem 0.5rem'}" class="data-list__column-button">
                <template #body="slotProps">
                  <Button class="assignmentButton" severity="secondary" icon="pi pi-clone" @click="cloneOrder(slotProps.index)"/>
                </template>
              </Column>
              
              <Column v-else :style="{ 'max-width': '6%', 'padding': '1rem 0.5rem' }" :header="$t('Select')" class="data-list__column-button">
                <template #body="slotProps">
                  <Button class="assignmentButton" severity="secondary" icon="pi pi-link" @click="checkDeliverySelected(slotProps.index, 'setTruck')" />
                </template>
              </Column>


            </DataTable>
          </div>
        </div>
      </div>
    </div>
  </div>

  <Dialog v-model:visible="alertModal"
      :dismissable-mask="true" :modal="true"
      :style="{'width': '40rem'}">
      <div class="alertModal">
          <p>You have selected a position that is not the first. If you continue, all the deliveries that are above will be canceled</p>
          <div class="alertButtons">
            <Button label="No" icon="pi pi-times" class="p-button btn" @click="closeModalAlert" />
            <Button label="Yes" icon="pi pi-check" class="p-button btn btn--primary" @click="confirmDispatch" />
              <!-- <lh-button size="medium" @click="closeModalAlert">{{ $t('NO') }}</lh-button>
              <lh-button size="medium" type="primary" @click="confirmDispatch">{{ $t('YES') }}</lh-button> -->
          </div>
      </div>
  </Dialog>
</template>
  
  
<script setup>
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';

import moment from 'moment';
import VehicleService from "@/services/api/vehicles.js";
import DeliveryService from '@/services/api/delivery';

import commonClasses from "@/store/commonClasses.js";

  
</script>

<script>
export default {
  name: "AssignmentListModal",
  props: [
    'assignmentType',
    'assignmentData'
  ],
  components: {
    DataTable,
    Column,
    Button,
    Dialog,
    InputText
  },
  data: function () {
    return {
      deliveries: [],
      loading: true,
      alertModal: false,
      tempIndexAction: null,
      displayMomentFormat: JSON.parse(localStorage.getItem("dateFormat")) ? JSON.parse(localStorage.getItem("dateFormat")).moment : 'DD/MM/YYYY',
      filters: {
        '_source.soldto.name': { value: null, matchMode: 'contains' },
        '_source.shipto.address.name': { value: null, matchMode: 'contains' },
      },
    };
  },
  mounted() {
    this.getDeliveries()
  },
  methods: {
    async getDeliveries() {
      let result = null
      try {
        switch (this.assignmentType) {
          case 'vehicle':
            result = await VehicleService.assignedVehicleDeliveries(this.assignmentData)
            break;
          case 'truck':
            result = await VehicleService.assignedTruckDeliveries(this.assignmentData)
            break;
          case 'owner':
            result = await VehicleService.assignedOwnerDeliveries(this.assignmentData)
            break;
          case 'fwa':
            result = await VehicleService.assignedFWADeliveries(this.assignmentData)
            break;
          case 'last':
            result = await VehicleService.lastAssignedDeliveries(this.assignmentData)
            break;
          case 'unplanned':
            result = await VehicleService.assignedUnplanned(this.assignmentData)
            break;
        }
        
        this.deliveries = result.data
        this.loading = false
        
      } catch (err) {
        console.error('Error getting vehicles:', err)
      }
    },
    checkDeliverySelected(index, action) {
      if ((this.assignmentType == 'vehicle' || this.assignmentType == 'truck') && index > 0) {
        this.tempIndexAction = { index, action }
        this.showModalAlert()
      } else {
        this.dispatch(index, action)
      }
    },
    cloneOrder(index) {
      this.$emit('clone', this.deliveries[index])
    },
    showModalAlert() {
        this.alertModal = true
    },
    closeModalAlert() {
        this.alertModal = false
    },
    dispatch(index, action) {
      this.$emit('dispatch', this.deliveries[index], action)
    },
    confirmDispatch() {
      this.alertModal = false
      // deliveries to deleted
      let deliveryListToCancel = this.deliveries.slice(0,this.tempIndexAction.index).map(d => d._id)
      for (const id of deliveryListToCancel) {
        DeliveryService.cancelDelivery('Z001', 'Automatic', `Automatic cancelation from assignments. ${moment(new Date()).tz(JSON.parse(sessionStorage.getItem('user')).country.timezone).format("DD/MM/yyyy HH:mm")}`, id, JSON.parse(sessionStorage.getItem('user')) )
      }

      this.dispatch(this.tempIndexAction.index, this.tempIndexAction.action)
    },
    bodySoldTo(delivery) {
      switch (this.assignmentData.orderType) {
        case 'W_IN':
          return delivery._source.shipto.address.name
        
        default:
          return delivery._source.soldto.name
      }
    },
    bodyShipTo(delivery) {
      switch (this.assignmentData.orderType) {
        case 'W_IN':
          return delivery._source.soldto.name
          // return delivery._source.material[0].plantName
        default:
          return delivery._source.shipto.address.name
      }
    }
  },
  computed: {
    headerSoldTo() {
      switch (this.assignmentData.orderType) {
        case 'OUT':
          return this.$t('Sold to')
        
        case 'IN':
          return this.$t('Vendor')

        case 'W_IN':
          return this.$t('Ship to')
        
        default:
          return this.$t('Origin Plant') 

      }
    },
    headerShipTo() {
      switch (this.assignmentData.orderType) {
        case 'OUT':
          return this.$t('Ship to')
        
        case 'IN':
          return this.$t('Plant')

        case 'W_IN':
          return this.$t('Sold to')
        
        default:
          return this.$t('Destination Plant') 

      }

    }
  }
};
</script>
  
<style lang="scss">
@use "@/assets/css/lh-ds/core/functions" as *;
@use "@/assets/css/hodim/layout/data-list";

.cl {
  $border-color: #d8d8d8;

  &-content {
    .row {
      display: flex;
      flex-direction: column;

      &.app-content--assigment {
        padding: 0 10px;
      }
    }

    &.app-content--vehicles {
      overflow-y: visible;
    }
  }

  &-change {
    width: 20%;
  }

  &-results {
    flex: 1;

    margin-top: 0;
    // border: 1px solid $border-color;
    background-color: white;

    &__wrapper {
      height: 100%;
      overflow: auto;
    }
  }

  &-result {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: rem(12px) rem(20px);

    cursor: pointer;

    +.cl-result {
      border-top: 1px solid $border-color;
    }

    &__name {
      font-weight: bold;
      margin-bottom: rem(1px);
    }

    &__ref {
      color: rgba(black, 0.5);
    }

    &__right {
      display: flex;
      align-items: center;

      *+* {
        margin-left: rem(16px);
      }
    }

    &__tag {
      padding: rem(4px) rem(8px);
      color: #ff2000;
      border: 1px solid;
      border-radius: 2px;
      font-size: rem(12px);
      line-height: rem(20px);
    }
  }
}

.modal_footer-buttons {
  display: flex;
  align-items: flex-start;
  width: 50%;
  padding: 15px;

  lh-button {
    margin-right: 5px;
  }
}

.assignmentButton {
  border: 1px solid darkgrey;
}

.cl-results.assignment {
  $border-color: #d8d8d8;
  .p-datatable {
    table {
      border: 1px solid $border-color;
      margin-top: 0px;
      margin-bottom: 0px;
    }
    .p-datatable-thead > tr:first-child > th {
      background: lightgrey;
      color: darkslategrey;
    }
    &-tbody > tr > td {
      padding: 1rem 0.35rem;
    }
  }

}

.alertModal {
  .alertButtons {
    button.p-button.p-component.btn {
      margin: 0 1rem;
    }
  }
}

</style>
  