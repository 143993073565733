<template>
  <Step :title="$t('Sending plant')" class="materials-step">
    <div class="columns table-header is-align-items-center">
      <div class="column">
        {{ $t("Material") }}
      </div>

      <div class="column">
        {{ $t("DAP") }}
      </div>

      <div class="column">
        {{ $t("DN") }}
      </div>

      <div class="column">
        {{ $t("Destination") }}
      </div>

      <div class="column">
        {{ $t("Treatment") }}
      </div>

      <div class="column">
        {{ $t("Tonage") }}
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <div class="is-flex is-flex-direction-column">
          <span>{{ material.materialID }}</span>
          <span>{{ material.descriptions[0].text }}</span>
          <span>{{ `Plant: ${material.plant}` }}</span>
        </div>
      </div>

      <div class="column">
        <div class="is-flex is-flex-direction-column">
          <span v-for="reference in outboundFromDelivery.references">
            {{ reference.dap }}
          </span>
        </div>
      </div>

      <div class="column">
        <div class="is-flex is-flex-direction-column">
          <span v-for="reference in outboundFromDelivery.references">
            {{ reference.dn_number }}
          </span>
        </div>
      </div>
      
      <div class="column">
        <span>{{ outboundFromDelivery.originInfo.type }} - {{ outboundFromDelivery.originInfo.code }}  - {{ outboundFromDelivery.originInfo.description }}</span>
      </div>
      
      <div class="column">
        <span>{{outboundFromDelivery.treatmentInfo.name}}</span>
      </div>
      
      <div class="column">
        {{ delivery._source.netWeight }}
      </div>
    </div>
  </Step>
</template>

<script setup>

import Dialog from 'primevue/dialog';
import Button from "primevue/button";
import Step from "@/components/dispatch/order/utils/step";
import WasteService from "@/services/api/waste";
</script>

<script>
export default {
  components: {
    Step,
    WasteService,
    Dialog,
    Button,
  },
  data: function () {
    return {
      alreadyHaveWasteInfo: false,
      outboundFromDelivery: null,

      delivery: {},
      material: {},

      fireQInfo: {},
      flowType: 'TRANSFER_IN',
      typeOrder: 'W_TRANSFER_IN'
    };
  },
  computed: {
    orderType() {
      return this.$store.state.dispatch ? this.$store.state.dispatch.orderType :this.typeOrder;
    },
  },
  beforeMount() {
    this.delivery = this.$store.state.dispatch ? this.$store.state.dispatch.delivery : this.getDeliveryMock();
    this.material = this.delivery._source.material[0];

    if (this.delivery._source.material[0].wasteOutboundInfo) {
      this.alreadyHaveWasteOutbound = true
      this.outboundFromDelivery = this.delivery._source.material[0].wasteOutboundInfo
      this.fireQInfo = {
        EuropeanWasteCode: this.outboundFromDelivery.fireQ.ewc,
        EuropeanWasteDescription: this.outboundFromDelivery.fireQ.ewc_name,
        InertWasteCategoriesCode: this.outboundFromDelivery.fireQ.category,
        EcomineroCode: this.outboundFromDelivery.fireQ.ecomineroCode,
      }
    }
    // this.getDataSendingPlant()
  },
  methods: {
    getPlantInfo() {
      return JSON.parse(sessionStorage.getItem("user")).plant;
    },
    // async getDataSendingPlant() {
    //   const fireQFromDelivery = this.delivery._source.material[0].materialClassification
    //   console.log(this.delivery._source.material[0].materialClassification)

    //   this.fireQInfo = fireQFromDelivery ? {
    //                       EuropeanWasteCode: fireQFromDelivery.characteristics.wasteCode.characteristicValue,
    //                       EuropeanWasteDescription: fireQFromDelivery.characteristics.wasteCode.description,
    //                       InertWasteCategoriesCode: fireQFromDelivery.characteristics.category.characteristicValue,
    //                       EcomineroCode: fireQFromDelivery.characteristics.ecominero.characteristicValue,
    //                     }
    //                   : (await WasteService.getFireQInfo(this.delivery)).data.fireQResponse

    //   // Store Outbound FireQData in Delivery
    //   if (this.$store.state.dispatch) {
    //     const delivery = this.$store.state.dispatch.delivery
    //     const fireQUpdated = {
    //       ewc: this.fireQInfo.EuropeanWasteCode,
    //       ewc_name: this.fireQInfo.EuropeanWasteDescription,
    //       category: this.fireQInfo.InertWasteCategoriesCode,
    //       ecomineroCode: this.fireQInfo.EcomineroCode
    //     }
    //     delivery._source.material[0].fireQOutbound = fireQUpdated
        
    //     this.$store.commit('dispatch/setDelivery', delivery)
    //   }
    // },
    showNotification(title, text, type, duration) {
      this.$notify({
        title,
        text,
        type,
        duration,
        closeOnClick: true
      })
    },
    storeFireQDataInDelivery(fireQData) {
      if (this.$store.state.dispatch) {
        const delivery = this.$store.state.dispatch.delivery
        const fireQUpdated = {
          ...delivery._source.material[0].dap, 
          fireQ: { 
            ewc: fireQData.EuropeanWasteCode,
            ewc_name: fireQData.EuropeanWasteDescription,
            category: fireQData.InertWasteCategoriesCode,
            ecomineroCode: fireQData.EcomineroCode 
          } 
        }
        delivery._source.material[0].dap = fireQUpdated
        
        this.$store.commit('dispatch/setDelivery', delivery)
      }
    },
    getDeliveryMock() {
      return {
        _index: ".ds-hodim-delivery-hodim_qa-2024.06.17-000003",
        _id: "CNR9KZABAllAV3TTRWjB",
        _version: 5,
        _score: 1,
        _source: {
          shipto: {
            address: {
              country: "FR",
              transportationZone: "",
              city: "SARLAT LA CANEDA",
              street: "ZI DE MADRAZES - LE PECH DE MADRAZE",
              postalcode: null,
              name: "M+ 24 SARLAT M+ 24 SARLAT"
            },
            contract: "2520601",
            contracts: [
              {
                incoterm: "DDP",
                contract: "2520601"
              }
            ],
            fullSearch: "M+ 24 SARLAT M+ 24 SARLAT SARLAT LA CANEDA 2520601",
            shipto: "101033"
          },
          netWeight: 5,
          material: [
            {
              productHierarchy: "A10AG20SG10",
              materialID: "15606304",
              purchaseUoM: "",
              descriptions: [
                {
                  language: "EN",
                  text: "ITC 1 4/8 Cal C BDM Préfa",
                },
                {
                  language: "FR",
                  text: "ITC 1 4/8 Cal C BDM Préfa",
                },
              ],
              baseUoM: "TNE",
              volume: {
                unit: "",
                value: 0,
              },
              userQuantity: 5,
              materialGroup: "011605",
              plant: "F3S4",
            },
          ],
          incoterm: "DDP",
          DeliveryNo: "",
          isOffline: false,
          deliveryNoteNumber: "",
          status: "Scheduled",
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/hodim/index.scss";
@import "@/assets/css/lh-ds/core/functions";

.materials-step {
  &__table-header {
    font-size: 1rem;
    font-weight: bold;
  }

  &.overlay::before {
    content: "";
    position: absolute;
    inset: 0;
    z-index: 1;
    background-color: #fff;
    opacity: 0.5;
  }
}

.table-header {
  font-size: 1rem;
  font-weight: bold;
  background-color: #eee;
  height: 40px;
}
</style>
