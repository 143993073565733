<template>
  <Step class="control-step" :title="$t('Control')">
   
            
      <table>
        <tr style="width:100%">
          <td style="width:10%">{{$t('Visual')}}</td>
          <td style="width:30%"> 
            <div class="is-flex is-flex-wrap-wrap">
              <div class="is-flex is-align-items-center mr-4">
                <RadioButton v-model="visual" @change='visualUpdate("ACCEPTED")' inputId="visual1" :disabled="alreadyHaveControlInfo" name="visual" value="ACCEPTED" />
                <label for="visual1" class="ml-1">{{$t('Accepted')}}</label>
              </div>

              <div class="is-flex is-align-items-center mr-4">
                <RadioButton v-model="visual" @change='visualUpdate("REFUSED")' inputId="visual2" :disabled="alreadyHaveControlInfo" name="visual" value="REFUSED" />
                <label for="visual2" class="ml-1">{{$t('Refused')}}</label>
              </div>

              <div class="is-flex is-align-items-center mr-4">
                <RadioButton v-model="visual" @change='visualUpdate("BOAT")' inputId="visual3" :disabled="alreadyHaveControlInfo" name="visual" value="BOAT" />
                <label for="visual3" class="ml-1">{{$t('Boat Dispute')}}</label>
              </div>

              <div class="is-flex is-align-items-center mr-4">
                <RadioButton v-model="visual" @change='visualUpdate("UNCONTROLLED")' inputId="visual4" :disabled="alreadyHaveControlInfo" name="visual" value="UNCONTROLLED" />
                <label for="visual4" class="ml-1">{{$t('Uncontrolled')}}</label>
              </div>
            </div>
            </td>
            <td style="width:60%" > 
            <div class="is-flex is-flex-wrap-wrap" v-if="visual == 'REFUSED'">
              <div class="is-flex is-align-items-center mr-4" style="width:80%">
                <InputText :disabled="alreadyHaveControlInfo" placeholder="Visual refusal reason" v-model="visualRefusalReason" style="width:80%"
							  v-on:change="setVisualRefusalReasonValue(visualRefusalReason)" />
              </div>
            </div>
          </td>
        </tr>
        <tr style="width:100%">
          <td style="width:10%">{{$t('Olactif')}}</td>
          <td style="width:30%"> 
            <div class="is-flex is-flex-wrap-wrap">
              <div class="is-flex is-align-items-center mr-4">
                <RadioButton v-model="olactif" @change='olactifUpdate("ACCEPTED")' inputId="olactif1" :disabled="alreadyHaveControlInfo" name="olactif" value="ACCEPTED" />
                <label for="olactif1" class="ml-1">{{$t('Accepted')}}</label>
              </div>

              <div class="is-flex is-align-items-center mr-4">
                <RadioButton v-model="olactif" @change='olactifUpdate("REFUSED")' inputId="olactif2" :disabled="alreadyHaveControlInfo" name="olactif" value="REFUSED" />
                <label for="olactif2" class="ml-1">{{$t('Refused')}}</label>
              </div>

              <div class="is-flex is-align-items-center mr-4">
                <RadioButton v-model="olactif" @change='olactifUpdate("BOAT")' inputId="olactif3" :disabled="alreadyHaveControlInfo" name="olactif" value="BOAT" />
                <label for="olactif3" class="ml-1">{{$t('Boat Dispute')}}</label>
              </div>

              <div class="is-flex is-align-items-center mr-4">
                <RadioButton v-model="olactif" @change='olactifUpdate("UNCONTROLLED")' inputId="olactif4" :disabled="alreadyHaveControlInfo" name="olactif" value="UNCONTROLLED" />
                <label for="olactif4" class="ml-1">{{$t('Uncontrolled')}}</label>
              </div>
            </div>
            </td>
            <td style="width:60%" > 
            <div class="is-flex is-flex-wrap-wrap" v-if="olactif == 'REFUSED'">
              <div class="is-flex is-align-items-center mr-4" style="width:80%">
                <InputText :disabled="alreadyHaveControlInfo" placeholder="Olactif refusal reason" v-model="olactifRefusalReason" style="width:80%"
							  v-on:change="setOlactifRefusalReasonValue(olactifRefusalReason)" />
              </div>
            </div>
          </td>
        </tr>
        <tr style="width:100%">
          <td style="width:10%">{{$t('Camera')}}</td>
          <td style="width:30%"> 
            <div class="is-flex is-flex-wrap-wrap">
              <div class="is-flex is-align-items-center mr-4">
                <RadioButton v-model="camera" @change='cameraUpdate("ACCEPTED")' inputId="camera1" :disabled="alreadyHaveControlInfo" name="camera" value="ACCEPTED" />
                <label for="camera1" class="ml-1">{{$t('Accepted')}}</label>
              </div>

              <div class="is-flex is-align-items-center mr-4">
                <RadioButton v-model="camera" @change='cameraUpdate("REFUSED")' inputId="camera2" :disabled="alreadyHaveControlInfo" name="camera" value="REFUSED" />
                <label for="camera2" class="ml-1">{{$t('Refused')}}</label>
              </div>

              <div class="is-flex is-align-items-center mr-4">
                <RadioButton v-model="camera" @change='cameraUpdate("BOAT")' inputId="camera3" :disabled="alreadyHaveControlInfo" name="camera" value="BOAT" />
                <label for="camera3" class="ml-1">{{$t('Boat Dispute')}}</label>
              </div>

              <div class="is-flex is-align-items-center mr-4">
                <RadioButton v-model="camera" @change='cameraUpdate("UNCONTROLLED")' inputId="camera4" :disabled="alreadyHaveControlInfo" name="camera" value="UNCONTROLLED" />
                <label for="camera4" class="ml-1">{{$t('Uncontrolled')}}</label>
              </div>
            </div>
            </td>
            <td style="width:60%" > 
            <div class="is-flex is-flex-wrap-wrap" v-if="camera == 'REFUSED'">
              <div class="is-flex is-align-items-center mr-4" style="width:80%">
                <InputText :disabled="alreadyHaveControlInfo" placeholder="Camera refusal reason" v-model="cameraRefusalReason" style="width:80%"
							  v-on:change="setCameraRefusalReasonValue(cameraRefusalReason)" />
              </div>
            </div>
          </td>
        </tr>
      
      </table>

  </Step>
</template>

<script setup>

import Step from "@/components/dispatch/order/utils/step";
import RadioButton from 'primevue/radiobutton';
import InputText from "primevue/inputtext";
</script>

<script>
export default {
  components: {
    Step,
    RadioButton,
    InputText
  },
  data: function () {
    return {
      visual: '',
      olactif: '',
      camera: '',
      visualRefusalReason: '',
      olactifRefusalReason: '',
      cameraRefusalReason: '',

      delivery: {},
      alreadyHaveControlInfo: false,
    };
  },
  mounted() {
    
    this.delivery = this.$store.state.dispatch ? this.$store.state.dispatch.delivery : {};
    this.orderType = this.$store.state.dispatch ? this.$store.state.dispatch.orderType : {};

    const wasteInfo = this.delivery._source.material[0].wasteInfo

    if (wasteInfo) {
      if (wasteInfo.control) {
        this.alreadyHaveControlInfo = true;

        this.visual = wasteInfo.control.visual
        this.olactif = wasteInfo.control.olactif
        this.camera = wasteInfo.control.camera

        this.visualRefusalReason = wasteInfo.control.visualRefusalReason
        this.olactifRefusalReason = wasteInfo.control.olactifRefusalReason
        this.cameraRefusalReason = wasteInfo.control.cameraRefusalReason
      }
    } else {
      if(this.delivery._source.material[0].dap) this.delivery._source.material[0].dap.control =  { visual: '', visualRefusalReason: '', olactif: '',olactifRefusalReason: '',  camera: '',cameraRefusalReason: ''  } 
      if(this.delivery._source.material[0].wasteTransferIn)  this.delivery._source.material[0].wasteTransferIn.control = { visual: '', visualRefusalReason: '', olactif: '',olactifRefusalReason: '',  camera: '',cameraRefusalReason: ''}
      
      //AutoSelect UNCONTROLLED option by default
      if(this.delivery._source.material[0].dap || 
         this.delivery._source.material[0].wasteTransferIn ||
         this.delivery._source.material[0].wasteOutboundInfo
        ) {
          //this.autoSelectUncontrolled() // On 11/12/2024 it is requested to remove Uncontrolled as default value
         }
    }
    this.$store.commit('dispatch/setDelivery', this.delivery)
  },

  methods: {
    autoSelectUncontrolled(){
      //Set UNCONTROLLED as default value for visual, olactif and camera
      
      this.visual ='UNCONTROLLED';
      this.olactif ='UNCONTROLLED';
      this.camera ='UNCONTROLLED';

      this.visualUpdate("UNCONTROLLED");
      this.olactifUpdate("UNCONTROLLED");
      this.cameraUpdate("UNCONTROLLED");

    },
    visualUpdate(value) {
      console.log('visualUpdate', value)
      
      //Reset the refusalReaseon
      this.visualRefusalReason='';

      if (!this.$store.state.dispatch) {
        return
      }

      if (this.orderType === 'Transfer In') {
        if (this.delivery._source.material[0].wasteTransferIn) {
          this.delivery._source.material[0].wasteTransferIn = {
            ...this.delivery._source.material[0].wasteTransferIn,
            control: { ...this.delivery._source.material[0].wasteTransferIn.control, visual: value }
          }
        }
      } else {
        this.delivery._source.material[0].dap = {
          ...this.delivery._source.material[0].dap,
          control: { ...this.delivery._source.material[0].dap.control, visual: value }
        }
      }
      
      this.$store.commit('dispatch/setDelivery', this.delivery)
    },
    olactifUpdate(value) {
      console.log('olactifUpdate', value)

      //Reset the refusalReaseon
      this.olactifRefusalReason='';
     
      if (!this.$store.state.dispatch) { 
        return
      }

      if (this.orderType === 'Transfer In') {
        if (this.delivery._source.material[0].wasteTransferIn) {
          this.delivery._source.material[0].wasteTransferIn = {
            ...this.delivery._source.material[0].wasteTransferIn,
            control: { ...this.delivery._source.material[0].wasteTransferIn.control, olactif: value }
          }
        }
      } else {
        this.delivery._source.material[0].dap = {
          ...this.delivery._source.material[0].dap,
          control: { ...this.delivery._source.material[0].dap.control, olactif: value }
        }
      }
      this.$store.commit('dispatch/setDelivery', this.delivery)
    },
    cameraUpdate(value) {
      console.log('cameraUpdate', value)
      //Reset the refusalReaseon
      this.cameraRefusalReason='';

      if (!this.$store.state.dispatch) {
        return
      }

      if (this.orderType === 'Transfer In') {
        if (this.delivery._source.material[0].wasteTransferIn) {
          this.delivery._source.material[0].wasteTransferIn = {
            ...this.delivery._source.material[0].wasteTransferIn,
            control: { ...this.delivery._source.material[0].wasteTransferIn.control, camera: value }
          }
        }
      } else {
        this.delivery._source.material[0].dap = {
          ...this.delivery._source.material[0].dap,
          control: { ...this.delivery._source.material[0].dap.control, camera: value }
        }
      }
      this.$store.commit('dispatch/setDelivery', this.delivery)
    },
    setCameraRefusalReasonValue(value) {
      console.log('setCameraRefusalReasonValue',value);
      if (!this.$store.state.dispatch) {
        return
      }

      if (this.orderType === 'Transfer In') {
        this.delivery._source.material[0].wasteTransferIn = {
          ...this.delivery._source.material[0].wasteTransferIn,
          control: { ...this.delivery._source.material[0].wasteTransferIn.control, cameraRefusalReason: value }
        }
      } else {
        this.delivery._source.material[0].dap = {
          ...this.delivery._source.material[0].dap,
          control: { ...this.delivery._source.material[0].dap.control, cameraRefusalReason: value }
        }
      }
      
      this.$store.commit('dispatch/setDelivery', this.delivery)
    },
    setVisualRefusalReasonValue(value) {
      console.log('setVisualRefusalReasonValue',value);
      if (!this.$store.state.dispatch) {
        return
      }

      if (this.orderType === 'Transfer In') {
        this.delivery._source.material[0].wasteTransferIn = {
          ...this.delivery._source.material[0].wasteTransferIn,
          control: { ...this.delivery._source.material[0].wasteTransferIn.control, visualRefusalReason: value }
        }
      } else {
        this.delivery._source.material[0].dap = {
          ...this.delivery._source.material[0].dap,
          control: { ...this.delivery._source.material[0].dap.control, visualRefusalReason: value }
        }
      }
      
      this.$store.commit('dispatch/setDelivery', this.delivery)
    },
    setOlactifRefusalReasonValue(value) {
      console.log('setOlactifRefusalReasonValue',value);
      if (!this.$store.state.dispatch) {
        return
      }

      if (this.orderType === 'Transfer In') {
        this.delivery._source.material[0].wasteTransferIn = {
          ...this.delivery._source.material[0].wasteTransferIn,
          control: { ...this.delivery._source.material[0].wasteTransferIn.control, olactifRefusalReason: value }
        }
      } else {
        this.delivery._source.material[0].dap = {
          ...this.delivery._source.material[0].dap,
          control: { ...this.delivery._source.material[0].dap.control, olactifRefusalReason: value }
        }
      }
      
      this.$store.commit('dispatch/setDelivery', this.delivery)
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/hodim/index.scss";
@import "@/assets/css/lh-ds/core/functions";

.control-step {
  &__table-header {
    font-size: 1rem;
    font-weight: bold;
  }

  &.overlay::before {
    content: "";
    position: absolute;
    inset: 0;
    z-index: 1;
    background-color: #fff;
    opacity: 0.5;
  }
}
</style>
