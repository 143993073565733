<template>
    <!-- For Outbounds -->
    <div class="columns" v-if="orderType == 'Outbound' || isWaste == true" >
        <div class="column">
            <FormControl :label="$t('Client')">
                {{ $store.state.dispatch.delivery._source.soldto.customer }}<br>
                {{ $store.state.dispatch.delivery._source.soldto.name }}
            </FormControl>
        </div>
        <div class="column">
            <FormControl :label="$t('Ship to')">
                <div>
                    {{ $store.state.dispatch.delivery._source.shipto.shipto }} <br/>
                    {{ $store.state.dispatch.delivery._source.shipto.address.name }}, {{ $store.state.dispatch.delivery._source.shipto.address.city }}
                </div>
            </FormControl>
        </div>
        <div class="column" >
            <FormControl :label="$t('Contract')">
                {{ $store.state.dispatch.delivery._source.contract.contract ? $store.state.dispatch.delivery._source.contract.contract : '-' }}
            </FormControl>
        </div>

        <!-- Exclusivos de delivery outbound -->
        <div class="column">
            <FormControl :label="$t('Plant')">
                {{ $store.state.dispatch.delivery._source.material[0].plant ? $store.state.dispatch.delivery._source.material[0].plant : '-' }}
            </FormControl>
        </div>
        <div class="column">
            <FormControl :label="$t('PO Number')">
                {{ $store.state.dispatch.delivery._source.PONumber ? $store.state.dispatch.delivery._source.PONumber : '-' }}
            </FormControl>
        </div>
        <div class="column">
            <FormControl :label="$t('Delivery Type')">
                {{ $store.state.dispatch.delivery._source.deliveryType ? $store.state.dispatch.delivery._source.deliveryType : '-' }}
            </FormControl>
        </div>
        <div class="column">
            <FormControl :label="$t('Status')">
                <Status style="max-width: max-content" :name="$store.state.dispatch.delivery._source.status ? $store.state.dispatch.delivery._source.status : '-'" />
            </FormControl>
        </div>
        <div class="column">
            <FormControl :label="$t('Delivery #')">
                {{ $store.state.dispatch.delivery._source.deliveryNO ? $store.state.dispatch.delivery._source.deliveryNO : '-' }}
            </FormControl>
        </div>
    </div>

    <!-- For Inbounds -->

    <div class="columns" v-else-if="orderType == 'Inbound' && isWaste == false" >
        <div class="column" style="flex-grow: 2;">
            <FormControl :label="$t('Vendor / Vendor subrange')">
                {{ $store.state.dispatch.delivery._source.soldto.customer }}<br>
                {{ ($store.state.dispatch.delivery._source.soldto.name).substring(0, 40) }}
                {{ ($store.state.dispatch.delivery._source.contract.selected.originName).substring(0, 40) }}
            </FormControl>
        </div>
        <div class="column" style="flex-grow: 2;">
            <FormControl :label="$t('Plant')">
                <div>
                    {{ $store.state.dispatch.delivery._source.shipto.shipto }} <br/>
                    {{ $store.state.dispatch.delivery._source.shipto.address.name }}, {{ $store.state.dispatch.delivery._source.shipto.address.city }}
                </div>
            </FormControl>
        </div>
        <div class="column" >
            <FormControl :label="$t('Schedule Agreement')">
                {{ $store.state.dispatch.delivery._source.contract.contract ? $store.state.dispatch.delivery._source.contract.contract : '-' }}
            </FormControl>
        </div>

        <div class="column">
            <FormControl :label="$t('Delivery #')">
                {{ $store.state.dispatch.delivery._source.deliveryNO ? $store.state.dispatch.delivery._source.deliveryNO : '-' }}
            </FormControl>
        </div>
        <div class="column"></div>
        <div class="column"></div>
    </div>

     <!-- For Transfers -->
    <div class="columns" v-else-if="orderType == 'Transfer In' || orderType == 'Transfer Out'" >
        <div class="column" style="flex-grow: 2;">
            <FormControl :label="$t('Origin Plant')">
                {{ $store.state.dispatch.delivery._source.material[0].plant }}<br>
                {{ $store.state.dispatch.delivery._source.material[0].plantName }}
            </FormControl>
        </div>
        <div class="column" style="flex-grow: 2;">
            <FormControl :label="$t('Destination Plant')">
                <div>
                    {{ $store.state.dispatch.delivery._source.shipto.shipto }} <br/>
                    {{ $store.state.dispatch.delivery._source.shipto.address.name }}, {{ $store.state.dispatch.delivery._source.shipto.address.city }}
                </div>
            </FormControl>
        </div>
        <div class="column" >
            <FormControl :label="$t('Agreement')">
                {{ $store.state.dispatch.delivery._source.contract.contract ? $store.state.dispatch.delivery._source.contract.contract : '-' }}
            </FormControl>
        </div>

        <div class="column">
            <FormControl :label="$t('Delivery #')">
                {{ $store.state.dispatch.delivery._source.deliveryNO ? $store.state.dispatch.delivery._source.deliveryNO : '-' }}
            </FormControl>
        </div>
        <div class="column"></div>
        <div class="column"></div>
    </div>
   
</template>

<script setup>
import FormControl from "@/components/common/FormControl.vue";
import Status from "@/components/common/Status.vue";
</script>

<script>
export default {
    name: "AssignmentDeliveryData",
    components: {
        FormControl,
        Status
    },
    computed: {
        orderType() {
            return this.$store.state.dispatch.orderType
        },
        isWaste() {
            return this.$store.state.dispatch.isWaste
        }
    }
}
</script>

<style lang="scss">
.columns {

    &.assign {
        .searchType {
            .form-control__label {
                padding-top: 0.8rem;
            }
        }

        .form-control__label {

            .text {
                padding-top: 0.8rem;
            }

            .p-inputtext {
                width: 2rem;
                text-align: center;
                height: 2rem;
                margin-top: 10px;

                &.numberData {
                    cursor: pointer;
                    padding: 5px 5px 0 5px;
                    background-color: #1D4370;
                    color: white;
                    width: 3rem;
                    height: 2.5rem;
                }
            }
        }
    }
}

.entryBtn {
    button {
        width: 80%;
        margin-top: 2.8rem;
    }
}
</style>