import JSZip from "jszip";
import s3Service from "@/services/api/s3";

export const sanitizeFilename = (filename) => {
  return filename.replace(/\s+/g, '_').replace(/[^a-zA-Z0-9\-_.]/g, '');
}

export const splitFileInChunks = (file, numChars) => {
  const fileArray = [];
  let index = 0;
  while(index < file.length) {
    let end = Math.min(index + numChars, file.length);
    fileArray.push(file.slice(index, end));
    index += numChars;
  }
  return fileArray;
}

export const uploadAttachments = async (attachments, objectType, objectId, s3Prefix) => {
  try {
    // A zip file is generated with all the files uploaded by the user
    const zip = new JSZip();
    for (let file of attachments) {
      zip.file(sanitizeFilename(file.file.name), file.file);
    }
    const zipFile = await zip.generateAsync({ type: 'base64' });

    // The zip file is split in parts so it can be sent to the server because the requests have a top limit of 100kb
    const numChars = 100000;
    const zipArray = splitFileInChunks(zipFile, numChars);

    // Each of the split parts is sent to the server individually
    if (!objectType || !objectId) {
      throw new Error("One or more of these props are missing: objectType, objectId");
    }
    const tempDir = [objectType, objectId];
    await Promise.all(zipArray.map((chunk, index) => s3Service.uploadAttachmentsChunk(tempDir, chunk, index)));

    // Once all the parts have been sent to the server, a request is sent to upload the complete zip file to S3
    const { data: filesUploaded } = await s3Service.uploadAttachments(tempDir, s3Prefix);
    return filesUploaded;
  } catch (error) {
    throw error;
  }
}


