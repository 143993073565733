<template>
  <div class="treatment m-6">
    <DataTable
      :value="qualificationsFinal"
      scrollDirection="horizontal"
      :rows="7"
    >
      <Column :header="$t('Name')"> 
        <template #body="slotProps">
          {{ $t(slotProps.data.name) }}
        </template>
      </Column>

      <Column :header="$t('Value')"> 
        <template #body="slotProps">
          {{ $t(slotProps.data.value) }}
        </template>
      </Column>

      <Column :header="$t('Select')" :style="{ width: '10%' }" class="data-list__column-button">
        <template #body="slotProps">
          <Button
            class="p-button btn"
            icon="zmdi zmdi-arrow-right zmdi-hc-lg"
            @click="selectQualification(slotProps)"
          />
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script setup>
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
</script>

<script>
export default {
  name: "QualificationModal",
  props: ['qualificationsFinal'],
  components: {
    DataTable,
    Column,
    Button,
    Dialog,
  },
  methods: {
    selectQualification(e) {
      this.$emit("setQualification", e);
    },
  },
};
</script>

<style lang="scss">
@use "@/assets/css/lh-ds/core/functions" as *;
@use "@/assets/css/hodim/layout/data-list";

.treatment {
  .p-datatable {
    .p-datatable-thead > tr > th {
      background: #eee;
    }
  }
}
</style>
