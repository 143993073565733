import moment from "moment";
import { addMillisecondsNoFormat, getHoursBetweenHours, getMinsFromNow, getMinsBetweenDates} from "@/components/Deliveries/planner/helpers/time";
import { statusColors } from "./colors";
import { getTimeZone, getMomentInTimezone } from "@/helper/rocks-time";

class Tour {
  constructor(
    deliveryStarted = null,
    loadStarted = null,
    loadEnded = null,
    loadingLocationLeft = null,
    unloadingLocationEntered = null,
    unloadingStarted = null,
    unloadingEnded = null,
    deliveryAccepted = null,
    unloadingLocationLeft = null,
    loadingLocationEntered = null
  ) {
    this.deliveryStarted = deliveryStarted;
    this.loadStarted = loadStarted;
    this.loadEnded = loadEnded;
    this.loadingLocationLeft = loadingLocationLeft;
    this.unloadingLocationEntered = unloadingLocationEntered;
    this.unloadingStarted = unloadingStarted;
    this.unloadingEnded = unloadingEnded;
    this.deliveryAccepted = deliveryAccepted;
    this.unloadingLocationLeft = unloadingLocationLeft;
    this.loadingLocationEntered = loadingLocationEntered;
  }

  getLastTimeStamp() {
    const tourEvents = Object.keys(this);
    for (let index = (tourEvents.length-1); index > -1; index--) {
      const tourEvent = tourEvents[index];
      if (!(this[tourEvent] === null)) {
        return index;
      }
    }
    return 0;
  }

  getLastStatus() {
    const tourEvents = Object.keys(this);
    return tourEvents[this.getLastTimeStamp()];
  }
}

class DeliveryTour {
  constructor(actual, planned, customer) {
    this.actual = actual;
    this.planned = planned;
    this.customer = customer;
  }

  getDelayInMinutes() {
    const lastTimeStamp = this.actual.getLastTimeStamp();
    const lastStateIndex = lastTimeStamp - 1 // Quitamos el pending
    const times = Object.values(this.planned)
    if(lastStateIndex === -1) return getMinsFromNow(times[0])
    const delay = getMinsFromNow(times[lastStateIndex])
    return delay;
  }

  getActualvsPlannedDelay() {
    const lastTimeStamp = this.actual.getLastTimeStamp();
    const lastStateIndex = lastTimeStamp - 1
    const times = Object.values(this.planned)
    const actualTimes = Object.values(this.actual)
    if(actualTimes[0]){
      if(lastStateIndex === -1) return getMinsBetweenDates(times[0], actualTimes[0])
      const delay = getMinsBetweenDates(times[lastStateIndex], actualTimes[0])
      return delay;
    }
    else{
      return this.getDelayInMinutes();
    }
    
  }

  getActualvsCustomerDelay() {
    const lastTimeStamp = this.actual.getLastTimeStamp();
    const lastStateIndex = lastTimeStamp - 1
    const times = Object.values(this.customer)
    const actualTimes = Object.values(this.actual)
    if(actualTimes[0]){
      if(lastStateIndex === -1) return getMinsBetweenDates(times[0], actualTimes[0])
      const delay = getMinsBetweenDates(times[lastStateIndex], actualTimes[0])
      return delay;
    }
    else{
      return this.getDelayInMinutes();
    }
    
  }

  getStatus() {
    return this.actual.getLastStatus()
  }

  getColor() {
    return statusColors[this.actual.getLastStatus()]
  }

  getStartTime() {
    return this.planned.deliveryStarted
  }

  getEndTime() {
    return this.planned.loadingLocationEntered
  }

  getActualStartTime() {
    return this.actual.deliveryStarted
  }

  getActualEndTime() {
    return this.actual.loadingLocationEntered
  }

  get duration() {
    return getHoursBetweenHours(this.getStartTime(), this.getEndTime());
  }

  isCompleted() {
    for (const state of Object.values(this.actual)){
      if(!state) {
        return false
      }
    }
    return true;
  }

  addTime(delta) {
    const newDeliveryTour = new DeliveryTour(new Tour(), new Tour());
    newDeliveryTour.actual = {...this.actual};
    newDeliveryTour.planned = {...this.planned};
    try {
      this.addMilliseconds(newDeliveryTour.actual, delta.milliseconds);
      this.addMilliseconds(newDeliveryTour.planned, delta.milliseconds);
      return newDeliveryTour;
    } catch (error) {
      console.error(error);
    }
  }

  addMilliseconds(tour, milliseconds) {
    for (let key in tour) {
      const value = tour[key];
      tour[key] = addMillisecondsNoFormat(value, milliseconds)
    }
  }
}

const getETA = (tour) => {
  const { actual: actualTour, planned: plannedTour } = tour;
  const dates = {
    unloadingLocationEntered: actualTour.unloadingLocationEntered,
    loadingLocationLeft: actualTour.loadingLocationLeft,
    loadEnded: actualTour.loadEnded,
    loadStarted: actualTour.loadStarted,
    deliveryStarted: actualTour.deliveryStarted,
  }
  let plannedETAminutes;
  let lastDate;
  for (const [key, value] of Object.entries(dates)) {
    if(value) {
      plannedETAminutes = moment(plannedTour.unloadingLocationEntered).diff(moment(plannedTour[key]), 'minutes');
      lastDate = value;
      break;
    }
  }
  if(!lastDate) return null;
  return getMomentInTimezone(lastDate, getTimeZone()).add(plannedETAminutes, 'minutes').format('yyyy-MM-DDTHH:mm:ss.SSSZ');
}

export {
  Tour,
  DeliveryTour,
  getETA
}