'use strict';
import { app } from '@/main.js'
const TransitService = {
    saveTransit(transit) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post('/transit/createTransit', transit)
                .then(response => {
                    return resolve(response)
                }).catch(error => {
                    reject({
                        ret: error.response.status
                        , msg: error.response.data
                    });
                });
        })
    },
    releaseTransit(transit) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post('/transit/releaseTransict', transit)
                .then(response => {
                    return resolve(response)
                }).catch(error => {
                    reject({
                        ret: error.response.status
                        , msg: error.response.data
                    });
                });
        })
    },
    getTransits(data) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post('/transit/searchTransits', data)
                .then(response => {
                    return resolve(response)
                }).catch(error => {
                    reject({
                        ret: error.response.status
                        , msg: error.response.data
                    });
                });
        })
    },
    getTransit(id) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.get(`/transit/searchTransit/${id}`)
                .then(response => {
                    return resolve(response)
                }).catch(error => {
                    reject({
                        ret: error.response.status
                        , msg: error.response.data
                    });
                });
        })
    },
    getTimestamps(id) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.get(`/transit/getTransitTimestamps/${id}`)
                .then(response => {
                    return resolve(response)
                }).catch(error => {
                    reject({
                        ret: error.response.status
                        , msg: error.response.data
                    });
                });
        })
    },
    getTransitsFiltered(filters) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post('/transit/filterTransits', filters)
                .then(response => {
                    return resolve(response)
                }).catch(error => {
                    reject({
                        ret: error.response.status
                        , msg: error.response.data
                    });
                });
        })
    },
    searchTransit(searchTerm) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post("/transit/searchTransitByVehicle", searchTerm)
                .then(response => {
                    return resolve(response)
                }).catch(error => {
                    reject({
                        ret: error.response.status
                        , msg: error.response.data
                    });
                });
        })
    },
    createEmtyTransit(transit) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post('/transit/createEmtyTransit', transit)
                .then(response => {
                    return resolve(response)
                }).catch(error => {
                    reject({
                        ret: error
                        , msg: error
                    });
                });
        })
    }
}
    // getOrdersFiltered(filters) {
    //     return new Promise((resolve, reject) => {
    //         app.config.globalProperties.$axios.post('/transit/filterOrders', filters)
    //             .then(response => {
    //                 return resolve(response)
    //             }).catch(error => {
    //                 reject({
    //                     ret: error.response.status
    //                     , msg: error.response.data
    //                 });
    //             });
    //     })
    // },
    // searchOrderByNumber(searchTerm) {
    //     return new Promise((resolve, reject) => {
    //         app.config.globalProperties.$axios.post('/order/searchOrderByNumber', searchTerm)
    //             .then(response => {
    //                 return resolve(response)
    //             }).catch(error => {
    //                 reject({
    //                     ret: error.response.status
    //                     , msg: error.response.data
    //                 });
    //             });
    //     })
    // }

export default TransitService;