<template>
  <div class="filesList">
    <div class="columns mt-3 ml-3">
      <div class="column is-one-fifth">
        <FileUpload mode="basic" @uploader="onLoad" customUpload :auto="true" :multiple="true" :chooseLabel="$t('Choose')" />
      </div>
      <div class="column is-one-fifth">
        <lh-button size="large" slot="action" @click="clearFileList">{{ $t('CLEAR') }}</lh-button>
      </div>
      <div class="column is-one-fifth" v-if="uploadFiles">
        <lh-button size="large" slot="action" @click="onUpload">{{ $t('UPLOAD') }}</lh-button>
      </div>
    </div>

    <form id="drop-form" @drop.prevent="handleFileDrop" @dragover.prevent @dragenter.prevent>
      <div v-if="filesList.length == 0" class="filesListPanel p-5">
        <span>{{$t('Drag and drop files here')}}</span>
      </div>
      <div v-if="filesList.length > 0" class="filesListPanel pl-5 pr-5 pt-3">
        <div v-for="(item, index) in filesList" :key="index">
          <div class="fileListElement">
            <div class="columns my-1">
              <div :class="!hasCategories ? 'column ml-2 py-0' : 'column ml-2'">
                <span class="file-link" @click="downloadFile(item)"><b>{{ item.file ? item.file.name : item.filename }}</b></span><br>
                <span>{{ item.file ? formatBytes(item.file.size) : formatBytes(item.filesize) }}</span>
              </div>
              <div class="column" v-if="hasCategories">
                <FormControl :label="$t('Category')">
                  <Dropdown v-model="item.category" :options="filesCategories" @change="changeCategoryAttachments(item)" optionLabel="name" optionValue="value"
                    :placeholder="$t('Select A Category')" :disabled="item.disabled" />
                </FormControl>
              </div>
              <div class="column" v-if="hasCategories">
                <FormControl :label="$t('Comments')">
                  <Textarea v-model="item.comments" rows="2" cols="50" maxlength="200" autoResize />
                </FormControl>
              </div>
              <div :class="!hasCategories ? 'column mr-2 py-0' : 'column mr-2'">
                <i @click="removeElement(index)" :class="!hasCategories ? 'zmdi zmdi-close' : 'zmdi zmdi-close custom-i'"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script setup>
import JSZip from 'jszip';
import FileUpload from 'primevue/fileupload';
import Textarea from 'primevue/textarea';
import Dropdown from "primevue/dropdown";
import FormControl from "@/components/common/FormControl.vue";
import countriesService from "@/services/api/countries";
import s3Service from "@/services/api/s3";
import * as fileHelper from '@/helper/files';
</script>

<script>
export default {
  props: {
    hasCategories: {
      type: Boolean,
      default: true
    },
    maxFileSize: {
      type: Number,
      default: 1000000,
    },
    lovType: {
      type: String,
      required: true
    },
    attachmentsCategoryBlocked:{
      type: String,
      required: false
    },
    /* Prop that states if the upload button is displayed. If it's not displayed, the parent component can handle the files upload 
    using the updateFilesList event emitted by this component.
    If it's displayed, the files are uploaded to s3 but the parent component has to handle adding the files to the related object */
    uploadFiles: {
      type: Boolean,
      default: false
    },
    filesList: {
      type: Array,
      default: function () {
        return [];
      }
    },
    /* The object related to the attachments (order, delivery, shipto...). These props are only used in the upload method, so they
    will only be used if the upload button is visible */
    objectType: {
      type: String,
    },
    objectId: {},
    s3Prefix: {
      type: String,
    }
  },
  components: {
    FileUpload
  },
  emits: ['updateFilesList', 'uploadedFiles', 'deleteFile'],
  data: function () {
    return {
      dragAndDropCapable: false,
      filesCategories: []
    }
  },
  mounted() {
    this.dragAndDropCapable = this.determineDragAndDropCapable();
    if (this.dragAndDropCapable) { this.bindEvents() }
    this.getFilesCategories();
  },
  methods: {

    getFilesCategories() {
      countriesService.getCountryLovByName(this.lovType).then(response => {
        if (response.data.length > 0) {
          this.filesCategories = response.data.filter(lov => lov.active === '1');
        }
      }).catch(error => console.log(error));
    },
    
    onLoad(event) {
      for (let file of event.files) {
        if (file.size < this.maxFileSize) {
          this.filesList.push({ file: file, comments: '', category: '' });
        } else {
          this.$notify({
            title: "File Size Exceeded",
            text: `The uploaded file is too large. Please upload a file smaller than ${maxFileSize}B.`,
            type: "error",
            duration: -1,
            closeOnClick: true
          })
        }
      }
    },
    clearFileList() {
      this.filesList.splice(0, this.filesList.length);
    },
    removeElement(index) {
      this.$emit('deleteFile', index);
      this.filesList.splice(index, 1);
    },
    formatBytes(bytes, decimals = 2) {
      if (!+bytes) return '0 Bytes'

      const k = 1024
      const dm = decimals < 0 ? 0 : decimals
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

      const i = Math.floor(Math.log(bytes) / Math.log(k))
      return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
    },
    bindEvents() {
      let element = document.getElementById('drop-form');
      let events = ['drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop'];
      if (element != null && element != undefined) {
        events.forEach(function (evt) {
          element.addEventListener(evt, function (e) {
            e.preventDefault();
            e.stopPropagation();
          }.bind(this), false);
        }.bind(this));
      }
    },
    handleFileDrop(event) {
      for (let file of event.dataTransfer.files) {
        if (file.size < this.maxFileSize) {
          this.filesList.push({ file: file, comments: '', category: '' });
        } else {
          this.$notify({
            title: "File Size Exceeded",
            text: `The uploaded file is too large. Please upload a file smaller than ${maxFileSize}B.`,
            type: "error",
            duration: -1,
            closeOnClick: true
          })
        }
      }
    },
    determineDragAndDropCapable() {
      // Create a test element to see if events are present that let us do drag and drop.
      // Check to see if the `draggable` event is in the element or the `ondragstart` and `ondrop` events are in the element. 
      // If they are, then we have what we need for dragging and dropping files.
      let div = document.createElement('div');
      return (('draggable' in div) || ('ondragstart' in div && 'ondrop' in div));
    },
    async onUpload() {
      let loader = this.$loading.show();
      try {
        // A zip file is generated with all the files uploaded by the user
        const zip = new JSZip();
        for (let file of this.filesList) {
          zip.file(fileHelper.sanitizeFilename(file.file.name), file.file);
        }
        const zipFile = await zip.generateAsync({ type: 'base64' });

        // The zip file is split in parts so it can be sent to the server because the requests have a top limit of 100kb
        const numChars = 100000;
        const zipArray = fileHelper.splitFileInChunks(zipFile, numChars);

        // Each of the split parts is sent to the server individually
        if (!this.objectType || !this.objectId) {
          throw new Error("One or more of these props are missing: objectType, objectId");
        }
        const tempDir = [this.objectType, this.objectId];
        await Promise.all(zipArray.map((chunk, index) => s3Service.uploadAttachmentsChunk(tempDir, chunk, index)));

        // Once all the parts have been sent to the server, a request is sent to upload the complete zip file to S3
        const { data: filesUploaded } = await s3Service.uploadAttachments(tempDir, this.s3Prefix);
        this.$emit("uploadedFiles", filesUploaded);
        loader.hide();
      } catch (error) {
        loader.hide();
        this.$notify({
          title: this.$t('Uploading Error'),
          text: this.$t('The files could not be uploaded. Please, try again.'),
          type: 'error',
          duration: 3000
        });
      }
    },
    downloadFile(file){
      let loader = this.$loading.show();
      try {
        if(file.filepath) {
          s3Service.getFile(file.filepath).then((response) => {
            if(response.data) {
              const blob = new Blob([response.data], { type: 'application/octet-stream' });
              const url = URL.createObjectURL(blob);
              const link = document.createElement('a');
              link.href = url;
              link.download = file.filename;
              link.click();
              URL.revokeObjectURL(url); 
            }
            loader.hide();
          }).catch(err => {
            loader.hide();
            this.$notify({
              title: this.$t('Downloading Error'),
              text: this.$t('An error occurred when downloading the file. Please, try again.'),
              type: 'error',
            });
          });
        } else {
          const fileUrl = URL.createObjectURL(file.file);
          const link = document.createElement('a');
          link.href = fileUrl;
          link.download = file.file.name;
          link.click();
          URL.revokeObjectURL(fileUrl);
        }
      } catch (error) {
        this.$notify({
          title: this.$t('Downloading Error'),
          text: this.$t('An error occurred when downloading the file. Please, try again.'),
          type: 'error',
        });
      } finally {
        loader.hide();
      }
    },
    changeCategoryAttachments(selectedItem){
      console.log('changeCategoryAttachments >>>',selectedItem);
      let selectedItemCategory=selectedItem.category
      //Do not show DAP category option in the categories dropdown if there already exists one DAP attachment
      console.log('changeCategoryAttachments attachmentsCategoryBlocked >>>',this.attachmentsCategoryBlocked)
      if(this.attachmentsCategoryBlocked !=undefined && this.attachmentsCategoryBlocked !=null && this.attachmentsCategoryBlocked != ''){
          if(selectedItemCategory == this.attachmentsCategoryBlocked){
            this.$notify({
              title: this.$t('DAP file already attached'),
              text: this.$t('DAP file is already attached, please select a different category'),
              type: 'error',
            });
            selectedItem.category = ''
          }
      }
    }
  },
  watch: {
    filesList: {
      deep: true,
      handler(newVal, oldVal) {
        this.$emit("updateFilesList", newVal);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/lh-ds/core/functions';
@import '@/assets/css/hodim/layout/data-list';

.filesList {
  border: 1px solid #d8d8d8;
  border-radius: 6px;

  .is-one-fifth {
    max-width: 200px;
  }
}

.fileListElement {
  border: 1px solid #d8d8d8;
  border-radius: 6px;
  padding: 10px !important;
  margin-bottom: 1rem !important;

  .columns {
    align-items: center;

    .column:last-child {
      width: 10%;
      flex: none;
    }
  }

  .file-link {
    text-decoration: underline;
    cursor: pointer;
  }
}

i {
  float: right;
  font-size: x-large;
  cursor: pointer;
}

.custom-i {
  margin-top: 15px;
  margin-right: 10px !important;
}

i:hover {
  color: red;
}

.filesListPanel {
  border-top: 1px solid #d8d8d8;
}

input[type="file"] {
  display: none;
}
</style>