import commonClasses from "./commonClasses"

export default class Order {
    constructor(){
        this.orderType = commonClasses.orderTypes.Outbound
        this.salesArea = {}
        this.soldto = new soldto()
        this.shipto = new shipto()
        this.incoterm = ''
        this.contract = new contract()
        this.selectedDate = ''
        this.selectedTime = ''
        this.deliveryType = ''
        this.PONumber = ''
        this.Materials = []
        this.notes = []
        this.cash = {
            netPrice : 0,
            currency: '',
            value : 0,
            unitPrice : 0,
            isCash : false
        }
        this.vendor = new vendor()
        this.STPO = new STPO()
        this.purchaseOrg = {}

        //trading
    }
}

class contract{
    constructor(){
        this.contract = '',
        this.documentType = ''
    }
}

class shipto {
    constructor(){
        this.shipto = null,
        this.address = new address()
    }
}

class soldto {
    constructor(){
        this.name = '',
        this.customer = null,
        this.city = '',
        this.country = ''
        this.name = ''
        this.name1 = ''
        this.name2 = ''
        this.postalcode = ''
        this.street = ''
        this.deliveryBlock = ''
    }
}

class address {
    constructor(){
        this.city = '',
        this.country = '',
        this.name = '',
        this.street = '',
        this.transportationZone = null
    }
}

class vendor {
    constructor(){
        this.vendor = null,
        this.address = new address()
        this.location = new location()
        this.general
        {
            this.blockForQualityReasons = ''
            this.deletionFlag = ''
            this.purchasingBlock = ''      
        }
    }
}

class location {
    constructor(){
        this.longitude = ""
        this.latitude = ""
    }
}

class STPO{
    constructor(){
        this.documentNumber = '',
        this.purchaseOrderType = ''
    }
}

