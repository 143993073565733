"use strict";
import { app } from "@/main.js";

const DataSyncService = {
  sendAckToEdge(data) {
    return new Promise((resolve, reject) => {
      app.config.globalProperties.$axios
        .post("/dataSync/sendAck", data)
        .then((response) => {
          return resolve(response);
        })
        .catch((error) => {
          reject({
            ret: error.response.status,
            msg: error.response.data,
          });
        });
    });
  },
};

export default DataSyncService;
