<template>
  <Step :title="$t('Weighbridges')" class="weighbridges-step">

    <!-- INBOUND ENTRY WEIGHT -->
    <div class="columns">
      <div class="column">
        <FormControl :label="$t('Entry Weight')">
          <div class="weighbridges-step__input">
            <InputText :style="{
              width: '150px', height: '50px', 'color': manuallyWeightEntry > 0 ? '#e08600' : '#000',
              'border-color': manuallyWeightEntry > 0 ? '#e08600' : '#d8d8d8'}" id="Entry"
              :disabled="disabledEntryWeightInput"
              :value="currentEntryWeight.weight > 0 ? transformToUnit(currentEntryWeight.weight, 'KGM', 'TNE') : manuallyWeightEntry"
              @keyup.enter="setManuallyWeight($event, 'InputText')" />
            <div class="weighbridges-step__input-custom-tag">
              <p>{{ uom }}</p>
            </div>
            <i v-if="currentEntryWeight.weight <= 0 && permissions.weight.find(w => w.name_menu === 'Manual Weight').mode.includes('WR')"
              id="Entry" :style="{ 'margin-top': '16px' }" class="zmdi zmdi-edit" @click="allowWeightInput"></i>

          </div>
        </FormControl>
      </div>
      <div class="column" v-if="currentEntryWeight.weight > 0">
        <FormControl :label="$t('Weighbridge')">
          {{ currentEntryWeight.weightBridge }}
        </FormControl>
      </div>
      <div class="column" v-if="currentEntryWeight.weight > 0">
        <FormControl :label="$t('Date')">
          {{ moment(currentEntryWeight.date).tz(userTimezone).format(displayMomentFormat) }}
        </FormControl>
      </div>
      <div class="column" v-if="currentEntryWeight.weight > 0">
        <FormControl :label="$t('Time')">
          {{ moment(currentEntryWeight.date).tz(userTimezone).format("HH:mm:ss") }}
        </FormControl>
      </div>
      <div class="column" v-if="currentEntryWeight.weight > 0">
        <i id="Entry" :style="{ 'margin-top': '35px', 'margin-right': '7px' }" class="pi pi-trash"
          @click="clearWeightInputEntryWasteInbound">
        </i>
      </div>
    </div>

    <!-- EXIT WEIGHT -->
    <div class="columns" v-if="exitWeightNeeded && currentEntryWeight.weight > 0">
      <div class="column">
        <FormControl :label="$t('Exit Weight')">
          <div class="weighbridges-step__input">
            <InputText :style="{
              width: '150px', height: '50px', 'color': manuallyWeightExit > 0 ? '#e08600' : '#000',
              'border-color': manuallyWeightExit > 0 ? '#e08600' : '#d8d8d8'
            }" id="Exit"
              :disabled="disabledExitWeightInput"
              :value="currentExitWeight.weight > 0 ? transformToUnit(currentExitWeight.weight, 'KGM', 'TNE') : manuallyWeightExit"
              @keyup.enter="setManuallyWeight($event, 'InputText')" />
            <div class="weighbridges-step__input-custom-tag">
              <p>{{ uom }}</p>
            </div>
            <i v-if="currentExitWeight.weight <= 0 && permissions.weight.find(w => w.name_menu === 'Manual Weight').mode.includes('WR')"
              id="Exit" :style="{ 'margin-top': '16px' }" class="zmdi zmdi-edit" @click="allowWeightInput"></i>

          </div>
        </FormControl>
      </div>
      <div class="column" v-if="currentExitWeight.weight > 0">
        <FormControl :label="$t('Weighbridge')">
          {{ currentExitWeight.weightBridge }}
        </FormControl>
      </div>
      <div class="column" v-if="currentExitWeight.weight > 0">
        <FormControl :label="$t('Date')">
          {{ moment(currentExitWeight.date).tz(userTimezone).format(displayMomentFormat) }}
        </FormControl>
      </div>
      <div class="column" v-if="currentExitWeight.weight > 0">
        <FormControl :label="$t('Time')">
          {{ moment(currentExitWeight.date).tz(userTimezone).format("HH:mm:ss") }}
        </FormControl>
      </div>
      <div class="column" v-if="currentExitWeight.weight > 0">
        <i id="Exit" :style="{ 'margin-top': '35px', 'margin-right': '7px' }" class="pi pi-trash"
          @click="clearWeightInput">
        </i>
      </div>
    </div>

    <!-- TARE WEIGHT -->
    <div class="columns" v-if="currentEntryWeight.weight > 0">
      <div class="column is-one-fifth">
        <FormControl :label="$t('Tare Weight')">
          <div class="weighbridges-step__input">
            <InputText :style="{ width: '150px', height: '50px', 'color': '#000', 'border-color': '#d8d8d8' }" id="Tare"
              :disabled="true" :value="tare || 0" />
            <div class="weighbridges-step__input-custom-tag">
              <p>{{ uom }}</p>
            </div>
          </div>
        </FormControl>
      </div>
    </div>

    <!-- NET WEIGHT -->
    <div class="columns" v-if="currentNetWeight">
      <div class="column">
        <FormControl :label="$t('Net Weight')">
          <div class="weighbridges-step__input">
            <InputText :style="{
              width: '150px', height: '50px', 'color': '#000',
              'border-color': '#d8d8d8'
            }" id="Net" :disabled="true"
              :value="currentNetWeight ? currentNetWeight : 0" />
            <div class="weighbridges-step__input-custom-tag">
              <p>{{ uom }}</p>
            </div>
          </div>
        </FormControl>
      </div>
    </div>

    <!-- WEIGHBRIDGES -->
    <div v-if="!currentNetWeight" class="columns">
      <div class="column" v-for="wb in weighbridges">
        <Weighbridge @setWeight="setWeight" :weighbridge="wb" />
      </div>
    </div>

    <!-- ALERT -->
    <div v-if="disabledEntryWeightInput === false || disabledExitWeightInput === false">
      <p class="alert__text">{{ $t('Setting the weight manually will be registered!') }}</p>
    </div>
  </Step>
</template>

<script setup>
import moment from 'moment';

import InputText from 'primevue/inputtext';
import Checkbox from 'primevue/checkbox';
import Step from "@/components/dispatch/order/utils/step";
import FormControl from "../../common/FormControl";
import Weighbridge from '@/components/Deliveries/weighbridges/weighbridge.vue';

import weighbridgesService from "@/services/api/weighbridges.js";
import vehicleService from "@/services/api/vehicles";
import { transformToUnit } from '../../../helper/weightUnit';
</script>

<script>
export default {
  components: {
    InputText,
    Checkbox,
    Step,
    FormControl
  },
  data: function () {
    return {
      user: JSON.parse(sessionStorage.getItem('user')),
      permissions: JSON.parse(sessionStorage.getItem('user')).menu,
      userTimezone: JSON.parse(sessionStorage.getItem('user')).country.timezone,
      displayMomentFormat: JSON.parse(localStorage.getItem("dateFormat")) ? JSON.parse(localStorage.getItem("dateFormat")).moment : 'DD/MM/YYYY',
      weighbridges: [],
      disabledEntryWeightInput: true,
      manuallyWeightEntry: 0,
      disabledExitWeightInput: true,
      manuallyWeightExit: 0,
      uom: 'TNE'
    }
  },
  computed: {
    delivery() {
      return this.$store.state.dispatch.delivery;
    },
    orderType() {
      return this.$store.state.dispatch.orderType;
    },
    tare() {
      return this.$store.state.dispatch.tare;
    },
    tareExpired() {
      return this.$store.state.dispatch.tareExpired;
    },
    exitWeightNeeded() {
      return this.$store.state.dispatch.exitWeightNeeded;
    },
    currentEntryWeight() {
      return this.$store.state.dispatch.entryWeight;
    },
    currentExitWeight() {
      return this.$store.state.dispatch.exitWeight;
    },
    currentNetWeight() {
      return this.$store.state.dispatch.netWeight;
    },
    currentTruck() {
      return this.$store.state.dispatch.currentTruck;
    }
  },
  created() {
    if (this.user.terminal !== undefined && this.user.terminal !== null) {
      this.getWeighbridges(this.user.terminal.id, this.user.plant.id);
    }

    if (this.delivery && this.delivery._source && this.delivery._source.hasOwnProperty('exitWeightNeeded')) {
      this.$store.commit('dispatch/setExitWeightNeeded', this.delivery._source.exitWeightNeeded === 'true')
    } else if (!this.tare || this.tareExpired) {
      this.$store.commit("dispatch/setExitWeightNeeded", true);
    }

    if (this.delivery._source.firstWeight.weight > 0) {
      this.$store.commit('dispatch/setEntryWeight', this.delivery._source.firstWeight)
    }
  },
  methods: {
    getWeighbridges(terminalId, plantId) {
      weighbridgesService.getWeighbridgeByTerminalAndPlant(terminalId, plantId).then((response) => {
        if (response.data.length > 0) { this.weighbridges = response.data }
      }).catch(error => {
        console.error(error);
      })
    },
    editVehicleTare(vehicleId, tare, minLoad) {
      vehicleService.editVehicleTare({ vehicleId, tare, minLoad }).catch(error => console.error('ERROR EDITING VEHICLE -->', error));
    },
    async setWeight(wb) {
      if (wb) {
        if (this.currentEntryWeight.weight === null || this.currentEntryWeight.weight === 0) {
          if (await this.validateWeight(wb.weight)) {
            this.disabledEntryWeightInput = true;
            const newEntryWeight = {
              date: moment().tz(this.userTimezone).format('yyyy-MM-DDTHH:mm:ss.SSSZ'),
              weight: transformToUnit(wb.weight, wb.uom, 'KGM'),
              weightBridge: wb.wb.weighbridge,
              manual: false,
              user: {
                userID: this.user.id_user,
                userName: this.user.fullname
              },
            }
            this.$store.commit("dispatch/setEntryWeight", newEntryWeight);
            if (!this.exitWeightNeeded) {
              let netWeight = transformToUnit(this.currentEntryWeight.weight, 'KGM', 'TNE') - this.tare;
              this.$store.commit("dispatch/setNetWeight", netWeight);
            }
          }
        } else if (this.currentExitWeight.weight === null || this.currentExitWeight.weight === 0) {
          if (await this.validateWeight(wb.weight)) {
            this.disabledExitWeightInput = true;
            const newExitWeight = {
              date: moment().tz(this.userTimezone).format('yyyy-MM-DDTHH:mm:ss.SSSZ'),
              weight: transformToUnit(wb.weight, wb.uom, 'KGM'),
              weightBridge: wb.wb.weighbridge,
              manual: false,
              user: {
                userID: this.user.id_user,
                userName: this.user.fullname
              },
            }
            this.$store.commit("dispatch/setExitWeight", newExitWeight);
            let netWeight = transformToUnit(this.currentEntryWeight.weight, 'KGM', 'TNE') - transformToUnit(this.currentExitWeight.weight, 'KGM', 'TNE');
            this.$store.commit("dispatch/setNetWeight", netWeight);
            this.$store.commit("dispatch/setTare", transformToUnit(wb.weight, wb.uom, 'TNE'));
            const minLoad = Math.max(this.currentTruck.mwa - this.tare, 0);
            this.editVehicleTare(this.currentTruck.vehicleId, this.tare, minLoad);
          }
        }
      }
    },
    allowWeightInput(e) {
      if (e.target['id'] === 'Entry') { this.disabledEntryWeightInput = false }
      if (e.target['id'] === 'Exit') { this.disabledExitWeightInput = false }
    },
    async setManuallyWeight(e) {
      if (await this.validateWeight(e.target['value'])) {
         if (e.target['id'] === 'Entry') {
          // console.log(e.target['id']);
          this.manuallyWeightEntry = e.target['value'];
          this.disabledEntryWeightInput = true;
          const newEntryWeight = {
            date: moment().tz(this.userTimezone).format('yyyy-MM-DDTHH:mm:ss.SSSZ'),
            weight: transformToUnit(this.manuallyWeightEntry, 'TNE', 'KGM'),
            weightBridge: this.user.fullname,
            manual: true,
            user: {
              userID: this.user.id_user,
              userName: this.user.fullname
            },
          }
          this.$store.commit("dispatch/setEntryWeight", newEntryWeight);
          // if (!this.exitWeightNeeded) {
          //   let netWeight = transformToUnit(this.currentEntryWeight.weight, 'KGM', 'TNE') - this.tare
          //   this.$store.commit("dispatch/setNetWeight", netWeight);
          // }
        } else if (e.target['id'] === 'Exit') {
          this.manuallyWeightExit = e.target['value'];
          this.disabledExitWeightInput = true;
          const newExitWeight = {
            date: moment().tz(this.userTimezone).format('yyyy-MM-DDTHH:mm:ss.SSSZ'),
            weight: transformToUnit(this.manuallyWeightExit, 'TNE', 'KGM'),
            weightBridge: this.user.fullname,
            manual: true,
            user: {
              userID: this.user.id_user,
              userName: this.user.fullname
            },
          }
          this.$store.commit("dispatch/setExitWeight", newExitWeight);
          let netWeight = transformToUnit(this.currentEntryWeight.weight, 'KGM', 'TNE') - transformToUnit(this.currentExitWeight.weight, 'KGM', 'TNE');
          this.$store.commit("dispatch/setNetWeight", netWeight);
          this.$store.commit("dispatch/setTare", this.manuallyWeightExit);
          const minLoad = Math.max(this.currentTruck.mwa - this.tare, 0);
          this.editVehicleTare(this.currentTruck.vehicleId, this.tare, minLoad);
        }
      } else {
        this.clearWeightInput({ target: { id: e.target['id'] } });
      }
    },
    clearWeightInputEntryWasteInbound() {
      // ESTE CODIGO ES TEMPORAL, HAY QUE CAMBIAR EN LA EXPRESS ORDER QUE SE PUEDAN CREAR W_IN SIN EL FIRST WEIGHT
      this.manuallyWeightEntry = 0;
      this.$store.commit("dispatch/resetEntryWeight");
    },
    clearWeightInput(e) {
        this.manuallyWeightExit = 0;
        this.disabledExitWeightInput = true;
        this.$store.commit("dispatch/resetExitWeight");
        this.$store.commit("dispatch/resetNetWeight");
    },
    async validateWeight(weight) {  
      if (this.isValidNumber(weight)) {
        return true;
      } else {
        return false;
      }
    },
    isValidNumber(weight) {
      if (isNaN(Number(weight))) {
        this.$notify({
          title: this.$t('Error'),
          text: this.$t('Weight should be a number'),
          type: 'error',
        })
        return false;
      }
      if (Number(weight) < 0) {
        this.$notify({
          title: this.$t('Error'),
          text: this.$t('Weight should be higher than 0'),
          type: 'error',
        })
        return false;
      }
      return true;
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/hodim/index.scss';
@import '@/assets/css/lh-ds/core/functions';

.weighbridges-step {
  &.overlay::before {
    content: '';
    position: absolute;
    inset: 0;
    z-index: 1;
    background-color: #fff;
    opacity: 0.5;
  }

  &__horizontal-form-control {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    gap: 1rem;
    margin-bottom: 1.5rem;
  }

  &__input {
    display: flex;

    &-custom-tag {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50px;
      background-color: rgb(216, 216, 216);
      margin: 0px !important;
      font-size: 0.875rem;
    }

    i {
      margin-left: 1.2rem;
    }
  }

  .alert__text {
    color: red;
    margin-bottom: 10px;
  }
}
</style>