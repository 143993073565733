<script setup>
</script>
<script>
export default {

}
</script>
<template>
    <svg  style="width: 50px; height: 50px;" preserveAspectRatio="none" viewBox="0 0 100 100" x="0px" y="0px">
        <path  d="M57,25H54a3,3,0,0,0-2.8,1.935c-3.013-.406-8.648-2.86-13.569-5.928l3.623-3.622a2.473,2.473,0,0,0,.006-3.5L37.127,9.747a2.511,2.511,0,0,0-3.506-.012L28.55,14.8a9.043,9.043,0,0,0-10.463,2.288L4.978,32.082a7.024,7.024,0,0,0-1.152,7.439l-1.3,1.3a3.181,3.181,0,0,0-.854,1.5L.051,48.775a1.824,1.824,0,0,0,2.186,2.171l6.419-1.614c.1-.023.2-.057.292-.089A4.456,4.456,0,0,0,16,49.294a4.443,4.443,0,0,0,7,0,4.465,4.465,0,0,0,7.938-2.186A15.013,15.013,0,0,0,39,49c5.6,0,8.791-1.268,9.881-1.8a5.551,5.551,0,0,1,2.3-.2A3,3,0,0,0,54,49h3a3,3,0,0,0,3-3V28A3,3,0,0,0,57,25ZM19.815,36H17a5.006,5.006,0,0,0-5,5,4.835,4.835,0,0,0,.575,2.241L9.018,46.8,4.2,41.977,31,15.181,35.818,20ZM35.047,11.138a.451.451,0,0,1,.654.011l4.161,4.162a.452.452,0,0,1-.011.654l-2.619,2.619-4.816-4.817ZM6.483,33.4,19.591,18.411A7.043,7.043,0,0,1,27,16.357L5.391,37.956A5.04,5.04,0,0,1,6.483,33.4ZM2.075,48.925,3.33,43.937l3.732,3.734ZM12.5,49a2.489,2.489,0,0,1-1.93-.926l3.266-3.266a5.089,5.089,0,0,0,1.164.75V46.5A2.5,2.5,0,0,1,12.5,49Zm7,0A2.5,2.5,0,0,1,17,46.5V46h5v.5A2.5,2.5,0,0,1,19.5,49Zm7,0A2.5,2.5,0,0,1,24,46.5V46s4.92.011,5,.02v.48A2.5,2.5,0,0,1,26.5,49Zm21.52-3.607C47.035,45.876,44.186,47,39,47c-4.573,0-6.787-1.31-7.712-2.1A4.011,4.011,0,0,0,28.76,44H17a3.079,3.079,0,0,1-3-3,3,3,0,0,1,3-3H30a13.134,13.134,0,0,0,5.447-1.1,1,1,0,0,0-.891-1.791A11.325,11.325,0,0,1,30,36H22.644L36.189,22.455c4.744,3,11.077,5.971,14.811,6.465V45A6.737,6.737,0,0,0,48.02,45.393ZM58,46a1,1,0,0,1-1,1H54a1,1,0,0,1-1-1V28a1,1,0,0,1,1-1h3a1,1,0,0,1,1,1Z"/>
        
    </svg>
</template>