'use strict';
const config = require('../../../config');
import { app } from '@/main.js'

const PriceService = {
  getCashSalePrice(data) {
    return new Promise((resolve, reject) => {
      app.config.globalProperties.$axios.post('/integration/sap/getDeliveryCashSalePrice', data)
        .then(response => {
          return resolve(response)
        }).catch(error => {
          reject(error);
        });
    })
  },

  getAllCashSalePrices(data) {
    return new Promise((resolve, reject) => {
      app.config.globalProperties.$axios.post('/integration/sap/getAllDeliveryCashSalePrices', data)
        .then(response => {
          return resolve(response)
        }).catch(error => {
          reject(error);
        });
    })
  },

  getOrderCummulativePrice(data) {
    return new Promise((resolve, reject) => {
      app.config.globalProperties.$axios.post('/integration/sap/getOrderCummulativePrice', data)
        .then(response => {
          return resolve(response)
        }).catch(error => {
          reject(error);
        });
    })
  }
}
export default PriceService;