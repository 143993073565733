'use strict';
import { app } from '@/main.js';
const DriversService = {
    getDrivers() {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.get("/masterData/drivers").then((response) => {
                return resolve(response);
            }).catch(error => { reject({ ret: error.response.status, msg: error.response.data }); });
        })
    },
    getDriver(id) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.get(`/masterData/driver/${id}`).then((response) => {
                return resolve(response);
            }).catch(error => { reject({ ret: error.response.status, msg: error.response.data }); });
        })
    },
    searchDriver(term) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post("/masterData/searchDriver", { searchTerm: term }).then((response) => {
                return resolve(response);
            }).catch(error => { reject({ ret: error.response.status, msg: error.response.data }); });
        })
    },
    //Mysql
    getDriversMysql(limit) {
        return new Promise((resolve, reject) => {
            const url = limit ? `/masterData/getAllDrivers/${limit}` : "/masterData/getAllDrivers";

            app.config.globalProperties.$axios.get(url).then((response) => {
                return resolve(response);
            }).catch(error => { reject({ ret: error.response.status, msg: error.response.data }); });
        })
    },
    getDriverByIdMysql(id) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.get(`/masterData/getDriverById/${id}`).then((response) => {
                return resolve(response);
            }).catch(error => { reject({ ret: error.response.status, msg: error.response.data }); });
        })
    },
    getDriverByInternalId(id) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.get(`/masterData/getDriverByInternalId/${id}`).then((response) => {
                return resolve(response);
            }).catch(error => { reject({ ret: error.response.status, msg: error.response.data }); });
        })
    },
    searchDriversMysql(searchTerm) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post("/masterData/findDrivers", { search: searchTerm }).then((response) => {
                return resolve(response);
            }).catch(error => { reject({ ret: error.response.status, msg: error.response.data }); });
        })
    },
    async createDriver(driver) {
        return new Promise((resolve, reject) => {
            //driver.cluster = driver.cluster.id;
            app.config.globalProperties.$axios.post("/masterData/createDriver", driver).then((response) => {
                return resolve(response);
            }).catch(error => { reject({ ret: error.response.status, msg: error.response.data }); });
        })
    },
    uploadDriverImage(image, MPQCId, plant, imageName, contentType) {
        return new Promise((resolve, reject) => {
            console.log('DATA? ',  {img: image, MPQCId, plant, imageName, contentType})
            app.config.globalProperties.$axios.post(`/integration/s3/uploadDriverImage`, 
                {img: image, MPQCId, plant, imageName, contentType})
                .then(response => {
                    return resolve(response)
                }).catch(error => {
                    reject({
                        ret: error.response.status
                        , msg: error.response.data
                    });
                });
        })
    },
    updateDriver(driver) {
        return new Promise((resolve, reject) => {
            //driver.cluster = driver.cluster.id;
            app.config.globalProperties.$axios.put("/masterData/updateDriver", driver).then((response) => {
                return resolve(response);
            }).catch(error => { reject({ ret: error.response.status, msg: error.response.data }); });
        })
    },
    deleteDriver(id) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.delete(`/masterData/deleteDriver/${id}`).then((response) => {
                return resolve(response);
            }).catch(error => { reject({ ret: error.response.status, msg: error.response.data }); });
        })
    },
    getDriverImage(imageUrl) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post(`/integration/s3/getDriverImage`, {imageUrl}).then((response) => {
                return resolve(response);
            }).catch(error => { reject({ ret: error.response.status, msg: error.response.data }); });
        })
    },
}

export default DriversService;