<script setup>
import moment from 'moment';
</script>
<template>
    <div>
        <div class="" ref="formContainer">
            <div class="data-list__table row" style="padding: 1rem;">
                <div class="columns">
                    <div class="column">
                        <Timeline :value="historyLog">
                            <template #marker="slotProps">
                            <span class="flex w-2rem h-2rem align-items-center justify-content-center text-white border-circle z-1 shadow-1" style="border: solid #e2e8f0">
                                <i :class="slotProps.item.icon"></i>
                            </span>
                        </template>
                            <template #opposite="slotProps">
                                <small class="p-text-seconday">{{moment(slotProps.item._source['@timestamp']).format(displayDateTimeFormat)}}</small>
                            </template>
                            <template #content="slotProps">
                                <div class="p-mb-2">
                                    <span class="p-tag p-tag-rounded p-tag-success">{{
                                        slotProps.item._source.event

                                    }}</span>
                                </div>
                                <div class="p-mb-2">
                                    <span class="p-tag p-tag-rounded p-tag-info">{{
                                        slotProps.item._source.user
                                    }}</span>
                                </div>
                                <div class="p-mb-2">
                                    <span class="p-tag p-tag-rounded p-tag-info">
                                        <Button class="p-button btn btn--secondary" style="width: 9rem;"> Compare </Button>
                                    </span>
                                </div>
                            </template>
                        </Timeline>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Timeline from 'primevue/timeline';
import Button from 'primevue/button';

import LogService from "@/services/api/historyLog.js";
export default {
    name: 'DeliveryLog',
    components: {
        Column,
        DataTable,
        Timeline,
        Button
    },
    props: {
        delivery: {
            type: Object,
            required: false
        },
        order: {
            type: Object,
            required: false
        }
    },
    data() {
        return {
            historyLog: null,
            displayDateTimeFormat: JSON.parse(localStorage.getItem("dateFormat")) ? JSON.parse(localStorage.getItem("dateFormat")).moment + " HH:mm" : 'DD/MM/YYYY HH:mm',
        }
    },
    mounted() {
        if(this.delivery != undefined){
            this.getDeliveryLog(this.delivery._id);
        }
        else{
            this.getOrderLog(this.order._id);
        }
    },
    methods: {
        getIcon(event) {
            if(event == "USER_EVENT")
                return "zmdi zmdi-account";
            if(event == "E1_EVENT")
                return "zmdi zmdi-settings";
            if(event == "SYSTEM_EVENT")
                return "zmdi zmdi-settings";
        },
        getDeliveryLog(deliveryId) {
            let loader = this.$loading.show({
                container : this.$refs.formContainer,
            })
            LogService.getDeliveryLog(deliveryId)
                .then(response => {
                    let { data } = response;
                    data = data.map(item => {
                        item.icon = this.getIcon(item._source.type);
                        return item;
                    })
                    this.historyLog = data;
                    loader.hide()
                })
                .catch(error => {
                    console.error(error);
                    loader.hide()
                });
        },
        getOrderLog(orderId) {
            let loader = this.$loading.show({
                container : this.$refs.formContainer,
            })
            LogService.getOrderLog(orderId)
                .then(response => {
                    let { data } = response;
                    data = data.map(item => {
                        item.icon = this.getIcon(item._source.type);
                        return item;
                    })
                    this.historyLog = data;
                    loader.hide()
                })
                .catch(error => {
                    console.error(error);
                    loader.hide()
                });
        }
    }
}
</script>

<style>
.flex {
    display: flex !important;
}
.align-items-center {
    align-items: center !important;
}
.justify-content-center {
    justify-content: center !important;
}
.h-2rem {
    height: 2rem !important;
}
.w-2rem {
    width: 2rem !important;
}
.border-circle {
    border-radius: 50% !important;
}
.p-timeline .p-timeline-event-marker {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.p-timeline .p-timeline-event-marker {
    border: 2px solid #e2e8f0;
    border-radius: 50%;
    width: 1.125rem;
    height: 1.125rem;
    background-color: #ffffff;
}
.p-timeline .p-timeline-event-marker::before {
    content: " ";
    border-radius: 50%;
    width: 0.375rem;
    height: 0.375rem;
    background-color: #404040;
}
.p-timeline .p-timeline-event-marker::after {
    content: " ";
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    box-shadow: 0px 0.5px 0px 0px rgba(0, 0, 0, 0.06), 0px 1px 1px 0px rgba(0, 0, 0, 0.12);
}
.p-timeline.p-timeline-vertical .p-timeline-event-connector {
    width: 2px;
}
.p-timeline .p-timeline-event-connector {
    background-color: #e2e8f0;
}
</style>
