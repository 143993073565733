const delayColors = {
  Red: "#ff2000",
  Yellow: "#ffb800",
};

// todo: define colors for statuses
const statusColors = {
  pending: "#EEEEEE",
  ghost: "#777777",
  deliveryStarted: "#B4C8E6",
  loadStarted: "#006EBE",
  loadEnded: "#325596",
  loadingLocationLeft: "#6E32A0",
  unloadingLocationEntered: "#FFFF00",
  unloadingStarted: "#FFD764",
  unloadingEnded: "#FFD764",
  deliveryAccepted: "#00AF50",
  unloadingLocationLeft: "#9B55CD",
  loadingLocationEntered: "#B4C8E6",
};

export { delayColors, statusColors };
