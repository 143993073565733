<template>
  <Step :title="$t('Audit data')" class="audit-step" >
    <div class="columns">
      <div class="column">
        <FormControl :label="$t('Created by')">
          {{ delivery ? delivery._source.auditData.createdBy : '-' }}
        </FormControl>
      </div>
      <div class="column">
        <FormControl :label="$t('Created on')">
          {{ delivery ? moment(delivery._source.auditData.createdDate).format(displayMomentFormat) : '-' }}
        </FormControl>
      </div>
      <div class="column">
        <FormControl :label="$t('Last change by')">
          {{ delivery ? delivery._source.auditData.lastModify : '-' }}
        </FormControl>
      </div>
      <div class="column">
        <FormControl :label="$t('Last change on')">
          {{ delivery ? moment(delivery._source.auditData.lastModifyDate).format(displayMomentFormat) : '-' }}
        </FormControl>
      </div>
      <div class="column">
        <FormControl :label="$t('Rocks Id')">
          <p @click="copyToClipboard(delivery ? delivery._id : '', 'Rocks Id')">{{ delivery ? delivery._id : '-' }}</p>
        </FormControl>
      </div>
      <div class="column">
        <FormControl :label="$t('Delivery NO')">
          <p @click="copyToClipboard(delivery ? delivery._source.deliveryNO : '', 'Delivery NO')">{{ delivery ? delivery._source.deliveryNO : '-' }}</p>
        </FormControl>
      </div>
    </div>
  </Step>
</template>

<script setup>
import moment from 'moment';

import Step from "@/components/dispatch/order/utils/step";
import FormControl from "../../common/FormControl";
</script>

<script>
export default {
  components: {
    Step,
    FormControl
  },
  data: function () {
    return {
      displayMomentFormat: JSON.parse(localStorage.getItem("dateFormat")) ? JSON.parse(localStorage.getItem("dateFormat")).moment : 'DD/MM/YYYY',
    }
  },
  computed: {
    delivery() {
      return this.$store.state.dispatch.delivery;
		}
  },
  methods: {
    copyToClipboard(textToCopy, fieldName) {
      console.log ("OCPU!!")
      if(textToCopy && textToCopy != 'NOT AVAILABLE'){
        navigator.clipboard.writeText(textToCopy);
        this.$notify({
          title: fieldName + ' ' + this.$t("copied to clipboard"),
          type: "success"
        })
      }
    },
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/hodim/index.scss';
@import '@/assets/css/lh-ds/core/functions';

.audit-step {
  &.overlay::before {
    content: '';
    position: absolute;
    inset: 0;
    z-index: 1;
    background-color: #fff;
    opacity: 0.5;
  } 
}
</style>