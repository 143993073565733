<template>
  <div class="m-6">
   <div><strong>{{ $t("DAP") }}:</strong> {{dapExtraInfoData.dap}}</div>
    <div><strong>{{ $t("Arrival Date") }}:</strong> {{dapExtraInfoData.dap_date_from}}</div>
    <div><strong>{{ $t("Expiration Date") }}:</strong>  {{dapExtraInfoData.dap_date_to}}</div>
    <div><strong>{{ $t("DAP Tonnage") }}:</strong> {{dapExtraInfoData.dap_quantity_percentage}} {{ $t("%") }}</div>
    <div><strong>{{ $t("Tonnage") }}:</strong>  {{dapExtraInfoData.dap_quantity}} {{dapExtraInfoData.dap_uom}}</div>
    <div><strong>{{ $t("Tonnage accumulated") }}: </strong> {{dapExtraInfoData.dap_quantity_acum}} {{dapExtraInfoData.dap_uom}}</div>
  </div>
</template>

<script setup>
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import WasteService from "@/services/api/waste";
import CountriesService from "@/services/api/countries";
</script>

<script>
export default {
  name: "DapExtraDataModal",
  props:["dapExtraInfoData"],
  components: {
    DataTable,
    Column,
    Button,
    Dialog,
  },
  data: function () {
    return {
      treatments: [],
      loading: true,
    };
  },
  mounted() {
    this.loading=false;
  },
  methods: {
    selectTreatment(e) {
      this.$emit("setTreatment", e);
    },
  },
};
</script>

<style lang="scss" scoped>
@use "@/assets/css/lh-ds/core/functions" as *;
@use "@/assets/css/hodim/layout/data-list";

</style>
