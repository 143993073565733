import { app } from '@/main.js'

const RegionsService = {
    getRegions(country){
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.get(`/masterdata/getRegions/${country}`)
                .then(response => {
                    // console.log("respuesta");
                    // console.log(response)
                    return resolve(response)
                })
                .catch(error => { reject({ ret: error.response.status, msg: error.response.data }); });
        });
    },
    getRegion(region, country){
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.get(`/masterdata/getRegion/${region}/${country}`)
                .then(response => {
                    // console.log("respuesta");
                    // console.log(response)
                    return resolve(response)
                })
                .catch(error => { reject({ ret: error.response.status, msg: error.response.data }); });
        });
    },
    getPlantsRegion(region){
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.get(`/masterdata/getPlantsRegion/${region}`)
                .then(response => {
                    // console.log("respuesta");
                    // console.log(response)
                    return resolve(response)
                })
                .catch(error => { reject({ ret: error.response.status, msg: error.response.data }); });
        });
    },
    getPlantsRegionAvailable(country){
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.get(`/masterdata/getPlantsRegionAvailable/${country}`)
                .then(response => {
                    // console.log("respuesta");
                    // console.log(response)
                    return resolve(response)
                })
                .catch(error => { reject({ ret: error.response.status, msg: error.response.data }); });
        });
    },
    createRegion(region){
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post(`/masterdata/createRegion`, region)
                .then(response => {
                    // console.log("respuesta");
                    // console.log(response)
                    return resolve(response)
                })
                .catch(error => { reject({ ret: error.response.status, msg: error.response.data }); });
        });
    },
    editRegion(region){
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.put(`/masterdata/editRegion`, region)
                .then(response => {
                    // console.log("respuesta");
                    // console.log(response)
                    return resolve(response)
                })
                .catch(error => { reject({ ret: error.response.status, msg: error.response.data }); });
        });
    },
    getClustersFromPlants(plants){
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post("/masterData/regionsByPlants", { plants }).then((response) => {
                return resolve(response);
            }).catch(error => { reject({ ret: error.response.status, msg: error.response.data }); });
        })
    }
}

export default RegionsService;