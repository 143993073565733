<template>
  <div class="treatment m-6">
    <DataTable
      :value="treatments"
      scrollDirection="horizontal"
      :loading="loading"
      :rows="7"
    >
      <Column :header="$t('Code')"> 
        <template #body="slotProps">
          {{ $t(slotProps.data.value) }}
        </template>
      </Column>

      <Column :header="$t('Name')"> 
        <template #body="slotProps">
          {{ $t(slotProps.data.name) }}
        </template>
      </Column>

      <Column :header="$t('Select')" :style="{ width: '10%' }" class="data-list__column-button">
        <template #body="slotProps">
          <Button
            class="p-button btn"
            icon="zmdi zmdi-arrow-right zmdi-hc-lg"
            @click="selectTreatment(slotProps)"
          />
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script setup>
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import WasteService from "@/services/api/waste";
import CountriesService from "@/services/api/countries";
</script>

<script>
export default {
  name: "TreatmentModal",
  props: ['treatments'],
  components: {
    DataTable,
    Column,
    Button,
    Dialog,
  },
  data: function () {
    return {
      treatments: [],
      loading: true,
    };
  },
  mounted() {
    this.loading=false;
  },
  methods: {
    selectTreatment(e) {
      this.$emit("setTreatment", e);
    },
  },
};
</script>

<style lang="scss">
@use "@/assets/css/lh-ds/core/functions" as *;
@use "@/assets/css/hodim/layout/data-list";

.treatment {
  .p-datatable {
    .p-datatable-thead > tr > th {
      background: #eee;
    }
  }
}
</style>
