<template>
  <div class="destination-available m-6">
    <DataTable
      :value="destinations"
      scrollDirection="horizontal"
      :loading="loading"
      :rows="7"
    >
      <Column :header="$t('Open')">
        <template #body="slotProps">
          <div :style="slotProps.data.allowed_open_check == 'N' ? 'red': 'black'">
            <Checkbox v-model="slotProps.data.isChecked" binary disabled/>
            <span>
              <i v-if="slotProps.data.allowed_open_check == 'N'" class="zmdi zmdi-info pl-2 mt-1" style="color:red; font-size:x-large" @click="openInfoDetailsModal('waste_open_check')" ></i>
            </span>
          </div>
        </template>
      </Column>

      <Column :header="'Destination'"> 
        <template #body="slotProps">
          {{ $t(slotProps.data.code) }}
        </template>
      </Column>

      <Column :header="$t('Name')"> 
        <template #body="slotProps">
          {{ $t(slotProps.data.description) }}
        </template>
      </Column>

      <Column :header="$t('Product')"> 
        <template #body="slotProps">
          {{ $t(slotProps.data.product_name) }}
        </template>
      </Column>

      <Column :header="$t('Open date')"> 
        <template #body="slotProps">
          {{ $t(slotProps.data.open_dt) }}
        </template>
      </Column>

      <Column :header="$t('Capacity')"> 
        <template #body="slotProps">
          <div :style="slotProps.data.allowed_capacity_check == 'N' ? 'red': 'black'">{{ $t(slotProps.data.capacity) }}
            <span><i class="zmdi zmdi-info pl-2" style="color:red" v-if="slotProps.data.allowed_capacity_check == 'N' " @click="openInfoDetailsModal('waste_capacity_check')" ></i></span>
          </div>
        </template>
      </Column>

      <!-- <Column :header="$t('Description')"> 
        <template #body="slotProps">
          {{ $t(slotProps.data.description) }}
        </template>
      </Column> -->

      <!-- <Column :header="$t('EWC')"> 
        <template #body="slotProps">
          {{ $t(slotProps.data.product_code) }}
        </template>
      </Column> -->

      <Column :header="$t('Dumping Qty')"> 
        <template #body="slotProps">
          {{ $t(slotProps.data.capacity_cons) }} {{ $t(slotProps.data.uom) }}
        </template>
      </Column>

      <Column :header="$t('Select')" :style="{ width: '10%' }" class="data-list__column-button">
        <template #body="slotProps">
          <Button
            class="p-button btn"
            icon="zmdi zmdi-arrow-right zmdi-hc-lg"
            @click="selectDestination(slotProps)"
            :disabled="slotProps.data.allowed_open_check == 'N' || slotProps.data.allowed_capacity_check  == 'N'"
            />

        </template>
      </Column>

      <template #footer>
        <Button class="btn-back" :label="$t('Back')" @click="backToAvailableTypes" />
      </template>
    </DataTable>
     <Dialog v-model:visible="showInfoDetailsModal" modal :style="{ width: '25rem' }">
      <div class="p-4">
        <h3> {{$t(detailsModalErrorMsg.type)}}</h3>
        {{$t(detailsModalErrorMsg.errorMsg)}}
      </div>
    </Dialog>
  </div>
</template>

<script setup>
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import WasteService from "@/services/api/waste";
import Checkbox from "primevue/checkbox";
</script>

<script>
export default {
  name: "AvailableOriginModal",
  components: {
    DataTable,
    Column,
    InputText,
    Button,
    Dialog,
    Checkbox,
  },
  props: ['destinationType', 'materialEWC', 'deliveryQuantity','materialID','flowType'],
  data: function () {
    return {
      destinations: [],
      loading: true,
      showInfoDetailsModal: false,
      detailsModalErrorMsg: '',
      isChecked: true,
    };
  },
  mounted() {
    this.getAvailableDestinations();
  },
  methods: {
    async getAvailableDestinations() {
      const plant = JSON.parse(sessionStorage.getItem("user")).plant.code
      const materialEWC = this.materialEWC;
      const deliveryQuantity = this.deliveryQuantity;
      const materialID = this.materialID;

      const params = {
        type: this.destinationType,
        orderType: this.flowType,
        plant,
        deliveryQuantity,
        materialEWC,
        materialID
      }

      try {
        WasteService.getAvailableDestinations(params).then( (response) => {
          console.log('getAvailableDestinations response',response)
          let destinationsArray = response.map(item => {
                    return {...item,isChecked: item.open === 'Y' ? true : false};
                });
          this.destinations = destinationsArray;
        }).catch((error) =>{
            console.log("error getAvailableDestinations %O", error);
              this.$notify({
                title: this.$t("Result action"),
                text: this.$t(`Error when getting Available Origins`),
                type: 'warn',
                duration: 3000
            });
        });

      } catch (error) {
        console.log("error getAvailableDestinations CATCH %O", error);
        console.error('Error getting Origins')
      } finally {
        this.loading = false
      }
    },

    selectDestination(e) {
      this.$emit("setAvailableDestination", e);
    },
    openInfoDetailsModal(type){
      this.showInfoDetailsModal = true; 
      this.detailsModalErrorMsg = {
        type:type,
        errorMsg: type + '_msg'
      }
    },
    markCheck(check) {
      if (check === 'Y') {
        this.isChecked = true;
        return
      }
      this.isChecked = false;
    },
    backToAvailableTypes() {
      this.$emit("backToAvailableTypes");
    }
  },
};
</script>

<style lang="scss">
@use "@/assets/css/lh-ds/core/functions" as *;
@use "@/assets/css/hodim/layout/data-list";

.btn-back {
  padding-inline: 30px;
  padding-block: 10px;
  background-color: transparent;
}

.destination-available {
  .p-datatable {
    .p-datatable-thead > tr > th {
      background: #eee;
    }
  }
}
</style>
