<template>
  <Step :title="$t('Material')" class="materials-step">
    <div class="columns table-header is-align-items-center">
      <div class="column">
        {{ $t("Material") }}
      </div>

      <div class="column">
        {{ $t("EWX / Category") }}
      </div>

      <div class="column">
        {{ $t("Quantity") }}
      </div>
    </div>

    <div class="columns">
      <div v-if="material && material.descriptions" class="column">
        <div class="is-flex is-flex-direction-column">
          <span>{{ material.materialID }}</span>
          <span>{{ material.descriptions[0].text }}</span>
        </div>
      </div>

      <div v-if="fireQInfo" class="column">
        <div class="is-flex is-flex-direction-column">
          <span>{{ fireQInfo.EuropeanWasteCode }}</span>
          <span>{{ fireQInfo.InertWasteCategoriesCode }}</span>
          <span>{{ fireQInfo.EcomineroCode }}</span>
        </div>
      </div>
      
      <div class="column">
        <div v-if="delivery" class="is-flex">
          <span class="mr-2"> {{ delivery._source.netWeight }}</span>
          <span> TNE </span>
        </div>
      </div>
    </div>
    
    <div class="columns is-align-items-center">
      <div class="column has-text-weight-semibold is-one-fifth">
        {{ $t("Reference (DAP)") }}
        <!-- <span @click="openReferenceModal" class="btn-link pl-1">{{$t('See reference')}}</span> -->
      </div>

      <div class="column has-text-weight-semibold is-one-fifth">
        {{ $t("Destination") }}
      </div>

      <div class="column has-text-weight-semibold is-one-fifth">
        {{ $t("Treatment Code") }}
      </div>
    </div>

    <div class="columns">
      <div class="column is-one-fifth">
        <div class="is-flex is-flex-direction-column">
          <span v-for="reference in outboundFromDelivery.references">
            {{ reference.dap }}
          </span>
        </div>
      </div>

      <div class="column is-one-fifth">
        <!-- <div v-if="alreadyHaveWasteInfo" class="">
          <span> {{ outboundFromDelivery.originInfo.code }} </span>
          <span> &nbsp;- </span>
          <span> &nbsp; </span>
          <span> {{ outboundFromDelivery.originInfo.type}}</span>
        </div> -->

        <div class="is-flex">
          <InputText
            v-if="originSelected !== null"
            :value="displayAvailableDestination"
            @input="() => {
              originSelected = null
              availableOriginSelected = null
              availableOrigins = []
            }"
            :style="{width: '100%', border: '1px solid black'}"
            :disabled="disableInputs || !alreadyHaveWasteInfo"
          />

          <v-select
            v-if="originSelected === null"
            select
            label="type"
            item-text="number"
            item-value="number"
            class="seeker__wrapper"
            :style="{width: '100%'}"
            v-model="originSelected"
            :options="originTypes"
            :clearable="false"
            @search="searchDestinationTypes"
            @update:modelValue="selectOrigin({data:originSelected})"
            :disabled="disableInputs"
          >
            <template #no-options="{ search, searching, loading }">
              {{ $t("Type at least 2 characters") }}
            </template>
            <template #option="option">
              <div class="seeker-item">
                <div class="seeker-item__name">{{ option.type }}</div>
                <!--<div class="seeker-item__desc">
                  {{ option.code }}
                </div>-->
              </div>
            </template>
          </v-select>

          <Button
            class="p-button btn btn--secondary ml-1"
            icon="zmdi zmdi-assignment zmdi-hc-lg"
            :style="{ border: 'none' }"
            @click="openOriginModal()"
            :disabled="disableInputs || !alreadyHaveWasteInfo"
          />
        </div>
      </div>

      <div class="column is-one-fifth">
        <!-- Treatment -->
        <!-- <div v-if="alreadyHaveWasteInfo">
          <span> {{ outboundFromDelivery.treatmentInfo.value }} </span>
          <span> &nbsp;- </span>
          <span> &nbsp; </span>
          <span> {{ outboundFromDelivery.treatmentInfo.name}}</span>
        </div> -->

        <div class="is-flex">
          <InputText
            :style="{ width: '100%', border: '1px solid black' }"
            :value="displayTreatment"
            readonly
            :disabled="disableInputs || disableTreatmentCode || !(availableOriginSelected !='' && availableOriginSelected !=null)"
          />

          <Button
            class="p-button btn btn--secondary"
            icon="zmdi zmdi-assignment zmdi-hc-lg"
            :style="{ border: 'none' }"
            @click="openTreatmentModal()"
            :disabled="disableInputs || disableTreatmentCode || !(availableOriginSelected !='' && availableOriginSelected !=null)"
          />
        </div>
      </div>
    </div>

    <Dialog
      v-model:visible="showOriginModal"
      :header="$t('Origin')"
      :draggable="false"
      style="width: 75rem"
    >
      <OriginModal @setOrigin="selectOrigin" :flowType="flowType" />
    </Dialog>

    <Dialog
      v-model:visible="showAvailableOriginsModal"
      :header="$t('Available Destination')+ ' (' + $t(originTypeSelectedValue) + ')'"
      :draggable="false"
      style="width: 85rem"
    >
      <AvailableOriginIntraModal
        @setAvailableDestination="selectAvailableOrigin"
        @backToAvailableTypes="backToAvailableTypes"
        :destinationType="originSelected.type"
        :materialEWC ="fireQInfo !== null ? fireQInfo.EuropeanWasteCode: null"
        :deliveryQuantity="delivery._source.netWeight"
        :flowType="flowType"
      />
    </Dialog>

    <Dialog
      v-model:visible="showTreatmentModal"
      :header="$t('Treatment')"
      :draggable="false"
      style="width: 75rem"
    >
      <TreatmentModal @setTreatment="selectTreatment" @disableTreatment="disableTreatment" :treatments="treatments" />
    </Dialog>

    <Dialog
      v-model:visible="showReferenceModal"
      :header="$t('Reference')"
      :draggable="false"
      style="width: 75rem"
    >
      <ReferenceModal
        :references="references"
      />
    </Dialog>

    <Dialog
      v-model:visible="showDAPExtraDataModal"
      :header="$t('Reference Extra Info')"
      :draggable="false"
      :style="dapExtraInfoStyle"
    >
      <ReferenceExtraInfoData :dapExtraInfoData="dapExtraInfoData"/>
    </Dialog>
  </Step>
</template>

<script setup>

import Dialog from 'primevue/dialog';
import Button from "primevue/button";
import Step from "@/components/dispatch/order/utils/step";
import WasteService from "@/services/api/waste";
import InputText from 'primevue/inputtext';
import OriginModal from '@/components/Deliveries/data-table/OriginModal.vue';
import AvailableOriginIntraModal from '@/components/Deliveries/data-table/AvailableOriginIntraModal.vue';
import TreatmentModal from '@/components/Deliveries/data-table/TreatmentModal.vue';
import CountriesService from '@/services/api/countries';
import ReferenceModal from '@/components/Deliveries/data-table/ReferenceModal.vue';
import ReferenceExtraInfoData from '@/components/Deliveries/data-table/ReferenceExtraDataModal.vue';

</script>

<script>
export default {
  components: {
    Step,
    WasteService,
    Dialog,
    Button,
    InputText,
    OriginModal,
    AvailableOriginIntraModal,
    TreatmentModal,
    ReferenceModal,
    ReferenceExtraInfoData,
    CountriesService,
  },
  data: function () {
    return {
      alreadyHaveWasteInfo: false,
      outboundFromDelivery: null,

      delivery: {},
      material: {},

      originSelected: null,
      availableOrigins: [],
      showOriginModal: false,

      availableOriginSelected: null,
      showAvailableOriginsModal: false,

      showTreatmentModal: false,
      treatmentSelected: {},
      disableTreatmentCode: true,
      treatments: [],

      references: [],
      referenceFiltered: [],
      showReferenceModal: false,

      showDAPExtraDataModal: false,
      dapExtraInfoData: [],

      momentFormat: JSON.parse(localStorage.getItem("dateFormat")) ? JSON.parse(localStorage.getItem("dateFormat")).moment : 'DD/MM/YYYY',
      originTypeSelectedValue: '',
      fireQInfo: null,
      typeOrder: 'W_TRANSFER_IN',
      flowType: 'TRANSFER_IN',
    };
  },
  computed: {
    orderType() {
      return this.$store.state.dispatch ? this.$store.state.dispatch.orderType : this.typeOrder;
    },
    displayAvailableDestination() {
      const { code, type, description } = this.availableOriginSelected !== null ? this.availableOriginSelected : {};

      //return code && `${code} - ${type} - ${description}`;
      return code && `${code} - ${description}`;
    },
    displayTreatment() {
      const { value, name } = this.treatmentSelected;

      return value && name;
    },
  },
  beforeMount() {
    this.delivery = this.$store.state.dispatch ? this.$store.state.dispatch.delivery : this.getDeliveryMock();
    this.material = this.delivery._source.material[0];

    console.log(this.delivery._source.material[0].wasteOutboundInfo)
    if (this.delivery._source.material[0].wasteOutboundInfo) {
      this.alreadyHaveWasteInfo = true
      this.outboundFromDelivery = this.delivery._source.material[0].wasteOutboundInfo
      this.fireQInfo = {
        EuropeanWasteCode: this.outboundFromDelivery.fireQ.ewc,
        EuropeanWasteDescription: this.outboundFromDelivery.fireQ.ewc_name,
        InertWasteCategoriesCode: this.outboundFromDelivery.fireQ.category,
        EcomineroCode: this.outboundFromDelivery.fireQ.ecomineroCode,
      }
    }
  },
  methods: {
    getPlantInfo() {
      return JSON.parse(sessionStorage.getItem("user")).plant;
    },
    async searchDestinationTypes(search) {
      if (!search || search.length < 2) return;
      this.loading = true;

      try {
        this.originTypes = await WasteService.getDestinationsTypesByFlowAndSearch(this.flowType,search);
      } catch (error) {
        console.log("error searchDestinationTypes %O", error);
        console.error("Error getting Origins Types");
      } finally {
        this.loading = false;
      }
    },
    async getTreatments() {
      try {
        const plant = JSON.parse(sessionStorage.getItem("user")).plant.code;

        //IN CASE OF TRANSFER_OUT flow, SEND AS FILTER THE RECEIVING PLANT (IN THE SP THE SYSTEM WILL TAKE THE plant_waste_type PARAMENTER OF RECEIVING PLANT)
        const receivingPlant = this.delivery._source.shipto.shipto

        let dataWaste = {
          filterData:{
            type:receivingPlant,//this.availableOriginSelected, 
          },
          //dapData: this.material.dap,
          //material: this.material,
          plant,
          flowType: this.flowType
        }

        const { data } = await CountriesService.getCountryLovWasteTreatmentData('WASTE_TREATMENT_CODES', dataWaste)

        this.treatments = data
        console.log('getTreatments',this.treatments[0])

        //AutoSelect value in case of getting defaultValueFrom BBDD (All values will have the same defaulValue, so we can take the first position of the array)

        if(this.treatments[0].defaultValue !='' && this.treatments[0].defaultValue !='null'&& this.treatments[0].defaultValue !=null) {
          let automaticTreatmentObj = this.treatments.find(((e) => e.value = this.treatments[0].defaultValue))
          let automaticTreatment={
            data: automaticTreatmentObj
          }
          this.selectTreatment(automaticTreatment)
        }
        if(this.treatments[0].disabled == '1' || this.treatments[0].disabled == 1) {
            this.disableTreatment(true)
        }
      } catch (error) {
        console.error('Error getting Treatments',error)
      } finally {
        this.loading = false
      }
    },

    async selectOrigin(origin) {
      this.originSelected = origin.data;
      this.originTypeSelectedValue = origin.data.type;
      this.availableOriginSelected = null;
      this.showAvailableOriginsModal = true;
      this.disableTreatmentCode = true;
      this.closeOriginModal();
    },

    async selectAvailableOrigin(availableOrigin) {
      // Update delivery
      if (this.$store.state.dispatch) {
        const { id, code, type, description } = availableOrigin.data
        const delivery = this.$store.state.dispatch.delivery
        const materialUpdated = { id, type, code, description}
        delivery._source.material[0].wasteTransferIn = {
          ...delivery._source.material[0].wasteTransferIn,
          destination: materialUpdated
        }

        this.$store.commit('dispatch/setDelivery', delivery)
      }

      this.availableOriginSelected = availableOrigin.data;
      this.disableTreatmentCode = false;
      this.closeAvailableOriginModal();
      this.getTreatments();
    },

    async selectTreatment(treatment) {
      // Update delivery
      const { value, name } = treatment.data
      if (this.$store.state.dispatch) {
        const delivery = this.$store.state.dispatch.delivery
        delivery._source.material[0].wasteTransferIn = {
          ...delivery._source.material[0].wasteTransferIn,
          treatment: { value, name }
        }

        this.$store.commit('dispatch/setDelivery', delivery)
      }

      this.treatmentSelected = treatment.data;

      this.closeTreatmentModal();
    },

    async disableTreatment(value){
      this.disableTreatmentCode = value
    },

    async openReferenceExtraData(reference) {
      this.showDAPExtraDataModal=true;
      this.dapExtraInfoData = reference;
    },

    openOriginModal() {
      this.showOriginModal = true;
    },
    closeOriginModal() {
      this.showOriginModal = false;
    },

    openAvailableDestinationModal() {
      this.showAvailableOriginsModal = true;
    },
    closeAvailableOriginModal() {
      this.showAvailableOriginsModal = false;
    },

    openTreatmentModal() {
      this.showTreatmentModal = true;
    },
    closeTreatmentModal() {
      this.showTreatmentModal = false;
    },

    openReferenceModal() {
      this.showReferenceModal = true;
    },
    closeReferenceModal() {
      this.showReferenceModal = false;
    },

    backToAvailableTypes() {
      this.closeAvailableOriginModal()
      this.openOriginModal()
    },

    showNotification(title, text, type, duration) {
      this.$notify({
        title,
        text,
        type,
        duration,
        closeOnClick: true
      })
    },
    getDeliveryMock() {
      return {
        _index: ".ds-hodim-delivery-hodim_qa-2024.06.17-000003",
        _id: "CNR9KZABAllAV3TTRWjB",
        _version: 5,
        _score: 1,
        _source: {
          shipto: {
            address: {
              country: "FR",
              transportationZone: "",
              city: "SARLAT LA CANEDA",
              street: "ZI DE MADRAZES - LE PECH DE MADRAZE",
              postalcode: null,
              name: "M+ 24 SARLAT M+ 24 SARLAT"
            },
            contract: "2520601",
            contracts: [
              {
                incoterm: "DDP",
                contract: "2520601"
              }
            ],
            fullSearch: "M+ 24 SARLAT M+ 24 SARLAT SARLAT LA CANEDA 2520601",
            shipto: "101033"
          },
          netWeight: 5,
          material: [
            {
              productHierarchy: "A10AG20SG10",
              materialID: "15606304",
              purchaseUoM: "",
              descriptions: [
                {
                  language: "EN",
                  text: "ITC 1 4/8 Cal C BDM Préfa",
                },
                {
                  language: "FR",
                  text: "ITC 1 4/8 Cal C BDM Préfa",
                },
              ],
              baseUoM: "TNE",
              volume: {
                unit: "",
                value: 0,
              },
              userQuantity: 5,
              materialGroup: "011605",
              plant: "F3S4",
            },
          ],
          incoterm: "DDP",
          DeliveryNo: "",
          isOffline: false,
          deliveryNoteNumber: "",
          status: "Scheduled",
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/hodim/index.scss";
@import "@/assets/css/lh-ds/core/functions";

.materials-step {
  &__table-header {
    font-size: 1rem;
    font-weight: bold;
  }

  &.overlay::before {
    content: "";
    position: absolute;
    inset: 0;
    z-index: 1;
    background-color: #fff;
    opacity: 0.5;
  }
}

.table-header {
  font-size: 1rem;
  font-weight: bold;
  background-color: #eee;
  height: 40px;
}

table {
  margin-top: 0px;
}
</style>
