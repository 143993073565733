<script>
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import Tooltip from 'primevue/tooltip';
export default {
  props: {
    category: String,
    note_text: String,
    note_categories: Array,
    index: Number,
    enableDeleteButton: Boolean,
    audit_data: JSON,
    action: String
  },
  directives: { tooltip: Tooltip },
  components: {
    InputText,
    Dropdown
  },
  data: function () {
    return {
      user: JSON.parse(sessionStorage.getItem('user')),
      disableEdit: false
    }
  },
  methods: {
    onChange(e) {

      this.audit_data.lastModifyDate = new Date();
      this.audit_data.lastModifyBy = {
        id: this.user.id_user,
        fullName: this.user.fullname,
        userName: this.user.username
      };

      this.$emit('update:audit_data', this.audit_data);
      this.$emit('update:note_text', this.note_text);
      this.$emit('update:category', this.category);
      this.$emit('update:note_description', this.note_categories.find((c) => c.value == this.category).name);
      this.$emit('change');
    },
    deleteNote() {
      this.$emit('delete', this.index);
    }
  },
  created() {
    if (!this.enableDeleteButton && this.note_text != null) {
      this.disableEdit = true
    }
  }
}
</script>
<template>
  <div class="note-body">
    <i v-if="action == 'edit'" class="auditIcon zmdi zmdi-info-outline zmdi-hc-lg" v-tooltip="'Created By: ' + audit_data.createdBy.fullName + '    ' + 'Updated By: ' + audit_data.lastModifyBy.fullName"></i>
    <Dropdown v-model="category" :disabled="disableEdit" @change="onChange" :options="note_categories" optionDisabled="disabled" optionLabel="name" optionValue="value" filter :placeholder="$t('Select note category')" style="width: 23%;"/>
    <InputText v-model="note_text" :disabled="disableEdit" @input="onChange" style="width: 75%; margin-left: 20px; margin-right: 20px" />
    <div v-if="enableDeleteButton" class="action_btn" @click="deleteNote">
      <i class="zmdi zmdi-delete zmdi-hc-lg"></i>
    </div>
  </div>
</template>

<style lang="scss">
@use '@/assets/css/lh-ds/core/functions' as *;

.auditIcon {
  margin-right: 20px;
}

.action_btn {
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border: solid 1px #b9b9b9;
  border-radius: 5px;
  cursor: pointer;
}

.action_btn:hover {
    background-color: rgb(244, 244, 244);
}

.note-body {
  margin: 0px;
  border-top: solid 1px #a7a7a7;
  padding: 20px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.note-option {
  display: flex;
  justify-content: left;
  align-items: center;
}
</style>