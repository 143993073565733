<template>
  <!-- TABLE HEADER -->
  <div class="columns table-header is-align-items-center">
    <div class="column" :style="{'flex-grow': '0', 'flex-basis': '262px'}">
      {{ $t("Material") }}
    </div>
    <div class="column">
      {{ $t("EWC/category") }}
    </div>
    <div class="column" :style="{ marginLeft: '52%' }">
      {{ $t("Quantity") }}
    </div>
  </div>

  <div v-if="material && material.descriptions">
    <div class="columns">
      <div class="column" :style="{'flex-grow': '0', 'flex-basis': '262px'}">
        <div class="is-flex is-flex-direction-column">
          <span>{{ material.materialID }}</span>
          <span>{{ material.descriptions[0].text }}</span>
        </div>
      </div>

      <div class="column">
        <div v-if="material && material.ewxCategoryDAP" class="is-flex is-flex-direction-column">
          <span>{{ material.ewxCategoryDAP.EuropeanWasteCode }}</span>
          <span>{{ material.ewxCategoryDAP.InertWasteCategoriesCode }}</span>
          <span>{{ material.ewxCategoryDAP.EcomineroCode }}</span>
        </div>
      </div>

      <div v-if="delivery" class="column" :style="{ marginLeft: '52%'}">
        <div class="is-flex">
          <span class="mr-2"> {{ delivery._source.netWeight }}</span>
          <span> TNE </span>
        </div>
      </div>
    </div>
  </div>

  <div v-if="material">
    <div class="columns">
      <div class="column is-one-fifth">
        <FormControl label="DAP">
          <div class="is-flex is-align-items-center" style="position: relative;">
            <InputText :value="material.dap !== undefined ? material.dap.erpCode : ''" readonly style="width: 100%"/>
            <span style="position: absolute; right: 5%;">
              <i style="cursor: pointer;" class="zmdi zmdi-info zmdi-hc-lg pl-2" @click="DAPExtraData" ></i>
            </span>
          </div>
          <div v-if="dapEasyConnectionError"> 
            <div style="color:red" class="ml-1">
              <strong>{{ $t("DAPEasyConnection") }} {{ $t("Error") }}</strong>
            </div>
          </div>
          <div v-if="!dapEasyConnectionError"> 
            <div v-if="invalidDapEasyDataError" style="color:red" class="ml-1"> 
              <strong>{{ $t("Invalid DAP EASY Data") }}</strong>
            </div>
            <div v-if="!invalidDapEasyDataError">
              <div v-if="disableDAPRocks && material.dap !== undefined" style="color:red" class="ml-1">
                <strong>{{ $t("DAP EASY data") }}</strong>
              </div>

              <div v-if="disableDAPEasy && material.dap !== undefined" style="color:red" class="ml-1">
                <strong>{{ $t("DAP ROCKS internal data") }} </strong>
              </div>
            </div>

          </div>
        </FormControl>
      </div>

      <!-- Destination -->
      <div class="column is-one-fifth" style="position: relative">
        <div class="label__input">
          <div class="form-control__label">{{ $t("Destination") }}</div>
        </div>

        <div v-if="alreadyHaveWasteInfo" class="column is-two-fifth is-flex is-flex-direction-row pl-0">
          <span> {{ availableDestinationSelected && availableDestinationSelected.code }} </span>
          <!--<span> &nbsp;- </span>
          <span> &nbsp; </span>
          <span> {{ availableDestinationSelected && availableDestinationSelected.type}}</span>-->
          <span> &nbsp;- </span>
          <span> &nbsp; </span>
          <span> {{ availableDestinationSelected && availableDestinationSelected.description}}</span>
        </div>

        <div v-else class="is-flex">
          <InputText
            v-if="destinationTypeSelected !== null"
            :value="displayAvailableDestination"
            @input="() => {
              destinationTypeSelected = null
              availableDestinationSelected = null
              availableDestinations = []
            }"
            :style="{width: '100%', border: '1px solid black'}"
            :disabled="disableInputs || disableDestinationTypes"
          />

          <v-select
            v-if="destinationTypeSelected === null"
            select
            label="type"
            item-text="number"
            item-value="number"
            class="seeker__wrapper"
            :style="{width: '100%'}"
            v-model="destinationTypeSelected"
            :options="destinationTypes"
            :clearable="false"
            @search="searchDestinationTypes"
            @update:modelValue="selectDestinationType({data:destinationTypeSelected})"
            :disabled="disableInputs || disableDestinationTypes"
          >
            <template #no-options="{ search, searching, loading }">
              {{ $t("Type at least 2 characters") }}
            </template>

            <template #option="option">
              <div class="seeker-item">
                <div class="seeker-item__name">{{ option.type }}</div>

                <!--<div class="seeker-item__desc">
                  {{ option.code }}
                </div>-->
              </div>
            </template>
          </v-select>

          <Button
            class="p-button btn btn--secondary ml-1"
            icon="zmdi zmdi-assignment zmdi-hc-lg"
            :style="{ border: 'none' }"
            @click="openDestinationModal()"
            :disabled="disableInputs || disableDestinationTypes"
          />
        </div>
      </div>

      <!-- Treatment -->
      <div class="column is-one-fifth">
        <FormControl :label="$t('Treatment Code')">
          <div v-if="alreadyHaveWasteInfo" class="column is-one-fifth is-flex is-flex-direction-row pl-0" style="width: 180px">
            <span> {{ treatmentSelected.value }} </span>
            <span> &nbsp;- </span>
            <span> &nbsp; </span>
            <span> {{ treatmentSelected.name}}</span>
          </div>

          <div v-else class="is-flex">
            <InputText
              :style="{ width: '100%', border: '1px solid black' }"
              :value="displayTreatment"
              readonly
              :disabled="disableInputs || disableTreatmentCode || !(availableDestinationSelected !='' && availableDestinationSelected !=null)"
            />

            <Button
              class="p-button btn btn--secondary"
              icon="zmdi zmdi-assignment zmdi-hc-lg"
              :style="{ border: 'none' }"
              @click="openTreatmentModal()"
              :disabled="disableInputs || disableTreatmentCode || !(availableDestinationSelected !='' && availableDestinationSelected !=null)"
            />
          </div>
        </FormControl>
      </div>

      <!-- Create DAP ROCKS/ DAP EASY BUTTON -->
      <div class="column" :style="{ marginTop: '35px', position: 'absolute', right: '50px' }">
        <div class="is-flex">
          <Button @click="openDAPModal" class="btn-mat mr-3" label="DAP ROCKS"  :disabled="disableDAPRocks"/>

          <Button @click="openDAPModal" class="btn-mat" label="DAP EASY"  :disabled="disableDAPEasy"/>
        </div>
      </div>
    </div>
  </div>

  <Dialog
    v-model:visible="showDestinationTypeModal"
    :header="$t('Destination')"
    :draggable="false"
    style="width: 75rem"
  >
    <DestinationsTypesModal @setDestination="selectDestinationType" :flowType="flowType" />
  </Dialog>

  <Dialog
    v-model:visible="showAvailableDestinationsModal"
    :header="$t('Available Destination') + ' (' + $t(destinationTypeSelectedValue) + ')'"
    :draggable="false"
    style="width: 85rem"
  >
    <AvailableDestinationsModal
      @setAvailableDestination="selectAvailableDestination"
      @backToAvailableTypes="backToAvailableTypes"
      :destinationType="destinationTypeSelected.type"
      :materialEWC ="material.ewxCategoryDAP != undefined ? material.ewxCategoryDAP.EuropeanWasteCode: null"
      :deliveryQuantity="delivery._source.netWeight"
      :materialID="material.materialID"
      :flowType="flowType"
    />
  </Dialog>

  <Dialog
    v-model:visible="showTreatmentModal"
    :header="$t('Treatment')"
    :draggable="false"
    style="width: 75rem"
  >
    <TreatmentModal @setTreatment="selectTreatment" @disableTreatment="disableTreatment" :treatments="treatments" />
  </Dialog>

  <Dialog
    v-model:visible="showDAPModal"
    @update:visible="closeModal"
    :header="!disableDAPRocks ? (isNewDapRocksModal ? $t('New DAP') : $t('Edit DAP')): $t('DAP EASY')"
    :draggable="false"
    style="width: 38rem"
  >
    <CreateDAPModal 
      @closeModal="closeDAPModal" 
      @dapCreated="DAPCreated" 
      @addDAPAttachment="addDAPAttachment"
      @disableScreen="disableScreen" 
      :enableCreateDAP="enableCreateDAP"
      :DAPform="material.dapEasyData !== undefined 
                  ? material.dapEasyData 
                  : material.dapRocksData !== undefined ? material.dapRocksData : DAPform"
      :shipto="delivery._source.shipto.shipto" 
      :soldto="delivery._source.soldto.customer"
      :deliveryQuantity="delivery._source.netWeight" 
      :delivery="delivery._source"
      :isNewDapRocksModal="isNewDapRocksModal"
      :readOnlyDapModal="readOnlyDapModal"
      :filesList="attachments"
      :calendarFormat="calendarFormat"
    />
  </Dialog>
   <Dialog
    v-model:visible="showDAPExtraDataModal"
    :header="$t('DAP Extra Info')"
    :draggable="false"
    :style="dapExtraInfoStyle"
  >
    <DapExtraDataModal :dapExtraInfoData="dapExtraInfoData"/>
  </Dialog>
</template>

<script setup>
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import FormControl from '@/components/common/FormControl.vue';
import InputText from 'primevue/inputtext';

import DestinationsTypesModal from '@/components/Deliveries/data-table/DestinationsTypesModal.vue';
import AvailableDestinationsModal from '@/components/Deliveries/data-table/AvailableDestinationsModal.vue';
import TreatmentModal from '@/components/Deliveries/data-table/TreatmentModal.vue';
import CreateDAPModal from '@/components/Deliveries/data-table/CreateDAPModal.vue';
import CountriesService from "@/services/api/countries";
import DapExtraDataModal from '@/components/Deliveries/data-table/DapExtraDataModal.vue';
import WasteService from '@/services/api/waste';
import moment from 'moment';
</script>

<script>
export default {
  name: "MaterialInbound",
  props: ['delivery', 'material', 'fireQResponse', 'disableInputs', 'enableCreateDAP', 'disableDAPRocks', 'disableDAPEasy', 'readonlyFields','showDAPModalFromParent','isNewDapRocksModal', 'alreadyHaveWasteInfo','attachments','dapEasyConnectionError','invalidDapEasyDataError','readOnlyDapModal'],
  mounted() {
    if (this.alreadyHaveWasteInfo) {
      this.destinationTypeSelected = { type: this.delivery._source.material[0].wasteInfo.destinationType };
      this.availableDestinationSelected = { type: this.delivery._source.material[0].wasteInfo.destinationInfo.type, code: this.delivery._source.material[0].wasteInfo.destinationInfo.code,description: this.delivery._source.material[0].wasteInfo.destinationInfo.description };
      this.treatmentSelected = { value: this.delivery._source.material[0].wasteInfo.treatmentInfo.value, name: this.delivery._source.material[0].wasteInfo.treatmentInfo.name };

      this.disableDestinationTypes = true;
    }

    console.log('DAP FORM dapRocks',this.material.dapRocksData)
    console.log('DAP FORM dapEasy',this.material.dapEasyData)
  },
  watch: {
    showDAPModalFromParent(newValue) {
      console.log('entramos cambio', newValue)
      this.showDAPModal = newValue;
    }
  },
  data: function() {
    return {
      destinationTypes: [],
      destinationTypeSelected: null,
      showDestinationTypeModal: false,
      calendarFormat: JSON.parse(localStorage.getItem("dateFormat")) ? JSON.parse(localStorage.getItem("dateFormat")).calendar : 'dd/mm/yy',
      momentFormat: JSON.parse(localStorage.getItem("dateFormat")) ? JSON.parse(localStorage.getItem("dateFormat")).moment : 'DD/MM/YYYY',
      treatmentSelected: {},
      showTreatmentModal: false,
      dataWaste:{},
      treatments: [],
      dapExtraInfoData:[],
      availableDestinations: [],
      availableDestinationSelected: null,
      showAvailableDestinationsModal: false,

      disableDestinationTypes: false,
      disableAvailableDestination: true,
      disableTreatmentCode: true,

      showDAPModal: false,
      showDAPExtraDataModal: false,
      dapExtraInfoStyle:'width: 30rem;',
      filesList:[],
      DAPform: {
        code:     { value: this.material.dap ? this.material.dap.erpCode : '', name: this.$t('Code'), required: false, maxLength: null, readOnly: true },
        tonnage:  { value: this.material.dap ? this.material.dap.estimateTonnage : '', name: this.$t('Tonnage'), required: true, maxLength: null, readOnly: false },
        fromDate: { value:  new Date(), name: this.$t('From Date'), required: true, maxLength: null, readOnly: false },
        toDate:   { value: new Date(new Date(new Date().setFullYear(new Date().getFullYear() + 1)).setDate(new Date().getDate()-1)), name: this.$t('To Date'), required: true, maxLength: null, readOnly: false},
        product:  { value: this.material.materialID, name: this.$t('Product'), required: true, maxLength: null, readOnly: this.readonlyFields },
        product_name:  { value: this.material.descriptions[0].text , name: this.$t('Product Name'), required: true, maxLength: null, readOnly: this.readonlyFields },
        ewc:      { value: this.fireQResponse.EuropeanWasteCode, name: this.$t('ewc'), required: true, maxLength: null, readOnly: this.readonlyFields },
        ewc_name:      { value: this.fireQResponse.EuropeanWasteDescription, name: this.$t('ewc Name'), required: false, maxLength: null, readOnly: this.readonlyFields },
        category: { value: this.fireQResponse.InertWasteCategoriesCode,name: this.$t('Category'), required: true, maxLength: null, readOnly: this.readonlyFields },
        topology: { value: false, name: this.$t('Topology'), required: false, maxLength: null, readOnly: false },
        dapEasyTotalResponse: this.material.dapEasyTotalResponse
      },
      destinationTypeSelectedValue: '',
      flowType: 'INBOUND',
      typeOrder: 'W_IN'
    }
  },
  methods: {
    closeModal() {
      this.$emit('closeDAPModal');
    },
    async searchDestinationTypes(search) {
      if (!search || search.length < 2) return;
      this.loading = true;

      try {
        this.destinationTypes = await WasteService.getDestinationsTypesByFlowAndSearch(this.flowType,search);
      } catch (error) {
        console.log("error searchDestinationTypes %O", error);
        console.error("Error getting Destinations Types");
      } finally {
        this.loading = false;
      }
    },

    async searchAvailableDestinations(search) {
      if (!search || search.length < 2) return;

      const plant = JSON.parse(sessionStorage.getItem("user")).plant.code;
      const materialEWC = this.material.ewxCategoryDAP != undefined ? this.material.ewxCategoryDAP.EuropeanWasteCode: null;
      const deliveryQuantity = this.delivery._source.netWeight;
      const materialID = this.material.materialID;

      const params = {
        type: this.destinationTypeSelected.type,
        orderType: this.flowType,
        plant,
        materialEWC,
        deliveryQuantity,
        materialID,
        isSearching:true,
        search
      }
      
      this.loading = true;
      
      try {
        //this.availableDestinations = await WasteService.getAvailableDestinations(params, true, search);
        this.availableDestinations = await WasteService.getAvailableDestinations(params);
      } catch (error) {
        console.error("Error getting Available Destinations");
      } finally {
        this.loading = false;
      }
    },
 async getTreatments() {

      console.log('getTreatments')
        try {
          const plant = JSON.parse(sessionStorage.getItem("user")).plant.code;
          let dataWaste ={
            filterData:this.availableDestinationSelected,
            //dapData: this.material.dap,
            //material: this.material,
            plant:plant,
            flowType: this.flowType
          }

          const { data } = await CountriesService.getCountryLovWasteTreatmentData('WASTE_TREATMENT_CODES',dataWaste)

          this.treatments = data
          console.log('getTreatments',this.treatments[0])

          //AutoSelect value in case of getting defaultValueFrom BBDD (All values will have the same defaulValue, so we can take the first position of the array)

          if(this.treatments[0].defaultValue !='' && this.treatments[0].defaultValue !='null'&& this.treatments[0].defaultValue !=null) {
            let automaticTreatmentObj = this.treatments.find(((e) => e.value = this.treatments[0].defaultValue))
            let automaticTreatment={
              data: automaticTreatmentObj
            }
            this.selectTreatment(automaticTreatment)
          }
          if(this.treatments[0].disabled =='1' || this.treatments[0].disabled ==1) {
              this.disableTreatment(true)
          }
        } catch (error) {
          console.error('Error getting Treatments',error)
        } finally {
          this.loading = false
        }
    },
    async selectDestinationType(destinationType) {
      console.log('destinationTypeSelected >> ',destinationType)
      if (this.$store.state.dispatch) {
        const delivery = this.$store.state.dispatch.delivery
        const materialUpdated = { ...delivery._source.material[0].dap, destinationType: destinationType.data.type }
        delivery._source.material[0].dap = materialUpdated
        
        this.$store.commit('dispatch/setDelivery', delivery)
      }

      this.destinationTypeSelected = destinationType.data;
      this.destinationTypeSelectedValue = destinationType.data.type;
      this.treatmentSelected = {};
      this.availableDestinationSelected = {};
      this.showAvailableDestinationsModal = true;
      this.disableAvailableDestination=false;
      this.disableTreatmentCode = true;
      this.closeDestinationModal();
    },

    async selectTreatment(treatment) {
      if (this.$store.state.dispatch) {
        const { value, name } = treatment.data
        const delivery = this.$store.state.dispatch.delivery
        const materialUpdated = { ...delivery._source.material[0].dap, treatmentInfo: { value, name } }
        delivery._source.material[0].dap = materialUpdated
        
        this.$store.commit('dispatch/setDelivery', delivery)
      }

      this.treatmentSelected = treatment.data;
      console.log('treatmentSelected',this.treatmentSelected)

      this.closeTreatmentModal();
    },
    async disableTreatment(value){
      this.disableTreatmentCode=value
    },
    async selectAvailableDestination(availableDestination) {
      console.log('selectAvailableDestination >> ',availableDestination)
      if (this.$store.state.dispatch) {
        const { id, code, type, description } = availableDestination.data
        const delivery = this.$store.state.dispatch.delivery
        const materialUpdated = { ...delivery._source.material[0].dap, destinationInfo: { id, code, type, description } }
        delivery._source.material[0].dap = materialUpdated

        this.$store.commit('dispatch/setDelivery', delivery)
      }

      this.availableDestinationSelected = availableDestination.data;
      this.treatmentSelected = {};
      this.disableTreatmentCode = false;
      this.closeAvailableDestinationModal();
      this.getTreatments();
    },

    openDestinationModal() {
      this.showDestinationTypeModal = true;
    },
    closeDestinationModal() {
      this.showDestinationTypeModal = false;
    },

    openAvailableDestinationModal() {
      this.showAvailableDestinationsModal = true;
    },
    closeAvailableDestinationModal() {
      this.showAvailableDestinationsModal = false;
    },

    openTreatmentModal() {
      /*const plant = JSON.parse(sessionStorage.getItem("user")).plant.code;
      this.dataWaste={
        filterData:this.availableDestinationSelected,
        dapData: this.material.dap,
        material: this.material,
        plant:plant
      }*/
      this.showTreatmentModal = true;
    },
    closeTreatmentModal() {
      this.showTreatmentModal = false;
    },
    openDAPModal() {
      console.log('openDAPModal')
      this.showDAPModal = true;
    },
    closeDAPModal() {
      console.log('closeDAPModal')
      this.showDAPModal = false;
    },
    DAPCreated(dapCreatedData) {
      console.log('DAPCreated', dapCreatedData)
      this.showDAPModal = false;
      this.$emit('dapCreated', dapCreatedData)
    },
    addDAPAttachment(dapAttachment){
      console.log('addDAPAttachment MaterialInbound component', dapAttachment)
      this.filesList.push(dapAttachment);
      this.$emit('addDAPAttachment', dapAttachment)
    },
    disableScreen() {
      this.$emit('disableScreen')
    },
    backToAvailableTypes() {
      this.closeAvailableDestinationModal()
      this.openDestinationModal()
    },

    async DAPExtraData() {
      const plant = JSON.parse(sessionStorage.getItem("user")).plant.code;
      console.log('DAPExtraData', this.material.dap)

      
      if(this.material.dap.from == ''|| this.material.dap.from == null || this.material.dap == undefined ){
        this.material.dap.from = (this.disableDAPEasy && this.material.dap !== undefined) ? 'DAP_ROCKS': ((this.disableDAPRocks && this.material.dap !== undefined) ? 'DAP_EASY': '')
      }
     
      let dapData={
            dapData: this.material.dap,
            shipto: this.delivery._source.shipto.shipto,
            plant:plant
      }

      const response = await WasteService.getDAPExtraData(dapData).then( 
                    (response) => {
                        console.log('DAPExtraData response',response)
                        this.showDAPExtraDataModal=true;
                        console.log('DAPExtraData',this.momentFormat)
                        this.dapExtraInfoData = response.data;
                        
                        let dateFromFormatted = moment(response.data.dap_date_from, "YY/MM/DD").format(this.momentFormat)
                        let dateToFormatted = moment(response.data.dap_date_to, "YY/MM/DD").format(this.momentFormat)
                        
                        this.dapExtraInfoData.dap_date_from=dateFromFormatted;
                        this.dapExtraInfoData.dap_date_to=dateToFormatted;
                        this.dapExtraInfoStyle= "width: 30rem;   border: 4px solid "+ this.dapExtraInfoData.dap_color_percentage;

                    }).catch(
                    (error) =>
                    {
                      console.log("getDAPExtraData error %O", error);
                      let title = this.$t("Result Action");
                      let text = this.$t('DAP Extra data not available');
                      let type = 'warn';
                      this.$notify({
                        title,
                        text,
                        type,
                        closeOnClick: true
                      })
                    }
                );
    }
  },
  computed: {
    displayTreatment() {
      const { value, name } = this.treatmentSelected;

      return value && name;
    },
    displayAvailableDestination() {
      console.log('displayAvailableDestination',this.availableDestinationSelected)
      const { code, type, description } = this.availableDestinationSelected;

      //return code && `${code} - ${type} - ${description}`;
      return code && `${code} - ${description}`;
    },
  }
}
</script>

<style lang="scss" scoped>
// @use '@/assets/css/lh-ds/core/icon';
// @use '@/assets/css/lh-ds/core/color';
// @use '@/assets/css/lh-ds/core/font';
@use '@/assets/css/lh-ds/core/functions' as *;
.option-disabled {
  color: red;
  pointer-events: none;
}
.btn-mat {
  background-color: #eee;
  padding: 10px;
  border: 2px solid #5d5d5d;
  border-radius: 3px;
}

.table-header {
  font-size: 1rem;
  font-weight: bold;
  background-color: #eee;
  height: 40px;
}
</style>