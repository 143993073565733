<template>
    <Dialog :header="$t('Manual entry weight')" :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
        :style="{ width: '600px' }" :modal="true" v-model:visible="show" @after-hide="modalClose()">

        <div :style="{ display: 'flex' }">
            <div :style="{ padding: '15px' }">
                <FormControl :label="$t('Manual Entry Reason')">
                    <Dropdown v-model="manualEntryReason" :options="manualEntryReasons" optionLabel="name" optionValue="value"
                        placeholder="Manual Entry Reason" v-on:change="selectOption" />
                </FormControl>
            </div>
            <div :style="{ padding: '15px' }">
                <FormControl :label="$t('Manual Entry Reason Description')">
                    <Textarea v-model="manualEntryReasonDescription" rows="3" cols="30" />
                </FormControl>
            </div>
        </div>

        <template #footer>
            <div style="text-align: right;">
                <Button label="No" icon="pi pi-times" class="p-button btn" @click="modalClose()" />
                <Button label="Yes" icon="pi pi-check" class="p-button btn btn--primary" @click="save()" />
            </div>
        </template>
    </Dialog>
</template>
<script>
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import Dropdown from 'primevue/dropdown';
import Textarea from 'primevue/textarea';

import CountriesService from '@/services/api/countries';
// import DeliveryService from '@/services/api/delivery';

export default {
    components: {
        Button,
        Dialog,
        Dropdown,
        Textarea
    },
    mounted() {
        this.getManualEntryReasons()
    },
    data: function () {
        return {
            show: true,
            manualEntryReasons: [],
            manualEntryReason: null,
            manualEntryReasonDescription: null
        }
    },
    methods: {
        async getManualEntryReasons() {
            try {
                const { data: LOV } = await CountriesService.getCountryLovByName('MANUAL_ENTRY_WEIGHT');
                this.manualEntryReasons = LOV;
            } catch (error) {
                console.log(error)
            }
        },
        modalClose() {
            this.$emit('close')
        },
        save() {
            let data = {
                manualEntryReason: this.manualEntryReason,
                manualEntryReasonDescription: this.manualEntryReasonDescription
            }
            this.$emit('saved', data)
        }
    }
}
</script>
<style></style>