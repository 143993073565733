<template>
  <div class="destination-types m-6">
    <DataTable
      :value="destinations"
      scrollDirection="horizontal"
      :loading="loading"
      :rows="7"
    >
      <Column :header="$t('Destination')"> 
        <template #body="slotProps">
          {{ $t(slotProps.data.code) }}
        </template>
      </Column>

      <Column :header="$t('Description')"> 
        <template #body="slotProps">
          {{ $t(slotProps.data.description) }}
        </template>
      </Column>

      <Column :header="$t('Select')" :style="{ width: '10%' }" class="data-list__column-button">
        <template #body="slotProps">
          <Button
            class="p-button btn"
            icon="zmdi zmdi-arrow-right zmdi-hc-lg"
            @click="selectDestination(slotProps)"
          />
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script setup>
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import WasteService from "@/services/api/waste";
</script>

<script>
export default {
  name: "DestinationsTypesModal",
  props: ['flowType'],
  components: {
    DataTable,
    Column,
    Button,
    Dialog,
  },
  data: function () {
    return {
      destinations: [],
      loading: true,
    };
  },
  mounted() {
    this.getDestinations();
  },
  methods: {
    async getDestinations() {
      console.log('getDestinations',this.flowType)
      try {
        this.destinations = await WasteService.getDestinationsTypesByFlow(this.flowType);
      } catch (error) {
        console.log("error getDestinations %O", error);
        console.error('Error getting Destinations')
      } finally {
        this.loading = false
      }
    },
    selectDestination(e) {
      this.$emit("setDestination", e);
    },
  },
};
</script>

<style lang="scss">
@use "@/assets/css/lh-ds/core/functions" as *;
@use "@/assets/css/hodim/layout/data-list";

.destination-types {
  .p-datatable {
    .p-datatable-thead > tr > th {
      background: #eee;
    }
  }
}
</style>
