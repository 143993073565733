export default class paletReturn {

    constructor() {

        const defaultRow = new row()
        this.rows = [defaultRow]

    }

    addNewRow() {

        const newRow = new row()
        this.rows.push(newRow)

    }

    deleteRow(index) {

        this.rows.splice(index, 1)

    }

    setDefaultRows() {

        const defaultRow = new row()
        this.rows = [defaultRow]

    }

    print(){
        console.log("PALET RETURN ORDER ==>", JSON.stringify(this))
    }

}


class row {

    constructor() {
        this.date = new Date()
        
        this.soldTo = {}
        this.shipTo = {}
        this.contract = {}
        this.material = {}
        this.reference = "0"
        this.quantity = 0
        this.appQuantity = 0
        this.steps = new steps()
        this.errors = new errors()
        this.options = new options()
    }

    setSoldTo(selectedSoldTo){
        this.soldTo = selectedSoldTo
        this.steps.shipto = true
    }

    setShipTo(selectedShipTo) {
        this.shipTo = selectedShipTo
        this.steps.material = true
    }

    setMaterial(selectedMaterial) {
        this.material = selectedMaterial
        this.steps.reference = true
    }

    changeInputValue(name, newValue) {
        this[name] = parseInt(newValue)
    }
}

class steps {
    constructor() {
        this.shipto = false
        this.material = false
        this.reference = false
        this.quantities = false

        this.materialErr = false
    }
}

class options {
    constructor() {
        this.shipTos = []
        this.references = []
        this.materials = []
    }
}

class errors {
    constructor(){
        this.material = false
    }
}