<template>
    <ModalWindow :isVisible="isVisible" @hide="close" @show="open" size="900px">
        <template #header>
            <h3>{{ $t('Create Driver') }}</h3>
        </template>
        <div class="modal w-100 p-5 ml-3 mr-3">
            <form v-on:submit.prevent="createDriver" ref="driversForm" id="driversForm">
                <div class="columns">
                    <div class="column">
                        <div class="flex flex-column gap-2">
                            <label for="imqc"><b>{{ $t('MPQC Id *') }}</b></label><br>
                            <InputText id="imqc" v-model="driversForm.MPQCId.value" aria-describedby="IMQC"
                                @keydown="driverExists = false" :class="driversForm.MPQCId.validation_status"/>
                            <br>
                            <label style="color:red;" v-if="driverExists"><small>
                                {{ $t('The driver with this MPQC already exists') }}</small></label>
                        </div>

                        <div class="flex flex-column gap-2 mt-5">
                            <label for="imqcDate"><b>{{ $t('MPQC Expiry date *') }}</b></label><br>
                            <Calendar v-model="driversForm.MPQCExpiryDate.value" :dateFormat="calendarFormat.calendar"  
                                :class="driversForm.MPQCExpiryDate.validation_status"/>
                        </div>

                        <div class="flex flex-column gap-2 mt-5">
                            <label for="intDriverName"><b>{{ $t('Internal Driver Name * ') }}</b></label><br>
                            <InputText id="intDriverName" v-model="driversForm.internalDriverName.value"
                                aria-describedby="intDriverName" :class="driversForm.internalDriverName.validation_status" />
                        </div>

                        <div class="flex flex-column gap-2 mt-5">
                            <label for="basePlant"><b>{{ $t('Base Plant * ') }}</b></label><br>
                            <Dropdown v-model="driversForm.basePlant.value" :options="plants" filter optionLabel="name"
                                @change="getClusters" optionValue="code" placeholder="Select a Plant" style="width: 206px"
                                :class="driversForm.basePlant.validation_status">
                            </Dropdown>
                        </div>

                        <div class="flex flex-column gap-2 mt-5">
                            <label for="cluster"><b>{{ $t('Cluster *') }}</b></label><br>
                            <InputText id="cluster" disabled v-model="driversForm.cluster.value.code" aria-describedby="cluster"
                                :class="driversForm.cluster.validation_status"/>
                        </div>

                        <div class="flex flex-column gap-2 mt-5">
                            <label for="phoneNumber"><b>{{ $t('Phone *') }}</b></label><br>
                            <InputText id="phoneNumber" v-model="driversForm.phoneNumber.value" aria-describedby="phone Number" 
                                :class="driversForm.phoneNumber.validation_status"/>
                        </div>

                    </div>
                    <div class="column">
                        <div class="flex flex-column gap-2">
                            <label for="licenseNumber"><b>{{ $t('Driver License Number') }}</b></label><br>
                            <InputText id="licenseNumber" v-model="driversForm.driverLicenseNumber.value"
                                aria-describedby="licenseNumber" :class="driversForm.driverLicenseNumber.validation_status"/>
                        </div>

                        <div class="flex flex-column gap-2 mt-5">
                            <label for="expDate"><b>{{ $t('Drivers Expiry Date') }}</b></label><br>
                            <Calendar v-model="driversForm.driversExpiryDate.value" :dateFormat="calendarFormat.calendar" 
                                :class="driversForm.driversExpiryDate.validation_status"/>
                        </div>

                        <div class="flex flex-column gap-2 mt-5">
                            <label for="address"><b>{{ $t('Address') }}</b></label><br>
                            <InputText id="address" v-model="driversForm.address.value" aria-describedby="address" 
                                :class="driversForm.address.validation_status"/>
                        </div>

                        <div class="flex flex-column gap-2 mt-5">
                            <label for="city"><b>{{ $t('City') }}</b></label><br>
                            <InputText id="city" v-model="driversForm.city.value" aria-describedby="city" 
                                :class="driversForm.city.validation_status"/>
                        </div>

                        <div class="flex flex-column gap-2 mt-5">
                            <label for="postalCode"><b>{{ $t('Postal Code') }}</b></label><br>
                            <InputText id="postalCode" v-model="driversForm.postalCode.value" aria-describedby="postal Code" 
                                :class="driversForm.postalCode.validation_status"/>
                        </div>

                        <div class="flex flex-column gap-2 mt-5">
                            <label for="comments"><b>{{ $t('Comments') }}</b></label><br>
                            <Textarea id="comments" cols="22" v-model="driversForm.comments.value" aria-describedby="comments"
                                :class="driversForm.comments.validation_status"></Textarea>
                        </div>
                    </div>
                    <div class="column">
                        <div class="flex flex-column gap-2">
                            <Image :src='defaultImg' alt="Image" width="250" />
                            <label for="file-upload" class="custom-file-upload"> {{ $t('Upload image') }} </label>
                            <input id="file-upload" type="file" ref="image" @change="uploadFile($event)">
                            <label style="color:red;" v-if="payloadError"><small>
                                {{ $t('File too long, try to send one smaller') }}</small></label>
                        </div>
                        <div class="flex flex-column gap-2 mt-5">
                            <label for="status" class="mb-3"><b>{{ $t('Status') }}</b></label><br>
                            <InputSwitch v-model="driversForm.status.value" />
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <template #footer>
            <div class="loader" style="float:right; margin-right: 20px;" v-if="loading"></div> 
            <div style="float: right; margin-right: 1rem;" v-if="!loading">
                <Button class="p-button btn btn--primary" type="submit" :disabled="loading" form='driversForm' :label="$t('Save')" />
                <Button class="p-button btn btn--secondary" :label="$t('Close')" :disabled="loading" @click="close" />
            </div>
        </template>
    </ModalWindow>
</template>

<script>
import ModalWindow from "./core/ModalWindow.vue";
import InputText from 'primevue/inputtext';
import Image from 'primevue/image';
import FileUpload from 'primevue/fileupload';
import InputSwitch from 'primevue/inputswitch';
import Calendar from 'primevue/calendar';
import Dropdown from 'primevue/dropdown';
import Button from 'primevue/button';
import defaultImage from '@/assets/userUnknown.jpg';

import plantsService from '@/services/api/plants';
import regionService from '@/services/api/regions';
import driversService from "@/services/api/drivers";
import Textarea from 'primevue/textarea';
import moment from "moment";

export default {
    name: "DriverModal",
    components: {
        ModalWindow,
        InputText,
        Image,
        FileUpload,
        InputSwitch,
        Calendar,
        Dropdown,
        Button,
        Textarea
    },
    props: {
        driver: [],
        isVisible: {
            type: Boolean,
            default: false,
        },
        drivers: [],
        message: '',
    },
    data() {
        return {
            calendarFormat: JSON.parse(localStorage.getItem('dateFormat')) || { calendar: 'dd/mm/yy', moment: 'DD/MM/YY' },
            driversForm: {
                MPQCId: { value: '', required: true, read_only: false, name: 'MPQCId', validation_status: 'none' },
                MPQCExpiryDate: { value: '', required: true, read_only: false, name: 'MPQCExpiryDate', validation_status: 'none' },
                internalDriverName: { value: '', required: true, read_only: false, name: 'internal Driver Name', validation_status: 'none' },
                basePlant: { value: '', required: true, read_only: false, name: 'base Plant', validation_status: 'none' },
                city: { value: '', required: false, read_only: false, name: 'city', validation_status: 'none' },
                driverLicenseNumber: { value: '', required: true, read_only: false, name: 'driver License Number', validation_status: 'none' },
                driversExpiryDate: { value: '', required: true, read_only: false, name: 'drivers Expiry Date', validation_status: 'none' },
                cluster: { value: '', required: true, read_only: false, name: 'cluster', validation_status: 'none' },
                address: { value: '', required: false, read_only: false, name: 'address', validation_status: 'none' },
                postalCode: { value: '', required: false, read_only: false, name: 'postal Code', validation_status: 'none' },
                status: { value: true, required: true, read_only: false, name: 'status', validation_status: 'none' },
                image: { value: '', required: false, read_only: false, name: 'image', validation_status: 'none' },
                phoneNumber: { value: '', required: false, read_only: false, name: 'phone Number', validation_status: 'none' },
                comments: { value: '', required: false, read_only: false, name: 'comments', validation_status: 'none' }
            },
            defaultImg: defaultImage,
            clusters: [],
            plants: [],
            imageName: '',
            contentType: '',
            payloadError: false,
            userCountry: JSON.parse(sessionStorage.getItem('user')).id_country,
            driverExists: false,
            isUpdate: false,
            imageChanged: false,
            loading: false,
            driversList: this.drivers

        }
    },
    mounted() {
        this.getPlants();
        //console.log('DRIVERS FORM --->', this.driversForm)

        if(this.driversList == undefined) {
            driversService.getDriversMysql().then(response => {
                this.driversList = response.data[0];
            }).catch(err => console.error(err))
        }
    },
    methods: {
        close(message) {
            this.resetForm();
            this.$emit("setDriver", {name: this.driversForm.internalDriverName.value, autoId: 0, value: this.driversForm.MPQCId.value})
            this.$emit("hide", message);
        },
        async open() {
            this.$emit("show");
            this.resetForm();
            this.checkUpdate();
        },
        async getPlants() {
            this.resetForm();
            plantsService.getPlantsByCountry(this.userCountry).then(resp => {
                this.plants = resp.data;
            });
        },
        async getClusters() {
            regionService.getClustersFromPlants([this.driversForm.basePlant.value]).then(resp => {
                if (resp.data.length > 0) {
                    this.driversForm.cluster.value = resp.data[0];
                }
            })
        },
        async getDriverImage(image) {
            await driversService.getDriverImage(image).then(resp => {
                if(resp.data.image == ''){
                    this.defaultImg = defaultImage;
                }else{
                    this.defaultImg = 'data:image/jpeg;base64,' + resp.data.image;
                    this.driver.image = this.defaultImg;
                }
            })
        },
        // validateForm(){
        //     //this.driversForm
        // },
        async createDriver(event) {
            //set driver data to masterdata
            this.loading = true;
            // let validForm = this.validateForm();
            let found = this.driversList.some(x => x.MPQCId == this.driversForm.MPQCId.value);
            this.driversForm.MPQCExpiryDate.value = moment(this.driversForm.MPQCExpiryDate.value).format('YYYY-MM-DD');
            this.driversForm.driversExpiryDate.value = moment(this.driversForm.driversExpiryDate.value).format('YYYY-MM-DD');
            
            if (!found) {
                if (this.driversForm.driversExpiryDate.value != undefined && this.driversForm.MPQCExpiryDate.value != '') {
                    let img = this.driversForm.image.value;
                    delete this.driversForm.image.value;
                    let driver = this.getDriversData();
                    driver.status = driver.status == false ? 0 : 1;
                    //console.log('DRIVER TO SEND TO MYSQL --------->', driver)
                    
                    await driversService.createDriver(driver);
                    await driversService.uploadDriverImage(img, this.driversForm.MPQCId.value, this.driversForm.basePlant.value, 
                        this.imageName, this.contentType).then(resp => {
                            this.close('CREATE');
                        });
                }
            } else {
                if (this.isUpdate) {
                    
                    if (this.imageChanged) { //image has changed
                        await driversService.uploadDriverImage(this.driversForm.image.value, this.driversForm.MPQCId.value, 
                            this.driversForm.basePlant.value, this.imageName, this.contentType);
                    }
                    let driver = this.getDriversData();
                    driver.status = driver.status == false ? 0 : 1;
                    //console.log('DRIVER TO SEND TO MYSQL --------->', driver)

                    await driversService.updateDriver(driver).then(resp => {
                        this.close('UPDATE');
                    })
                } else {
                    this.driverExists = true;
                }
            }
            
            this.loading = false;
        },
        getDriversData(){
            let driver = {};
            for(let field of Object.entries(this.driversForm)){
                const [key, value] = field;
                if(key == 'cluster'){
                    driver[key] = value.value.id;
                }else{
                    driver[key] = value.value;
                }
            }
            return driver;
        },
        async uploadFile(event) {
            const image = this.$refs.image.files[0];
            this.payloadError = false;
            this.imageName = image.name;
            this.contentType = image.type;
            this.imageChanged = true;
            if (image.size > 70000) {
                //image too large
                this.defaultImg = defaultImage;
                this.$refs.image.value = '';
                this.payloadError = true;
            } else {
                const reader = new FileReader();
                reader.readAsDataURL(image);
                reader.onload = e => {
                    this.defaultImg = e.target.result;
                    this.driversForm.image.value = e.target.result;
                };
            }

        },
        fillDriversForm(){
            for(let field of Object.entries(this.driver)){
                let [key, value] = field;
                //console.log('FIELD -->', field)
                value = value == null || value == 'null' ? '' : value;

                if (key == 'licenseNumber'){
                    this.driversForm.driverLicenseNumber.value = value;
                }else if (key == 'cluster'){
                    this.driversForm.cluster.value = { code: value, value: value };
                }else if(key != 'id' && key != 'imgUrl'){
                    this.driversForm[key].value = value;
                }
            }
        },
        checkUpdate() {
            if (this.driver != undefined && this.driver.MPQCId != '') {
                this.isUpdate = true;
                this.fillDriversForm();
                this.driversForm.MPQCExpiryDate.value = this.driversForm.MPQCExpiryDate.value != '' //&& this.driversForm.MPQCExpiryDate !=
                    ? new Date(this.driversForm.MPQCExpiryDate.value) : ""
                this.driversForm.driversExpiryDate.value = this.driversForm.driversExpiryDate.value != '' 
                    && this.driversForm.driversExpiryDate.value != '0000-00-00'
                    ? new Date(this.driversForm.driversExpiryDate.value) : "";
                this.driversForm.status.value = this.driversForm.status.value == 1 ? true : false;
                //this.driversForm.driverLicenseNumber.value = this.driversForm.licenseNumber.value;
                this.getClusters();
                this.getDriverImage(this.driver.imgUrl);
            } else {//new driver
                this.resetForm()
            }
        },
        resetForm() {
            this.driversForm.MPQCId.value = '',
            this.driversForm.MPQCExpiryDate.value = '',
            this.driversForm.internalDriverName.value = '',
            this.driversForm.basePlant.value = '',
            this.driversForm.city.value = '',
            this.driversForm.driverLicenseNumber.value = '',
            //this.driversForm.LicenseNumber.value = '',
            this.driversForm.driversExpiryDate.value = '',
            this.driversForm.cluster.value = '',
            this.driversForm.address.value = '',
            this.driversForm.postalCode.value = '',
            this.driversForm.status.value = true,
            this.driversForm.image.value = '';
            this.driversForm.phoneNumber.value = '';
            this.driversForm.comments.value = '';
            this.defaultImg = defaultImage;
            this.imageChanged = false;
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/css/index.scss";

.btn {
    height: 47.6px;
}

input[type="file"] {
    display: none;
}

.custom-file-upload {
    border: 1px solid black;
    display: inline-block;
    padding: 10px 71px;
    cursor: pointer;
    color: white;
    background-color: black;
}


.loader {
    border: 8px solid #f3f3f3; /* Light grey */
    border-top: 6px solid black; /* Blue */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.field_is_valid {
    border: 1px solid rgb(31, 236, 31);
}

.field_is_not_valid {
    border: 1px solid rgb(236, 62, 31);
}
</style>