<script setup>

import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import moment from 'moment';

import DeliveryModalVue from '../PendingToSignApp/DeliveryModal.vue';
import Status from '@/components/common/Status.vue';
import deliveryService from "@/services/api/delivery.js";
import Dialog from 'primevue/dialog';
import * as WeightUnit from '@/helper/weightUnit';
import * as Socket from '@/plugins/socket_client';
</script>

<script>
export default {
  data: function () {
    return {
      rows: 10,
      allFiltersVisible: false,
      Deliveries: [],
      DeliveriesAux: [],
      ordersList: [],
      deliveriesList: [],
      expandedRows: [],
      filters: null,
      filtersValues: [],
      currentDelivery: null,
      dataDelivery: { cnn: '', truckPlate: '' },
      currentOrder: null,
      currentOrderDeliveries: [],
      currentDate: new Date(),
      plantsCombo: null,
      userPlant: null,
      user: null,
      value: null,
      status: [{ id: 'status', value: 'Scheduled' }],
      selectedDelivery: null,
      param: this.$route.query.id || null,
      calendarFormat: JSON.parse(localStorage.getItem("dateFormat")) ? JSON.parse(localStorage.getItem("dateFormat")).calendar : 'dd/mm/yy',
      displayMomentFormat: JSON.parse(localStorage.getItem("dateFormat")) ? JSON.parse(localStorage.getItem("dateFormat")).moment : 'DD/MM/YYYY',
      numberMask: JSON.parse(localStorage.getItem("numberFormat")) || "de-DE",
      isBlocked: false,
      displayDeliver: false,
      openSignModal: false
    }
  },
  mounted() {
    this.getDeliveries(this.user);
    if (this.param !== null) {
      this.showNewModal(this.param, false)
    }
    let userPlant = JSON.parse(sessionStorage.getItem("user")).plant.code;
    Socket.add("pickup", userPlant);
  },
  created() {
    this.currentDate = moment().format("yyyy-MM-DD");
    let plants = JSON.parse(sessionStorage.getItem("user")).plants;
    this.plantsCombo = plants.map(p => ({ id: 'plant', value: p.code }));
    this.userPlant = JSON.parse(sessionStorage.getItem("user")).plant.code;
    this.filtersValues = [];
    this.user = JSON.parse(sessionStorage.getItem("user"));
    const socket = Socket.connect();
    socket.on("pickup", this.onEventHandler);
    Socket.add("pickup", this.userPlant)
  },
  computed: {
    console: () => console,
  },
  methods: {
    goTo(path) {
      if (path) this.$router.push(path);
    },
    onEventHandler(data) {
      if(typeof data.data.delivery !== 'undefined'){
        if(((typeof data.data.delivery._source.signPending !== 'undefined' ? data.data.delivery._source.signPending : true) && data.data.delivery._source.deliveryType == 'PickUp') && data.data.delivery._source.status == 'Shipping'){
          this.insertNewDelivery(data.data.delivery);
        }
      }
      
    },
    insertNewDelivery(newDelivery){
      let matches = this.Deliveries.filter( function(data){ return data._id == newDelivery._id } );
      if(matches.length == 0){
        this.Deliveries.push(newDelivery);
      }
    },
    getDeliveries(user) {
      deliveryService.getPendingDeliveries(user).then((response) => {
        this.Deliveries = response.data.data;
        this.DeliveriesAux = this.Deliveries;

      })
    },
    getDeliveriesList() {
      this.deliveriesList = [];
      this.Deliveries.forEach((d) => {
        this.deliveriesList.push(d._id)
      })
    },
    closeModal(data) {
      this.getDeliveries(this.user);
      this.$notify({
        title: this.$t("Delivery status changed"),
        type: 'success',
      });
      if (data === 'exit') {
        this.displayDeliver = false;
      }
    },
    showNewModal(id, openSignModal) {
      this.openSignModal = openSignModal;
      if (this.dataDelivery.cnn !== '') {
        this.currentDelivery = null;
      }
      this.getDeliveriesList();
      deliveryService.getDelivery(id).then((response) => {
        this.currentDelivery = response.data[0];
        this.displayDeliver = true;
        this.dataDelivery = { 
          cnn: this.currentDelivery._source.cnn != undefined ? this.currentDelivery._source.cnn : this.currentDelivery._source.deliveryNO , 
          truckPlate: this.currentDelivery._source.logistic.truck.plateNumber
        };
      })
    },
    assignBlocked(data) {
      this.isBlocked = data;
    },
    closeNewModal() {
      this.getDeliveries(this.user);
    }
  }
}
</script>

<template>
  <div class="app-content data-list orders">
    <div class="data-list__table row__orderList row">
      <DataTable :value="Deliveries" v-model:expandedRows="expandedRows" :paginator="true"
        :rows="rows" :rowsPerPageOptions="[3, 5, 10, 20, 50]"
        paginatorTemplate="RowsPerPageDropdown CurrentPageReport PrevPageLink PageLinks NextPageLink"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" contextMenu
        v-model:contextMenuSelection="selectedDelivery" scrollDirection="horizontal" ref="dt">
        <template #empty>
          <p>{{ $t('No matches') }}</p>
        </template>

        <Column :style="{ width: '10%' }" field="_source.status" :header="$t('Status')" :sortable="true">
          <template #body="slotProps">
            <Status v-if="slotProps.data._source.status" :name="slotProps.data._source.status" @click="showNewModal(slotProps.data._id, true)" class="clickable"/>
          </template>
        </Column>

        <Column :style="{ width: '10%' }" field="_source.deliveryNO" :header="$t('CNN')" :sortable="true">
          <template #body="slotProps">
            <span class="order-number-btn" @click="showNewModal(slotProps.data._id, false)">
              {{
                slotProps.data._source.deliveryNoteNumber ? slotProps.data._source.deliveryNoteNumber :
                slotProps.data._source.deliveryNO
              }}</span>
          </template>
        </Column>

        <Column :style="{ width: '10%' }" field="_source.deliveryLocalDate" :header="$t('Date')" :sortable="true">
          <template #body="slotProps">
            <span @click="showNewModal(slotProps.data._id, true)" class="clickable">{{
              moment(slotProps.data._source.deliveryLocalDate).format(displayMomentFormat)
            }}</span>
          </template>
        </Column>

        <Column :style="{ width: '12%' }" field="_source.shipto.address.name" :header="$t('Ship to')" :sortable="true">
          <template #body="slotProps">
            <div class="nowrap clickable" @click="showNewModal(slotProps.data._id, true)">
              {{ slotProps.data._source.shipto.address.name }}
            </div>
          </template>
        </Column>

        <Column :style="{ width: '17%' }" field="_source.netWeight" :header="$t('Net weight')" :sortable="true">
          <template #body="slotProps">
            <div class="nowrap clickable" @click="showNewModal(slotProps.data._id, true)">
              {{ slotProps.data._source.netWeight + " " + WeightUnit.getUnitISOCode(slotProps.data._source.products != undefined ? slotProps.data._source.products[0].item.uom || 'TNE' : 'TNE') }}
            </div>
          </template>
        </Column>

        <Column :style="{ width: '25%' }" field="_source.logistic.carrier.name" :header="$t('Haulier')" :sortable="true">
          <template #body="slotProps">
            <div v-if="slotProps.data._source.logistic.carrier && slotProps.data._source.logistic.carrier.name"
              class="nowrap clickable" @click="showNewModal(slotProps.data._id, true)">
              {{ slotProps.data._source.logistic.carrier.name }}
            </div>
          </template>
        </Column>

        <Column :style="{ width: '10%' }" field="_source.logistic.truck.plateNumber" :header="$t('Truck')"
          :sortable="true">
          <template #body="slotProps">
            <div v-if="slotProps.data._source.logistic.truck && slotProps.data._source.logistic.truck.plateNumber"
              class="nowrap clickable" @click="showNewModal(slotProps.data._id, true)">
              {{ slotProps.data._source.logistic.truck.plateNumber }}
            </div>
          </template>
        </Column>

        <Column :style="{ width: '5%' }" field="_source.status">
          <template #body="slotProps">
            <div class="sign_btn"  @click="showNewModal(slotProps.data._id, true)">
              {{ $t('Sign') }}
              <i class="zmdi zmdi-border-color"></i>
            </div>
          </template>
        </Column>

      </DataTable>

      <Dialog :header="'Conveyance Note #' + dataDelivery.cnn + ' - Truck ' + dataDelivery.truckPlate"
        v-model:visible="displayDeliver" :breakpoints="{ '960px': '75vw', '640px': '90vw' }" :style="{ width: '972px' }"
        :dismissable-mask="true" closeOnEscape closable :modal="true" @hide="closeNewModal">
        <DeliveryModalVue v-if="currentDelivery !== null" @checkData="closeModal($event)"
          :delivery="currentDelivery" :deliveriesList="deliveriesList" :openSignModal="openSignModal"
          @checkBlockedD="assignBlocked($event)" />
      </Dialog>

    </div>
  </div>
</template>

<style lang="scss">
@use '@/assets/css/lh-ds/core/functions' as *;
@use '@/assets/css/hodim/layout/data-list';
@use '@/assets/css/hodim/layout/data-list/ordercard';
@use '@/assets/css/hodim/layout/data-list/multiselect';
@import '@/assets/css/hodim/layout/data-list/calendar';

.clickable {
  cursor: pointer;
}

.sign_btn {
  border: 2px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  color: black;
  border-radius: 3px;
  height: 35px;
  cursor: pointer;
  .zmdi {
    margin-left: 5px;
  }
}

.sign_btn:hover {
  background-color: black;
  color: white;
}

.orders {

  .p-paginator-rpp-options {
    margin-left: 0;
    margin-right: auto;
  }

  lh-dialog {
    &::part(body) {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.order-number-btn {
  cursor: pointer;
  font-weight: bold;
}

// in order to nowrap work, table needs to have a width
.p-datatable table {
  min-width: auto;
  width: 100%;
}

.p-dialog-title {
  margin: auto;
}

.data-list__table.row__orderList,
.data-list__table.row.row__orderList {
  margin-top: 0 !important;
}
</style>
