<template>
  <Step :title="$t('Weighbridges')" class="weighbridges-step wOut" >


    <!-- Si hay delivery cargada puede:
    meter entry weight
    meter exit weight 
    todo desde wb o manual -->


    <!-- Cajas Pesos con delivery -->
    <!-- Esto solo sale si hay una delivery cargada -->
    <!-- Si no la hay, únicamente se permite tarar el vehículo por WB y manual -->
    
    <div class="is-flex is-flex-direction-column">
    <div class="column" v-if="delivery !== null">        
      <!-- Entry -->
        <div class="columns">
          <div class="column">
            <FormControl :label="$t('Entry Weight')" class="inputWeight">
              <InputText id="Entry" :style="{
                width: '150px', height: '50px', color: '#000',
                'border-color': disabledEntryWeightInput === true && manualEntry == false ? '#0089FF' : entryWeight.weight == 0 ? '#e08600' : '#d8d8d8'
              }"
                :value="transformToUnit(entryWeight.weight, entryWeight.weight >= 100 ? entryExitWeightUnit : 'TN', shownWeighingUOM)"
                :disabled=disabledEntryWeightInput
                @keyup.enter="setManuallyWeight($event, 'InputText')"

              />

              <div class="input_custom_tag">
                <p>{{ shownWeighingUOM }}</p>
              </div>
              <i v-if="entryWeight.weight != 0 && delivery._source.firstWeight.weight == 0" id="Entry" :style="{ 'margin-top': '15px', 'margin-left': '20px' }" class="pi pi-trash"
                @click="clearWeightInput" />
            </FormControl>
          </div>
          <div class="column">
            <FormControl :label="$t('Date')">
              {{ entryWeight.date ? moment(entryWeight.date).tz(userTimezone).format(displayMomentFormat) : "" }}
            </FormControl>
          </div>
          <div class="column">
            <FormControl :label="$t('Time')">
              {{ entryWeight.date ? moment(entryWeight.date).tz(userTimezone).format("HH:mm:ss") : "" }}
            </FormControl>
          </div>
        </div>

         <!-- Exit -->
        <div class="columns" v-if="entryWeight.weight != 0">
          <div class="column">
            <FormControl :label="$t('Exit Weight')" class="inputWeight">
              <InputText id="Exit" :style="{
                width: '150px', height: '50px', color: '#000',
                'border-color': '#d8d8d8'
              }"
                :value="transformToUnit(exitWeight.weight, exitWeight.weight >= 100 ? entryExitWeightUnit : 'TN', shownWeighingUOM)"
              :disabled=disabledExitWeightInput
                @keyup.enter="setManuallyWeight($event, 'InputText')"

              />

              <div class="input_custom_tag">
                <p>{{ shownWeighingUOM }}</p>
              </div>
              <i v-if="exitWeight.weight != 0" id="Exit" :style="{ 'margin-top': '15px', 'margin-left': '20px' }" class="pi pi-trash"
              @click="clearWeightInput" />
            </FormControl>
          </div>
          <div class="column">
            <FormControl :label="$t('Date')">
              {{ exitWeight.date ? moment(exitWeight.date).tz(userTimezone).format(displayMomentFormat) : "" }}
            </FormControl>
          </div>
          <div class="column">
            <FormControl :label="$t('Time')">
              {{ exitWeight.date ? moment(exitWeight.date).tz(userTimezone).format("HH:mm:ss") : "" }}
            </FormControl>
          </div>
        </div>

        <!-- Net -->
        <div class="columns" v-if="currentNetWeight">
          <div class="column">
            <FormControl :label="$t('Net Weight')" class="inputWeight">
              <InputText id="Net" :style="{
                width: '150px', height: '50px', color: '#000',
                'border-color': '#d8d8d8'
              }"
                :value=currentNetWeight
                :disabled=true
              />

              <div class="input_custom_tag">
                <p>{{ shownWeighingUOM }}</p>
              </div>
            </FormControl>
          </div>
          <div class="column"></div>
          <div class="column"></div>
        </div>
    </div>

    <!-- Cajas WB -->

    <div class="column">
        <div class="columns weightDialog">
          <div v-if="disabledWeightInput" class="column" v-for="wb in weighbridges">
            <Weighbridge @setWeight="setWeight" :weighbridge="wb" />
          </div>

          <FormControl v-else :label="$t('Entry Weight')" class="inputWeight" :style="{ marginRight: '20px'}">
            <InputText id="Entry" 
              :style="{ width: '200px', height: '50px', 'color': '#000', 'border-color': '#d8d8d8' }"
              :value="transformToUnit(entryWeight.weight, entryExitWeightUnit, shownWeighingUOM)"
              @keyup.enter="setManuallyWeight($event, 'InputText')" @update:modelValue="updateDataState"
            />

            <div class="input_custom_tag">
              <p>{{ shownWeighingUOM }}</p>
            </div>
          </FormControl>

          <i v-if="permissions.weight.find(w => w.name_menu === 'Manual Weight').mode.includes('WR') && delivery == null"
            :style="{ 'margin-top': '16px' }" class="zmdi zmdi-edit" @click="disabledWeightInput = !disabledWeightInput"></i>
        </div>

        <ManualEntryWeightModal v-if="openManualEntry" @saved="saveManualEntryWeight" @close="closeManualEntry()" />
      </div>
  </div>
  </Step>

  <Dialog :header="'Record vehicle tare'" v-model:visible="tareModal"
      :dismissable-mask="true" 
      :style="{'width': '40rem'}">
      <div class="alertModal">
          <p>{{ $t('Wants to record the vehicle tare?')}} </p>
          <div class="alertButtons">
              <lh-button size="medium" type="primary" @click="confirmRecordTare(false)">{{ $t('NO') }}</lh-button>
              <lh-button size="medium" @click="confirmRecordTare(true)">{{ $t('YES') }}</lh-button>
          </div>
      </div>
  </Dialog>

</template>


<script setup>
import Step from "@/components/dispatch/order-outbound/utils/step.vue";
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import ManualEntryWeightModal from '@/components/dispatch/byTruck/ManualEntryWeightModal'
import Weighbridge from '@/components/Deliveries/weighbridges/weighbridge.vue';
import FormControl from "@/components/common/FormControl.vue";

import VehicleService from "@/services/api/vehicles.js";
import WeighbridgesService from "@/services/api/weighbridges.js";

import moment from 'moment';
import * as Validation from '@/helper/validateWeights';
import { transformToUnit } from '@/helper/weightUnit';
import commonClasses from "@/store/commonClasses.js";
</script>

<script>
export default {
  name: 'WeighbridgesOut',
  components: {
    Step,
    InputText,
    ManualEntryWeightModal,
    Weighbridge,
    FormControl,
    Dialog
  },
  data: function () {
    return {
      weighbridges: [],
      weighingExpirationRange: null,
      disabledWeightInput: true,
      disabledEntryWeightInput: false,
      disabledExitWeightInput: false,
      entryWeight: {
        date: null,
        weight: 0,
        weightBridge: null,
        weight_number: '',
        user: {
          userID: '',
          userName: ''
        }
      },
      exitWeight: {
        date: null,
        weight: 0,
        weightBridge: null,
        weight_number: '',
        user: {
          userID: '',
          userName: ''
        }
      },
      entryExitWeightUnit: 'KGM',
      shownWeighingUOM: 'TNE',
      plant: null,
      user: null,
      permissions: null,  
      userTimezone: null,
      displayMomentFormat: JSON.parse(localStorage.getItem("dateFormat")) ? JSON.parse(localStorage.getItem("dateFormat")).moment : 'DD/MM/YYYY',
      openManualEntry: false,
      manualEntry: false,
      vehicleTare: null,
      tareModal: false
    }
  },
  created() {
    this.plant = JSON.parse(sessionStorage.getItem("user")).plant
    this.user = JSON.parse(sessionStorage.getItem("user"))
    this.userTimezone = this.user.country.timezone
    this.permissions = this.user.menu
    this.weighingExpirationRange = JSON.parse(localStorage.getItem("userPlant")).plant_parameters.find(p => p.code == 'latest_weight_days').value

    if (this.user.terminal !== undefined && this.user.terminal !== null) {
      this.getWeighbridges(this.user.terminal.id, this.plant.id)
    }

    if(this.delivery != null) {
      this.loadWeightInputs()
    }
    // if (this.delivery != null && this.delivery._source.status == commonClasses.deliveryStatus.Loading) {
    //   // console.log ("entryWeight from delivery firstWeight!")
    //   this.entryWeight = this.delivery._source.firstWeight
    //   this.disabledEntryWeightInput = true
    // } else if (this.delivery != null && this.delivery._source.status == commonClasses.deliveryStatus.Scheduled) {
    //   // Si el camión tiene tara válida y hay una delivery cargada en estado Schedule.. entryWeight == peso vehículo
    //   // Lo replico cuando se carga la delivery en el watch
    //   // console.log ("entryWeight from vehicle tare!")
    //   if (!this.tareExpired) {
    //     this.entryWeight = this.$store.state.dispatch.entryWeight
    //     this.disabledEntryWeightInput = true
    //   }
    // }
  },
  methods: {
    getWeighbridges(terminalId, plantId) {
      WeighbridgesService.getWeighbridgeByTerminalAndPlant(terminalId, plantId).then((response) => {
        if (response.data.length > 0) {
          this.weighbridges = response.data
        }
      }).catch((err) => { console.error(err) })
    },
    async setWeight(wb) {
      
      let weight = Number(wb.weight * 1000)
      // Solo tarar camion pesada camion
      if (this.delivery === null && this.toTareVehicle == true) {
        try {
          let stopper = await this.validate(this.currentTruck, weight, 'TARE', 'OUT', this.currentTrailer, null, this.entryExitWeightUnit)
          if (!stopper) {
            this.entryWeight = {
              date: moment().tz(this.userTimezone).format('yyyy-MM-DDTHH:mm:ss.SSSZ'),
              weight,
              weightBridge: wb.wb.id,
              weight_number: "",
              user: {
                userID: this.user.id_user,
                userName: this.user.username
              }
            };

            this.vehicleTare = { // se usa en la confirmación de confirmRecordTare
              vehicle_id: this.currentTruck.vehicleId,
              trailer_id: this.currentTrailer ? this.currentTrailer.vehicleId : undefined,
              tare: wb.weight,
              date: moment().tz(this.userTimezone).format('yyyy-MM-DDTHH:mm:ss.SSSZ'),
              user: this.user.username,
              weight_number: "", //número de pesada
              weightbridge: wb.wb.id
            }
            
            // this.$store.commit('dispatch/setEntryWeight', this.entryWeight)
            this.disabledEntryWeightInput = true
            
            this.tareModal = true
          }
        } catch (err) {
          console.error('ERROR CREATING VEHICLE TARE -->', err)
        }
      }

      // EntryWeight para la delivery cargada
      else if (this.delivery != null && this.entryWeight.weight == 0 && this.toTareVehicle == false) {
        try {
          let stopper = await this.validate(this.currentTruck, weight, 'TARE', 'OUT', this.currentTrailer, null, this.entryExitWeightUnit)
          if (!stopper) {
            this.entryWeight = {
              date: moment().tz(this.userTimezone).format('yyyy-MM-DDTHH:mm:ss.SSSZ'),
              weight,
              weightBridge: wb.wb.id,
              weight_number: "",
              user: {
                userID: this.user.id_user,
                userName: this.user.username
              }
            };
    
            this.$store.commit('dispatch/setEntryWeight', this.entryWeight)
            this.disabledEntryWeightInput = true
          }
        } catch (err) {
          console.error('ERROR CREATING VEHICLE TARE -->', err)
        }
      }

      // ExitWeight para la delivery cargada
      else if (this.delivery != null && this.entryWeight.weight != 0 && this.exitWeight.weight == 0 && this.toTareVehicle == false) {
        try {
          let stopper = await this.validate(this.currentTruck, weight, 'MWA', 'OUT', this.currentTrailer, null, this.entryExitWeightUnit)
          if (!stopper) {
            this.exitWeight = {
              date: moment().tz(this.userTimezone).format('yyyy-MM-DDTHH:mm:ss.SSSZ'),
              weight: Number(wb.weight * 1000),
              weightBridge: wb.wb.id,
              weight_number: "",
              user: {
                userID: this.user.id_user,
                userName: this.user.username
              }
            }

            this.$store.commit('dispatch/setExitWeight', this.exitWeight)
            this.$store.commit('dispatch/setNetWeight', this.currentNetWeight)
            this.disabledExitWeightInput = true

          }

        } catch (err) {
          console.error('ERROR EXIT WEIGHT -->', err)
        }
      }

    },
    async setManuallyWeight(e) {
      
      let weight = Number(e.target['value'] * 1000)

      if (e.target['id'] === 'Entry') {
        try {
          let stopper = await this.validate(this.currentTruck, weight, 'TARE', 'OUT', this.currentTrailer, null, this.entryExitWeightUnit)
          if (!stopper) {
            this.entryWeight = {
              date: moment().tz(this.userTimezone).format('yyyy-MM-DDTHH:mm:ss.SSSZ'),
              weight: transformToUnit(e.target['value'], this.shownWeighingUOM, this.entryExitWeightUnit),
              weightBridge: this.user.fullname,
              weight_number: '',
              manual: true,
              reason: '',
              reasonCode: '',
              user: { 
                userID: this.user.id_user,
                userName: this.user.username
              }
            }
            this.disabledEntryWeightInput = true
            this.manualEntry = true
            this.openManualEntry = true

          }
        
          // const stopper = await this.validate(this.currentTruck, this.entryWeight.weight, 'TARE', 'OUT', this.currentTrailer, null, this.entryExitWeightUnit)
          // if (stopper === false && this.delivery == null) {
          //   this.openManualEntry = true
          //   // this.$store.commit('dispatch/setEntryWeight', this.entryWeight)
          //   // this.currentVehicle.minLoad = Math.max(this.currentVehicle.mwa - e.target['value'] - 0.2, 0);
          //   // this.editVehicleTare(this.currentVehicle.vehicleId, e.target['value'], this.currentVehicle.minLoad);
          // }
        } catch (err) {
          console.error('ERROR CREATING VEHICLE TARE -->', err)
        }

			} else if (e.target['id'] === 'Exit') {
        try {
          let stopper = await this.validate(this.currentTruck, weight, 'MWA', 'OUT', this.currentTrailer, null, this.entryExitWeightUnit)
          
          if (!stopper) {
            this.exitWeight = {
              date: moment().tz(this.userTimezone).format('yyyy-MM-DDTHH:mm:ss.SSSZ'),
              weight: transformToUnit(e.target['value'], this.shownWeighingUOM, this.entryExitWeightUnit),
              weightBridge: this.user.fullname,
              weight_number: '',
              manual: true,
              reason: '',
              reasonCode: '',
              user: { 
                userID: this.user.id_user,
                userName: this.user.username
              }
            }

            this.$store.commit('dispatch/setExitWeight', this.exitWeight)
            this.$store.commit('dispatch/setNetWeight', this.currentNetWeight)
            this.disabledExitWeightInput = true
            this.manualEntry = true

          }
        } catch (err) {
          console.error('ERROR MANUAL EXIT WEIGHT -->', err)
        }

        // try {
        //   const stopper = await this.validate(this.currentTruck, this.exitWeight.weight, 'MWA', 'OUT', this.currentTrailer, null, this.entryExitWeightUnit)
        // } catch (err) {
        //   console.error (err)
        // }
				// this.manuallyWeightExit = transformToUnit(e.target['value'], this.shownWeighingUOM, this.entryExitWeightUnit);
				// this.currentExitWeight = this.manuallyWeightExit;
				// this.disabledExitWeightInput = true;
				// this.currentWeightBridgeExit = JSON.parse(sessionStorage.getItem("user")).fullname;
				// this.dateExitWeight = moment().tz(this.userTimezone).format('yyyy-MM-DDTHH:mm:ss.SSSZ');
				// this.userIDExitWeight = JSON.parse(sessionStorage.getItem("user")).id_user;
				// this.userNameExitWeight = JSON.parse(sessionStorage.getItem("user")).fullname;
				// this.validateWeights()
			}
		},
    saveManualEntryWeight(data) {
        this.entryWeight.reason = data.manualEntryReason
        this.entryWeight.reasonCode = data.manualEntryReasonDescription
        this.openManualEntry = false
        this.$store.commit('dispatch/setEntryWeight', this.entryWeight)
    },
    closeManualEntry() {
      this.openManualEntry = false
    },
    async validate(vehicle, weight, type, direction, trailer, deliveryNetW, uom, numMaterials) {
      let validation = await Validation.validate(vehicle, weight, type, direction, trailer, deliveryNetW, uom, numMaterials);
      this.$notify({
        title: validation.title,
        text: validation.text,
        type: validation.type,
        duration: 5000 // validation.stop === true ? 5000 : 3000
      })
      return validation.stop
    },
    clearWeightInput(e) {
      if (e.target['id'] === 'Entry') {
				this.disabledEntryWeightInput = false;
        this.entryWeight = {
          date: null,
          weight: 0,
          weightBridge: null,
          weight_number: '',
          user: {
            userID: '',
            userName: ''
          }
        }
        this.$store.commit("dispatch/resetEntryWeight");
			}
      if (e.target['id'] === 'Exit') {
				this.disabledExitWeightInput = false;
        this.exitWeight = {
          date: null,
          weight: 0,
          weightBridge: null,
          weight_number: '',
          user: {
            userID: '',
            userName: ''
          }
        }
        this.$store.commit("dispatch/resetExitWeight");
			}
		},
    loadWeightInputs() {
      switch(this.delivery._source.status) {

        case commonClasses.deliveryStatus.Loading: 
          this.entryWeight = this.delivery._source.firstWeight
          this.disabledEntryWeightInput = true
          break;

        case commonClasses.deliveryStatus.Scheduled:
          if (!this.tareExpired) {
            this.entryWeight = this.$store.state.dispatch.entryWeight
            this.disabledEntryWeightInput = true
          }
          break;

        case commonClasses.deliveryStatus.OnHold:
          break;
        
        case commonClasses.deliveryStatus.Deleted:
          break;
        
        case commonClasses.deliveryStatus.Returned:
          break;

        case commonClasses.deliveryStatus.Blocked:
          break;

        default:
          this.entryWeight = this.delivery._source.firstWeight
          this.exitWeight = this.delivery._source.secondWeight
      }
    },
    async confirmRecordTare(confirm) {
      confirm
        ? await VehicleService.createVehicleTare(this.vehicleTare)
        : this.$store.commit('dispatch/setOneTimeTare', true )

      this.$store.commit('dispatch/setEntryWeight', this.entryWeight)
      this.tareModal = false
    }
  },
  computed: {
    orderType() {
      return this.$store.state.dispatch.orderType
    },
    currentTruck() {
      return this.$store.state.dispatch.currentTruck
    },
    currentTrailer() {
      return this.$store.state.dispatch.currentTrailer
    },
    delivery() {
      return this.$store.state.dispatch.delivery
    },
    currentNetWeight() {
      return this.entryWeight.weight != 0 && this.exitWeight.weight != 0
        ? transformToUnit(this.exitWeight.weight, 'KGM', 'TNE') - transformToUnit(this.entryWeight.weight, 'KGM', 'TNE')
        : null  
    },
    toTareVehicle() {
      return this.$store.state.dispatch.toTareVehicle
    },
    tareExpired() {
      return this.$store.state.dispatch.tareExpired;
    },

  },
  watch: {
   '$store.state.dispatch.delivery'(newVal, oldVal) {
      if (newVal != null) {
        if (newVal._source.status == 'Loading' && this.orderType != 'Inbound') {
          this.entryWeight = newVal._source.firstWeight
          this.disabledEntryWeightInput = true
        } else if (newVal._source.status == 'Scheduled' && this.orderType != 'Inbound') {
          // Si el camión tiene tara válida y hay una delivery cargada en estado Schedule.. entryWeight == peso vehículo
          // Lo replico cuando se carga la delivery en el watch
          console.log ("entryWeight from vehicle tare!")
          if (!this.tareExpired) {
            this.entryWeight = this.$store.state.dispatch.entryWeight
            this.disabledEntryWeightInput = true
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" >
@import '@/assets/css/hodim/index.scss';
@import '@/assets/css/lh-ds/core/functions';

.weighbridges-step {
  &.overlay::before {
    content: '';
    position: absolute;
    inset: 0;
    z-index: 1;
    background-color: #fff;
    opacity: 0.5;
  }

  &__input {
    display: flex;

    &-custom-tag {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50px;
      background-color: rgb(216, 216, 216);
      margin: 0px !important;
      font-size: 0.875rem;
    }

    i {
      margin-left: 1.2rem;
    }
  }
  &.wOut {
    .new-order__step-content-wrapper {
      display: flex;
    }
  }

  .inputWeight {
    div.form-control__input {
      display: flex;
    }

    .input_custom_tag {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50px;
      background-color: rgb(216, 216, 216);
      height: 50px;
    }
  }

  .alert__text {
    color: red;
    margin-bottom: 10px;
  }
}
</style>