<template>
  <div id="truck_map" ref="truck_map"></div>
</template>
<script>
import * as gmaps from "@/plugins/here";
import InputText from "primevue/inputtext";
import AutoComplete from "primevue/autocomplete";
import config from '@/../config'
import { app } from '@/main.js'


export default {
  name: "Map",
  props: ["route", "routeToCalculate"],
  components: {
    AutoComplete,
    InputText,
  },
  data() {
    return {
      platform: null,
      apikey: app.config.globalProperties.$config.HERE_MAPS.API_KEY,
      center: { lat: 48.499998, lng: 23.3833318 },
      map: null,
      coordenadas: { lat: '', lng: ''},

      countrySearch: null,
      countriesSearch: null,
    };
  },
  watch: {
    routeToCalculate(newRoute, oldRoute){
      console.log('new Route To calculate ==>', newRoute)
      console.log('old To calculate ==>', oldRoute)
      this.getRoutes(this.map)
    },
    route(newRoute, oldRoute){
      this.getRoutes(this.map)
    }
  },
  created() { },
  async mounted() {
    // Initialize the platform object:
    const platform = new window.H.service.Platform({
      apikey: this.apikey,
    });
    this.platform = platform;
    this.initializeHereMap();
  },
  methods: {
    initializeHereMap() {
      const mapContainer = this.$refs.truck_map;
      const H = window.H;
      // Obtain the default map types from the platform object
      var maptypes = this.platform.createDefaultLayers();

      // Instantiate (and display) a map object:
      var map = new H.Map(mapContainer, maptypes.vector.normal.map, {
        zoom: 10,
        center: { lat: 52.5160, lng: 13.3779 },
        // center object { lat: 40.730610, lng: -73.935242 }
      });
      this.map = map;

      addEventListener("resize", () => map.getViewPort().resize());

      // add behavior control
      new H.mapevents.Behavior(new H.mapevents.MapEvents(map));

      // add UI
      H.ui.UI.createDefault(map, maptypes);

      // Listener para añadir Marker
      map.addEventListener("tap", (e) => this.onClick(e, map));

      // End rendering the initial map
      //console.log('POLYLINE TO SHOT ==>', this.route)
      this.getRoutes(map)
    },
    getRoutes(map) {
      let routingParameters = {
        // The routing mode:
        routingMode: 'fast',
        transportMode: 'truck',
        // The start point of the route:
        origin: `${this.routeToCalculate !== null ? this.routeToCalculate.origin.lat : '52.5160'},${this.routeToCalculate !== null ? this.routeToCalculate.origin.lng : '13.3779'}`,
        // The end point of the route:
        destination: `${this.routeToCalculate !== null ? this.routeToCalculate.destination.lat : '52.5206'},${this.routeToCalculate !== null ? this.routeToCalculate.destination.lng : '13.3862'}`,
        // To retrieve the shape of the route we choose the route
        // representation mode 'display'
        return: 'polyline,turnByTurnActions,actions,instructions,travelSummary',
      };

      if (this.routeToCalculate !== null) {
        routingParameters.arrivalTime = `${this.routeToCalculate.siteArrival}`
      }

      const onResult = (result) => {
        let route,
          routeShape,
          startPoint,
          endPoint,
          linestring;

        if (result.routes) {

          route = result.routes[0];

          if(this.routeToCalculate !== null) {
            this.getReturnTrip(route, map);
          }
          else{
            if(this.route === 0){
              this.$emit('routeCalculate', route)
            }

            this.clearPreviousRoutes(map)
            this.addRouteShapeToMap(route, map)
          }

        }
      }

      let router = this.platform.getRoutingService(null, 8);
      router.calculateRoute(routingParameters, onResult,
        function (error) {
          alert(error.message);
        });
    },

    getReturnTrip(myRoute, map) {
      let returnRouter = this.platform.getRoutingService(null, 8);

      let returnRoutingParameters = {
        routingMode: 'fast',
        transportMode: 'truck',
        destination: `${this.routeToCalculate !== null ? this.routeToCalculate.origin.lat : '52.5160'},${this.routeToCalculate !== null ? this.routeToCalculate.origin.lng : '13.3779'}`,
        origin: `${this.routeToCalculate !== null ? this.routeToCalculate.destination.lat : '52.5206'},${this.routeToCalculate !== null ? this.routeToCalculate.destination.lng : '13.3862'}`,
        return: 'polyline,turnByTurnActions,actions,instructions,travelSummary',
        departureTime: `${this.routeToCalculate.returnDeparture}`
      };

      let returnTrip = (returnRoute) => {
        myRoute.returnDuration = returnRoute.routes[0].sections[0].travelSummary.duration / 60;
          if(this.route === 0){
            this.$emit('routeCalculate', myRoute)
          }

          this.clearPreviousRoutes(map)
          this.addRouteShapeToMap(myRoute, map)
      };

      returnRouter.calculateRoute(returnRoutingParameters, returnTrip,
        function (error) {
          alert(error.message);
        }
      );

    },

    onClick(evt, map) {
      var coord = map.screenToGeo(
        evt.currentPointer.viewportX,
        evt.currentPointer.viewportY
      );
      console.log(
        "Clicked at " +
          Math.abs(coord.lat.toFixed(4)) +
          (coord.lat > 0 ? "N" : "S") +
          " " +
          Math.abs(coord.lng.toFixed(4)) +
          (coord.lng > 0 ? "E" : "W")
      );
      this.coordenadas = {
        lat: coord.lat.toFixed(4),
        lng: coord.lng.toFixed(4),
      };
      //this.setMarker(this.coordenadas, map);
    },
    setMarker(coords, map) {
      console.log(coords);
      if (map.getObjects().length > 0) {
        map.removeObject(map.getObjects()[0]);
        let marker = new H.map.Marker(coords, { volatility: true });
        this.marker = marker;
        map.addObject(marker);
      } else {
        let marker = new H.map.Marker(coords, { volatility: true });
        this.marker = marker;
        map.addObject(marker);
      }

      this.getRoutes(map);
      // this.$emit('mapCoords', coords);
      return;
    },

    clearPreviousRoutes(map) {
      map.removeObjects(map.getObjects());
    },

    addRouteShapeToMap(route, map) {
      console.log('ROUTE TO ADD ==>', route)
      route.sections.forEach((section) => {
        // decode LineString from the flexible polyline
        let linestring = H.geo.LineString.fromFlexiblePolyline(this.route);
        // Create a polyline to display the route:
        let polyline = new H.map.Polyline(linestring, {
          style: {
            lineWidth: 4,
            strokeColor: 'rgba(0, 128, 255, 0.7)'
          }
        });

        // Add the polyline to the map
        map.addObject(polyline);
        // And zoom to its bounding rectangle
        map.getViewModel().setLookAtData({
          bounds: polyline.getBoundingBox()
        });
      });
    }
  },
};
</script>
  
  
<style scoped>
@import "~@here/maps-api-for-javascript/bin/mapsjs-ui.css";

.search_button {
  position: fixed;
  top: 200px;
  z-index: 999;
  left: 25px;
}

.map_parent {
  position: relative;
  /* min-height: 70vh; */
  height: 100%;
  max-height: 80vh;
  min-height: 80vh;
}

#truck_map {
  width: 100%;
  height: 100%;
  /* position: absolute; */
}

#truck_map {
  cursor: move;
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

#truck_map:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

.td_marker_label {
  /* margin-top: 80px; */
  background: rgba(255, 255, 255, 0.9);
  padding: 2px 5px 2px 5px;
  border-radius: 10px;
}

.td_dom_marker {
  text-align: center;
  touch-action: none;
  cursor: pointer;
}

.here_control_label {
  font-size: 2.5em;
}

.here_control_button>div {
  width: unset;
  padding-left: 1em;
  padding-right: 1em;
}

.td_dom_marker_image {
  user-select: none;
}

.td_dom_marker>* {
  margin-left: -50%;
  display: table;
  /* margin-top: -25%; */
  margin-top: -8%;
}

.H_ib_body {
  width: 35em;
}

.H_ib_content {
  line-height: normal;
}

.H_ib_content .content {
  /* white-space: nowrap; */
}

.height_100 {
  height: 100%;
}

.buttons__map {
  display: flex;
  justify-content: center;
}</style>