<script>
export default {
  name: "ModalMessage",
  props: {
    msg: {
      type: String
    },
    style: {
      type: Object
    }
  },
};
</script>

<template>
  <p class="modal__content-msg" :style="style">
    {{ msg }}
  </p>
</template>

<style lang="scss" scoped>
.modal {
  &__content-msg {
    padding: 1rem 1.8rem 2rem; 
    font-size: 1.05rem;
  }
}
</style>