<template>
  <div class="column">
    <div class="weight-card">
      <div class="weight-card__title">Weight {{ wbConfig.direction }} #{{ weighbridge.weighbridge }}</div>
      <!-- <div class="weight-card__content">{{ getCurrentWeight(wb) }}</div> -->
      <div class="weight-card__content">{{ weighbridge.weighbridge.includes('simulator') ? simulatedWeight : weightInShownUOM }}</div>
      <Button class="p-button btn btn--secondary" :label="$t('GET WEIGHT')" @click="setWeight(wbConfig)" />
    </div>
  </div>
</template>

<script>
import weighbridgesService from "@/services/api/weighbridges.js";
import { transformToUnit } from '@/helper/weightUnit';
import Button from 'primevue/button';
import * as Socket from '@/plugins/socket_client';
export default {
  name: "Weighbridge",
  components: {
    Button
  },
  props: {
    weighbridge: Object
  },
  data: function () {
    return {
      wbConfig: {},
      shownWeightsUom: 'TON',
      simulatedWeight: 0
    }
  },
  created() {
    //console.log("Wb para configurar ", this.weighbridge)
    weighbridgesService.getWeighbridgeById(this.weighbridge.id).then(response => {
      //console.log("Response ==> ", response.data)
      this.wbConfig = response.data
      if(this.weighbridge.weighbridge.includes('simulator')) {
        this.simulatedWeight = this.getSimulatedWeight();
        setInterval(() => {
          this.simulatedWeight = this.getSimulatedWeight();
        }, 1500);
      } else {
        Socket.add("weight", this.wbConfig.weighbridge) 
      }
    })
  },
  methods: {
    setWeight(wb) {
      this.$emit('setWeight', { wb: wb, weight: this.weighbridge.weighbridge.includes('simulator') ? this.simulatedWeight : this.weight, uom: this.weighbridge.unit_measure })

    },
    getSimulatedWeight() {
      const newWeight = this.weighbridge.weighbridge.startsWith('simulator_in') ? this.getWeightInSimulate() : this.getWeightOutSimulate();
      return transformToUnit(newWeight, this.weighbridge.unit_measure, this.shownWeightsUom).toFixed(2);
    },
    getWeightInSimulate() {
      let min = 5;
      let max = 15;
      return Math.floor(Math.random() * (max - min) + min);
    },
    getWeightOutSimulate() {
      let min = 25;
      let max = 45;
      return Math.floor(Math.random() * (max - min) + min);
    }   
  },
  computed: {
    console: () => console,
    weight() {
      return this.$store.getters.getWiegths.find(w => w.bascula == this.wbConfig.weighbridge) != null
        ? this.$store.getters.getWiegths.find(w => w.bascula == this.wbConfig.weighbridge).weight
        : 0
    },
    weightInShownUOM() {
      return transformToUnit(this.weight, this.weighbridge.unit_measure, this.shownWeightsUom).toFixed(2);
    }
  }

}
</script>

<style lang="scss" scoped>
@import '@/assets/css/hodim/index.scss';
@import '@/assets/css/lh-ds/core/functions';


.modal-delivery {
  $bg-color: #F6F6F6;
  //width: 1200px;

  &__summary,
  lh-collapse::part(header),
  lh-collapse::part(body) {
    padding-left: var(--padding-h);
    padding-right: var(--padding-h);
    padding: 15px;
  }

  &__summary {
    padding-top: rem(14px);
    padding-bottom: rem(38px);
    background-color: $bg-color;
    padding: 15px;

    &-actions {
      display: flex;
      justify-content: space-between;

      margin-bottom: rem(42px);

      &-left,
      &-right {
        *+* {
          margin-left: rem(16px);
        }
      }

      &-left {
        lh-button {
          display: inline-block;
        }
      }
    }
  }

  .alert__text {
    color: red;
    margin-bottom: 10px;
  }

  .label__input {
    width: 60px;
    margin-top: 12px;
  }

  lh-collapse {
    border-top: 2px solid #EEEEEE;

    &::part(header) {
      box-sizing: border-box;
      height: rem(56px);
      background-color: $bg-color;
      font-size: rem(14px);
    }
  }

  .form-control {
    font-size: rem(14px);

    &__input {}
  }

  .control {
    &__label {
      display: flex;
      align-items: center;
      font-weight: bold;
      width: 60px;
    }

    &.is-horizontal {
      display: flex;

      .control {
        &__input {
          display: flex;
          margin-left: rem(20px);
          font-size: 0.875rem;

          *+* {
            margin-left: rem(20px);
          }

        }
      }
    }
  }
}

.weight-card {
  display: flex;
  flex-direction: column;
  padding: 0 rem(16px) rem(16px);
  min-height: rem(180px);
  border-radius: 4px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);

  &__title {
    padding: rem(4px) 0 rem(7px);
    border-bottom: 1px solid #EEE;
    font-size: rem(14px);
    line-height: rem(24px);
    font-weight: bold;
  }

  &__content {
    flex: 1;
    display: flex;
    align-items: center;
    font-size: rem(24px);
  }
}

.vs__search {
  height: 3rem;
  border: 1px solid black;
  padding-left: 2rem;
  outline: none;
  width: 4000px;
  display: flex;
}

.vs__actions {
  display: none;
  align-items: center;
  padding: 4px 6px 0 3px;
}

.vs__dropdown-menu {
  position: absolute;
  overflow: auto;
  z-index: 999;
  // display: none;
  width: 100%;
  border: 1px solid;
  margin-top: 0.25rem;
  background-color: white;
  padding-left: 0px;
  max-height: 250px;
}

.vs__dropdown-option {
  display: flex;
  justify-content: space-between;
  font-size: rem(16px);
  font-weight: bold;
  border-top: 1px solid;
}

.seeker-item {
  padding: 0.625rem 1rem;

  &__name {
    display: flex;
    justify-content: space-between;
    font-size: rem(16px);
    font-weight: bold;

    span {
      font-size: rem(14px);
      font-weight: normal;
    }
  }

  &__desc {
    color: rgba(black, .5);
  }
}

.vs__selected {
  display: none;
}

.collapse__summary {
  margin-left: 10px;
}
</style>