import { createRouter, createWebHistory } from "vue-router";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/login",
      name: "login",
      component: () => import("../views/Login.vue")
      //redirect: "/new-order",
    },
    {
      path: "/",
      name: "home",
      component:  () => import("../components/common/FullscreenComponent.vue"),
      // redirect: "/login",
    },
    {
      path: "/pending-to-sign",
      name: "Pending to Sign",
      component:  () => import("../components/PendingToSignApp/PendingToSignApp.vue"),
    },
    {
      path: "/shovel-app",
      name: "Shovel app",
      component:  () => import("../components/ShovelApp/ShovelApp.vue"),
    },
    {
      path: "/select-plant-app",
      name: "Select plant and app",
      component:  () => import("../views/SelectPlant&App.vue"),
    },
    {
      path: "/fullscreen-view",
      name: "Fullscreen view",
      component:  () => import("../components/common/FullscreenComponent.vue"),
    },
    {
      path: "/deliveries",
      name: "Delivery List",
      component: () => import("../components/Deliveries/Deliveries2View.vue"),
      beforeEnter: (to, from, next) => {
        let user = JSON.parse(sessionStorage.getItem("user"));
        let permission = user.menu;

        const plantPermission = permission.deliveries.find(m => m.name_menu === 'Delivery');
        if(plantPermission.mode.includes('R')){
          next()
        }else{
          next('/')
        }

      }
    },
    {
      path: "/dispatch",
      name: "Dispatch",
      component: ()=> import("../components/dispatch/DispatchView.vue")
    }
  ],
});

router.beforeEach((to, from, next) => {
  let user = sessionStorage.getItem("user");

  var isAuthenticated = false;
  try {
    isAuthenticated = (user !== undefined && user !== null);
  } catch (e) {
    console.log(e)
  }

  if (to.path != '/ConfigFile' && to.path != '/login') {
    if (!isAuthenticated) {
      next('/login')
    } else {
      next();
    }
  } else { 
    next(); 
  }
});
export default router;