export default {
    namespaced: true,
    state: {
      orderType: 'Outbound',
      isWaste: false,
      incoterm: 'DDP',
      delivery: null,
      tare: null,
      tareExpired: false,
      loadingLocations: [],
      attachmentsToUpload: [],
      attachmentsToDelete: [],
      notes: [],
      declaredNetWeight: null,
      entryWeight: {
        date: null,
        weight: null,
        weightBridge: null,
        manual: false,
        user: {
          userID: null,
          userName: null
        }
      },
      exitWeight: {
        date: null,
        weight: null,
        weightBridge: null,
        manual: false,
        user: {
          userID: null,
          userName: null
        }
      },
      transferInWeight: {
        date: null,
        weight: null,
        weightBridge: null,
        manual: false,
        user: {
          userID: null,
          userName: null
        }
      },
      exitWeightNeeded: null,
      netWeight: null,
      currentTruck: null,
      currentTrailer: null,
      dispatchAction: false,
      toTareVehicle: true,
      oneTimeTare: false,
      isPreloaded: false
    },
    getters:{
      getOrderType(state) {
          return state.orderType
      },
      getIncoterm(state) {
        return state.incoterm
      },
      getDelivery(state) {
        return state.delivery
      },
      getTare(state) {
        return state.tare;
      },
      getTareExpired(state) {
        return state.tareExpired;
      },
      getLoadingLocations(state) {
        return state.loadingLocations;
      },
      getAttachmentsToUpload(state) {
        return state.attachmentsToUpload;
      },
      getNotes(state) {
        return state.notes;
      },
      getEntryWeight(state) {
        return state.entryWeight;
      },
      getExitWeight(state) {
        return state.exitWeight;
      },
      getNetWeight(state) {
        return state.netWeight;
      },
      getDeclaredNetWeight(state) {
        return state.declaredNetWeight;
      },
      getCurrentTruck(state) {
        return state.currentTruck;
      },
      getCurrentTrailer(state) {
        return state.currentTrailer;
      },
      getExitWeightNeeded(state) {
        return state.exitWeightNeeded;
      },
      getToTareVehicle(state) {
        return state.toTareVehicle;
      },
      getOneTimeTare(state) {
        return state.oneTimeTare;
      }
    },
    mutations: {
      setIsPreloaded(state, type){
        state.isPreloaded = type
      },
      setOrderType(state, type) {
        state.orderType = type;
      },
      setIncoterm(state, incoterm) {
        state.incoterm = incoterm
      },
      setDelivery(state, delivery) {
        //if(delivery != null)
        state.delivery = delivery;
      },
      setTare(state, tare) {
        state.tare = tare;
      },
      setTareExpired(state, tareExpired) {
        state.tareExpired = tareExpired;
      },
      setLoadingLocations(state, loadingLocations) {
        state.loadingLocations = loadingLocations;
      },
      setAttachmentsToUpload(state, attachments) {
        state.attachmentsToUpload = attachments;
      },
      setAttachmentsToDelete(state, attachments) {
        state.attachmentsToDelete = attachments;
      },
      setNotes(state, notes) {
        state.notes = notes;
      },
      setToTareVehicle(state, value) {
        state.toTareVehicle = value
      },
      addAttachmentToDelete(state, attachment) {
        state.attachmentsToDelete = [...state.attachmentsToDelete, attachment];
      },
      setEntryWeight(state, weight) {
        state.entryWeight = weight;
      },
      setIsWaste(state, value) {
        state.isWaste = value
      },
      resetEntryWeight(state) {
        state.entryWeight.date = null;
        state.entryWeight.weight = null;
        state.entryWeight.weightBridge = null;
        state.entryWeight.manual = null;
        state.entryWeight.user.userID = null;
        state.entryWeight.user.userName = null;
      },
      setTransferInEntryWeight(state, weight) {
        state.transferInWeight = weight;
      },
      resetTransferInEntryWeight(state) {
        state.transferInWeight.date = null;
        state.transferInWeight.weight = null;
        state.transferInWeight.weightBridge = null;
        state.transferInWeight.manual = null;
        state.transferInWeight.user.userID = null;
        state.transferInWeight.user.userName = null;
      },
      setExitWeight(state, weight) {
        state.exitWeight = weight;
      },
      resetExitWeight(state) {
        state.exitWeight.date = null;
        state.exitWeight.weight = null;
        state.exitWeight.weightBridge = null;
        state.exitWeight.manual = null;
        state.exitWeight.user.userID = null;
        state.exitWeight.user.userName = null;
      },
      setNetWeight(state, netWeight) {
        state.netWeight = netWeight;
      },
      setCashSalePrice(state, price) {
        state.cashSalePrice = price;
      },
      setDeliveryCashSalePrices(state, data) {
        state.deliveryCashSalePrices = {
          currency: data.currency || "EUR",
          itemNumber: data.itemNumber,
          material: data.material,
          pricing: data.pricing
        }
      },
      setPaymentInfo(state, payment) {
        state.paymentInfo = payment;
      },
      resetNetWeight(state) {
        state.netWeight = null;
      },
      setDeclaredNetWeight(state, netWeight) {
        state.declaredNetWeight = netWeight;
      },
      resetDeclaredNetWeight(state) {
        state.declaredNetWeight = null;
      },
      setCurrentTruck(state, truck) {
        state.currentTruck = truck;
      },
      setCurrentTrailer(state, trailer) {
        state.currentTrailer = trailer;
      },
      setExitWeightNeeded(state, value) {
        state.exitWeightNeeded = value;
      },
      setDispatchAction (state) {
        state.dispatchAction = !state.dispatchAction
      },
      setOneTimeTare (state, value) {
        state.oneTimeTare = value
      },
      resetValues (state) {
          state.orderType = 'Outbound';
          state.isWaste = false,
          state.incoterm = 'DDP';
          state.delivery = null;
          state.tare = null;
          state.tareExpired = false;
          state.loadingLocations = [];
          state.attachmentsToUpload = [];
          state.attachmentsToDelete = [];
          state.notes = [];
          state.declaredNetWeight = null;
          state.entryWeight = {
            date: null,
            weight: null,
            weightBridge: null,
            manual: false,
            user: {
              userID: null,
              userName: null
            }
          };
          state.exitWeight = {
            date: null,
            weight: null,
            weightBridge: null,
            manual: false,
            user: {
              userID: null,
              userName: null
            }
          };
          state.transferInWeight = {
            date: null,
            weight: null,
            weightBridge: null,
            manual: false,
            user: {
              userID: null,
              userName: null
            }
          };
          state.exitWeightNeeded = null;
          state.netWeight = null;
          state.currentTruck = null;
          state.currentTrailer = null;
          state.dispatchAction = false;
          state.toTareVehicle = true;
          state.oneTimeTare = false;
      }
    },
    actions: {
    }
};