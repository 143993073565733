'use strict';
import { app } from '@/main.js'

const OrderService = {
    saveOrder(order) {
        if(Array.isArray(order.shipto.address)){ order.shipto.address = order.shipto.address[0] }
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post('/order/createOrder', order)
                .then(response => {
                    return resolve(response)
                }).catch(error => {
                    reject({
                        ret: error.response.status
                        , msg: error.response.data
                    });
                });
        })
    },
    saveOrderTransfer(order) {
        //let plant = sessionStorage.getItem('user').plant
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post('/order/createOrderTransfer', order)
                .then(response => {
                    return resolve(response)
                }).catch(error => {
                    reject({
                        ret: error.response.status
                        , msg: error.response.data
                    });
                });
        })
    },
    saveOrderLocal(order) {
        //let plant = sessionStorage.getItem('user').plant
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post('/order/createOrderLocal', order)
                .then(response => {
                    return resolve(response)
                }).catch(error => {
                    reject({
                        ret: error.response.status
                        , msg: error.response.data
                    });
                });
        })
    },
    saveOrderDraf(order) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post('/order/createOrderDraft', order)
                .then(response => {
                    return resolve(response)
                }).catch(error => {
                    reject({
                        ret: error.response.status
                        , msg: error.response.data
                    });
                });
        })
    },
    saveOrderBlocked(order) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post('/order/createOrderBlocked', order)
                .then(response => {
                    return resolve(response)
                }).catch(error => {
                    reject({
                        ret: error.response.status
                        , msg: error.response.data
                    });
                });
        })
    },
    updateOrder(order) {
        console.log("LLEGO AL UPDATE ==> ", order)
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post('/order/updateOrder', order)
                .then(response => {
                    return resolve(response)
                }).catch(error => {
                    reject({
                        ret: error.response.status
                        , msg: error.response.data
                    });
                });
        })
    },
    getOrders(user) {
        return new Promise((resolve, reject) => {
            console.log("app.config.globalProperties.$axios", app.config.globalProperties.$axios)
            app.config.globalProperties.$axios.post('/order/searchOrders', user)
                .then(response => {
                    return resolve(response)
                }).catch(error => {
                    reject({
                        ret: error.response.status
                        , msg: error.response.data
                    });
                });
        })
    },
    getTransfers(user) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post('/order/searchTransfers', user)
                .then(response => {
                    return resolve(response)
                }).catch(error => {
                    reject({
                        ret: error.response.status
                        , msg: error.response.data
                    });
                });
        })
    },
    getTransfersFiltered(filters) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post('/order/filterTransfers', filters)
                .then(response => {
                    return resolve(response)
                }).catch(error => {
                    reject({
                        ret: error.response.status
                        , msg: error.response.data
                    });
                });
        })
    },
    getOrdersFiltered(filters) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post('/order/filterOrders', filters)
                .then(response => {
                    return resolve(response)
                }).catch(error => {
                    reject({
                        ret: error.response.status
                        , msg: error.response.data
                    });
                });
        })
    },
    getOrder(id) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.get(`/order/searchOrder/${id}`)
                .then(response => {
                    return resolve(response)
                }).catch(error => {
                    reject({
                        ret: error.response.status
                        , msg: error.response.data
                    });
                });
        })
    },
    searchOrderByNumber(searchTerm) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post('/order/searchOrderByNumber', searchTerm)
                .then(response => {
                    return resolve(response)
                }).catch(error => {
                    reject({
                        ret: error.response.status
                        , msg: error.response.data
                    });
                });
        })
    },
    releaseProvisionalOrder(data) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post('/order/releaseProvisionalOrder', data)
                .then(response => {
                    return resolve(response)
                }).catch(error => {
                    reject({
                        ret: error.response.status
                        , msg: error.response.data
                    });
                });
        })
    },
    cancelProvisionalOrder(data) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post('/order/cancelProvisionalOrder', data)
                .then(response => {
                    return resolve(response)
                }).catch(error => {
                    reject({
                        ret: error.response.status
                        , msg: error.response.data
                    });
                });
        })
    },
    cancelRequestedOrder(data) {
      return new Promise((resolve, reject) => {
          app.config.globalProperties.$axios.post('/order/cancelRequestedOrder', data)
              .then(response => {
                  return resolve(response)
              }).catch(error => {
                  reject({
                      ret: error.response.status
                      , msg: error.response.data
                  });
              });
      })
  },

    searchOrderByContract(searchTerm) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post('/order/searchOrderByContract', searchTerm)
                .then(response => {
                    return resolve(response)
                }).catch(error => {
                    reject({
                        ret: error.response.status
                        , msg: error.response.data
                    });
                });
        })
    },
    getTotalOrders(user, status) {
        return new Promise((resolve, reject) => {
            const userToSend = {
                id_user: user.id,
                id_country: user.id_country,
                country: user.country,
                plant: user.plant,
                
            }
            app.config.globalProperties.$axios.post('/order/getTotalOrders', { user: userToSend, status: status })
                .then(response => {
                    return resolve(response)
                }).catch(error => {
                    reject({
                        ret: error.response.status
                        , msg: error.response.data
                    });
                });
        })
    },
    getTotalRejectedRequests(plant) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.get(`/order/getTotalRejectedRequests/${plant}`)
                .then(response => {
                    return resolve(response)
                }).catch(error => {
                    reject(error);
                });
        })
    },
    getRejectedRequests(plant) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.get(`/order/getRejectedRequests/${plant}`)
                .then(response => {
                    return resolve(response)
                }).catch(error => {
                    reject(error);
                });
        })
    },
    getOrderByON(oN) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.get(`/order/getOrderByON/${oN}`)
                .then(response => {
                    return resolve(response)
                }).catch(error => {
                    reject({
                        ret: error.response.status
                        , msg: error.response.data
                    });
                });
        })
    },
    updateOrderStatus(orderUpdate) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post('/order/updateOrderStatus', orderUpdate)
                .then(response => {
                    return resolve(response)
                }).catch(error => {
                    reject({
                        ret: error.response.status
                        , msg: error.response.data
                    });
                });
        })
    },
    completeOrder(order) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post('/order/completeOrder', order)
                .then(response => {
                    return resolve(response)
                }).catch(error => {
                    reject({
                        ret: error.response.status
                        , msg: error.response.data
                    });
                });
        })
    },
    savePurchaseOrder(order) {
        //let plant = sessionStorage.getItem('user').plant
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post('/purchaseorder/createOrder', order)
                .then(response => {
                    return resolve(response)
                }).catch(error => {
                    reject({
                        ret: error.response.status
                        , msg: error.response.data
                    });
                });
        })
    },
    savePurchaseOrderDraf(order) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post('/purchaseorder/createOrderDraft', order)
                .then(response => {
                    return resolve(response)
                }).catch(error => {
                    reject({
                        ret: error.response.status
                        , msg: error.response.data
                    });
                });
        })
    },
    savePurchaseOrderBlocked(order) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post('/purchaseorder/createOrderBlocked', order)
                .then(response => {
                    return resolve(response)
                }).catch(error => {
                    reject({
                        ret: error.response.status
                        , msg: error.response.data
                    });
                });
        })
    },
    updatePurchaseOrder(order) {
        console.log("LLEGO AL UPDATE ==> ", order)
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post('/purchaseorder/updateOrder', order)
                .then(response => {
                    return resolve(response)
                }).catch(error => {
                    reject({
                        ret: error.response.status
                        , msg: error.response.data
                    });
                });
        })
    },
    checkDuplicateOrder(contract, deliveryDate, Materials){
        let data = {
            contract,
            deliveryDate,
            Materials
        }
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post("/order/duplicateOrders", data).then((response) => {
                return resolve(response);
            })
            .catch((error) => { reject({ ret: error.response.status, msg: error.response.data }); });
        });
    },
    checkSoldtoShiptoDuplicatedOrder(soldto, shipto, deliveryDate){
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post("/order/duplicateOrdersSoldtoShipto", { soldto, shipto, deliveryDate }).then((response) => {
                return resolve(response);
            })
            .catch((error) => { reject({ ret: error.response.status, msg: error.response.data }); });
        });
    },
    updateOrderAttachments(order) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.put("/order/updateOrderAttachments", order).then((response) => {
                return resolve(response);
            })
            .catch((error) => { reject({ ret: error.response.status, msg: error.response.data }); });
        });
    },
    checkContract(data) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post('/order/checkContract', data)
                .then(response => {
                    return resolve(response)
                }).catch(error => {
                    reject({
                        ret: error.response.status
                        , msg: error.response.data
                    });
                });
        })
    },
}
export default OrderService;