"use strict";
import { app } from "@/main.js";

const DeliveryNoteService = {
  getDeliveryNote(delivery) {
    const bucket = delivery._source.document.s3.bucketName;
    const key = delivery._source.document.s3.fileName;
    const user = JSON.parse(sessionStorage.getItem("user"));
    var data = {
      bucket_name: bucket,
      fileName: key,
      delivery: {
        id: delivery._id,
        cnn: delivery._source.cnn,
        deliveryNoteNumber: delivery._source.deliveryNoteNumber,
      },
    };
    return new Promise((resolve, reject) => {
      app.config.globalProperties.$axios
        .post(`/integration/s3/download`, data)
        .then((response) => {
          return resolve(response);
        })
        .catch((error) => {
          reject({
            ret: error.response.status,
            msg: error.response.data,
          });
        });
    });
  },
  printDN(data) {
    return new Promise((resolve, reject) => {
      app.config.globalProperties.$axios
        .post(`/printDN`, data)
        .then((response) => {
          return resolve(response);
        })
        .catch((error) => {
          reject({
            ret: error.response.status,
            msg: error.response.data,
          });
        });
    });
  },
  createDN(data) {
    console.log("CREATE DN Data ==> ", data);
    const { delivery, user, printer } = data
      return new Promise((resolve, reject) => {
        app.config.globalProperties.$axios
          .post(`/createDN`, { delivery: delivery, user: user, printer: printer })
          .then((response) => {
            return resolve(response);
          })
          .catch((error) => {
            reject({
              ret: error.response.status,
              msg: error.response.data,
            });
          });
      });
  },
  sendDeliveryNoteParameters(data) {
    return new Promise((resolve, reject) => {
        app.config.globalProperties.$axios.post(`/masterData/updateDeliveryNoteNumber`, data)
            .then((response) => {
                return resolve(response);
             })
            .catch(error =>{ reject({ ret: error.response.status, msg: error.response.data }); });
    });
  },
};
export default DeliveryNoteService;