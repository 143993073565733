<template>
  <div v-if="!disableButtons" class="new-order__step">
    <div class="new-order__step-head" :style="{'justify-content': 'flex-start'}" v-if="!$store.state.dispatch.isPreloaded">
      <!-- <h2>{{ title }}</h2> -->
      <Dropdown v-model="orderType" :options="orderOptions" />
      <Checkbox v-if="wastePlantEnabled" class="p-checkbox-m0 waste" inputId="wasteCheckbox" v-model="isWaste" :binary="true" />
      <label v-if="wastePlantEnabled" for="wasteCheckbox" class="ml-2"> {{ $t('Is waste') }} </label>

    </div>
    <div class="new-order__step-content">
      <div class="new-order__step-content-wrapper" >
        <slot/>
      </div>
    </div>
    <div class="new-order__step-footer">
      <slot name="footer"></slot>
    </div>
  </div>

  <div v-else class="new-order__step">
    <div class="new-order__step-head" :style="{'justify-content': 'flex-start'}">
      <!-- <h2>{{ title }}</h2> -->

      <Button class="btn btn--secondary btn--large"
        :label="$t('Restart')" 
        :style="{ 'width': '10rem'}"
        @click="() => this.$emit('resetDispatch')" />
    </div>
    <div class="new-order__step-content">
      <div class="new-order__step-content-wrapper" >
        <slot/>
      </div>
    </div>
    <div class="new-order__step-footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
import Dropdown from 'primevue/dropdown';
import Checkbox from 'primevue/checkbox';
import Button from 'primevue/button';

export default {
  components: {
		Dropdown,
		Checkbox,
    Button
	},
  props: {
    disableButtons: {
      type: Boolean,
      default: false 
    }
  },
  data: function () {
      return {
          orderOptions: [
              'Outbound',
              'Inbound',
              'Transfer In',
              'Transfer Out'
          ],
          wastePlantEnabled: JSON.parse(sessionStorage.getItem('user')).plant.plant_parameters.find(p => p.code == 'waste_enabled').value == 1 ? true : false
      }
  },
  computed: {
    orderType: {
      get() {
        return this.$store.state.dispatch.orderType;
      },
      set(value) {
        this.$store.commit('dispatch/setOrderType', value);
        this.$store.commit('dispatch/setDelivery', null);
      }
    },
    isWaste: {
      get() {
        return this.$store.state.dispatch.isWaste;
      },
      set(value) {
        this.$store.commit('dispatch/setIsWaste', value);
        this.$store.commit('dispatch/setDelivery', null);
      }
    }
  }

}
</script>


<style lang="scss">
@use '@/assets/css/lh-ds/core/icon';
@use '@/assets/css/lh-ds/core/color';
@use '@/assets/css/lh-ds/core/font';
@use '@/assets/css/lh-ds/core/functions' as *;
@use '@/assets/css/hodim/core/mixin';


.new-order__step {
  position: relative;
  padding: rem(36px) rem(40px);
  background-color: white;

  // step head
  &-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // min-height: rem(37px); // icon button height

    
    .p-checkbox-m0.waste {
      margin-left: 2rem;
    }

    h2 {
      font-size: rem(24px);
    }

    &-action {
      position: absolute;
      top: rem(22px);
      right: rem(20px);
      display: flex;
      align-items: center;

      & > * + * {
        margin-left: rem(8px);
      }
    }
  }

  // step content
  &-content {
    $grid-item-min-size: rem(220px);

    // max-width: rem(535px);
    margin-top: rem(16px);
  }

  &-footer {
    margin-top: rem(35px);

    &:empty {
      display: none;
    }

    * + * {
      margin-left: rem(32px);
    }

    lh-button {
      display: inline-block;
    }
  }
}
</style>
