<script setup>
import VehicleService from "@/services/api/vehicles.js";
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import CreateVehicle from './CreateVehicleModal.vue';
</script>

<script>
export default {
  name: "TrailerList",
  props: {
    carrier: ''
  },
  components: {
    Button,
    InputText,
    Column,
    DataTable,
    Dialog,
    CreateVehicle
  },
  data: function () {
    return {
      Trailers: [],
      TrailersAux: [],
      trailer: null,
      loading: true,
      displayModalcreateVehicle: false,
      vehicleTypes: null,
      userCountryCode: JSON.parse(sessionStorage.getItem('user')).country.code
    }
  },
  mounted() {
    this.getVehicleTypes()
    this.getTrailers()
  },
  methods: {
    async getVehicleTypes() {
      try {
        let response = await VehicleService.getVehicleTypesOpts(this.userCountryCode)
        this.vehicleTypes = response.data.map((element) => {
          return {
            name: element.description,
            code: element.vehicleType
          }
        })
      } catch (err) { console.error('ERROR GETTING VEHICLE TYPES -->', err) }
    },
    async getTrailers() {
      let country = JSON.parse(sessionStorage.getItem("user")).country.code
      let search = {
        filters: {
          country,
          fleetCategory: ['PM207029'],
          haulierName: [this.carrier],
        }
      }
      try {
        let response = await VehicleService.getVehicles(search)
        this.Trailers = response.data
        this.TrailersAux = response.data
        this.loading = false;
      } catch (err) {
        console.log('Error getting trailers:', err)
      }
    },
    selectTrailer(e) {
      this.trailer = e.data ? e.data : e;
      this.$emit('checkTrailer', this.trailer)
    },
    searchTrailers(e) {
      let country = JSON.parse(sessionStorage.getItem("user")).country.code
      let searchTerm = e.target.value
      if (searchTerm != null && searchTerm.length >= 3) {
        let term = {
          fleetCategory: ['PM207029'],
          searchTerm: searchTerm,
          haulierName: this.carrier,
          country
        }
        VehicleService.searchVehicle(term).then(response => {
          // console.log('response: ', response)
          this.Trailers = response.data
          this.TrailersAux = response.data
        }).catch(err => {
          console.log(err)
        })
      }
      if (searchTerm.length === 0) {
        this.Trailers = this.getTrailers();
      }
    },
    closeCreateVehicleModal(trailer) {
      if (trailer !== null) { this.selectTrailer(trailer) }
      else { this.getVehicles() }
      this.displayModalcreateVehicle = false
    },
    showVehicleType(typeCode) {
      const item = this.vehicleTypes.find(e => e.code === typeCode)
      return item ? item.name : ''
    }
  }
}
</script>

<template>
  <div class="app-content">
    <div class="app-content__content cl-content">
      <div class="row">
        <div class="cl-search" style="width: 100%;">
          <span class="p-input-icon-left">
            <i class="pi pi-search" />
            <InputText type="text" :placeholder="$t('Search')" @input="searchTrailers" />
          </span>
          <Button class="p-button-secondary create-button" :label="$t('New Vehicle')" style="margin-left: 20px; padding: 10px;"
            @click="displayModalcreateVehicle = true"></Button>
        </div>
        <!-- <Button class="p-button-secondary create-button" label="New Vehicle" @click="newVehicle" /> -->
        <div class="cl-results">
          <div class="cl-results__wrapper">
            <DataTable :value="Trailers" scrollDirection="horizontal" :loading="loading" :paginator="true" :rows="7"
              paginatorTemplate="CurrentPageReport PrevPageLink PageLinks NextPageLink" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}">
              <Column field="licensePlateNumber" :header="$t('License Plate Number')" :sortable="true">
              </Column>
              <Column field="vehicleId" :header="$t('Vehicle Id')" :sortable="true">
              </Column>
              <Column field="carrierName" :header="$t('Haulier')" :sortable="true">
              </Column>
              <Column field="details.category.vehicleType" :header="$t('Vehicle Type')" :sortable="true">
                <template #body="slotProps">
                  {{ showVehicleType(slotProps.data.details.category.vehicleType) }}
                </template>
              </Column>
              <Column field="capacity" :header="$t('Truck Capacity')" :sortable="true">
              </Column>
              <Column :style="{ width: '10%' }" class="data-list__column-button">
                <template #body="slotProps">
                  <Button class="p-button btn btn--primary" label="SELECT" @click="selectTrailer(slotProps)" />
                </template>
              </Column>
            </DataTable>
          </div>
        </div>
      </div>
    </div>
    <!-- <lh-dialog position="center" title="Vehicle" modal ref="modalNewVehicle">
      <div slot="action" class="modal_footer-buttons">
        <lh-button @click="close">{{ $t('Close') }}</lh-button>
        <lh-button type="primary" @click="saveCircuit"> {{ $t('Save') }} </lh-button>
      </div>
    </lh-dialog> -->
    <Dialog :style="{ width: '40vw' }" :breakpoints="{ '960px': '75vw', '640px': '90vw' }" v-model:visible="displayModalcreateVehicle" closable :draggable="false"
      :dismissable-mask="true" :header="$t('New Vehicle')">
      <CreateVehicle @close="closeCreateVehicleModal" />
    </Dialog>
  </div>
</template>

<style lang="scss" scoped>
@use '@/assets/css/lh-ds/core/functions' as *;
@use '@/assets/css/hodim/layout/data-list';


.cl {
  $border-color: #D8D8D8;

  &-content {
    .row {
      display: flex;
      flex-direction: column;
    }
  }

  &-search {
    width: 30%;
    margin-top: 1.5rem;
  }

  &-results {
    flex: 1;

    margin-top: rem(38px);
    border: 1px solid $border-color;
    background-color: white;

    &__wrapper {
      height: 100%;
      overflow: auto;
    }
  }

  &-result {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: rem(12px) rem(20px);

    cursor: pointer;

    +.cl-result {
      border-top: 1px solid $border-color;
    }

    &__name {
      font-weight: bold;
      margin-bottom: rem(1px);
    }

    &__ref {
      color: rgba(black, .5);
    }

    &__right {
      display: flex;
      align-items: center;

      *+* {
        margin-left: rem(16px);
      }
    }

    &__tag {
      padding: rem(4px) rem(8px);
      color: #FF2000;
      border: 1px solid;
      border-radius: 2px;
      font-size: rem(12px);
      line-height: rem(20px);
    }
  }

}

.modal_footer-buttons {
  display: flex;
  align-items: flex-start;
  width: 50%;
  padding: 15px;

  lh-button {
    margin-right: 5px;
  }
}
</style>
