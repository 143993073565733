'use strict';
import { app } from '@/main.js'
import commonClasses from "@/store/commonClasses"

const DeliveryService = {
    saveDivert(data){
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post('/delivery/saveDivert', data)
                .then(response => {
                    return resolve(response)
                }).catch(error => {
                    reject({
                        ret: error.response.status,
                        msg: error.response.data
                    });
                });
        })
    },
    getDivertDeliveries(materials){
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post('/delivery/getPosibleDeliveries', materials)
                .then(response => {
                    return resolve(response)
                }).catch(error => {
                    reject({
                        ret: error.response.status,
                        msg: error.response.data
                    });
                });
        })
    },
    saveRetroSequence(retroSequence) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post('/delivery/saveRetroSequence', retroSequence)
                .then(response => {
                    return resolve(response)
                }).catch(error => {
                    reject({
                        ret: error.response.status,
                        msg: error.response.data
                    });
                });
        })
    },
    saveRetroSequenceChanges(changes) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post('/delivery/saveRetroSequenceChanges', changes)
                .then(response => {
                    return resolve(response)
                }).catch(error => {
                    reject({
                        ret: error.response.status,
                        msg: error.response.data
                    });
                });
        })
    },
    async updateRetroSequence(retroSequence){
        const res = await app.config.globalProperties.$axios.post('/delivery/updateRetroSequence', retroSequence)

        return res.data
    },
    getRetroSequence(plant) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.get('/delivery/getRetroSequence/' + plant)
                .then(response => {
                    return resolve(response)
                }).catch(error => {
                    reject({
                        ret: error.response.status,
                        msg: error.response.data
                    });
                });
        })
    },
    getTour(shipto, plantCode, date) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post('/delivery/getTour', { shipto: shipto, plant: plantCode, date: date })
                .then(response => {
                    return resolve(response)
                }).catch(error => {
                    reject({
                        ret: error.response.status
                        , msg: error.response.data
                    });
                });
        })
    },
    getTotalDeliveries(user, status) {
        return new Promise((resolve, reject) => {
            const userToSend = {
                id_user: user.id,
                id_country: user.id_country,
                country: user.country,
                plant: user.plant,

            }
            app.config.globalProperties.$axios.post('/delivery/getTotalDeliveries', { user: userToSend, status: status })
                .then(response => {
                    return resolve(response)
                }).catch(error => {
                    reject({
                        ret: error.response.status
                        , msg: error.response.data
                    });
                });
        })
    },
    saveDelivery(delivery) {
        return new Promise((resolve, reject) => {
            console.log("llego aqui ==> ", delivery)
            app.config.globalProperties.$axios.post('/delivery/createDelivery', delivery)
                .then(response => {
                    return resolve(response)
                }).catch(error => {
                    reject({
                        ret: error.response.status
                        , msg: error.response.data
                    });
                });
        })
    },
    getDeliveries(plant) {
        return new Promise((resolve, reject) => {
            // delete user.countries
            // delete user.plants
            // delete user.menu
            let userPlant = {
                plant: plant
            }
            app.config.globalProperties.$axios.post('/delivery/searchDeliveries', userPlant)
                .then(response => {
                    return resolve(response)
                }).catch(error => {
                    reject({
                        ret: error.response.status
                        , msg: error.response.data
                    });
                });
        })
    },
    updateSAPDeliveryNote(sapDeliveryNote, deliveryId) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post('/delivery/updateSAPDeliveryNote', { sapDeliveryNote, deliveryId })
                .then(response => { return resolve(response) })
                .catch(error => { reject({ ret: error.response.status, msg: error.response.data }) })
        })
    },
    getDeliveriesById(ids) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post('/delivery/searchDeliveriesById', ids)
                .then(response => {
                    return resolve(response.data)
                }).catch(error => {
                    reject({
                        ret: error.response.status
                        , msg: error.response.data
                    });
                });
        })
    },
    getDeliveriesLogisticsById(ids) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post('/delivery/searchDeliveriesLogisticsById', ids)
                .then(response => {
                    return resolve(response.data)
                }).catch(error => {
                    reject({
                        ret: error.response.status
                        , msg: error.response.data
                    });
                });
        })
    },
    getDeliveryByExternalId(id) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.get('/delivery/getDeliveryByExternalId/' + id).then(response => {
                return resolve(response.data);
            }).catch(error => {
                reject({ ret: error.response.status, msg: error.response.data });
            })
        })
    },
    getPendingDeliveries(user) {
        return new Promise((resolve, reject) => {
            let userPlant = {
                plant: user.plant
            }
            app.config.globalProperties.$axios.post('/delivery/searchPendingDeliveries', userPlant)
                .then(response => {
                    return resolve(response)
                }).catch(error => {
                    reject({
                        ret: error.response.status
                        , msg: error.response.data
                    });
                });
        })
    },
    isOrderLineAssigned(orderNo, materialId, orderItem) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post('/delivery/isOrderLineAssigned', { orderNo: orderNo, materialId: materialId, orderItem: orderItem })
                .then(response => {
                    return resolve(response)
                }).catch(error => {
                    reject({
                        ret: error.response.status
                        , msg: error.response.data
                    });
                });
        })
    },
    signDelivery(deliverySign, user) {
        let userToken = {
            token: user.token
        }
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post('/delivery/signDelivery', { deliverySign: deliverySign, user: userToken })
                .then(response => {
                    return resolve(response)
                }).catch(error => {
                    reject({
                        ret: error.response.status
                        , msg: error.response.data
                    });
                });
        })
    },
    cancelDelivery(cancelReason, cancelReasonText, cancelDescription, deliveryId, user) {
        let userData = {
            name: user.fullname,
            id: user.id_user
        }
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post('/delivery/cancelDelivery',
                {
                    cancelReason: cancelReason,
                    cancelReasonText: cancelReasonText,
                    cancelDescription: cancelDescription,
                    deliveryId: deliveryId,
                    user: userData
                })
                .then(response => {
                    return resolve(response)
                }).catch(error => {
                    reject({
                        ret: error.response.status
                        , msg: error.response.data
                    });
                });
        })
    },
    markAsSignedDelivery(deliveryId, status = undefined) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post('/delivery/markAsSignedDelivery', { deliveryId: deliveryId, deliveryStatus: status })
                .then(response => {
                    return resolve(response)
                }).catch(error => {
                    reject({
                        ret: error.response.status
                        , msg: error.response.data
                    });
                });
        })
    },
    retroTicket(deliveryId, status = undefined) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post('/delivery/retroTicket', { deliveryId: deliveryId, deliveryStatus: status })
                .then(response => {
                    return resolve(response)
                }).catch(error => {
                    reject({
                        ret: error.response.status
                        , msg: error.response.data
                    });
                });
        })
    },
    markAsPaperTicketDelivery(deliveryId) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post('/delivery/markAsPaperTicketDelivery', { deliveryId })
                .then(response => {
                    return resolve(response)
                }).catch(error => {
                    reject({
                        ret: error.response.status
                        , msg: error.response.data
                    });
                });
        })
    },
    getDispacthedOrderLines(orderId) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post('/delivery/getDispacthedOrderLines', { orderId })
                .then(response => {
                    return resolve(response)
                }).catch(error => {
                    reject({
                        ret: error.response.status
                        , msg: error.response.data
                    });
                });
        })
    },
    removeAsPaperTicketDelivery(deliveryId) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post('/delivery/removeAsPaperTicketDelivery', { deliveryId })
                .then(response => { return resolve(response) })
                .catch(error => { reject({ ret: error.response.status, msg: error.response.data }) });
        })
    },
    getDeliveriesFiltered(filters) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post('/delivery/filterDeliveries', filters)
                .then(response => {
                    return resolve(response)
                }).catch(error => {
                    reject({
                        ret: error.response.status
                        , msg: error.response.data
                    });
                });
        })
    },
    getDeliveriesFilteredSmall(filters) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post('/delivery/filterDeliveriesSmall', filters)
                .then(response => {
                    return resolve(response)
                }).catch(error => {
                    reject({
                        ret: error.response.status
                        , msg: error.response.data
                    });
                });
        })
    },
    getDelivery(id) {
        // let microService = `/delivery/searchDelivery/${id}`
        // switch (deliveryUpdate.orderType) {
        //     case commonClasses.orderType.Outbound:
        //         microService = `/delivery/searchDelivery/${id}`
        //         break;
        //     case commonClasses.orderType.Inbound:
        //         microService = `/inbound/searchInbound/${id}`
        //         break;
        // }
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.get(`/delivery/searchDelivery/${id}`)
                .then(response => {
                    return resolve(response)
                }).catch(error => {
                    reject({
                        ret: error.response.status
                        , msg: error.response.data
                    });
                });
        })
    },
    searchDeliveryByNumber(searchTerm) {

        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post("/delivery/searchDeliveryByNumber", searchTerm)
                .then(response => {
                    return resolve(response)
                }).catch(error => {
                    reject({
                        ret: error.response.status
                        , msg: error.response.data
                    });
                });
        })
    },
    searchDeliveryByContract(searchTerm) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post("/delivery/searchDeliveryByContract", searchTerm)
                .then(response => {
                    return resolve(response)
                }).catch(error => {
                    reject({
                        ret: error.response.status
                        , msg: error.response.data
                    });
                });
        })
    },
    getOrderDeliveries(order) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.get(`/delivery/searchOrderDeliveries/${order}`)
                .then(response => {
                    return resolve(response)
                }).catch(error => {
                    reject({
                        ret: error.response.status
                        , msg: error.response.data
                    });
                });
        })
    },
    updateDelivery(deliveryUpdate, user) {
        // let userToken = {
        //     token: user.token
        // }
        let microService = '/delivery/updateDelivery'
        // switch (deliveryUpdate.orderType) {
        //     case commonClasses.orderTypes.Inbound:
        //         microService = '/delivery/updateDeliveryInbound'
        //         break;
        //     default:
        //         microService = '/delivery/updateDelivery'
        //         break;
        // }
        console.log("deliveryUpdate.orderType: ", deliveryUpdate.orderType)
        console.log("microService: ", microService)
        return new Promise((resolve, reject) => {
            // app.config.globalProperties.$axios.post(microService, { deliveryUpdate: deliveryUpdate, user: userToken })
            app.config.globalProperties.$axios.post(microService, { deliveryUpdate })
                .then(response => {
                    return resolve(response)
                }).catch(error => {
                    reject({
                        ret: error.response.status
                        , msg: error.response.data
                    });
                });
        })
    },
    async updateDeliveryRetroticket(deliveryUpdate) {
        return await app.config.globalProperties.$axios.post("/delivery/updateDeliveryRetroticket", deliveryUpdate)
    },
    editDeliveriesDetails(data) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post('/delivery/editDeliveriesDetails', data)
                .then(response => {
                    return resolve(response)
                }).catch(error => {
                    reject({
                        ret: error.response.status,
                        msg: error.response.data
                    });
                });
        })
    },
    updateDeliveryLocal(deliveryUpdate, user) {
        let userToken = {
            token: user.token
        }
        let userObj = JSON.parse(sessionStorage.getItem("user"));
        const plantData = { id_parameter: userObj.plant.plant_parameters.find((p) => p.code === "dn_start_no").id_parameter, plant: userObj.plant.id }
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post('/delivery/updateDeliveryLocal', { deliveryUpdate: deliveryUpdate, user: userToken, plantData })
                .then(response => {
                    return resolve(response)
                }).catch(error => {
                    reject({
                        ret: error.response.status
                        , msg: error.response.data
                    });
                });
        })
    },
    resetDeliveryData(data, user) {
        let userToken = {
            token: user.token
        }
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post('/delivery/resetDeliveryData', { delivery: data, user: userToken })
                .then(response => {
                    return resolve(response)
                }).catch(error => {
                    reject({
                        ret: error.response.status
                        , msg: error.response.data
                    });
                });
        })
    },
    resetDeliveryDataLocal(data, user) {
        let userToken = {
            token: user.token
        }
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post('/delivery/resetDeliveryDataLocal', { delivery: data, user: userToken })
                .then(response => {
                    return resolve(response)
                }).catch(error => {
                    reject({
                        ret: error.response.status
                        , msg: error.response.data
                    });
                });
        })
    },
    updateDeliveryStatus(deliveryUpdate) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post('/delivery/updateDeliveryStatus', deliveryUpdate)
                .then(response => {
                    return resolve(response)
                }).catch(error => {
                    reject({
                        ret: error.response.status
                        , msg: error.response.data
                    });
                });
        })
    },
    getLatestTruckWeighing(id, id_plant, id_trailer = null) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post('/delivery/getLatestTruckWeighing', { id: id, id_plant, id_trailer })
                .then(response => {
                    return resolve(response)
                }).catch(error => {
                    reject({
                        ret: error.response.status
                        , msg: error.response.data
                    });
                });
        })
    },
    checkVehicleAvailability(id) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post('/delivery/checkVehicleAvailability', { id: id })
                .then(response => {
                    return resolve(response)
                }).catch(error => {
                    reject({
                        ret: error.response.status
                        , msg: error.response.data
                    });
                });
        })
    },
    checkBlockedDelivery(delivery, user) {
        let userCheck = {
            fullname: user.fullname
        }
        let tinyDelivery = {
            _id: delivery._id,
        }
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post('/delivery/checkBlockedDelivery', { delivery: tinyDelivery, user: userCheck })
                .then(response => {
                    return resolve(response)
                }).catch(error => {
                    reject({
                        ret: error.response.status
                        , msg: error.response.data
                    });
                });
        })
    },
    blockDelivery(delivery, user) {
        let userCheck = {
            fullname: user.fullname
        }
        let tinyDelivery = {
            _id: delivery._id,
        }
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post('/delivery/blockDelivery', { delivery: tinyDelivery, user: userCheck })
                .then(response => {
                    return resolve(response)
                }).catch(error => {
                    reject({
                        ret: error.response.status
                        , msg: error.response.data
                    });
                });
        })
    },
    unblockDelivery(delivery, user) {
        let userCheck = {
            fullname: user.fullname
        }
        let tinyDelivery = {
            _id: delivery._id,
        }
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post('/delivery/unblockDelivery', { delivery: tinyDelivery, user: userCheck })
                .then(response => {
                    return resolve(response)
                }).catch(error => {
                    reject({
                        ret: error.response.status
                        , msg: error.response.data
                    });
                });
        })
    },
    unblockTruck(id) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post('/delivery/unblockTruck', { id: id })
                .then(response => {
                    return resolve(response)
                }).catch(error => {
                    reject({
                        ret: error.response.status
                        , msg: error.response.data
                    });
                });
        })
    },
    getSapInfo(delivery, user) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post('/delivery/getSapInfo', { delivery: delivery })
                .then(response => {
                    return resolve(response)
                }).catch(error => {
                    reject({
                        ret: error.response.status
                        , msg: error.response.data
                    });
                });
        })
    },
    getERPMessage(id) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.get(`/delivery/getERPMessage/${id}`)
                .then(response => {
                    return resolve(response)
                }).catch(error => {
                    reject({
                        ret: error.response.status
                        , msg: error.response.data
                    });
                });
        })
    },
    updateDeliveryDispatchStatus(deliveryId, dispatchStatus) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post(`/delivery/updateDeliveryDispatchStatus`, { deliveryId, dispatchStatus })
                .then(response => {
                    return resolve(response)
                }).catch(error => {
                    reject({
                        ret: error.response.status
                        , msg: error.response.data
                    });
                });
        })
    },
    updateDeliveryTourRoute(delivery) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.put('/delivery/updateDeliveryTour', { delivery: delivery })
                .then(response => {
                    return resolve(response)
                }).catch(error => {
                    reject({
                        ret: error.response.status
                        , msg: error.response.data
                    });
                });
        })
    },
    updateDeliveryTourDates(deliveryId, tour, hotStorageMinutes) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.put('/delivery/updateDeliveryTourDates', { id: deliveryId, tour, hotStorageMinutes })
                .then(response => {
                    return resolve(response)
                }).catch(error => {
                    reject({
                        ret: error.response.status
                        , msg: error.response.data
                    });
                });
        })
    },
    saveTourRecalculation(tourRecalculation) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post('/delivery/addTourRecalculation', { tourRecalculation: tourRecalculation })
                .then(response => {
                    return resolve(response);
                }).catch(error => {
                    reject({
                        ret: error.response.status,
                        msg: error.response.data
                    })
                });
        })
    },
    updateDeliveryShipto(delivery) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.put('/delivery/updateDeliveryShipto', { delivery: delivery })
                .then(response => {
                    return resolve(response)
                }).catch(error => {
                    reject({
                        ret: error.response.status
                        , msg: error.response.data
                    });
                });
        })
    },
    unloadDelivery(data) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post('/delivery/unloadDelivery', { deliveryId: data.deliveryId, transitId: data.transitId })
                .then(response => {
                    return resolve(response)
                }).catch(error => {
                    reject({
                        ret: error.response.status
                        , msg: error.response.data
                    });
                });
        })
    },
    updateDeliveryAttachments(delivery) {
        return new Promise((resolve, reject) => {
            let tinyDelivery = {
                _id: delivery._id,
                _source: {
                    attachments: delivery._source.attachments
                }
            }
            app.config.globalProperties.$axios.put('/delivery/updateDeliveryAttachments', { delivery: tinyDelivery })
                .then(response => {
                    return resolve(response)
                }).catch(error => {
                    reject({
                        ret: error.response.status
                        , msg: error.response.data
                    });
                });
        })
    },
    updateDeliveryNotes(delivery) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.put('/delivery/updateDeliveryNotes', delivery)
                .then(response => {
                    return resolve(response)
                }).catch(error => {
                    reject({
                        ret: error.response.status
                        , msg: error.response.data
                    });
                });
        })
    },
    updateDeliveryHardPrint(deliveryId, hardPrint) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.put('/delivery/updateDeliveryHardPrint', { deliveryId, hardPrint })
                .then(response => {
                    return resolve(response)
                }).catch(error => {
                    reject({
                        ret: error.response.status
                        , msg: error.response.data
                    });
                });
        })
    },
    updateDeliveryHardPrintLocal(deliveryId, hardPrint) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.put('/delivery/updateDeliveryHardPrintLocal', { deliveryId, hardPrint })
                .then(response => {
                    return resolve(response)
                }).catch(error => {
                    reject({
                        ret: error.response.status
                        , msg: error.response.data
                    });
                });
        })
    },
    updateTransferInDeliveryData(deliveryUpdate) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.put('/delivery/updateTransferInDeliveryData', deliveryUpdate)
                .then(response => {
                    return resolve(response)
                }).catch(error => {
                    reject({
                        ret: error.response.status
                        , msg: error.response.data
                    });
                });
        })
    },
    async checkRetroTicket(retroTicketId, plant) {
        return await app.config.globalProperties.$axios.post("/delivery/checkRetroSequence", { retroId: retroTicketId, plant: plant })
    }
}
export default DeliveryService;